AgrimarketServices.service('marketinfosUserHashtagriService', ['$rootScope', '$http', '$location', 'configurationFactory',
    function ($rootScope, $http, $location, configurationFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
			    .success(function (data) {
			        callback(data, null, null, null);
			    })
			    .error(function (data, status, headers, config) {
			        callback(data, status, headers, config);
			    });
        };

        this.GetNewsHashtagriFromWsdApi = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'MarketInfoHashtagriController/GetNewsHashtagriFromWsdApi'
            };
            SendRequest(config, callback);
        };

        this.GetCategoryInfoMarkets = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'MarketInfoHashtagriController/GetCategoryInfoMarkets'
            };
            SendRequest(config, callback);
        };

        this.GetMarketInfosCategHashtagriFromWsdApi = function (idNewsCategWSDHashtagri, idNewsCategWSDTechnically, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'MarketInfoHashtagriController/GetMarketInfosCategHashtagriFromWsdApi',
                params: {
                    idNewsCategWSDHashtagri: idNewsCategWSDHashtagri,
                    idNewsCategWSDTechnically: idNewsCategWSDTechnically
                }
            };
            SendRequest(config, callback);
        };

        this.GetFutureMarketQuotes = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'MarketInfoHashtagriController/GetFutureMarketQuotes'
            };
            SendRequest(config, callback);
        };

        this.GetCashMarketQuotes = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'MarketInfoHashtagriController/GetCashMarketQuotes'
            };
            SendRequest(config, callback);
        };

        this.GetGlobalMacro = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'MarketInfoHashtagriController/GetGlobalMacro'
            };
            SendRequest(config, callback);
        };

        this.GetWeatherNews = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'MarketInfoHashtagriController/GetWeatherNews'
            };
            SendRequest(config, callback);
        };

        this.GetUSDANews = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'MarketInfoHashtagriController/GetUSDANews'
            };
            SendRequest(config, callback);
        };

        this.GetContentInfoMarketOS = function (idTypeBlockEditable, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'MarketInfoHashtagriController/GetContentInfoMarketOS',
                params: { idTypeBlockEditable: idTypeBlockEditable }
            };
            SendRequest(config, callback);
        };

    }]);