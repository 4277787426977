AgrimarketDirectives.directive('agrimarketEditableEnum', function ($compile) {
	return {
		transclude: false,
		replace: false,
		restrict: 'EA',
		compile: function (elm, attrs) {
			var key = attrs.agrimarketEditableEnumKey;
            var label = attrs.agrimarketEditableEnumLabel;
			elm.removeAttr('agrimarket-editable-enum');
			elm.removeAttr('agrimarket-editable-enum-key');
			elm.attr('editable-content-visible', 'false');
            elm.attr('editable-content-body', label);
			elm.attr('editable-ico-visible', 'modeEdition');
			elm.attr('editable-ico-action', 'openPopEditableEnum(\'' + key + '\')');
			elm.attr('editable', '');
			var fn = $compile(elm);
			return function (scope) {
				fn(scope);
			};
		}
	};
});