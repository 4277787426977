AgrimarketServices.service('offerService', ['$rootScope', '$http', 'configurationFactory', 'editableObjectsFactory', 'parametersFactory', 'modulesFactory', 'enumFactory', 'contractingDelayService',
    function ($rootScope, $http, configurationFactory, editableObjectsFactory, parametersFactory, modulesFactory, enumFactory, contractingDelayService) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null);
                })
                .error(function (data, status) {
                    callback(data, status);
                });
        };

        this.GetDefaultFilters = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Offer/GetDefaultFilters',
            };
            SendRequest(config, callback);
        };

        this.GetAllOffers = function (idStatus, idCrop, idProduct, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Offer/GetAll',
                params: { idStatus: idStatus, idCrop: idCrop, idProduct: idProduct }
            };
            SendRequest(config, callback);
        };

        this.GetAllOffersFinalisedActives = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Offer/GetAllFinalisedActives'
            };
            SendRequest(config, callback);
        };

        this.GetOfferById = function (id, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Offer/GetById',
                params: { idOffer: id }
            };
            SendRequest(config, callback);
        };

        this.GetOfferByIdAndIdExpiry = function (idOffer, idExpiry, idTypeFixation, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Offer/GetOfferByIdAndIdExpiry',
                params: { idOffer: idOffer, idExpiry: idExpiry, idTypeFixation: idTypeFixation }
            };
            SendRequest(config, callback);
        };

        this.GetBodyOffer = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Offer/GetBody'
            };
            SendRequest(config, callback);
        };

        this.SaveOffer = function (offer, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Offer/Save',
                data: offer
            };
            SendRequest(config, callback);
        };

        this.SaveParameterOffer = function (offer, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Offer/SaveParameterOffer',
                data: offer
            };
            SendRequest(config, callback);
        };

        this.SaveParametersOfferByCover = function (cover, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Offer/SaveParametersOfferByCover',
                data: cover
            };
            SendRequest(config, callback);
        };

        this.DeleteOffer = function (id, callback) {
            var config = {
                method: 'DELETE',
                url: configurationFactory.Url['Api'] + 'Offer/DeleteOffer',
                params: { idOffer: id }
            };
            SendRequest(config, callback);
        };

        this.GetDataOfferPaginateWithFilter = function (idCrop, idTypeOffer, idProduct, idContractCommitment, max, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Offer/GetDataOfferPaginateWithFilter',
                params: {
                    idCrop: idCrop,
                    idTypeOffer: idTypeOffer,
                    idProduct: idProduct,
                    idContractCommitment: idContractCommitment,
                    itemPerPage: max,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };
            SendRequest(config, callback);
        };

        this.GetAllFinalisedActivesVm = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Offer/GetAllFinalisedActivesVm'
            };
            SendRequest(config, callback);
        };

        this.DeleteAttachement = function (idOffer, idAttachment, callback) {
            var config = {
                method: 'DELETE',
                url: configurationFactory.Url['Api'] + 'Offer/DeleteAttachement',
                params: { idOffer: idOffer, idAttachment: idAttachment }
            };
            SendRequest(config, callback);
        };

        this.AllowUserToSubscribreToday = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Offer/AllowUserToSubscribreToday'
            };
            SendRequest(config, callback);
        };

        this.CanDeleteStrike = function (idStrike, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Offer/CanDeleteStrike',
                params: { idStrike: idStrike }
            };
            SendRequest(config, callback);
        };
        this.CheckIsValidTonnageCurrent = function (tonnageCurrent, tonnageMin, tonnageMax, quantityLeft, quotity, commitmentTonnageToFix, idTypeOrder, idTypeCover, tonnageMaxOfCompatibleComponent, tonnageMaxCropProduct, callback) {
            if (tonnageCurrent === null || tonnageCurrent === undefined)
                tonnageCurrent = '';

            if (tonnageMin === null || tonnageMin === undefined)
                tonnageMin = '';

            if (tonnageMax === null || tonnageMax === undefined)
                tonnageMax = '';

            if (quantityLeft === null || quantityLeft === undefined)
                quantityLeft = '';

            if (quotity === null || quotity === undefined)
                quotity = '';

            if (commitmentTonnageToFix === null || commitmentTonnageToFix === undefined)
                commitmentTonnageToFix = '';

            if (idTypeOrder === null || idTypeOrder === undefined)
                idTypeOrder = '';

            if (idTypeCover === null || idTypeCover === undefined)
                idTypeCover = '';

            if (tonnageMaxOfCompatibleComponent === null || tonnageMaxOfCompatibleComponent === undefined)
                tonnageMaxOfCompatibleComponent = '';

            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Offer/CheckIsValidTonnageCurrent',
                params: { tonnageCurrent: tonnageCurrent, tonnageMin: tonnageMin, tonnageMax: tonnageMax, quantityLeft: quantityLeft, quotity: quotity, commitmentTonnageToFix: commitmentTonnageToFix, idTypeOrder: idTypeOrder, idTypeCover: idTypeCover, tonnageMaxOfCompatibleComponent: tonnageMaxOfCompatibleComponent, tonnageMaxCropProduct: tonnageMaxCropProduct }
            };
            SendRequest(config, callback);
        };

        this.CheckIsValidHectaresCurrent = function (surfaceCurrent, surfaceMin, surfaceMax, quantityLeft, quotity, callback) {

            if (surfaceCurrent === null || surfaceCurrent === undefined) surfaceCurrent = '';

            if (surfaceMin === null || surfaceMin === undefined) surfaceMin = '';

            if (surfaceMax === null || surfaceMax === undefined) surfaceMax = '';

            if (quantityLeft === null || quantityLeft === undefined)
            quantityLeft = '';

            if (quotity === null || quotity === undefined)
            quotity = '';

            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Offer/CheckIsValidHectaresCurrent',
                params: { surfaceCurrent: surfaceCurrent, surfaceMin: surfaceMin, surfaceMax: surfaceMax, quantityLeft: quantityLeft, quotity: quotity}
            };
            SendRequest(config, callback);
        };


        this.GenerateStrikeAuto = function (strikeAutoVm, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Offer/GenerateStrikeAuto',
                data: strikeAutoVm
            };
            SendRequest(config, callback);
        };

        this.SaveVariablesForOffers = function (postData, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Offer/SaveVariablesForOffers',
                data: postData
            };
            SendRequest(config, callback);
        };

        this.DisableOrEnableOffers = function (offerAction, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Offer/DisableOrEnableOffers',
                data: offerAction
            };
            SendRequest(config, callback);
        };

        this.GetOfferPrice = function (idExpiry, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Offer/GetOfferPrice',
                params: { idExpiry: idExpiry }
            };
            SendRequest(config, callback);
        };

        this.GetInfoBuiltPriceForAdmin = function (idOffer, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Offer/GetInfoBuiltPriceForAdmin',
                params: { idOffer: idOffer }
            };
            SendRequest(config, callback);
        };

        this.PostInfoBuiltPriceForAdmin = function (infoBuiltPriceSaveForAdminVm, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Offer/PostInfoBuiltPriceForAdmin',
                data : infoBuiltPriceSaveForAdminVm
            };
            SendRequest(config, callback);
        };

        this.GetInfoBuiltPriceObservationsForAdmin = function (idOffer, startDateFilter, endDateFilter, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Offer/GetInfoBuiltPriceObservationsForAdmin',
                params: { idOffer: idOffer, startDateFilter: startDateFilter, endDateFilter: endDateFilter }
            };
            SendRequest(config, callback);
        };

        this.DeleteOfferMessage = function (id, callback) {
            var config = {
                method: 'DELETE',
                url: configurationFactory.Url['Api'] + 'Offer/DeleteOfferMessage',
                params: { idOfferMessage: id }
            };
            SendRequest(config, callback);
        };

        this.GetOfferMessageVm = function (idOffer, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Offer/GetOfferMessageVm',
                params: { idOffer: idOffer }
            };
            SendRequest(config, callback);
        };

        this.PostOfferMessageVm = function (offerMessageVm, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Offer/PostOfferMessageVm',
                data : offerMessageVm
            };
            SendRequest(config, callback);
        };

        this.GetDataOfferUserIncidence = function (idOffer, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Offer/GetDataOfferUserIncidence',
                params: { idOffer: idOffer }
            };
            SendRequest(config, callback);
        };

        this.SaveOfferUserIncidence = function (offerUserIncidenceVm, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Offer/SaveOfferUserIncidence',
                data : offerUserIncidenceVm
            };
            SendRequest(config, callback);
        };

        this.DeleteOfferUserIncidence = function (id, callback) {
            var config = {
                method: 'DELETE',
                url: configurationFactory.Url['Api'] + 'Offer/DeleteOfferUserIncidence',
                params: { idOfferUserIncidence: id }
            };
            SendRequest(config, callback);
        };
    }]);
