AgrimarketControllers.controller('weatherDataController', ['$scope', '$rootScope', '$sce', 'accuWeatherCallService', 'colonnesAccuWeatherCall',
function ($scope, $rootScope, $sce, accuWeatherCallService, colonnesAccuWeatherCall) {

        $scope.tableAccuWeatherCallDataVisible = colonnesAccuWeatherCall.data.DataVisible.split(',');
        $scope.tableAccuWeatherCallLibelle = colonnesAccuWeatherCall.data.ColumnName.split(',');

        $scope.buttonValidate = $rootScope.editableObjectsFactory.EditableObjects.Validate.Content;
        $scope.resultLabel = $rootScope.editableObjectsFactory.EditableObjects.Admin_AccuWheather_Result.Content;
        $scope.columnMonth = $rootScope.editableObjectsFactory.EditableObjects.Admin_AccuWheather_TableSumMonth_ColunmName_Month.Content;
        $scope.columnCallNumber = $rootScope.editableObjectsFactory.EditableObjects.Admin_AccuWheather_TableSumMonth_ColunmName_CallNumber.Content;
        $scope.buttonDetail = $rootScope.editableObjectsFactory.EditableObjects.Admin_AccuWheather_Details.Content;

        var init = function () {          
            $scope.maxAccuWeatherCall = 10;
            $scope.accuWeatherCallPageNo = 1;
            $scope.showDetails = false;
            $scope.dataAccuWeatherCall = {};
            $scope.dataAccuWeatherCall.reverse = true;
            $scope.dataAccuWeatherCall.sortKey = "CallDate";
            $scope.GetFilterAccuWeatherCall();
        };

        $scope.GetAccuWeatherCalls = function () {
            $scope.loading = true;
            accuWeatherCallService.GetAccuWeatherCallsPaginate($scope.startDateFilter, $scope.endDateFilter, $scope.maxAccuWeatherCall, $scope.accuWeatherCallPageNo, $scope.dataAccuWeatherCall.sortKey, $scope.dataAccuWeatherCall.reverse, function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.lstAccuWeatherCalls = data.ListVm;
                    $scope.totalAccuWeatherCalls = data.Total;
                    $scope.loading = false;
                }
            });
        };

        $scope.GetSumMonthAccuWeatherCalls = function () {
            accuWeatherCallService.GetSumMonthAccuWeatherCalls($scope.startDateFilter, $scope.endDateFilter,function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.lstSumMonthAccuWeatherCalls = data;
                }
            });
        };
    
        $scope.GetFilterAccuWeatherCall = function () {
            accuWeatherCallService.GetFilterAccuWeatherCall(function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.lstMontsForFilter = data.ListMonth;
                    $scope.startDateFilter = data.StartDate;
                    $scope.endDateFilter = data.EndDate;
                    $scope.GetSumMonthAccuWeatherCalls();
                    if ($scope.showDetails) {
                        $scope.GetAccuWeatherCalls();
                    }
                }
            });
        };
      
        $scope.ValidateFilter = function () {   
            $scope.GetSumMonthAccuWeatherCalls();
            if ($scope.showDetails) {
                $scope.GetAccuWeatherCalls();
            }
        };

        $scope.ShowTableAccuWheather = function() {
            if ($scope.showDetails) {
                $scope.GetAccuWeatherCalls();
            }
        };

        $scope.ClickDetails = function () { 
            if ($scope.Details)  {
                $scope.GetAccuWeatherCalls();
            }
        };

        $scope.Sort = function (sortKey, reverse) {
            $scope.dataAccuWeatherCall.sortKey = sortKey;
            $scope.dataAccuWeatherCall.reverse = reverse;
            $scope.GetAccuWeatherCalls();
        };

        $scope.GetData = function (pageNo) {
            $scope.accuWeatherCallPageNo = pageNo;
            $scope.GetAccuWeatherCalls();
        };

        init();
    }]);
