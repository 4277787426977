AgrimarketDirectives.directive('refresh', function() {
	return function(scope, iElement, iAttrs) {

		$(document).bind("keydown", function(e) {
			if ((e.which || e.keyCode) == 116) {
				e.preventDefault();
				try {
					scope[iAttrs.refreshOnrefresh]();
				} catch(g) {

				}
			}
		});
	};
	
});