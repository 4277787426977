AgrimarketServices.service('coverService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

    	var SendRequest = function (configfile, callback) {
    		$http(configfile)
			    .success(function (data) {
			    	callback(data, null);
			    })
			    .error(function (data, status) {
			    	callback(data, status);
			    });
    	};
	
    	this.SaveParameterCover = function (isAuto, callback) {
    	    var config = {
    			method: 'POST',
    			url: configurationFactory.Url['Api'] + 'Cover/SaveParameterCover',
    			params: {isAuto: isAuto}
    		};
    		SendRequest(config, callback);
    	};

    	this.GetAllAccounts = function (callback) {
    	    var config = {
    	        method: 'GET',
    	        url: configurationFactory.Url['Api'] + 'Cover/GetAllAccounts',
    	    };
    	    SendRequest(config, callback);
    	};


    	this.SaveParamsAccount = function (accountVm, callback) {
    	    var config = {
    	        method: 'POST',
    	        url: configurationFactory.Url['Api'] + 'Cover/SaveParamsAccount',
    	        data: accountVm
    	    };
    	    SendRequest(config, callback);
    	};
        
    }]);