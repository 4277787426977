AgrimarketControllers.controller('compagniesController', ['$scope', '$rootScope', 'compagnyService',
	function ($scope, $rootScope, compagnyService) {

		$scope.compagnies = [];

		var GetCompagnies = function () {
			compagnyService.GetAllCompagnies(function (data, status) {
				if (status !== null) {
					if (status === 400)
						$scope.productDefaultErrorMessage = data[0].errors[0].Message;
				}
				$scope.compagnies = data;
				$scope.showDetails = [];
				$scope.compagnies.forEach(function (item, index) {
					$scope.showDetails[index] = false;
				});
			});
		};

		var CheckIsNotAFloat = function (n) {
			return n % 1 === 0;
		};

        $scope.EditIsDefault = function (compagnie) {
            $scope.compagnies.forEach(function (e) {
                if (compagnie.IsDefault == false && e.IsDefault == true && e.IdCompagny != compagnie.IdCompagny) {
                    e.IsDefault = false;
                }
            });
        };

		var CheckValidOrderData = function (data, isCompagnie) {
			$scope.compagnieDefaultErrorMessage = "";
			$scope.compagnieValidMessage = "";
			if (!CheckIsNotAFloat(data.NewOrder)) {
				$scope.compagnieDefaultErrorMessage += "Le nombre doit être un entier.";
				return false;
			}

			if (!angular.isNumber(data.NewOrder)) {
				$scope.compagnieDefaultErrorMessage += "L'ordre doit être un nombre";
				return false;
			}
			if (isCompagnie && (data.NewOrder < 1 || data.NewOrder > $scope.compagnies.length)) {
				$scope.compagnieDefaultErrorMessage += "L'ordre doit être compris entre 1 et " + $scope.compagnies.length;
				return false;
			}
			return true;
		};

		var RemoveSelectedInput = function () {
			$scope.compagnies.forEach(function (p) {
				p.Selected = false;
				p.Editing = false;
			});
		};


		$scope.EditCompagnie = function (data) {
			$scope.compagnieDefaultErrorMessage = "";
			$scope.compagnieValidMessage = "";
			RemoveSelectedInput();
			data.Editing = true;
			data.Selected = true;
		};

		$scope.SaveCompagnieOrder = function (compagnie) {
			compagnie.Editing = false;
			compagnie.IsDefault = compagnie.IsDefault ? compagnie.IsDefault : false;
			if (CheckValidOrderData(compagnie, true)) {
				compagnyService.SaveCompagnie(compagnie, function (data, status) {
					if (status !== null) {
						if (status === 400)
							$scope.compagnieDefaultErrorMessage = data[0].errors[0].Message;
					}
					$scope.compagnieValidMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Parameter_Compagnies_ValidMessage.Content;
					GetCompagnies();
				});
			} else
				ErrorData(compagnie);
		};

		var ErrorData = function (compagnie) {
			compagnie.Editing = true;
			compagnie.Selected = true;
		};

		GetCompagnies();

		$rootScope.onRefresh = function () { GetCompagnies(); };
	}]);