AgrimarketControllers.controller('userManagementController', [
    '$scope', '$rootScope', '$routeParams', '$http', '$location', 'userInfosService', 'userCropCapitalService','parametersFactory',
	function ($scope, $rootScope, $routeParams, $http, $location, userInfosService, userCropCapitalService, parametersFactory) {
		if ($rootScope.CurrentUserRole !== 'User')
			$location.path('/login');

        $scope.viewMode = $routeParams.typeTab;
        $scope.isModuleAlertesSmsActive = $rootScope.Modules.ModuleAlertesSms.Active;

		var Init = function () {
			userInfosService.GetUserInfos(function (data, status) {
				if (status !== null) {
					if (status == 400)
						$scope.errorMessage = data[0].errors[0].Message;
				}
                $scope.userInfos = data;
				$scope.bAuthorizeUserEmailModification = parametersFactory.Parameters.bAuthorizeUserEmailModification.Value == '1';
				$scope.bCanUserEditInformations = parametersFactory.Parameters.bCanUserEditInformations.Value == '1';
			});

			if ($rootScope.bDisplayCapitalSocial) {
			    userCropCapitalService.GetUserCropCapital(function (data, status) {
			        if (status !== null) {
			            if (status == 400)
			                $scope.errorMessage = data[0].errors[0].Message;
			        }
			        $scope.userCropCapitals = data;
			    });
            }                     
		};
        
		$scope.SaveUserInfos = function() {
			if ($scope.CheckUserInfos()) {
				userInfosService.SaveUserInfos($scope.userInfos, function (data, status) {
					if (status !== null) {
						if (status == 400)
							$scope.errorMessage = data[0].errors[0].Message;
					}
					else
                        $scope.succesMessage = $rootScope.editableObjectsFactory.EditableObjects.Success_UserInfos_InformationHasBeenSaved.Content;
				});
			}
		};

		$scope.CheckUserInfos = function () {
			$scope.errorMessage = $scope.succesMessage = "";
			if ($scope.userInfos.Mail === "" || $scope.userInfos.Mail.indexOf('@') < 0 || $scope.userInfos.Mail.indexOf('.') < 0) {
                $scope.errorMessage += $rootScope.editableObjectsFactory.EditableObjects.Error_UserInfos_PleaseEnterValidMail.Content + "<br/>";
			}
			if (!$scope.userInfos.MobileNumber) {
                $scope.errorMessage += $rootScope.editableObjectsFactory.EditableObjects.Error_UserInfos_PleaseEnterMobileNumber.Content + "<br/>";
			}
			if ($scope.errorMessage === "")
				return true;
			return false;
        };

        $scope.SaveUserAdditionalService = function () {
            userInfosService.SaveUserAdditionalService($scope.userInfos, function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                }
                else
                    $scope.succesMessage = $rootScope.editableObjectsFactory.EditableObjects.Success_UserInfos_InformationHasBeenSaved.Content;
            });
        };

		Init();
	}
]);