AgrimarketServices.service('userInfosService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

    	var SendRequest = function (configfile, callback) {
    		$http(configfile)
			    .success(function (data) {
			    	callback(data, null);
			    })
			    .error(function (data, status) {
			    	callback(data, status);
			    });
    	};

    	this.GetUserInfos = function (callback) {
    		var config = {
    			method: 'GET',
    			url: configurationFactory.Url['Api'] + 'User/GetUserInfos',
    		};
    		SendRequest(config, callback);
    	};
	    
    	this.SaveUserInfos = function (userInfos, callback) {
    		var config = {
    			method: 'POST',
    			data: userInfos,
    			url: configurationFactory.Url['Api'] + 'User/SaveUserInfos',
    		};
    		SendRequest(config, callback);
        };

        this.SaveUserAdditionalService = function (userInfos, callback) {
            var config = {
                method: 'POST',
                data: userInfos,
                url: configurationFactory.Url['Api'] + 'User/SaveUserAdditionalService',
            };
            SendRequest(config, callback);
        };
    }]);