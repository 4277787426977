AgrimarketServices.service('wsdApiService', ['$rootScope', '$http', '$location', 'configurationFactory',
    function ($rootScope, $http, $location, configurationFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null, null, null);
                })
                .error(function (data, status, headers, config) {
                    callback(data, status, headers, config);
                });
        };

        this.GetExpiriesWSDByIdMarket = function (idMarket, callback) {
            var config = {
                method: 'get',
                url: configurationFactory.Url['Api'] + 'WsdApi/GetMarketByIdMarket',
                params: {
                    idMarket: idMarket
                }
            };
            SendRequest(config, callback);
        };

        this.GetExpiriesWSDByIdMarketWithQuotes = function (idMarket, idTypeQuote, typeLocat, callback) {
            var config = {
                method: 'get',
                url: configurationFactory.Url['Api'] + 'WsdApi/GetMarketByIdMarketWithExpiriesQuotes',
                params: {
                    idMarket: idMarket,
                    idTypeQuote: idTypeQuote,
                    typeLocat: typeLocat
                }
            };
            SendRequest(config, callback);
        };

        this.GetMarketsWSDToEuronext = function (callback) {
            var config = {
                method: 'get',
                url: configurationFactory.Url['Api'] + 'WsdApi/GetMarketsByLocat'
            };
            SendRequest(config, callback);
        };

        this.GetMarketsFutureAndCash = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'WsdApi/GetMarketsFutureAndCash'
            };
            SendRequest(config, callback);
        };

        this.GetLastValueForexWSDByIdCurrencies = function (idCurrency1, idCurrency2 , callback) {
            var config = {
                method: 'get',
                url: configurationFactory.Url['Api'] + 'WsdApi/GetLastValueForexWSDByIdCurrencies',
                params: {
                    idCurrency1: idCurrency1,
                    idCurrency2: idCurrency2
                }
            };
            SendRequest(config, callback);
        };

    }]);