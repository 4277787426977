AgrimarketControllers.controller('fluxrssController', ['$scope', '$rootScope', '$routeParams', '$sce', '$filter', '$http', '$location', 'newsService', 'currentUserFactory', 'configurationFactory', '$log', '$timeout', 'fluxrssService', 'colonnes', 'userTableService', 'enumFactory',
    function ($scope, $rootScope, $routeParams, $sce, $filter, $http, $location, newsService, currentUserFactory, configurationFactory, $log, $timeout, fluxrssService, colonnes, userTableService, enumFactory) {

    	if (!$rootScope.ModuleNews.Active)
    		$location.path('/admin/parameters/global');

    	$scope.searchText = "";
    	$scope.errorMessage = "";
    	$scope.lstFlux = [];
    	$scope.flux = {};
    	$scope.InitMessage = function () {
    		$scope.errorMessage = "";
    		$scope.successMessage = "";
    		$scope.errorMessagePopup = "";
    	};
    	$scope.data = { reverse: false, sortKey: "Position" };
    	$scope.pageNo = 1;
    	$scope.max = 10;

    	$scope.GetFlux = function () {
    		fluxrssService.GetFluxPaginateWithFilter($scope.searchText, $scope.max, $scope.pageNo, $scope.data.sortKey, $scope.data.reverse, function (data, status) {
    			if (status !== null) {
    				if (status === 400)
    					$scope.errorMessage = data[0].errors[0].Message;
    				else
    					$scope.errorMessage = data;
    			}
    			else {
    				$scope.lstFlux = data.ListVm;
    				$scope.total = data.Total;
    			}
    		});
    	};

    	$scope.AddFlux = function (popup) {
    		$scope.InitMessage();
    		$scope.flux = {};
    		$scope.txtButton = "Ajouter";
    		$scope.modalTitle = "Ajouter un Flux RSS";
    		popup();
    	};

    	$scope.EditFlux = function (item, index, openPoPup) {
    		$scope.InitMessage();
    		$scope.flux = angular.copy(item);
    		$scope.txtButton = "Modifier";
    		$scope.modalTitle = "Modifier un Flux RSS";
    		openPoPup();
    	};

    	$scope.CheckFlux = function () {
    		$scope.InitMessage();
    		if ($scope.flux.Label === undefined || $scope.flux.Label === null || $scope.flux.Label === "")
    			$scope.errorMessagePopup += "Veuillez renseigner un nom de flux<br/>";
    		else if ($scope.flux.Label.length > 20)
    			$scope.errorMessagePopup += "Veuillez renseigner un nom de flux de 20 caractères maximum<br/>";

    		if ($scope.flux.Link === undefined || $scope.flux.Link === null || $scope.flux.Link === "")
    			$scope.errorMessagePopup += "Veuillez renseigner le lien du flux<br/>";

    		if ($scope.flux.Position === undefined || $scope.flux.Position === null || $scope.flux.Position === "")
    			$scope.errorMessagePopup += "Veuillez renseigner la position du flux<br/>";
    		else if (($scope.lstFlux.length === 0 && ($scope.flux.Position < 0 || $scope.flux.Position > $scope.lstFlux.length + 1) ||
    				 ($scope.flux.IdFluxRss !== undefined && $scope.lstFlux.length > 0 && ($scope.flux.Position < 0 || $scope.flux.Position > $scope.lstFlux.length)
				)))
    			$scope.errorMessagePopup += "La position doit être de 1 <br/>";
    		else if ($scope.lstFlux.length > 0 && ($scope.flux.Position < 0 || $scope.flux.Position > ($scope.lstFlux.length + 1)))
    			$scope.errorMessagePopup += "La position doit être comprise entre 1 et " + ($scope.lstFlux.length + 1) + "<br/>";

    		if ($scope.errorMessagePopup !== "") {
    			$scope.errorMessagePopup = $sce.trustAsHtml($scope.errorMessagePopup);
    			return false;
    		}
    		return true;
    	};

    	$scope.SaveFlux = function () {
    		fluxrssService.PostFlux($scope.flux, function (data, status) {
    			if (status !== null) {
    				if (status == 400) {
    					$scope.errorMessagePopup = $sce.trustAsHtml(data[0].errors[0].Message);
    					$scope.openPopUp();
    					return false;
    				}
    			}
    			else {
    				$scope.lstFlux = $scope.filterGeneral = data;
    				$scope.Filter();
    				$scope.flux = {};
    				$scope.successMessage = "Votre flux RSS a bien été enregistré";
    			}
    			return true;
    		});
    	};

    	$scope.DeleteFlux = function (item) {
    		$scope.InitMessage();
    		fluxrssService.DeleteFlux(item.IdFluxRss, function (data, status) {
    			if (status !== null) {
    				if (status == 400)
    					$scope.errorMessage = data[0].errors[0].Message;
    				else
    					$scope.errorMessage = status;
    			} else {
    				$scope.lstFlux = $scope.filterGeneral = data;
    				$scope.flux = {};
    				$scope.successMessage = "Votre flux RSS a bien été supprimé";
    			}
    		});
    	};

    	$scope.GetData = function (pageNo) {
    		$scope.pageNo = pageNo;
    		$scope.GetFlux();
    	};

    	$scope.Sort = function (sortKey, reverse) {
    		$scope.data.sortKey = sortKey;
    		$scope.data.reverse = reverse;
    		$scope.GetFlux();
    	};

    	$scope.$watch("searchText", function (newValue, oldValue) {
    		if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
    			$scope.GetFlux();
    	});

    	$scope.Init = function () {    	  
    		$scope.tableFluxDataVisible = colonnes.data.DataVisible.split(',');
    		$scope.tableFluxLabels = colonnes.data.ColumnName.split(',');
    		$scope.InitMessage();
    		$scope.GetFlux();
    	};

    	$rootScope.onRefresh = function () {
    		$scope.Init();
    	};

    	$scope.Init();
    }]);
