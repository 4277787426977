/*
*   Directive de datetimepicker
*
*/

AgrimarketDirectives.directive('datepicker', function ($parse) {
	return function (scope, iElement, iAttrs) {
		//if (!Modernizr.inputtypes.date) {
	    iElement.attr('type', 'text');
			iElement.datepicker(
			{
			    firstDay: 1,
				dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
				monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
				dateFormat: iAttrs.datepickerOffer === "true" ? 'yy-mm-dd' : 'dd/mm/yy',
				minDate: iAttrs.datepickerMinDate,
				maxDate: iAttrs.datepickerMaxDate,
				onSelect: function (date) {
				    var getter = $parse(iAttrs.datepickerTarget);
					var setter = getter.assign; //get value
					setter(scope, date); //set value
					//if (iAttrs.datepickerOnselected !== undefined && iAttrs.datepickerOnselected !== null) {
					//	scope[iAttrs.datepickerOnselected]();
					//}
					scope.$apply();
				},
				onClose: function (dateText, inst) {
					iElement.datepicker("disable");
					setTimeout(function () {
						iElement.datepicker("enable");
					}, 500);
				},
				nextText: 'Suiv', prevText: 'Préc'
			});
		//}
		
	};
});