AgrimarketControllers.controller('alertMailController', ['$scope', '$rootScope', '$routeParams', '$sce', '$location', 'alertMailService', 'groupContactService', 'colonnesAlertMail',
function ($scope, $rootScope, $routeParams, $sce, $location, alertMailService, groupContactService, colonnesAlertMail) {

        $scope.viewMode = "Enabled";
        $scope.labelButtonEnableOrDisableAlert = $rootScope.editableObjectsFactory.EditableObjects.Admin_AlertMail_Button_Disable.Content;
        $scope.tableAlertMailDataVisible = colonnesAlertMail.data.DataVisible.split(',');
        $scope.tableAlertMailLibelle = colonnesAlertMail.data.ColumnName.split(',');

        $scope.subTabAlertEnable = $rootScope.editableObjectsFactory.EditableObjects.Admin_AlertMail_SubTab_AlertEnable.Content;
        $scope.subTabAlertDisable = $rootScope.editableObjectsFactory.EditableObjects.Admin_AlertMail_SubTab_AlertDisable.Content;
        $scope.buttonAddAlertMail = $rootScope.editableObjectsFactory.EditableObjects.Admin_AlertMail_Button_AddAlert.Content;
        $scope.popUpDeleteTitle = $rootScope.editableObjectsFactory.EditableObjects.Admin_AlertMail_PopUpDelete_Title.Content;
        $scope.popUpDeleteText = $rootScope.editableObjectsFactory.EditableObjects.Admin_AlertMail_PopUpDelete_Text.Content;


        var init = function () {          
            $scope.maxAlertMail = 10;
            $scope.alertMailPageNo = 1;
            $scope.dataAlertMail = {};
            $scope.dataAlertMail.reverse = false;
            $scope.dataAlertMail.sortKey = "Label";
            $scope.alertMailAction = {
                ListIdAlertMail: [],
                ActivatedAlertMail: null,
            };
            $scope.GetAlertMails();
        };

        $scope.GetAlertMails = function () {
            alertMailService.GetAlertMailsPaginate($scope.viewMode === "Enabled", $scope.idGroupContactSelected, $scope.maxAlertMail, $scope.alertMailPageNo, $scope.dataAlertMail.sortKey, $scope.dataAlertMail.reverse, function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.lstAlertMail = data.ListVm;
                    $scope.totalAlertMail = data.Total;
                }
            });
        };

        $scope.GetGroupContacts = function () {
            groupContactService.GetAll(function (data, status) {
                if (status === null)
                    $scope.lstGroupContacts = data;
                else if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
            });
        };
    
        $scope.SelectAll = function () {
            $scope.alertMailAction.ListIdAlertMail = [];

            if ($scope.allSelected) {
                $scope.lstAlertMail.forEach(function (alertMail) {
                    $scope.alertMailAction.ListIdAlertMail.push(alertMail.IdAlertMail);
                });
            }
        };

        $scope.SelectAlertMail = function (idAlertMail) {
            if ($scope.allSelected) {
                $scope.allSelected = false;
            }

            var index = $scope.alertMailAction.ListIdAlertMail.indexOf(idAlertMail);
            if (index >= 0) {
                $scope.alertMailAction.ListIdAlertMail.splice(index, 1);
            } else {
                $scope.alertMailAction.ListIdAlertMail.push(idAlertMail);
            }
        };

        $scope.DisableOrEnableAlertMail = function (value) {
            $scope.errorMessage = "";
            $scope.alertMailAction.ActivatedAlertMail = value;
            alertMailService.EditAlertMailState($scope.alertMailAction, function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    if ($scope.allSelected) {
                        $scope.allSelected = false;
                    }
                    init();
                }
            });
        };

        $scope.DeleteAlertMail = function (idAlertMail) {
            $scope.errorMessage = "";
            alertMailService.DeleteAlertMail(idAlertMail, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } 
                } else {
                    init();
                }
            });
        };

        $scope.FilterGroupContact = function () {
            $scope.errorMessage = "";
            $scope.alertMailAction.ListIdAlertMail = [];
            init();
        };

        $scope.Sort = function (sortKey, reverse) {
            $scope.dataAlertMail.sortKey = sortKey;
            $scope.dataAlertMail.reverse = reverse;
            $scope.GetAlertMails();
        };

        $scope.GetData = function (pageNo) {
            $scope.alertMailPageNo = pageNo;
            $scope.GetAlertMails();
        };

        $scope.clickTab = function (tab) {
            $scope.errorMessage = "";
            $scope.idGroupContactSelected = "";
            $scope.viewMode = tab;
            $scope.labelButtonEnableOrDisableAlert = $scope.viewMode === "Enabled" ? $rootScope.editableObjectsFactory.EditableObjects.Admin_AlertMail_Button_Disable.Content : $rootScope.editableObjectsFactory.EditableObjects.Admin_AlertMail_Button_Enable.Content;
            init();
        };

        $scope.AddAlertMail = function () { 
            $location.path('/admin/editorial/createAlertMail/-1'); 
        };

        $scope.EditAlertMail = function (id) {
            $location.path('/admin/editorial/createAlertMail/' + id);
        };

        $scope.DuplicateAlertMail = function (id) {
            $location.path('/admin/editorial/duplicateAlertMail/' + id);
        };

        init();
        $scope.GetGroupContacts();
    }]);
