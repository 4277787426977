AgrimarketServices.service('targetPriceService', ['editableObjectsFactory', 'enumFactory', '$filter',
    function (editableObjectsFactory, enumFactory, $filter) {

        this.CheckPriceAndDateIsValid = function (resumeOffer, idContractCommitment, contractualisationWithCommitment, offerPriceToCompare, paramAuthorizeTargetPriceUnderOfferPrice) {

            if (resumeOffer.idTypeOrder !== enumFactory.TypeOrders.Prixobjectif.IdTypeOrder)
                return null;

            /** Vérification du prix saisi ***/
            if (!resumeOffer.targetPriceCurrent)
                return editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterATargetPrice.Content;

            if (isNaN(resumeOffer.targetPriceCurrent) && resumeOffer.targetPriceCurrent.indexOf(",") !== -1)
                resumeOffer.targetPriceCurrent = resumeOffer.targetPriceCurrent.replace(",", ".");

            if (!Number(resumeOffer.targetPriceCurrent))
                return editableObjectsFactory.EditableObjects.Error_OfferSubscribe_TargetPriceIsNotValid.Content;

            resumeOffer.targetPriceCurrent = Number(resumeOffer.targetPriceCurrent);

            if (!paramAuthorizeTargetPriceUnderOfferPrice && resumeOffer.targetPriceCurrent < offerPriceToCompare)
                return editableObjectsFactory.EditableObjects.Error_OfferSubscribe_TargetPriceMustBeSuperirorOrEqualToOfferPrice.Content + offerPriceToCompare;

            /*** Vérification de la date saisie ***/
            if (!resumeOffer.AllowDateEndTargetPrice)
                return null;

            if (!resumeOffer.DateEndTargetPriceUser)
                return editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterEndDateValidityOfTargetPrice.Content;

            var dateEnd, dateMin, dateMax;
            var tab1 = $filter('date')(resumeOffer.DateEndTargetPriceUser, 'dd/MM/yyyy').split('/');
            var tab2 = $filter('date')(resumeOffer.DateMinTargetPrice, 'dd/MM/yyyy').split('/');
            var tab3 = $filter('date')(resumeOffer.DateMaxTargetPrice, 'dd/MM/yyyy').split('/');

            if (tab1.length === 3)
                dateEnd = Date.parse(tab1[2] + "-" + tab1[1] + "-" + tab1[0]);

            if (!dateEnd || dateEnd.isNaN)
                return editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterEndDateValidityOfTargetPrice;

            if (tab2.length === 3)
                dateMin = new Date(tab2[2] + "-" + tab2[1] + "-" + tab2[0]);

            if (tab3.length === 3)
                dateMax = new Date(tab3[2] + "-" + tab3[1] + "-" + tab3[0]);

            if (dateEnd < dateMin)
                return editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterDateAfter.Content + $filter('date')(dateMin, 'dd/MM/yyyy');

            if (dateEnd > dateMax)
                return editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterDateBefore.Content + $filter('date')(dateMax, 'dd/MM/yyyy');

            resumeOffer.DateEndTargetPrice = resumeOffer.DateEndTargetPriceUser;
            return null;
        };

        this.CheckTargetPriceNotification = function (resumeOffer, targetPriceNotificationByMail, paramCanChooseTargetPriceMessage, user) {

            if (resumeOffer.idTypeOrder === enumFactory.TypeOrders.Prixobjectif.IdTypeOrder &&
                paramCanChooseTargetPriceMessage &&
                !targetPriceNotificationByMail &&
                !user.MobileNumber)
                return editableObjectsFactory.EditableObjects.Error_OfferSubscribe_ErrorTargetPriceNotificationBySmsWithoutMobileNumber.Content;
            else
                return null;
        };

        this.GetPreviewFinalPrice = function (basePrice, refPrice, totalCondPart) {
            // On fait un Number().toString() afin de ne pas afficher des 0 inutiles après la virgule
            return Number(Number(((basePrice ? basePrice : 0) + (refPrice ? refPrice : 0) + (totalCondPart ? totalCondPart : 0))).toFixed(4)).toString();
        };
    }]);