AgrimarketControllers.controller('homePageApplicationMobilesController', ['$scope', '$rootScope', '$http', '$location', '$filter', 'currentUserFactory', 'configurationFactory', 'typeDashboardActualityAppMobileService', 'modulesFactory',
	function ($scope, $rootScope, $http, $location, $filter, currentUserFactory, configurationFactory, typeDashboardActualityAppMobileService, modulesFactory) {

		$scope.typeDashboardActualityAppMobiles = [];
		$scope.showTypeDashboardActualityAppMobiles = true;

		var GetTypeDashboardActualityAppMobiles = function () {
			typeDashboardActualityAppMobileService.GetAllTypeDashboardActualityAppMobile(function (data, status) {
				if (status !== null) {
					if (status === 400)
						$scope.typeDashboardActualityAppMobileDefaultErrorMessage = data[0].errors[0].Message;
				}
				$scope.typeDashboardActualityAppMobiles = data;
				$scope.typeDashboardActualityAppMobiles.forEach(function (item, index) {
					item.NewPosition = item.Position;
				});
			});
		};

		var CheckIsNotAFloat = function (n) {
			return n % 1 === 0;
		};

		var CheckValidOrderData = function (data) {
			$scope.typeDashboardActualityAppMobileDefaultErrorMessage = "";
			$scope.typeDashboardActualityAppMobileValidMessage = "";
			if (!CheckIsNotAFloat(data.NewPosition)) {
				$scope.typeDashboardActualityAppMobileDefaultErrorMessage += "Le nombre doit être un entier.";
				return false;
			}

			if (!angular.isNumber(data.NewPosition)) {
				$scope.typeDashboardActualityAppMobileDefaultErrorMessage += "L'ordre doit être un nombre";
				return false;
			}
			if (data.NewPosition < 1 || data.NewPosition > $scope.typeDashboardActualityAppMobiles.length) {
				$scope.typeDashboardActualityAppMobileDefaultErrorMessage += "L'ordre doit être compris entre 1 et " + $scope.typeDashboardActualityAppMobiles.length;
				return false;
			}
			return true;
		};


		var RemoveSelectedInput = function () {
			$scope.typeDashboardActualityAppMobiles.forEach(function (p) {
				p.Selected = false;
				p.Editing = false;
			});
		};


		$scope.EditTypeDashboardActualityAppMobile = function (data) {
			$scope.typeDashboardActualityAppMobileDefaultErrorMessage = "";
			$scope.typeDashboardActualityAppMobileValidMessage = "";
			RemoveSelectedInput();
			data.Editing = true;
			data.Selected = true;
		};

		$scope.SaveTypeDashboardActualityAppMobileOrder = function (typeDashboardActualityAppMobile) {
			typeDashboardActualityAppMobile.Editing = false;

			if (CheckValidOrderData(typeDashboardActualityAppMobile, true)) {
				typeDashboardActualityAppMobileService.SaveTypeDashboardActualityAppMobileOrder(typeDashboardActualityAppMobile, function (data, status) {
					if (status !== null) {
						if (status === 400)
							$scope.typeDashboardActualityAppMobileDefaultErrorMessage = data[0].errors[0].Message;
							$scope.openPopUp();
					}
					$scope.typeDashboardActualityAppMobileValidMessage = "Vos modifications ont bien été prises en compte.";
					GetTypeDashboardActualityAppMobiles();
				});
			} else
				ErrorData(typeDashboardActualityAppMobile);
		};

		var ErrorData = function (typeDashboardActualityAppMobile) {
			typeDashboardActualityAppMobile.Editing = true;
			typeDashboardActualityAppMobile.Selected = true;
		};

		GetTypeDashboardActualityAppMobiles();

		$rootScope.onRefresh = function () { GetTypeDashboardActualityAppMobiles(); };
	}]);