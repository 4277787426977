AgrimarketDirectives.directive('autoFocus', function ($timeout, $parse) {
    return {
        link: function (scope, element, attrs) {
            var model = $parse(attrs.focusMe);
            scope.$watch(model, function (value) {
                if (value === true) {
                    $timeout(function () {
                        element[0].focus();
                    });
                }
            });
            element.bind('focus', function (setPristine) {
                scope.$apply(model.assign(scope, setPristine.currentTarget.id));
            });
        }
    };
});