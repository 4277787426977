AgrimarketServices.service('closeDayService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

    	var SendRequest = function (configfile, callback) {
    		$http(configfile)
			    .success(function (data) {
			    	callback(data, null);
			    })
			    .error(function (data, status) {
			    	callback(data, status);
			    });
    	};

    	this.GetCloseDays = function (callback) {
    		var config = {
    			method: 'GET',
    			url: configurationFactory.Url['Api'] + 'CloseDay/GetCloseDays'
    		};
    		SendRequest(config, callback);
    	};

    	this.AddCloseDay = function (closeDay, callback) {
    		var config = {
    			method: 'POST',
    			url: configurationFactory.Url['Api'] + 'CloseDay/PostCloseDay',
    			data: closeDay
    		};
    		SendRequest(config, callback);
    	};
	    
    	this.EditCloseDay = function (closeDay, callback) {
    		var config = {
    			method: 'PUT',
    			url: configurationFactory.Url['Api'] + 'CloseDay/PutCloseDay',
    			data: closeDay
    		};
    		
    		SendRequest(config, callback);
    	};

    	this.DeleteCloseDay = function (id, callback) {
		    var config = {
			    method: 'DELETE',
			    url: configurationFactory.Url['Api'] + 'CloseDay/DeleteCloseDay',
			    params : { id: id }
		    };
    		SendRequest(config, callback);
    	};


    }]);