AgrimarketServices.service('signatureService', ['$http', 'configurationFactory', 'contractingDelayService',
    function ($http, configurationFactory, contractingDelayService) {

        var sendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null);
                })
                .error(function (data, status) {
                    callback(data, status);
                });
        };

        var _sendSmsForElectronicSignature = function (idContract, idConnection, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Contracts/SendSmsForElectronicSignature',
                cache: false,
                crossDomain: true,
                params: {
                    idContract: idContract,
                    idConnectionSelected: idConnection
                }
            };
            sendRequest(config, callback);
        };

        this.CheckAndSendSmsForElectronicSignature = function (contractVm, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Contracts/CheckAndSendSmsForElectronicSignature',
                cache: false,
                crossDomain: true,
                data: contractVm
            };
            sendRequest(config, callback);
        };

        this.CheckContractBaseAndSendSmsForElectronicSignature = function (contractContractBaseVm, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'ContractBase/CheckContractBaseAndSendSmsForElectronicSignature',
                cache: false,
                crossDomain: true,
                data: contractContractBaseVm
            };
            sendRequest(config, callback);
        };

        this.SendSmsElectronicSignatureForOption = function (idConnection, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'ContractsOption/SendSmsElectronicSignatureForOption',
                cache: false,
                crossDomain: true,
                params: {
                    idConnectionSelected: idConnection
                }
            };
            sendRequest(config, callback);
        };

        this.SendSmsForDocumentSignature = function (idDocument, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'User/StartDocumentSigning',
                cache: false,
                crossDomain: true,
                params: {
                    idDocument: idDocument
                }
            };
            sendRequest(config, callback);
        };

        this.SendSmsForElectronicSignature = function (timeToContracting, idContract, idConnection, callback) {
            var res = {
                isToLate: false,
                errorMessage: "",
                successMessage: ""
            };

            res.errorMessage = contractingDelayService.VerifyContractingDelay(timeToContracting);
            if (res.errorMessage) {
                res.isToLate = true;
                callback(res);
                return;
            }

            _sendSmsForElectronicSignature(idContract, idConnection, function ( data, status) {
                if (status !== null) {
                    if (status === 400)
                        res.errorMessage = data[0].errors[0].Message;
                } else
                    res.successMessage = data;

                callback(res);
            });
        };

        this.GetListConnection = function (idUser, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'User/GetListConnection',
                cache: false,
                crossDomain: true,
                params: { idUser: idUser }
            };
            sendRequest(config, callback);
        };

        this. SignatureWithCodeYouSign = function (fileId, authenticateId, code, idConnection, memberId, procedureId, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Signature/SignatureWithCodeYouSign',
                cache: false,
                crossDomain: true,
                params: { fileId: fileId, authenticateId: authenticateId, code: code, idConnectionSelected: idConnection, memberId: memberId, procedureId: procedureId }
            };
            sendRequest(config, callback);
        };

        this.ResendCodeYouSign = function (memberId, procedureId, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Signature/ResendCodeYouSign',
                cache: false,
                crossDomain: true,
                params: { memberId: memberId, procedureId: procedureId }
            };
            sendRequest(config, callback);
        };

        this.CheckSecurityCodeFirmOrCommitment = function (code, idContract, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Contracts/CheckSecurityCode',
                cache: false,
                crossDomain: true,
                params: { code: code, idContract: idContract }
            };
            sendRequest(config, callback);
        };

        this.CheckSecurityCodeBase = function (code, idContract, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'ContractBase/CheckSecurityCode',
                cache: false,
                crossDomain: true,
                params: { code: code, idContract: idContract }
            };
            sendRequest(config, callback);
        };

        this.CheckSecurityCodeOption = function (code, idContract, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'ContractOption/CheckSecurityCode',
                cache: false,
                crossDomain: true,
                params: { code: code, idContract: idContract }
            };
            sendRequest(config, callback);
        };

    }]);