/*
*   Directive d'ouverture de pop up de confirmation
*	2 parametres sont nécéssaire :
*	- confirmMessage : text du boutton de confirmation
*	- cancelMessage : text du bouton d'annulation
*
*   2 fonctions sont nécéssaires:
*       -callFunctionName: ouvre la modal
*       -confirmFunction: fonction a lancer apres la confirmation
*/

AgrimarketDirectives.directive('popupconfirm', function ($timeout) {
	var globParam;
	return function (scope, iElement, iAttrs) {

		scope[iAttrs.popupConfirm] = function () {
			iElement.unbind('dialogclose');
		    iElement.dialog("close");
			scope[iAttrs.confirmFunction](globParam);
		};

		scope[iAttrs.popupCancel] = function () {
			iElement.unbind('dialogclose');
			iElement.dialog("close");
			if (iAttrs.confirmCancelFunction !== undefined && iAttrs.confirmCancelFunction !== null) {
				scope[iAttrs.confirmCancelFunction](globParam);
			}
			
		};

		scope[iAttrs.callFunctionName] = function (param) {
			globParam = param;
			
			var viewportHeight = window.innerHeight - 20;
			
			iElement.bind('dialogclose', function (event) {
				if (iAttrs.confirmCancelFunction !== undefined && iAttrs.confirmCancelFunction !== null) {
					scope[iAttrs.confirmCancelFunction](globParam);
				}
			});
			
			iElement.dialog({
				resizable: false,
				modal: true
				
			});

			if (iAttrs.popupWidth !== undefined && iAttrs.popupWidth) {
				iElement.dialog({width : (scope[iAttrs.popupWidth] !== undefined ? scope[iAttrs.popupWidth] : iAttrs.popupWidth)});
			}
		    if (iAttrs.popupTitle) {
		    	iElement.dialog({
		    		title: (scope[iAttrs.popupTitle] !== undefined ? scope[iAttrs.popupTitle] : iAttrs.popupTitle)
		    	});
		    }
		    else {
		        iElement.dialog({
		            resizable: false,
		            modal: true
		        });
		    }

			if (iAttrs.popupHeight !== undefined && iAttrs.popupHeight) {
		        if (viewportHeight > 500)
					iElement.dialog({height: scope[iAttrs.popupHeight] !== undefined ? scope[iAttrs.popupHeight] <= viewportHeight? scope[iAttrs.popupHeight]: viewportHeight : !isNaN(iAttrs.popupHeight) ? iAttrs.popupHeight : "auto"});
			}

		};
		scope.$on('$destroy', function () {
		    if (iElement.is(':ui-dialog')) {
		        iElement.dialog('destroy').remove();
		    }
		});

	};
});