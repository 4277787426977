AgrimarketServices.service('carouselPictureService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null, null, null);
                })
                .error(function (data, status, headers, config) {
                    callback(data, status, headers, config);
                });
        };

        this.GetCarouselPicturePaginateWithFilter = function (searchText, max, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'carouselPicture/GetCarouselPicturePaginateWithFilter',
                params: {
                    searchText: searchText,
                    itemPerPage: max,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };
            SendRequest(config, callback);
        };

        this.PostCarouselPicture = function (data, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'carouselPicture/PostCarouselPicture',
                data: data
            };
            SendRequest(config, callback);
        };

        this.DeleteCarouselPicture = function (idCarouselPicture, callback) {
            var config = {
                method: 'DELETE',
                url: configurationFactory.Url['Api'] + 'carouselPicture/DeleteCarouselPicture',
                params: { idCarouselPicture: idCarouselPicture }
            };
            SendRequest(config, callback);
        };
    }]);