AgrimarketControllers.controller('builtPriceManagerController', ['$scope', '$rootScope', '$routeParams', 'offerService', 'parametersFactory', 'athenaOrdersService', 'coverService', '$sce', '$location','dateService', '$filter',
    function ($scope, $rootScope, $routeParams, offerService, parametersFactory, athenaOrdersService, coverService, $sce, $location, dateService, $filter) {

        var idOffer = $routeParams.idoffer;
        $scope.offer = {};
        $scope.isPopUpBuiltPriceOrder = true;
        $scope.labelExpiry = "";
        $scope.successMessageEditable = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_EditVariableForBase_SuccessMessage.Content;
        $scope.titlePage = $rootScope.editableObjectsFactory.EditableObjects.Menu_Admin_Offer_BuiltPriceManager.Content;
        $scope.offerLabel = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_BuiltPriceManager_OfferLabel.Content;
        $scope.totalQtyLabel = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_BuiltPriceManager_QuantityTotalLabel.Content;
        $scope.qtyExitBuiltPriceLabel = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_BuiltPriceManager_QuantityOfContractsAnticipatedExitLabel.Content;
        $scope.qtyAvailableLabel = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_BuiltPriceManager_QuantityAvailableForAnticipatedExitLabel.Content;
        
        $scope.dateObservation_Label = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_Date_Observation.Content;
        $scope.percentDay_Label = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_PercentDay.Content;
        $scope.percentFixeAccount_Label = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_PercentFixedAccount.Content;
        $scope.qtyToCovered_Label = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_QtyToCovered.Content;
        $scope.qtyExitAnticipated_Label = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_QtyExitAnticipated.Content;
        $scope.qtyCoveredWithLots_Label = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_QtyCoveredWithLots.Content;
        $scope.qtyCovered_Label = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_QtyCovered.Content;
        $scope.qtyToExitAnticipated_Label = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_QtyToExitAnticipated.Content;
        $scope.qtyRemainingToSell_Label = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_QtyRemainingToSell.Content;
        $scope.expiryCover_Label = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_ExpiryCover.Content;
        $scope.TotalBaseComponent_Label = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_TotalBaseComponentValue.Content;
        $scope.averageMarketPriceWithoutExitAnticipated_Label = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_AverageMarketPriceWithoutExitAnticipated.Content;
        $scope.averagePriceWithoutExitAnticipated_Label = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_AveragePriceWithoutExitAnticipated.Content;
        
        $scope.startDateFilter_Label = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_StartDateFilter.Content;
        $scope.endDateFilter_Label = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_EndDateFilter.Content;

        $scope.market_Label = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_Market_Label.Content;
        $scope.nbLotsCover_Label = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_NbLotsCover.Content;
        $scope.nbLotsToCover = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_NbLotsToCover.Content;
        $scope.paramNbDaysFilterDateBuiltPriceObservations = parametersFactory.Parameters.NbDaysFilterDateBuiltPriceObservations.Value;


        $scope.price_Label = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_Price.Content ;
        $scope.numOrder_Label = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_NumOrder.Content ;
        $scope.numExt_Label = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_NumExt.Content ;
        $scope.tonsCovered_Label = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_TonsCovered.Content ;
        $scope.lotCovered_Label = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_LotCovered.Content ;
        $scope.anyLot_Label =  $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_AnyLot.Content ;
        $scope.date_Label = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_Date.Content ;

        var init = function () {

            offerService.GetInfoBuiltPriceForAdmin(idOffer, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                } else {
                    $scope.isEuronextOpen = data.IsEuronextOpen;
                    $scope.offer = data;
                    $scope.qtyAvailableForExit = $filter('roundByDecimalAndConverter')(data.QuantityAvailableForAnticipatedExitBuiltPrice, '2');
                    $scope.StartDateFilter = $filter('date')(data.InfoBuiltPriceFilterVm.StartDateFilter, 'dd/MM/yyyy');
                    $scope.EndDateFilter = $filter('date')(data.InfoBuiltPriceFilterVm.EndDateFilter, 'dd/MM/yyyy');

                    $scope.getInfoBuiltPriceObservationsForAdmin(idOffer, $scope.StartDateFilter, $scope.EndDateFilter);
                }
            });
        };

        $scope.filterDate = function () {
            $scope.errorMessage = "";
            $scope.getInfoBuiltPriceObservationsForAdmin(idOffer, $scope.StartDateFilter, $scope.EndDateFilter);
        };

        $scope.saveOfferInfos = function () {
            $scope.errorMessage = null;
            $scope.successMessage = null;
            $scope.expiryAndBaseComponents = [];

            $scope.infoBuiltPriceObservations.LstBuiltPriceObservations.forEach(function(e) {
                $scope.expiryAndBaseComponents.push({
                    "IdOfferBuiltPriceObservation" : e.IdOfferBuiltPriceObservation,
                    "IdExpiryWsd": e.IdExpiryWsd,
                    "BaseComponentValues": e.LstBaseComponentValues
                });
            });

            offerService.PostInfoBuiltPriceForAdmin(
                {
                    IdOffer: idOffer,
                    QuantityAvailableForAnticipatedExitBuiltPrice: $scope.qtyAvailableForExit,
                    ExpiryAndBaseComponents: $scope.expiryAndBaseComponents
                },
                function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = data[0].errors[0].Message;
                    } else {
                        $scope.successMessage = $scope.successMessageEditable;
                        $scope.init();
                    }
                }
            );
        };

        $scope.getInfoBuiltPriceObservationsForAdmin = function(idOffer, startDateFilter, endDateFilter){
            offerService.GetInfoBuiltPriceObservationsForAdmin(idOffer, startDateFilter, endDateFilter,  function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                } else {
                                
                    var nowDate = new Date(); 
                    data.LstBuiltPriceObservations.forEach(function(item) { 
                        if (dateService.getFormatDateFromApi(item.DateObservation) == ((nowDate.getDate() < 10 ? '0' : '') + nowDate.getDate()+'/'+(nowDate.getMonth()+1)+'/'+nowDate.getFullYear())) {  
                            item.cssColorLine = "current_day" ;
                        } else if (dateService.compareDate1SuperiorDate2(nowDate, new Date(item.DateObservation))) {
                            item.cssColorLine = "past_day";
                        }
                    });
                    $scope.infoBuiltPriceObservations = data;
                }
            });
        };

        $scope.OpenPopUpOrder = function (openPopup) {
            $scope.orderPopUp = {};
            getInfosPopUpAthenaOrderBuiltPrice(idOffer, openPopup);
        };

        $scope.SavePopupOrder = function () {
            athenaOrdersService.PostAthenaOrderVm($scope.orderPopUp, function (data, status) {
                if (status !== null) {
                    if (status == 400) {
                        $scope.popupOrderErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                        $scope.openPopupOrder();
                    }
                }
                else
                    $scope.init();
            });
        };

        $scope.OpenPopUpSellOrders = function (openPopup, idOfferBuiltPriceObservation) {
            //appel api
            $scope.modalTitleSellOrders = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_LotCovered.Content ;

            $scope.errorMessageSellOrders = "";

            athenaOrdersService.GetAthenaOrdersSellByIdOfferBuiltPriceObservation(idOfferBuiltPriceObservation, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessageSellOrders = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.lstAthenaOrdersSell = data;
                    openPopup();
                }
            });
        };


        $scope.OpenPopUpExitAnticipatedOrders = function (openPopup, idOfferBuiltPriceObservation) {
            //appel api
            $scope.modalTitleExitAnticipatedOrders = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_ExitAnticipateds.Content ;

            $scope.errorMessageExitAnticipatedOrders = "";

            athenaOrdersService.GetAthenaOrdersExitAnticipatedByIdOfferBuiltPriceObservation(idOfferBuiltPriceObservation, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessageExitAnticipatedOrders = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.lstAthenaOrdersExitAnticipated = data;
                    openPopup();
                }
            });


        };

        $scope.Cancel = function () {
            $location.path('/admin/offers/' + ($rootScope.filtersListingOffers ? $rootScope.filtersListingOffers.idStatus : '' ));
        };

        function getInfosPopUpAthenaOrderBuiltPrice(idOffer, openPopup) {
            athenaOrdersService.GetInfosPopUpAthenaOrderBuiltPrice(idOffer,  function (data, status) {
                if (status !== null) {
                    if (status === 400){
                        $scope.errorMessage = data[0].errors[0].Message;
                    }
                } else {
                    $scope.labelExpiry = data.LabelExpiry;
                    $scope.orderPopUp.IdAccount = data.IdAccount;
                    $scope.orderPopUp.IdExpiryWsd = data.IdExpiry;
                    $scope.orderPopUp.Direction = data.Direction;
                    $scope.orderPopUp.IdOrderType = data.IdOrderType;
                    $scope.orderPopUp.IdMarketWsd = data.IdMarket;
                    $scope.orderPopUp.TxtQtyToSend = $rootScope.editableObjectsFactory.EditableObjects.Admin_Built_Price_Manager_PopUp_TxtQtyToSend.Content.replace("[QtyToSend]", data.QtyToSend);
                    $scope.orderPopUp.IdOfferBuiltPrice = idOffer;

                    $scope.openPopupOrder = openPopup;
                    
                    $scope.popupOrderErrorMessage = "";
                    $scope.modalTitle = $rootScope.editableObjectsFactory.EditableObjects.ModalTitleAddOrder.Content;
                
                    getAccounts();
                    openPopup();
                }
            });
        }

        function getAccounts() {
            coverService.GetAllAccounts(function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.popupOrderErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else
                    $scope.lstAccounts = data;
            });
        }

        init();
    }]);
