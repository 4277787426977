AgrimarketControllers.controller('dashboardController', [
    '$scope', '$rootScope', '$http', '$filter', '$location', '$sce', 'dashboardService',
    'enumFactory', 'quoteService', 'quoteCashHashtagriService', 'configurationFactory', 'carouselPictureService', '$timeout',
    function ($scope, $rootScope, $http, $filter, $location, $sce, dashboardService, enumFactory, quoteService, quoteCashHashtagriService, configurationFactory, carouselPictureService, $timeout) {

        if (!$rootScope.ModuleNews || !$rootScope.ModuleNews.Active)
            $location.path("/");

        
    }]);
