AgrimarketServices.service('contractingDelayService', ['parametersFactory', 'editableObjectsFactory', 'modulesFactory', 'enumFactory', 'contractsService',
    function (parametersFactory, editableObjectsFactory, modulesFactory, enumFactory, contractsService) {

        this.InitialisationContractingDelay = function (counter) {
            if ((modulesFactory.Modules.ModuleAutomaticCover.Active && parametersFactory.Parameters.AutomaticCover.Value) || parametersFactory.Parameters.IsDisplayContractingDelay.Value === "1" ) {
                // Sauvegarde de l'heure de début de la prise de position
                contractsService.SaveDateDebutSubscriptionContract(function (dataSave, statuSave) {
                    if (statuSave === null) {
                        counter.timer = parametersFactory.Parameters.ContractingDelay.Value ? parametersFactory.Parameters.ContractingDelay.Value : "00:00";

                        if (parametersFactory.Parameters.IsDisplayContractingDelay.Value === "1") {
                            // Counter global style
                            counter.counterType = "circular";
                            counter.strokeWidth = 3;
                            counter.strokeWidthBg = 6; // Si circular, doit être >= à strokeWidth, si 'linear' alors doit être == à strokeWidth !!!
                            counter.showShadow = true;
                            counter.showRays = false;
                            counter.colorRays = '#ffffff';
                            // Counter circular
                            counter.counterCicularSize = 20;
                            // Counter linear
                            counter.counterLinearWidth = 100; // valeur en %
                            // Typo
                            counter.fontSize = 11;
                            counter.fontColor = '#656565';
                            // Counter colors
                            counter.colorCounter = '#9acf1e';
                            counter.colorCounterBg = '#f1f1f1';
                            counter.colorCounterHalf = '#ed9731';
                            counter.colorCounterThird = '#881c1c';

                            counter.start = true;
                        }
                    }
                });
            }
        };

        this.VerifyContractingDelay = function (timeToContracting) {
            if (!timeToContracting)
                return null;

            if ((modulesFactory.Modules.ModuleAutomaticCover.Active && parametersFactory.Parameters.AutomaticCover.Value === "1") || parametersFactory.Parameters.IsDisplayContractingDelay.Value === "1") {
                var min, second, delay, timeToLimite;
                if (parametersFactory.Parameters.ContractingDelay.Value)
                    delay = parametersFactory.Parameters.ContractingDelay.Value.split(":");
                else {
                    var timeDefault = "00:00";
                    delay = timeDefault.split(":");
                }

                timeToLimite = angular.copy(timeToContracting);
                min = parseInt(delay[0]);
                second = parseInt(delay[1]);

                timeToLimite.setMinutes(timeToLimite.getMinutes() + min);
                timeToLimite.setSeconds(timeToLimite.getSeconds() + second);

                if (new Date() > timeToLimite)
                    return editableObjectsFactory.EditableObjects.Error_OfferSubscribe_DelayOfContratualizationHaveExceeded.Content + min + editableObjectsFactory.EditableObjects.Error_OfferSubscribe_MinuteAbbreviation.Content + second + editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseTakeBack.Content;
                else
                    return null;
            }
            else
                return null;
        };

    }]);