AgrimarketServices.service('productsService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

    	var SendRequest = function (configfile, callback) {
    		$http(configfile)
			    .success(function (data) {
			    	callback(data, null, null, null);
			    })
			    .error(function (data, status, headers, config) {
			    	callback(data, status, headers, config);
			    });
    	};

    	this.GetAllProducts = function (callback) {
    		var config = {
    			method: 'GET',
    			url: configurationFactory.Url['Api'] + 'Product/GetAllProducts'
    		};
    		SendRequest(config, callback);
        };

        this.GetAllProductsLight = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Product/GetAllProductsLight'
            };
            SendRequest(config, callback);
        };

    	this.GetProductsInOffers = function (callback) {
    		var config = {
    			method: 'GET',
    			url: configurationFactory.Url['Api'] + 'Product/GetProductsInOffers'
    		};
    		SendRequest(config, callback);
    	};

		this.GetAllImgProducts = function (callback) {
			var config = {
				method: 'GET',
				url : configurationFactory.Url['Api'] + 'Product/GetAllImgProducts'
			};
			SendRequest(config, callback);
		};

    	this.SaveProduct = function (product, callback) {
    		var config = {
    			method: 'PUT',
    			url: configurationFactory.Url['Api'] + 'Product/PutProduct',
    			headers: { 'Content-Type': 'application/json' },
    			data: product
    		};
    		SendRequest(config, callback);
    	};

		this.PutImgProduct = function (imageLibraryProduct, callback) {
			var config = {
				method: 'POST',
				url: configurationFactory.Url['Api'] + 'Product/PutImgProduct',
				headers: { 'Content-Type': 'application/json' },
				data: imageLibraryProduct
			};
			SendRequest(config, callback);
		};

    	this.SaveVariety = function (variety, callback) {
    		var config = {
    			method: 'PUT',
    			url: configurationFactory.Url['Api'] + 'Product/PutVariety',
    			headers: { 'Content-Type': 'application/json' },
    			data: variety
    		};
    		SendRequest(config, callback);
    	};

		this.CheckImageLibraryIsValid = function (IdImageLibrary) 
		{
			if (IdImageLibrary == undefined | IdImageLibrary == null) {
				return " Veuillez choisir une image";
			}
			return "";
		};

    }]);