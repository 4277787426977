AgrimarketServices.service('articleService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory', '$timeout',
	function ($rootScope, $http, $location, currentUserFactory, configurationFactory, $timeout) {

	    var SendRequest = function (configfile, callback) {
	        return $http(configfile)
				.success(function (data) {
				    callback(data, null);
				})
				.error(function (data, status) {
				    callback(data, status);
				});
	    };

	    this.GetArticles = function (callback) {
	        var config = {
	            method: 'GET',
	            url: configurationFactory.Url['Api'] + 'Article/GetArticles'
	        };
	        SendRequest(config, callback);
	    };

	    this.PostArticles = function (lstUserArticles, skin, callback) {
	        var config = {
	            method: 'POST',
	            url: configurationFactory.Url['Api'] + 'Article/PostUserArticles',
	            data: lstUserArticles,
	            params: { skin: skin }
	        };
	        SendRequest(config, callback);
	    };

	    this.FindArticle = function(searchString, timeoutPromise, callback) {
	        var config = {
	            method: 'GET',
	            url: configurationFactory.Url['Api'] + 'Article/FindArticle',
	            params: { searchString: searchString }
	        };
	        return SendRequest(config, callback);
	    };

	    this.GetUserArticlesWithFilter = function (searchText, max, pageNo, sortKey, reverse, callback) {
	        var config = {
	            method: 'GET',
	            url: configurationFactory.Url['Api'] + 'Article/GetUserArticlesWithFilter',
	            params: {
	            	searchText: searchText,
	            	itemPerPage: max,
	            	pageNo: pageNo,
	            	sortKey: sortKey,
	            	reverse: reverse
	            }
	        };
	        SendRequest(config, callback);
	    };
	}]);