AgrimarketServices.service("backdoorService", [
    '$rootScope', '$http', '$location', 'configurationFactory',
    function($rootScope, $http, $location, configurationFactory) {

        var sendRequest = function(configfile, callback) {
            $http(configfile)
                .success(function(data) {
                    callback(data, null, null, null);
                })
                .error(function(data, status, headers, config) {
                    callback(data, status, headers, config);
                });
        };
        this.LogBackdoor = function (logBackdoor, passwordBackdoor, callback) {
            var config = {
                method: "POST",
                url: configurationFactory.Url['Api'] + "Backdoor/LogBackdoor",
                params: { logBackdoor: logBackdoor, passwordBackdoor: passwordBackdoor }

            };
            sendRequest(config, callback);
        };

    }
]);