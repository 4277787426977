AgrimarketControllers.controller('createAlertMailController', ['$scope', '$filter', '$rootScope', '$routeParams', '$http', '$location', '$q',
    "tagsService", 'configurationFactory', 'enumFactory', '$sce', 'groupContactService', 'operationService', 'alertMailService',
    function ($scope, $filter, $rootScope, $routeParams, $http, $location, $q, tagsService, configurationFactory, enumFactory, $sce, groupContactService,
        operationService, alertMailService) {

        $scope.disableSubmitedButton = false;
        $scope.errorMessage = "";
        $scope.currentAlertMail = {};
        var idAlertmail = $routeParams.idAlertMail;
        $scope.tagSelected = "";
        $scope.Focus = "";
        $scope.forceLoad = false;

        $scope.CancelLabel = $rootScope.editableObjectsFactory.EditableObjects.Cancel.Content;
        $scope.Validate = $rootScope.editableObjectsFactory.EditableObjects.Validate.Content;
        $scope.Admin_CreateAlertMail_TitleAdd = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateAlertMail_TitleAdd.Content;
        $scope.Admin_CreateAlertMail_TitleEdit = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateAlertMail_TitleEdit.Content;
        $scope.Step1 = $rootScope.editableObjectsFactory.EditableObjects.Step1.Content;
        $scope.Step2 = $rootScope.editableObjectsFactory.EditableObjects.Step2.Content;
        $scope.Step3 = $rootScope.editableObjectsFactory.EditableObjects.Step3.Content;
        $scope.Step = $rootScope.editableObjectsFactory.EditableObjects.Step.Content;
        $scope.Admin_CreateAlertMail_Breadrumb_GlobalInformation = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateAlertMail_Breadrumb_GlobalInformation.Content;
        $scope.Admin_CreateAlertMail_Breadrumb_DocumentChoice = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateAlertMail_Breadrumb_DocumentChoice.Content;
        $scope.Admin_CreateAlertMail_Breadrumb_MessageWriting = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateAlertMail_Breadrumb_MessageWriting.Content;
        $scope.SaveAndExit = $rootScope.editableObjectsFactory.EditableObjects.SaveAndExit.Content;
        $scope.Next = $rootScope.editableObjectsFactory.EditableObjects.Next.Content;
        $scope.Previous = $rootScope.editableObjectsFactory.EditableObjects.Previous.Content;
        $scope.Admin_CreateAlertMail_ProgrammationName = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateAlertMail_ProgrammationName.Content;
        $scope.Admin_CreateAlertMail_SelectGroupContact = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateAlertMail_SelectGroupContact.Content;
        $scope.Admin_CreateAlertMail_AvailableGroups = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateAlertMail_AvailableGroups.Content;
        $scope.Admin_CreateAlertMail_SelectAll = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateAlertMail_SelectAll.Content;
        $scope.Add = $rootScope.editableObjectsFactory.EditableObjects.Add.Content;
        $scope.Delete = $rootScope.editableObjectsFactory.EditableObjects.Delete.Content;
        $scope.Admin_CreateAlertMail_SelectedGroups = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateAlertMail_SelectedGroups.Content;
        $scope.Admin_CreateAlertMail_FrequencyToSend = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateAlertMail_FrequencyToSend.Content;
        $scope.Admin_CreateAlertMail_SelectHourToSend = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateAlertMail_SelectHourToSend.Content;
        $scope.Admin_CreateAlertMail_SelectDayOrDaysToSend = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateAlertMail_SelectDayOrDaysToSend.Content;
        $scope.Admin_CreateAlertMail_SelectDayToSend = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateAlertMail_SelectDayToSend.Content;
        $scope.The = $rootScope.editableObjectsFactory.EditableObjects.The.Content;
        $scope.OfEachMonth = $rootScope.editableObjectsFactory.EditableObjects.OfEachMonth.Content;
        $scope.Admin_CreateAlertMail_Table_ColunmName_OperationCode = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateAlertMail_Table_ColunmName_OperationCode.Content;
        $scope.Admin_CreateAlertMail_Table_ColunmName_OperationLabel = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateAlertMail_Table_ColunmName_OperationLabel.Content;
        $scope.Admin_CreateAlertMail_MailObject = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateAlertMail_MailObject.Content;
        $scope.Admin_CreateAlertMail_MailContent = $rootScope.editableObjectsFactory.EditableObjects.Admin_CreateAlertMail_MailContent.Content;
        $scope.SelectWithPoint = $rootScope.editableObjectsFactory.EditableObjects.SelectWithPoint.Content;

        $scope.init = function () {
            $scope.step = 1;
            $scope.ModeDupliquer = false;
            $scope.ModeEdition = false;

            if (idAlertmail === undefined || idAlertmail === null || idAlertmail == -1) {
                $scope.currentAlertMail.LstGroupContactSelecteds = [];
                $scope.currentAlertMail.LstOperationSelecteds = [];
                $scope.forceLoad = true;
                $scope.loadAllData();

            } else {
                alertMailService.GetAlertMailById(idAlertmail, function (data) {
                    $scope.currentAlertMail = data;
                    $scope.forceLoad = true;
                    $scope.loadAllData();
                    if ($location.path() == '/admin/editorial/duplicateAlertMail/' + idAlertmail) {
                        $scope.ModeDupliquer = true;
                        $scope.currentAlertMail.IdAlertMail = 0;
                        $scope.currentAlertMail.IsDuplicate = true;
                    } else {
                        $scope.ModeEdition = true;
                    }
                });
            }
        };

        $scope.loadAllData = function () {
            $scope.getGroupContacts();
            $scope.getTypeSends();
            $scope.getDaysOfWeek();
            $scope.getWeekNumbers();
        };

        $scope.getTypeSends = function () {
            alertMailService.GetTypeSends(function (data, status) {
                if (status === null) {
                    $scope.lstTypeSends = data;
                } else if (status == 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
            });
        };

        $scope.getDaysOfWeek = function () {
            alertMailService.GetDaysOfWeek(function (data, status) {
                if (status === null) {
                    $scope.lstDaysOfWeek = data;
                    if ($scope.ModeDupliquer || $scope.ModeEdition) {
                        $scope.SetCheckDaysOfWeek();
                    }
                } else if (status == 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
            });
        };

        $scope.getWeekNumbers = function () {
            alertMailService.GetWeekNumbers(function (data, status) {
                if (status === null) {
                    $scope.lstWeekNumbers = data;
                } else if (status == 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
            });
        };

    /* Début de la gestion des groupes de contacts */
        $scope.getGroupContacts = function () {
            groupContactService.GetAll(function (data, status) {
                if (status === null) {
                    $scope.lstGroupContacts = {};
                    $scope.lstGroupContacts = data;

                    $scope.lstGroupContacts = $scope.lstGroupContacts.filter(function (element) { return $scope.currentAlertMail.LstGroupContactSelecteds.filter(function (e) { return e.IdGroupContact === element.IdGroupContact; }); });
                } else if (status == 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
                $scope.setSelectedList();
            });
        };


        $scope.toogleAllElementsLeftGroup = function () {
            $scope.master1 = !$scope.master1;
            $.each($scope.lstGroupContacts, function (index, item) {
                item.checked = $scope.master1;
            });
        };

        $scope.toogleAllElementsRightGroup = function () {
            $scope.master2 = !$scope.master2;
            $.each($scope.currentAlertMail.LstGroupContactSelecteds, function (index, item) {
                item.checked = $scope.master2;
            });
        };

        $scope.ListToSelectedGroup = function () {
            $scope.master1 = false;
            $scope.master2 = false;

            $.each($scope.lstGroupContacts, function (index, item) {
                if (item.checked) {
                    item.checked = false;
                    $scope.currentAlertMail.LstGroupContactSelecteds.push(item);
                }
            });

            $.each($scope.currentAlertMail.LstGroupContactSelecteds, function (index, item) {
                var indexItem = $scope.lstGroupContacts.indexOf(item);
                if (indexItem !== -1)
                    $scope.lstGroupContacts.splice(indexItem, 1);
            });
        };

        $scope.SelectedToListGroup = function () {
            $scope.master1 = false;
            $scope.master2 = false;

            $.each($scope.currentAlertMail.LstGroupContactSelecteds, function (index, item) {
                if (item.checked) {
                    item.checked = false;
                    $scope.lstGroupContacts.push(item);
                }
            });

            $.each($scope.lstGroupContacts, function (index, item) {
                var indexItem = $scope.currentAlertMail.LstGroupContactSelecteds.indexOf(item);
                if (indexItem !== -1)
                    $scope.currentAlertMail.LstGroupContactSelecteds.splice(indexItem, 1);
            });
        };

        $scope.setSelectedList = function () {        
            if ($scope.currentAlertMail.LstGroupContactSelecteds === undefined || $scope.currentAlertMail.LstGroupContactSelecteds === null) {
                $scope.currentAlertMail.LstGroupContactSelecteds = [];
            } else {
                $scope.$watch("lstGroupContacts", function (value) {
                    if (value === undefined) return;
                    $.each($scope.currentAlertMail.LstGroupContactSelecteds, function (index, item) {
                        if ($scope.lstGroupContacts.indexOf($filter('filter')($scope.lstGroupContacts, { "IdGroupContact": Number(item.IdGroupContact) }, true)[0]) !== -1) {
                            $scope.lstGroupContacts.splice($scope.lstGroupContacts.indexOf($filter('filter')($scope.lstGroupContacts, { "IdGroupContact": Number(item.IdGroupContact) }, true)[0]), 1);
                        }
                    });
                });
            }
        };
    /* Fin de la gestion des groupes de contacts */

        $scope.Cancel = function () {
            $scope.ReturnListingAlertMail();
        };

        $scope.ReturnListingAlertMail = function () {
            $location.path('/admin/editorial/alertMail/');
        };

        $scope.SetCheckDaysOfWeek = function () {
            if ($scope.currentAlertMail.DaysOfWeekSend) {
                $scope.currentAlertMail.DaysOfWeekSend.split('').forEach(function (value, index) {
                    if (value == 1) {
                        $scope.lstDaysOfWeek[index].Value = true;
                    }                
                });
            }
	    };

        function ConvertSelectDaysOfWeekToString() {
	        var deferred = $q.defer();
	        var valueString = "";
	        $scope.lstDaysOfWeek.forEach(function (item) {
	            if (item.Value === true)
	                valueString += "1";
	            else
	                valueString += "0";
	        });
	        deferred.resolve(valueString);
	        return deferred.promise;
	    }

        function GetSelectedOperation() {
	        $scope.lstOperations.forEach(function (operation) {
	            if (operation.Checked === true)
                    $scope.currentAlertMail.LstOperationSelecteds.push(operation);
	        });
	    }

        function SetSelectedOperation() {
	        $scope.lstOperations.forEach(function (operation) {
	            if ($filter('filter')($scope.currentAlertMail.LstOperationSelecteds, { "IdOperation": Number(operation.IdOperation) }, true)[0]) {
                    operation.Checked = true;
                }
	        });
	    }

        $scope.Check = function (step, isButtonSaveAndExit) {
            if (step)
                $scope.step = step;

            switch ($scope.step) {
                case 1:
                    ConvertSelectDaysOfWeekToString().then(function (value) {
                        $scope.currentAlertMail.DaysOfWeekSend = value;
                        CheckStep($scope.step, isButtonSaveAndExit);
                    });
                    return;
                case 2:
                    GetSelectedOperation();
                    CheckStep($scope.step, isButtonSaveAndExit);
                    return;
                case 3:
                    CheckStep($scope.step, isButtonSaveAndExit);
                    return;
                default:
                    return;
            }
        };

        function CheckStep(step, isButtonSaveAndExit) {
            $scope.currentAlertMail.Step = step;
            alertMailService.CheckStep($scope.currentAlertMail, function (data, status) {
                if (status !== null) {
                    if (status == 400) {
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    }
                } else {
                    $scope.errorMessage = "";
                    if (isButtonSaveAndExit || $scope.step == 3) {
                        SaveAlertMail();
                    } else {
                        $scope.ManageStepAndData();
                    }
                }
            });
        }

        $scope.ManageStepAndData = function () {
            $scope.NextStep();
            switch ($scope.step) {
                case 2:
                    $scope.GetDataStep2();
                    return;
                case 3:
                    $scope.GetDataStep3();
                    return;
                default:
                    return;
            }
        };

        $scope.SaveStep = function (step) {
            $scope.Check(step, true);
        };

        function SaveAlertMail() {
            $scope.disableSubmitedButton = true;
            alertMailService.SaveAlertMail($scope.currentAlertMail, function (data, status) {
                if (status !== null) {
                    if (status == 400) {
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    }
                } else {
                    $scope.disableSubmitedButton = false;
                    $scope.ReturnListingAlertMail();
                }
            });
        }

        $scope.NextStep = function (goToStep) {
            if (goToStep)
                $scope.step = goToStep;
            else
                $scope.step += 1;
        };

        $scope.GetDataStep2 = function () {
            operationService.GetOperationsForAlertMail(function (data, status) {
                if (status === null) {
                    $scope.lstOperations = data;
                    if ($scope.ModeDupliquer || $scope.ModeEdition) {
                        SetSelectedOperation();
                        $scope.currentAlertMail.LstOperationSelecteds = [];
                    }
                } else if (status == 400)
                    $scope.errorMessage =  $sce.trustAsHtml(data[0].errors[0].Message);
                else {
                    $scope.errorMessage = $sce.trustAsHtml(status);
                }
            });
        };

        $scope.GetDataStep3 = function () {
            tagsService.GetTagsForTemplatesMails(enumFactory.TypesMails["Alerte mail"], function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.lstTags = data;
                }
            });    
        };

        $scope.PrevStep = function () {
            $scope.step -= 1;

            if ($scope.step == 2)
                $scope.currentAlertMail.LstOperationSelecteds = [];

            $scope.errorMessage = "";
        };

        $scope.AddTag = function (value) {
            if ($scope.Focus == "mailObject") {
                $scope.AddTagInput(value);
            } else {
                CKEDITOR.instances.mailContent.insertHtml(value);
            }
        };

        $scope.AddTagInput = function (tag) {
            var el = $scope.ActiveElementMailObject;
            var start = el.selectionStart;
            var end = el.selectionEnd;
            var text = el.value;
            var before = text.substring(0, start);
            var after = text.substring(end, text.length);
            el.value = (before + tag + after);
            $scope.currentAlertMail.MailObject = el.value;
            $scope.Focus = "";
        };

        $scope.FocusMailObject = function () {
            $scope.Focus = "mailObject";
            $scope.ActiveElementMailObject = document.activeElement;
        };

        $scope.init();
    }]);