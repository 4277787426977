AgrimarketServices.service('groupContactService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null);
                })
                .error(function (data, status) {
                    callback(data, status);
                });
        };

        this.GetAll = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'GroupContact/GetAll'
            };
            SendRequest(config, callback);
        };

        this.GetAllForManage = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'GroupContact/GetAllForManage'
            };
            SendRequest(config, callback);
        };

        this.GetUserForGroup = function (idgroupContact, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'GroupContact/GetUserForGroup',
                params: { idGroup: idgroupContact }
            };
            SendRequest(config, callback);
        };

        this.SaveGroupContact = function (groupContact, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'GroupContact/SaveGroupContact',
                data: groupContact
            };
            SendRequest(config, callback);
        };

        this.DeleteGroupContact = function (idgroupContact, callback) {
            var config = {
                method: 'DELETE',
                url: configurationFactory.Url['Api'] + 'GroupContact/DeleteGroupContact',
                params: { id: idgroupContact }
            };
            SendRequest(config, callback);
        };

        this.AddUserInGroupContact = function (idGroupContact, idUser, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'GroupContact/AddUserInGroupContact',
                params: { idGroupContact: idGroupContact, idUser: idUser }
            };
            SendRequest(config, callback);
        };

        this.RemoveUserInGroupContact = function (idGroupContact, idUser, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'GroupContact/RemoveUserInGroupContact',
                params: { idGroupContact: idGroupContact, idUser: idUser }
            };
            SendRequest(config, callback);
        };

        this.GetGroupContactsInOffers = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'GroupContact/GetGroupContactsInOffers'
            };
            SendRequest(config, callback);
        };
    }]);