AgrimarketServices.service('blocDispositionService', ['$http', '$location', 'currentUserFactory', 'configurationFactory',
	function ($http, $location, currentUserFactory, configurationFactory) {

	    var SendRequest = function (configfile, callback) {
	        $http(configfile)
				.success(function (data) {
				    callback(data, null);
				})
				.error(function (data, status) {
				    callback(data, status);
				});
	    };

	    this.GetBlocDispositions = function (callback) {
	        var config = {
	            method: 'GET',
	            url: configurationFactory.Url['Api'] + 'BlocDisposition/GetBlocDispositions'
	        };
	        SendRequest(config, callback);
	    };

        this.GetBlocDispositionsFiltered = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'BlocDisposition/GetBlocDispositionsFiltered'
            };
            SendRequest(config, callback);
        };
        
        this.GetContentBlocDispositionById = function (id, callback) {
            var config = {
                method: 'GET',
                params: {
                    id: id
                },
                url: configurationFactory.Url['Api'] + 'BlocDisposition/GetContentBlocDispositionById'
            };
            SendRequest(config, callback);
        };

	    this.SaveBlocDispositions = function (lstBlocDispositions, callback) {
	        var config = {
	            method: 'POST',
	            data: lstBlocDispositions,
	            url: configurationFactory.Url['Api'] + 'BlocDisposition/SaveBlocDispositions'
	        };
	        SendRequest(config, callback);
	    };


        this.SaveGroupContactsOfContentBlocDisposition = function (idContentBlocDisposition, lstIdGroupContacts, callback) {
            var config = {
                method: 'POST',
                data: lstIdGroupContacts,
                params: {
                    idContentBlocDisposition: idContentBlocDisposition
                },
                url: configurationFactory.Url['Api'] + 'BlocDisposition/SaveGroupContactsOfContentBlocDisposition'
            };
            SendRequest(config, callback);
        };

	}]);