AgrimarketControllers.controller('coverController', [
    '$scope', '$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory', 'cropsService', '$filter',
    'parametersService', '$sce', 'parametersFactory', 'coverService', 'productsService', 'typeCoversService', 'offerService', 'coverParametersService', 'enumFactory', '$routeParams',
    function ($scope, $rootScope, $http, $location, currentUserFactory, configurationFactory, cropsService, $filter, parametersService, $sce, parametersFactory,
        coverService, productsService, typeCoversService, offerService, coverParametersService, enumFactory, $routeParams) {

        $scope.cover = {
            isAutomatic: '0',
        };

        $scope.paramsAccountCover =
            {
                Id: null,
                Name: null,
                IsGlobal: false,
                ShowDetailAccount: true
            };

        $scope.paramsDelayCover =
            {
                ShowDelayCover: true
            };

        $scope.lstProducts = [];
        $scope.lstTypeCovers = [];
        $scope.lstAccounts = [];
        $scope.TypeCover = enumFactory.TypeCover;
        $scope.enumTypeQuotes = enumFactory.TypeQuotes;
        $scope.filterTabs = {
            IdProduct: "",
            IdTypeCover: "",
            IdCrop: ""
        };
        $scope.viewMode = $routeParams.typeView;
        $scope.typeParameters = enumFactory.TypeParameters;
        $scope.filterCover = {
            idProductSelected: "",
            idCropSelected: ""
        };
        $scope.enumTypeOffer = enumFactory.TypeOffer;
        if (!$rootScope.ModuleAutomaticCover.Active)
            $location.path('/admin/parameters/global');

        $scope.RefreshTableOffers = function (initProducts) {
            offerService.GetAllFinalisedActivesVm(function (data, status) {
                $scope.filterTabs.IdTypeCover = "";
                if (status !== null) {
                    if (status == 400)
                        $scope.offerErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.offerTabs = angular.copy(data);

                    // Filtre Produit
                    if (initProducts) {
                        angular.forEach($scope.offerTabs, function (item) {
                            if ($filter('filter')($scope.lstProducts, { IdProduct: item.IdProduct }).length === 0)
                                $scope.lstProducts.push({ IdProduct: item.IdProduct, ProductLabel: item.LabelProduct });
                        });
                        if ($scope.lstProducts.length === 1)
                            $scope.filterTabs.IdProduct = $scope.lstProducts[0].IdProduct;
                    }

                    // Filtre Type couverture
                    $scope.lstTypeCovers = [];
                    angular.forEach($scope.offerTabs, function (item) {
                        if ($filter('filter')($scope.lstTypeCovers, { IdTypeCover: item.IdTypeCover }).length === 0) {
                            var typeCover = $filter('filter')($scope.typeCovers, { IdTypeCover: item.IdTypeCover });
                            if (typeCover.length > 0)
                                $scope.lstTypeCovers.push({ IdTypeCover: item.IdTypeCover, Label: typeCover[0] }.Label);
                        }
                    });
                    if ($scope.lstTypeCovers.length === 1)
                        $scope.filterTabs.IdTypeCover = $scope.lstTypeCovers[0].IdTypeCover;

                    // Filtre campagne
                    $scope.lstCrops = [];
                    angular.forEach($scope.offerTabs, function (item) {
                        if ($filter('filter')($scope.lstCrops, { IdCrop: item.IdCrop }).length === 0)
                            $scope.lstCrops.push({ IdCrop: item.IdCrop, Label: item.LabelCrop });
                    });
                    if ($scope.lstCrops.length === 1)
                        $scope.filterTabs.IdCrop = $scope.lstCrops[0].IdCrop;

                    $scope.FilterTableOffers();

                    if ($scope.offers)
                        $scope.offers.forEach(function (offer) {
                            if (offer.IdTypeQuote === $scope.enumTypeQuotes.Fixe) {
                                offer.IdTypeCover = $scope.TypeCover.Manuel;
                            }
                        });
                }
            });
        };

        $scope.init = function () {
            parametersService.GetParameters(function (dataParam, statusParam) {
                if (statusParam !== undefined && statusParam !== null) {
                    if (statusParam == 400) {
                        $scope.loginErrorMessage = dataParam[0].errors[0].Message;
                    }
                } else {
                    parametersFactory.Parameters = dataParam;
                    $scope.cover.isAutomatic = (parametersFactory.Parameters.AutomaticCover.Value === "1");
                }
            });
            var idsGroupParameters = enumFactory.GroupParameters.ContractingDelay + ";" + enumFactory.GroupParameters.DelayedCover;
            parametersService.GetParametersByIdsGroup(idsGroupParameters, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else
                    $scope.globalParameters = data;
            });

            coverService.GetAllAccounts(function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.accountErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.lstAccounts = angular.copy(data);
                    $scope.GlobalChoice();
                }
            });

            cropsService.GetCropsVisible(function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.productErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.crops = data;
                    if (data.length === 0) {
                        $scope.productErrorMessageFixed = "Vous n'avez renseigné aucun produit pour le moment.";
                    }
                    $scope.crop = data[0];
                }
            });


            productsService.GetAllProducts(function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.productErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.products = data;
                    if (data.length === 0) {
                        $scope.productErrorMessageFixed = "Vous n'avez renseigné aucun produit pour le moment.";
                    }
                    $scope.product = data[0];
                    $scope.filterCover.idProductSelected = angular.copy(data[0].IdProduct);

                    coverParametersService.GetCoverParameters(function (data, status) {
                        if (status !== null) {
                            if (status == 400)
                                $scope.productErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                        } else {
                            $scope.coverParemeters = data;

                        }

                        $scope.FilterCoverParameters();
                    });
                }
            });




            offerService.GetAllOffersFinalisedActives(function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.offerErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.offers = angular.copy(data);
                    if (data.length === 0)
                        $scope.offerErrorMessageFixed = "Vous n'avez renseigné aucune offre finalisée pour le moment.";
                    else {
                        $scope.offer = angular.copy(data[0]);
                        $scope.idOfferSelected = angular.copy(data[0].IdOffer);
                    }
                }
            });

            typeCoversService.GetTypeCovers(function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.offerErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.typeCovers = angular.copy(data);
                    $scope.RefreshTableOffers(true);
                }
            });
            $scope.message = "";
        };

        $scope.CheckAutomaticCover = function (showPopUp) {
            $scope.ResetMessages();
            var foundProductOrOffer = false;
            if (parametersFactory.Parameters.AutomaticCover.Value == "1" && $scope.cover.isAutomatic === "0") {
                $scope.products.some(function (product) {
                    if (product.IdTypeCover == enumFactory.TypeCover.Automatique || product.IdTypeCover == enumFactory.TypeCover.Differee) {
                        foundProductOrOffer = true;
                        return true;
                    }
                    return false;
                });
                if (!foundProductOrOffer) {
                    $scope.offers.some(function (offer) {
                        if (offer.IdTypeCover == enumFactory.TypeCover.Automatique || offer.IdTypeCover == enumFactory.TypeCover.Differee) {
                            foundProductOrOffer = true;
                            return true;
                        }
                        return false;
                    });
                }

                if (foundProductOrOffer) {
                    showPopUp();
                    return;
                }

            }
            //Recherche du sous compte global
            if ($scope.account === undefined || $scope.account === null || $scope.account === "") {
                var error = false;
                $scope.account = null;
                $scope.products.forEach(function (product) {
                    if (product.IdAccount === undefined || product.IdAccount === null || product.IdAccount === "") {
                        $scope.errorMessage = "Si le sous compte global est vide, il faut mettre un sous compte pour chaque produit";
                        error = true;
                        return;
                    }
                });
                if (error)
                    return;
            }
            $scope.UpdateCover();
        };

        $scope.CheckParameterProduct = function () {
            $scope.ResetMessages();

            if ($scope.coverParemeter.IdTypeCover == enumFactory.TypeCover.Differee) {
                if ($scope.coverParemeter.UpperLimit === undefined || $scope.coverParemeter.UpperLimit === null)
                    $scope.productErrorMessage += "Veuillez renseigner une borne haute.<br/>";
                else {
                    if ($scope.coverParemeter.UpperLimit < 0)
                        $scope.productErrorMessage += "La borne haute doit être positive.<br/>";

                }
                if ($scope.coverParemeter.LowerLimit === undefined || $scope.coverParemeter.LowerLimit === null)
                    $scope.productErrorMessage += "Veuillez renseigner une borne basse.<br/>";
                else {
                    if ($scope.coverParemeter.LowerLimit > 0)
                        $scope.productErrorMessage += "La borne basse doit être négative.<br/>";
                }
            }

            if ($scope.productErrorMessage !== "") {
                $scope.productErrorMessage = $sce.trustAsHtml($scope.productErrorMessage);
                return false;
            }

            $scope.UpdateParameterProductAndOffers();

            return true;
        };

        $scope.CheckParameterOffer = function () {
            $scope.ResetMessages();
            if ($scope.offer.IdTypeCover == enumFactory.TypeCover.Differee) {
                if ($scope.offer.UpperLimit === undefined || $scope.offer.UpperLimit === null)
                    $scope.offerErrorMessage += "Veuillez renseigner une borne haute.<br/>";
                else {
                    if ($scope.offer.UpperLimit < 0)
                        $scope.offerErrorMessage += "La borne haute doit être positive.<br/>";

                }
                if ($scope.offer.LowerLimit === undefined || $scope.offer.LowerLimit === null)
                    $scope.offerErrorMessage += "Veuillez renseigner une borne basse.<br/>";
                else {
                    if ($scope.offer.LowerLimit > 0)
                        $scope.offerErrorMessage += "La borne basse doit être négative.<br/>";
                }
            }

            if ($scope.offerErrorMessage !== "") {
                $scope.offerErrorMessage = $sce.trustAsHtml($scope.offerErrorMessage);
                return false;
            }
            else
                return true;
        };

        $scope.UpdateCover = function () {
            coverService.SaveParameterCover($scope.cover.isAutomatic, function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                } else {
                    parametersFactory.Parameters.AutomaticCover.Value = data;
                    $scope.message = "Votre changement de paramètre a bien été pris en compte";
                    $scope.RefreshTableOffers(false);
                }
            });
        };

        $scope.UpdateParameterProduct = function () {
            coverParametersService.SaveCoverParameter($scope.coverParemeter, function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.productErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.coverParemeter.IdCoverParameter = data.IdCoverParameter;
                    var cover = $scope.coverParemeters.filter(function (element) { return (element.IdCoverParameter === data.IdCoverParameter); })[0];

                    if (cover === undefined) {
                        $scope.coverParemeters.push(data);
                    }
                    else {
                        cover.IdTypeCover = data.IdTypeCover;
                        cover.LowerLimit = data.LowerLimit;
                        cover.UpperLimit = data.UpperLimit;
                        cover.IdAccount = data.IdAccount;
                    }

                    $scope.productValidationMessage = "Vos modifications ont bien été prises en compte.";
                    $scope.RefreshTableOffers(false);
                }
            });
            return true;
        };

        $scope.UpdateParameterOffer = function () {
            if (!$scope.CheckParameterOffer())
                return false;

            offerService.SaveParameterOffer($scope.offer, function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.offerErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.offers = data;
                    $scope.offerValidationMessage = "Vos modifications ont bien été prises en compte.";
                    $scope.RefreshTableOffers(false);
                }
            });
            return true;
        };

        $scope.UpdateParameterProductAndOffers = function () {
            $scope.UpdateParameterProduct();
            $scope.UpdateParametersOfferByCover($scope.coverParemeter);
        };

        $scope.UpdateParametersOfferByCover = function (cover) {
            $scope.ResetMessages();
            offerService.SaveParametersOfferByCover(cover, function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.productErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.offers = data;
                    $scope.offer = $filter('filter')($scope.offers, { IdOffer: parseInt($scope.offer.IdOffer) })[0];
                    $scope.productValidationMessage = "Vos modifications ont bien été prises en compte.";
                    $scope.RefreshTableOffers(false);
                }
            });
        };

        $scope.ChangeElementSelected = function (id, typeDDL) {
            $scope.ResetMessages();
            if (typeDDL == "Product")
                $scope.product = angular.copy($filter('filter')($scope.products, { IdProduct: id })[0]);
            else if (typeDDL == "Offer")
                $scope.offer = angular.copy($filter('filter')($scope.offers, { IdOffer: id })[0]);
        };

        $scope.ResetMessages = function () {
            $scope.productErrorMessage = $scope.productValidationMessage = "";
            $scope.offerErrorMessage = $scope.offerValidationMessage = "";
            $scope.accountErrorMessage = $scope.brokerErrorMessage = "";
            $scope.message = $scope.errorMessage = "";
            $scope.DelayerrorMessage = "";
        };

        $scope.FilterTableOffers = function () {
            $scope.offerTabsFilter = angular.copy($scope.offerTabs);
            if ($scope.filterTabs.IdProduct !== "")
                $scope.offerTabsFilter = angular.copy($scope.offerTabsFilter.filter(function (element) { return (element.IdProduct === Number($scope.filterTabs.IdProduct)); }));
            if ($scope.filterTabs.IdTypeCover !== "")
                $scope.offerTabsFilter = angular.copy($scope.offerTabsFilter.filter(function (element) { return (element.IdTypeCover === Number($scope.filterTabs.IdTypeCover)); }));
            if ($scope.filterTabs.IdCrop !== "")
                $scope.offerTabsFilter = angular.copy($scope.offerTabsFilter.filter(function (element) { return (element.IdCrop === Number($scope.filterTabs.IdCrop)); }));
        };

        $scope.FilterCoverParameters = function () {

            var idCrop = !$scope.filterCover.idCropSelected ? null : Number($scope.filterCover.idCropSelected);

            $scope.coverParemeter = angular.copy(
                $scope.coverParemeters.filter(function (element) { return (element.IdProduct === Number($scope.filterCover.idProductSelected) && element.IdCrop === idCrop); })[0]
            );

            if ($scope.coverParemeter == undefined) {
                $scope.coverParemeter = {
                    IdProduct: $scope.filterCover.idProductSelected,
                    IdCrop: $scope.filterCover.idCropSelected,
                    IdTypeCover: 1
                };
            }
        };

        $scope.GlobalChoice = function () {
            if ($scope.lstAccounts.length > 0)
                $scope.lstAccounts.forEach(function (item) {
                    if (item.IsGlobal) {
                        $scope.paramsAccountCover.Id = item.Id;
                        $scope.account = item.Name;
                        return;
                    }
                });
        };

        $scope.SaveParameters = function () {
            if (!$scope.CheckDelayParameters())
                return false;

            parametersService.SaveParametersGlobal($scope.globalParameters, function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                } else {
                    $scope.paramsAccountCover.IsGlobal = true;
                    coverService.SaveParamsAccount($scope.paramsAccountCover, function (data, status) {

                        if (status !== null) {
                            if (status == 400)
                                $scope.errorMessage = data[0].errors[0].Message;
                        } else {
                            $scope.lstAccounts = angular.copy(data);
                            $scope.GlobalChoice();
                            $scope.message = "Votre changement de paramètre a bien été pris en compte.";
                        }
                    });
                }
            });
            return true;
        };

        $scope.CheckDelayParameters = function () {
            $scope.ResetMessages();
            var time;
            $scope.globalParameters.forEach(function (group) {
                group.Parameters.forEach(function (param) {
                    if (param.Key == 'ContractingDelay') {
                        if (param.Value === undefined || param.Value === null || param.Value === "")
                            $scope.DelayerrorMessage += "Veuillez renseigner un délai de contractualisation valide.<br/>";
                        else {
                            time = param.Value.split(':');
                            if (!(time.length === 2 && time[0] <= 59 && time[1] <= 59))
                                $scope.DelayerrorMessage += "Le delai de contractualisation n'est pas dans le bon format 'mm:ss'.<br/>";
                        }
                    }

                    if (param.Key == 'HourEndDelayedCover') {
                        if (param.Value === undefined || param.Value === null || param.Value === "")
                            $scope.DelayerrorMessage += "Veuillez renseigner un horaire de fin de validité des couvertures diférée.<br/>";
                        else {
                            time = param.Value.split(':');
                            if (!(time.length === 2 && time[0] <= 23 && time[1] <= 59))
                                $scope.DelayerrorMessage += "L'horaire de fin de validité des couvertures diférée n'est pas dans le bon format 'hh:mm'.<br/>";
                        }
                    }

                });
            });

            if ($scope.paramsAccountCover.Id === undefined || $scope.paramsAccountCover.Id === null) {
                $scope.DelayerrorMessage += "Veuillez renseigner un sous-compte global <br/>";
            } else {
                $scope.paramsAccountCover.IsGlobal = true;
            }

            if ($scope.DelayerrorMessage !== "") {
                $scope.DelayerrorMessage = $sce.trustAsHtml($scope.DelayerrorMessage);
                return false;
            }
            else
                return true;
        };

        $rootScope.onRefresh = function () {
            $scope.init();
        };

        $scope.init();

    }]);