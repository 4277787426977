/*
*   Directive de gestion d'upload de fichier
*	3 parametres sont necessaire :
		-percentValue : pourcentage du telechargement en temps réel
		-fileNameValue : nom du fichier a faire apparaitre
		-errorValue : objet ou stocker les erreurs
*   2 fonctions sont nécéssaires:
*       -uploadFunctionName: nom de la fonction pour lancer l'upload
*		-deleteFunctionName: Nom de la fonction pour supprimer l'upload
*		-uploadFinishFunction : Fonction a lancer apres l'upload du fichier 
*		-buttonFunctionName : Fonction appler par un bouton qui lance l'explorateur (si on desire avoir un simple bouton plutot qu'un input type file)
*
*/

AgrimarketDirectives.directive('uploadfile', ['$timeout', '$upload', 'configurationFactory', 'uploadService',
	function($timeout, $upload, configurationFactory, uploadService) {
		return {
			link: function(scope, iElement, iAttrs) {


				if (iAttrs.fileNameValue !== undefined && iAttrs.fileNameValue !== null && iAttrs.fileNameValue !== "") {
					if (scope[iAttrs.fileNameValue] === undefined || scope[iAttrs.fileNameValue] === null || scope[iAttrs.fileNameValue] === "") {
						scope[iAttrs.fileNameValue] = '';
					}
				}
				if (iAttrs.percentValue !== undefined && iAttrs.percentValue !== null && iAttrs.percentValue !== "") {
					scope[iAttrs.percentValue] = '';
				}
				if (iAttrs.linkFileValue !== undefined && iAttrs.linkFileValue !== null && iAttrs.linkFileValue !== "") {
					scope[iAttrs.linkFileValue] = '';
				}

				if (iAttrs.buttonFunctionName !== undefined && iAttrs.buttonFunctionName !== null && iAttrs.buttonFunctionName !== "") {
					scope[iAttrs.buttonFunctionName] = function(idInput) {
						$("#" + idInput).click();
					};
				}

				scope[iAttrs.uploadFunctionName] = function($files) {

					var upload = function(i, $file) {

						$upload.upload({
							url: configurationFactory.Url['Api'] + 'Upload/Upload', // webapi url
							method: "POST",
							params: { idAttachment: !scope[iAttrs.modelIdAttachement] || isNaN(scope[iAttrs.modelIdAttachement]) ? -1 : scope[iAttrs.modelIdAttachement] },
							file: $file
						}).progress(function(evt) {
							// get upload percentage
							if (iAttrs.percentValue !== undefined && iAttrs.percentValue !== null && iAttrs.percentValue !== "") {
								scope[iAttrs.percentValue] = parseInt(100.0 * evt.loaded / evt.total) + '%';
							}
						}).success(function(data) {
				
							if (iAttrs.fileNameValue !== undefined && iAttrs.fileNameValue !== null && iAttrs.fileNameValue !== "") {
								scope[iAttrs.fileNameValue] = data.PublicLabel;
							}
							if (iAttrs.listAttachment !== undefined && iAttrs.listAttachment !== null && iAttrs.listAttachment !== "") {
								scope[iAttrs.listAttachment].push(data);
                            }

                            // file is uploaded successfully
                            if (iAttrs.uploadFinishFunction !== undefined && iAttrs.uploadFinishFunction !== null && iAttrs.uploadFinishFunction !== "") {
                                scope[iAttrs.uploadFinishFunction](data);
                            }
						}).error(function(data) {
							if (iAttrs.errorValue !== undefined && iAttrs.errorValue !== null && iAttrs.errorValue !== "") {
								scope[iAttrs.errorValue] = data;
                            }
						});
					};

					for (var i = 0; i < $files.length; i++) {
						var $file = $files[i];
						//(function (index) {

						if (iAttrs.fileNameValue !== undefined && iAttrs.fileNameValue !== null && iAttrs.fileNameValue !== "") {
							scope[iAttrs.fileNameValue] = '';
						}
						if (iAttrs.percentValue !== undefined && iAttrs.percentValue !== null && iAttrs.percentValue !== "") {
							scope[iAttrs.percentValue] = '';
						}
						if (iAttrs.linkFileValue !== undefined && iAttrs.linkFileValue !== null && iAttrs.linkFileValue !== "") {
							scope[iAttrs.linkFileValue] = '';
						}

						upload(i, $file);
						//})(i);
					}

				};

				scope[iAttrs.deleteFunctionName] = function (idAttachement) {
					var item = scope[iAttrs.listAttachment].filter(function (elem) {
						return elem.IdAttachment == idAttachement;
					})[0];
					uploadService.DeleteUpload(idAttachement, function(data, status) {
						if (status === undefined || status === null) {
							scope[iAttrs.listAttachment].splice(scope[iAttrs.listAttachment].indexOf(item), 1);
							if (iAttrs.fileNameValue !== undefined && iAttrs.fileNameValue !== null && iAttrs.fileNameValue !== "") {
								scope[iAttrs.fileNameValue] = '';
							}
							if (iAttrs.percentValue !== undefined && iAttrs.percentValue !== null && iAttrs.percentValue !== "") {
								scope[iAttrs.percentValue] = '';
							}
							if (iAttrs.linkFileValue !== undefined && iAttrs.linkFileValue !== null && iAttrs.linkFileValue !== "") {
								scope[iAttrs.linkFileValue] = '';
							}
							
						} else if (status == 400) {
							if (iAttrs.errorValue !== undefined && iAttrs.errorValue !== null && iAttrs.errorValue !== "") {
								scope[iAttrs.errorValue] = data[0].errors[0].Message;
							} else {
								if (iAttrs.errorValue !== undefined && iAttrs.errorValue !== null && iAttrs.errorValue !== "") {
									scope[iAttrs.errorValue] = status;
								}
							}
						}
					});
				};
			}
		};
	}]);