AgrimarketServices.service('notificationsService', ['$http', 'configurationFactory',
    function ($http, configurationFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null);
                })
                .error(function (data, status) {
                    callback(data, status);
                });
        };

        this.GetNotificationsPaginate = function (itemPerPage, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Notification/GetNotificationsPaginate',
                params: {
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };
            SendRequest(config, callback);
        };

        this.DeleteNotification = function (idNotification, callback) {
            var config = {
                method: 'DELETE',
                url: configurationFactory.Url['Api'] + 'Notification/DeleteNotification',
                params: {
                    idNotification: idNotification
                }
            };
            SendRequest(config, callback);
        };

        this.GetNotificationById = function (idNotification, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Notification/GetNotificationById',
                params: {
                    idNotification: idNotification ? idNotification : ""
                }
            };
            SendRequest(config, callback);
        };

        this.Save = function (notification, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Notification/Save',
                data: notification
            };
            SendRequest(config, callback);
        };
}]);