AgrimarketServices.service('quoteForexHashtagriService', ['$rootScope', '$http', '$location', 'configurationFactory',
    function ($rootScope, $http, $location, configurationFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
			    .success(function (data) {
			        callback(data, null, null, null);
			    })
			    .error(function (data, status, headers, config) {
			        callback(data, status, headers, config);
			    });
        };

        
        this.GetHistoForexByIdForexFromWsdApi = function (idForex, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'QuoteForexHashtagri/GetHistoForexByIdForexFromWsdApi',
                params: {
                    idForex: idForex
                }
            };
            SendRequest(config, callback);
        };
    }]);