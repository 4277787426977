AgrimarketServices.service('pdfService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
	function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

		var SendRequest = function (configfile, callback) {
			$http(configfile)
				.success(function (data) {
					callback(data, null);
				})
				.error(function (data, status) {
					callback(data, status);
				});
		};

		this.GetPDFConctract = function (idcontract, callback) {
			var config = {
				method: 'GET',
				url: configurationFactory.Url['Api'] + 'Pdf/GetContract',
				params: { idcontract: idcontract }
			};
			SendRequest(config, callback);
			
		};
		
		
	}]);