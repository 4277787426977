AgrimarketServices.service('categoryArticleService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
	function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

		var SendRequest = function (configfile, callback) {
			$http(configfile)
				.success(function (data) {
					callback(data, null);
				})
				.error(function (data, status) {
					callback(data, status);
				});
		};

		this.GetCategoryArticle = function (callback) {
			var config = {
				method: 'GET',
				url: configurationFactory.Url['Api'] + 'CategoryArticle/GetCategoryArticle'
			};
			SendRequest(config, callback);
		};

	}]);