AgrimarketControllers.controller("conditionsController", ["$scope", "$routeParams", "conditionsService", "typeOperationsService", "$filter","$rootScope",
    function ($scope, $routeParams, conditionsService, typeOperationsService, $filter, $rootScope) {


        /************** ViewMode = treeConditions ************/

        $scope.InitTreeConditions = function () {

            $scope.selectedAutoCompleteInput = {
                selectedTree: null
            };
            $scope.currentTree = {};
            $scope.currentTreeSelected = {};
            $scope.changed = false;
            $scope.treesFilter = [];
            $scope.lstTrees = [];
            $scope.typeOperations = [];
            $scope.showDetailtrees = [];
            $scope.isDisplayTree = false;
            $scope.isAbreCondPart = false;
            $scope.currentIndexTreeOperation = 0;
            $scope.formGroupCondition = { IdHidden: false };
            $scope.formGroupCondition.groupSelected = {};
            $scope.formGroupCondition.lstParticularConditions = [];
            $scope.indexCondition = -1;
            $scope.lstGroupConditions = [];
            $scope.showValidation = true;
            $scope.currentTreeGroupCondition = null;
            $scope.treeGroupFounded = false;

            typeOperationsService.GetTypeOperations(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                } else
                    $scope.typeOperations = data;
            });

            conditionsService.GetTrees(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                } else {
                    data.forEach(function (tree) {
                        tree.TreeGroups.forEach(function (group) {
                            $scope.currentIndexTreeOperation = $scope.GenerateIndexCondition(group, $scope.currentIndexTreeOperation, 0);
                        });
                    });
                    $scope.lstTrees = $scope.treesFilter = data;
                }

                $scope.treeDeleteToolTip = "Supprimer";

            });

            conditionsService.GetGroupConditions(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                } else {
                    $scope.lstGroupConditions = data;
                }
            });
        };

        $scope.GenerateIndexCondition = function (group, index) {
            group.TreeOperations.forEach(function (treeOperation) {
                index++;
                treeOperation.Index = index;
                treeOperation.TreeGroups.forEach(function (treeOperationGroup) {
                    index = $scope.GenerateIndexCondition(treeOperationGroup, index);
                });
            });

            return index;
        };

        $scope.$watch("selectedAutoCompleteInput.selectedTree", function (newValue) {
            if (newValue !== undefined && newValue !== null) {
                $scope.Filter();
            }
        });

        $scope.SaveTree = function () {
            $scope.showValidation = false;
            conditionsService.SaveTree($scope.currentTree, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                } else {
                    console.dir(data);
                    $scope.currentTree.IdTree = data;
                    var tree = angular.copy($scope.currentTree);
                    $scope.InitTreeConditions();
                    $scope.currentTreeSelected = tree;
                    $scope.DisplayTree();
                    $scope.succesMessage = "Vos modifications ont bien été prises en compte";
                }
                $scope.showValidation = true;
            });
        };

        $scope.VerifTree = function () {
            $scope.ResetMessages();
            if (!$scope.currentTree) {
                $scope.errorMessage = "Veuillez selectionnez un arbre";
                return false;
            }

            if ($scope.currentTree.Label === null || $scope.currentTree.Label === "" || $scope.currentTree.Label === undefined) {
                $scope.errorMessage = "Veuillez renseignez un nom";
                return false;
            }

            $scope.SaveTree();
            return true;
        };

        $scope.CheckChangeTree = function (item, popup) {

            $scope.currentTreeSelected = item;
            $scope.PopupChangeTreeSelectedTitle = "Modification en cours";
            if ($scope.changed)
                popup();
            else
                $scope.DisplayTree();
        };

        $scope.DisplayTree = function () {
            if ($scope.currentTreeSelected) {
                $scope.ResetMessages();
                $scope.changed = false;
                $scope.IdTreeDisplay = $scope.currentTreeSelected.IdTree;
                $scope.currentTree = angular.copy($scope.currentTreeSelected);
                $scope.isDisplayTree = true;
            }
        };

        $scope.AddTree = function () {
            $scope.ResetMessages();
            $scope.currentTree = {};
            $scope.IdTreeDisplay = null;
            $scope.isDisplayTree = true;
        };

        $scope.Filter = function () {
            $scope.errorMessage = "";
            var filterGeneral = angular.copy($scope.lstTrees);

            if ($scope.selectedAutoCompleteInput.selectedTree !== undefined && $scope.selectedAutoCompleteInput.selectedTree !== null && $scope.selectedAutoCompleteInput.selectedTree !== "") {
                filterGeneral = filterGeneral.filter(function (element) {
                    if ($scope.CheckElement(element.Label, $scope.selectedAutoCompleteInput.selectedTree)) {
                        return element;
                    }
                    return null;
                });
            }
            $scope.treesFilter = filterGeneral;
        };

        $scope.DeleteTree = function (item, popup) {
            $scope.ResetMessages();
            $scope.isDisplayTree = false;
            $scope.PopupDeleteTreeSelectedTitle = "Supprimer un arbre";
            $scope.messageConfirmPopup = "Êtes vous sûr de vouloir supprimer cet arbre ?";
            $scope.currentTree = angular.copy(item);
            $scope.cleanGroup = false;
            popup();
        };

        $scope.DeleteCurrentTree = function (popup) {
            $scope.PopupDeleteTreeSelectedTitle = "Supprimer le contenu de cet arbre";
            $scope.messageConfirmPopup = "Êtes vous sûr de vouloir supprimer le contenu de cet arbre ?";
            $scope.cleanGroup = true;
            popup();
        };

        $scope.DeleteTreeSelected = function () {
            if (!$scope.currentTree)
                return;

            if (!$scope.cleanGroup) {
                conditionsService.DeleteTree($scope.currentTree.IdTree, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = data[0].errors[0].Message;
                    } else {
                        $scope.InitTreeConditions();
                        $scope.succesMessage = "Votre suppression a bien été prise en compte";
                    }
                });
            } else {
                $scope.currentTree.TreeGroups = [];
            }
        };

        $scope.ResetMessages = function () {
            $scope.succesMessage = "";
            $scope.errorMessage = "";
            $scope.indexOperation = 0;
            $scope.indexGroup = null;
            $scope.IdTreeGroupCondition = null;
            $scope.update = false;
            $scope.delete = false;
            $scope.deleteServer = false;
        };

        $scope.ChangePosition = function (positionarray) {
            $scope.changed = true;
            var ids = positionarray.MovedId.split("_");
            if (ids.length === 3) {
                var idOperation = ids[1];
                var groups;
                if (idOperation === "0") //on est a la racine
                {
                    var group = angular.copy($scope.currentTree.TreeGroups[positionarray.MovedIndexStart]);
                    groups = angular.copy($scope.currentTree.TreeGroups);

                    groups.splice(positionarray.MovedIndexStart, 1);
                    groups.splice(positionarray.MovedIndexEnd, 0, group);

                    $scope.currentTree.TreeGroups = groups;
                } else {
                    groups = $scope.FindGroup(positionarray, $scope.currentTree.TreeGroups, idOperation);
                }
                $scope.currentTree.TreeGroups = groups;
            }
        };

        $scope.FindGroup = function (positionarray, treeGroups, idOperation) {
            treeGroups.forEach(function (group) {
                group.TreeOperations.forEach(function (operation) {
                    if (("" + operation.Index) === idOperation) {
                        var group = angular.copy(operation.TreeGroups[positionarray.MovedIndexStart]);
                        var groups = angular.copy(operation.TreeGroups);

                        groups.splice(positionarray.MovedIndexStart, 1);
                        groups.splice(positionarray.MovedIndexEnd, 0, group);

                        operation.TreeGroups = groups;
                        return operation.TreeGroups;
                    } else {
                        operation.TreeGroups = $scope.FindGroup(positionarray, operation.TreeGroups, idOperation);
                    }
                });
            });
            return treeGroups;
        };

        $scope.AddTreeGroup = function (indexOperation, popup) {
            $scope.ResetMessages();
            $scope.indexOperation = indexOperation;
            if ($scope.lstGroupConditions.length > 0) {
                $scope.formGroupCondition.lstGroupConditions = angular.copy($scope.lstGroupConditions);
                $scope.formGroupCondition.groupSelected = angular.copy($scope.lstGroupConditions[0]);
                $scope.formGroupCondition.groupSelected.AllCondition = false;
            }
            popup();
        };

        $scope.UpdateTreeGroup = function (indexOperation, indexGroup, treeGroup, popup) {
            $scope.ResetMessages();
            $scope.indexOperation = indexOperation;
            $scope.indexGroup = indexGroup;
            $scope.IdTreeGroupCondition = treeGroup.IdTreeGroupCondition;
            $scope.formGroupCondition.IsHidden = treeGroup.IsHidden;
            $scope.formGroupCondition.IsEditable = treeGroup.IsEditable;
            $scope.formGroupCondition.DoNotImpactThePrice = treeGroup.DoNotImpactThePrice;

            $scope.update = true;
            if ($scope.lstGroupConditions.length > 0) {
                $scope.formGroupCondition.lstGroupConditions = angular.copy($scope.lstGroupConditions);
            }

            for (var i = 0; i < $scope.formGroupCondition.lstGroupConditions.length; i++) {
                if ($scope.formGroupCondition.lstGroupConditions[i].IdGroupCondition === treeGroup.IdGroupCondition) {
                    var group = $scope.formGroupCondition.lstGroupConditions[i];

                    for (var j = 0; j < group.ParticularConditions.length; j++) {
                        var condition = group.ParticularConditions[j];
                        for (var k = 0; k < treeGroup.TreeConditions.length; k++) {
                            var treeCondition = treeGroup.TreeConditions[k];
                            if (condition.IdParticularCondition === treeCondition.IdParticularCondition) {
                                condition.IdTreeCondition = treeCondition.IdTreeCondition;
                                condition.DefaultValue = treeCondition.Value;
                                condition.Selected = true;
                                condition.Visible = treeCondition.Visible;
                                group.ParticularConditions[j] = condition;
                            }
                        }

                    }

                    $scope.formGroupCondition.groupSelected = group;

                    break;
                }
            }
            popup();
        };

        $scope.CheckAll = function () {
            $scope.formGroupCondition.groupSelected.AllCondition = !$scope.formGroupCondition.groupSelected.AllCondition;

            $scope.formGroupCondition.groupSelected.ParticularConditions.forEach(function (condition) {
                condition.Selected = $scope.formGroupCondition.groupSelected.AllCondition ? true : false;
            });
        };

        $scope.CheckAddTreeGroupSelected = function () {
            $scope.errorMessagePopup = "";
            var check = false;
            for (var i = 0; i < $scope.formGroupCondition.groupSelected.ParticularConditions.length; i++) {
                var condition = $scope.formGroupCondition.groupSelected.ParticularConditions[i];

                if (condition.Selected) {
                    check = true;
                    if (condition.DefaultValue !== undefined && condition.DefaultValue !== null && condition.DefaultValue !== "" && isNaN(condition.DefaultValue)) {
                        $scope.errorMessagePopup = "La valeur pour la condition " + condition.Label + " doit être un nombre.";
                        return false;
                    } else
                        condition.Value = null;
                }
            }

            // si mode de saissie libre, on ne bloque pas la sauvegarde d'ajout d'un groupe de condition, US 700
            if(!$scope.formGroupCondition.groupSelected.IdTypeFreeInput){
                if (!check) {
                    $scope.errorMessagePopup = "Il faut selectioner au moins une condition particulière";
                    return false;
                }
            }

            // Si on décoche des conditions du TreeGroupCondition, et qu'il existait des TreeOpération dessus, on les supprime
            var conditionsNotSelecteds = $filter('filter')($scope.formGroupCondition.groupSelected.ParticularConditions, { Selected: false });
            var currentTreeGroupCondition = findRecursiveTreeGroup($scope.currentTree.TreeGroups, $scope.IdTreeGroupCondition);
            if (currentTreeGroupCondition && currentTreeGroupCondition.TreeOperations && currentTreeGroupCondition.TreeOperations.length > 0) {
                for (var u = 0; u < conditionsNotSelecteds.length; u++) {
                    var conditionNotSelected = conditionsNotSelecteds[u];
                    /* jshint ignore:start */
                    currentTreeGroupCondition.TreeOperations = $filter('filter')(currentTreeGroupCondition.TreeOperations, function (item) {
                        return item.IdTreeCondition !== conditionNotSelected.IdTreeCondition && item.IdParticularCondition !== conditionNotSelected.IdParticularCondition;
                    });
                    /* jshint ignore:end */
                }
            }

            $scope.treeGroupFounded = false;
            $scope.currentTreeGroupCondition = null;
            return true;
        };

        var findRecursiveTreeGroup = function (treeGroups, idTreeGroupCondition) {
            if (treeGroups && treeGroups.length > 0) {
                for (var w = 0; w < treeGroups.length; w++) {
                    if ($scope.treeGroupFounded)
                        return $scope.currentTreeGroupCondition;

                    var currentTreeGroup = treeGroups[w];
                    if (currentTreeGroup.IdTreeGroupCondition === idTreeGroupCondition) {
                        $scope.treeGroupFounded = true;
                        $scope.currentTreeGroupCondition = currentTreeGroup;
                    }
                    else {
                        if (currentTreeGroup.TreeOperations && currentTreeGroup.TreeOperations.length > 0)
                            for (var z = 0; z < currentTreeGroup.TreeOperations.length; z++) {
                                findRecursiveTreeGroup(currentTreeGroup.TreeOperations[z].TreeGroups, idTreeGroupCondition);
                            }
                    }
                }
            }
        };

        $scope.AddTreeGroupSelected = function () {
            var treeGroup = {
                IdGroupCondition: $scope.formGroupCondition.groupSelected.IdGroupCondition,
                Label: $scope.formGroupCondition.groupSelected.Label,
                IsHidden: $scope.formGroupCondition.IsHidden,
                IsEditable: $scope.formGroupCondition.IsEditable,
                DoNotImpactThePrice: $scope.formGroupCondition.DoNotImpactThePrice,
                TreeConditions: []
            };

            if ($scope.update) {
                treeGroup.IdTreeGroupCondition = $scope.IdTreeGroupCondition;
            }

            $scope.formGroupCondition.groupSelected.ParticularConditions.forEach(function (condition) {
                if (condition.Selected)
                    treeGroup.TreeConditions.push({
                        IdTreeCondition: condition.IdTreeCondition,
                        Value: condition.DefaultValue,
                        Visible: condition.Visible,
                        Label: condition.Label,
                        IdParticularCondition: condition.IdParticularCondition
                    });
            });
            if ($scope.indexOperation === 0) {
                if ($scope.update) {
                    $scope.currentTree.TreeGroups[$scope.indexGroup].TreeConditions = treeGroup.TreeConditions;
                    $scope.currentTree.TreeGroups[$scope.indexGroup].IdGroupCondition = treeGroup.IdGroupCondition;
                    $scope.currentTree.TreeGroups[$scope.indexGroup].Label = treeGroup.Label;
                    $scope.currentTree.TreeGroups[$scope.indexGroup].IsHidden = treeGroup.IsHidden;
                    $scope.currentTree.TreeGroups[$scope.indexGroup].IsEditable = treeGroup.IsEditable;
                    $scope.currentTree.TreeGroups[$scope.indexGroup].DoNotImpactThePrice = treeGroup.DoNotImpactThePrice;
                    
                    if(!$scope.formGroupCondition.groupSelected.IdTypeFreeInput && $scope.currentTree.TreeGroups[$scope.indexGroup].TreeOperations){
                        for (var k = 0; k < $scope.currentTree.TreeGroups[$scope.indexGroup].TreeOperations.length; k++) {
                            $scope.currentTree.TreeGroups[$scope.indexGroup].TreeOperations[k].TreeConditions = treeGroup.TreeConditions;
                        }
                    }
                }
                else {
                    if (!$scope.currentTree.TreeGroups)
                        $scope.currentTree.TreeGroups = [];

                    $scope.currentTree.TreeGroups.push(treeGroup);
                }
            } else {
                $scope.ManageGroupConditon($scope.currentTree.TreeGroups, treeGroup, $scope.indexOperation);
            }
        };

        $scope.ManageGroupConditon = function (groups, groupToInsert, indexOperation) {
            for (var i = 0; i < groups.length; i++) {
                var group = groups[i];
                if (group.TreeOperations && group.TreeOperations.length > 0) {
                    for (var j = 0; j < group.TreeOperations.length; j++) {
                        var treeOperation = group.TreeOperations[j];
                        if (treeOperation.Index === indexOperation) {
                            if (!treeOperation.TreeGroups)
                                treeOperation.TreeGroups = [];

                            if ($scope.delete) {
                                treeOperation.TreeGroups.splice($scope.indexGroup, 1);
                            } else if ($scope.update) {
                                treeOperation.TreeGroups[$scope.indexGroup].TreeConditions = groupToInsert.TreeConditions;
                                treeOperation.TreeGroups[$scope.indexGroup].IdGroupCondition = groupToInsert.IdGroupCondition;
                                treeOperation.TreeGroups[$scope.indexGroup].Label = groupToInsert.Label;
                                treeOperation.TreeGroups[$scope.indexGroup].IsHidden = groupToInsert.IsHidden;
                                treeOperation.TreeGroups[$scope.indexGroup].IsEditable = groupToInsert.IsEditable;
                                treeOperation.TreeGroups[$scope.indexGroup].DoNotImpactThePrice = groupToInsert.DoNotImpactThePrice;

                                if (treeOperation.TreeGroups[$scope.indexGroup].TreeOperations)
                                    for (var k = 0; k < treeOperation.TreeGroups[$scope.indexGroup].TreeOperations.length; k++) {
                                        treeOperation.TreeGroups[$scope.indexGroup].TreeOperations[k].TreeConditions = groupToInsert.TreeConditions;
                                    }
                            } else
                                treeOperation.TreeGroups.push(groupToInsert);

                        } else if (treeOperation.TreeGroups && treeOperation.TreeGroups.length > 0) {
                            ($scope.ManageGroupConditon(treeOperation.TreeGroups, groupToInsert, indexOperation));
                        }
                    }
                }
            }
        };

        $scope.DeleteTreeGroup = function (indexOperation, indexGroup, popup) {
            $scope.ResetMessages();
            $scope.indexOperation = indexOperation;
            $scope.indexGroup = indexGroup;
            $scope.delete = true;
            $scope.PopupDeleteTreeGroupSelectedTitle = "Supprimer un groupe";
            popup();
        };

        $scope.DeleteTreeGroupSelected = function () {
            if ($scope.indexOperation === 0) {
                $scope.currentTree.TreeGroups.splice($scope.indexGroup, 1);
            } else {
                $scope.ManageGroupConditon($scope.currentTree.TreeGroups, null, $scope.indexOperation);
            }
        };

        $scope.AddTreeOperation = function (indexOperation, indexGroup) {
            $scope.ResetMessages();
            if (indexOperation === 0) {
                $scope.currentIndexTreeOperation++;
                if (!$scope.currentTree.TreeGroups[indexGroup].TreeOperations)
                    $scope.currentTree.TreeGroups[indexGroup].TreeOperations = [];

                $scope.currentTree.TreeGroups[indexGroup].TreeOperations.push({
                    IdTreeOperation: 0,
                    IdTypeOperation: $scope.typeOperations[0].IdTypeOperation,
                    IdTreeCondition: $scope.currentTree.TreeGroups[indexGroup].TreeConditions[0].IdTreeCondition,
                    IdParticularCondition: $scope.currentTree.TreeGroups[indexGroup].TreeConditions[0].IdParticularCondition,
                    TypeOperation: $scope.typeOperations[0].Label,
                    TreeGroups: [],
                    TreeConditions: $scope.currentTree.TreeGroups[indexGroup].TreeConditions,
                    Index: $scope.currentIndexTreeOperation
                });
                $scope.currentTree.TreeGroups[indexGroup].showDetail = true;
            } else {
                $scope.currentTree.TreeGroups.forEach(function (group) {
                    if (group.TreeOperations && group.TreeOperations.length > 0) {
                        $scope.ManageTreeOperation(group.TreeOperations, indexOperation, indexGroup);
                    }
                });
            }
        };

        $scope.ManageTreeOperation = function (operations, indexOperation, indexGroup) {
            for (var i = 0; i < operations.length; i++) {
                var operation = operations[i];
                if (operation.Index === indexOperation) {
                    if ($scope.delete) {
                        operations.splice(i, 1);
                    } else {
                        $scope.currentIndexTreeOperation++;
                        if (!operation.TreeGroups[indexGroup].TreeOperations)
                            operation.TreeGroups[indexGroup].TreeOperations = [];

                        operation.TreeGroups[indexGroup].TreeOperations.push({
                            IdTreeOperation: 0,
                            IdTypeOperation: $scope.typeOperations[0].IdTypeOperation,
                            IdTreeCondition: operation.TreeGroups[indexGroup].TreeConditions[0].IdTreeCondition,
                            IdParticularCondition: operation.TreeGroups[indexGroup].TreeConditions[0].IdParticularCondition,
                            TypeOperation: $scope.typeOperations[0].Label,
                            TreeGroups: [],
                            TreeConditions: operation.TreeGroups[indexGroup].TreeConditions,
                            Index: $scope.currentIndexTreeOperation
                        });
                        operation.TreeGroups[indexGroup].showDetail = true;
                    }
                } else {
                    if (operation.TreeGroups && operation.TreeGroups.length > 0) {
                        for (var j = 0; j < operation.TreeGroups.length; j++) {
                            var treeGroup = operation.TreeGroups[j];
                            if (treeGroup.TreeOperations && treeGroup.TreeOperations.length > 0)
                                $scope.ManageTreeOperation(treeGroup.TreeOperations, indexOperation, indexGroup);
                        }
                    }
                }
            }
        };

        $scope.DeleteOperation = function (indexOperation, popup) {
            $scope.ResetMessages();
            $scope.delete = true;
            $scope.indexOperation = indexOperation;
            $scope.PopupDeleteOperationSelectedTitle = "Supprimer une condition";

            popup();
        };

        $scope.DeleteOperationSelected = function () {
            if ($scope.indexOperation !== 0) {
                $scope.currentTree.TreeGroups.forEach(function (group) {
                    if (group.TreeOperations && group.TreeOperations.length > 0) {
                        $scope.ManageTreeOperation(group.TreeOperations, $scope.indexOperation, null);
                    }
                });
            }
        };

        /************** ViewMode = groupConditions ************/

        $scope.showGrp = [];
        $scope.InitGroupConditions = function () {

            $scope.selectedGroupAutoComplete = {
                selectedGroup: null
            };

            $scope.ResetForm();

            conditionsService.GetGroupConditions(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                } else {
                    $scope.lstGroups = data;
                }
            });
            $scope.GetTypeFreeInputs();
        };

        $scope.SaveGroupCondition = function () {

            if($scope.groupCondition.IsFreeInput && !$scope.groupCondition.IdTypeFreeInput){
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_Inform_Type_Free_Input_Group_Condition.Content;
                return;
            }

            if ($scope.groupCondition.Label === undefined || $scope.groupCondition.Label === null || $scope.groupCondition.Label === "") {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_Inform_Label_Group_Condition.Content;
                return;
            }

            if ($scope.indexGroup !== -1) {
                $scope.lstGroups[$scope.indexGroup] = angular.copy($scope.groupCondition);
            } else {
                $scope.lstGroups.push(angular.copy($scope.groupCondition));
            }
            $scope.ResetForm();
        };

        $scope.UpdateGroupCondition = function (item, index) {
            $scope.groupCondition = angular.copy(item);
            $scope.groupCondition.IsFreeInput = !!item.IdTypeFreeInput;
            $scope.buttonSaveText = "Modifier";
            $scope.textSave = "Modifier le groupe";
            $scope.indexGroup = index;
        };

        $scope.ResetForm = function () {
            $scope.groupCondition = {};
            $scope.buttonSaveText = "Ajouter";
            $scope.textSave = "Créer un groupe";
            $scope.errorMessage = "";
            $scope.succesMessage = "";
            $scope.errorMessagePopup = "";
            $scope.condition = {};
            $scope.indexGroup = -1;
            $scope.indexCondition = -1;
            $scope.errors = [];
        };

        $scope.SaveGroupConditions = function () {
            $scope.ResetForm();
            $scope.lstGroups.forEach(function (group, indexGroup) {
                if (group.ParticularConditions !== undefined && group.ParticularConditions !== null) {
                    group.ParticularConditions.forEach(function (condition, indexCondition) {

                        var message = "";
                        var error = {};
                        if (condition.DefaultValue === undefined || condition.DefaultValue === null || condition.DefaultValue === "") {
                            condition.DefaultValue = null;
                        } else if (condition.DefaultValue !== "" && isNaN(condition.DefaultValue)) {
                            message = "La valeur par defaut doit être un nombre";
                            error.DefaultValue = true;
                        }

                        if (condition.Label === undefined || condition.Label === null || condition.Label === "") {
                            message = "Veuillez saisir un libellé.";
                            error.Label = true;
                        }

                        if (!condition.Order) {
                            condition.Order = null;
                        } else if (condition.Order !== "" && isNaN(condition.Order)) {
                            message = "L'ordre doit être un nombre";
                            error.Order = true;
                        }

                        if (message !== "") {
                            if ($scope.errors[indexGroup] === undefined || $scope.errors[indexGroup] === null)
                                $scope.errors[indexGroup] = [];

                            $scope.errors[indexGroup][indexCondition] = error;

                            if ($scope.errorMessage === "")
                                $scope.errorMessage = message;
                        }
                    });
                }
            });

            if ($scope.errorMessage === "")
                conditionsService.SaveGroupConditions($scope.lstGroups, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = data[0].errors[0].Message;
                    } else {
                        $scope.InitGroupConditions();
                        $scope.succesMessage = "Vos modifications ont bien été prises en compte";
                    }
                });
        };

        $scope.DeleteGroup = function (index, popup) {
            $scope.ResetForm();
            $scope.PopupDeleteGroupSelectedTitle = "Supprimer un groupe de conditions";
            $scope.CancelBlocked = false;
            $scope.indexGroup = index;

            if ($scope.lstGroups[index].IsInTree) {
                $scope.PopupDeleteGroupSelectedText = "Impossible de supprimer ce groupe car il est actuellement utilisé dans un arbre de conditions.";
                $scope.CancelBlocked = true;
            } else if ($scope.lstGroups[index].HasContract) {
                $scope.PopupDeleteGroupSelectedText = "Impossible de supprimer ce groupe car il est actuellement utilisé dans un ou plusieurs contrats.";
                $scope.CancelBlocked = true;
            }
            else
                $scope.PopupDeleteGroupSelectedText = "Êtes vous sûre de vouloir supprimer ce groupe ?";

            popup();
        };

        $scope.DeleteGroupSelected = function () {

            if ($scope.indexGroup !== -1) {
                $scope.lstGroups.splice($scope.indexGroup, 1);
            }

            $scope.ResetForm();
        };

        $scope.FilterGroup = function (item) {

            if ($scope.selectedGroupAutoComplete.selectedGroup !== undefined && $scope.selectedGroupAutoComplete.selectedGroup !== null && $scope.selectedGroupAutoComplete.selectedGroup !== "") {
                if ($scope.CheckElement(item.Label, $scope.selectedGroupAutoComplete.selectedGroup)) {
                    return true;
                } else
                    return false;
            }
            return true;
        };

        $scope.AddCondition = function (index, popup) {
            $scope.ResetForm();
            $scope.condition = {};
            $scope.indexGroup = index;
            popup();
        };

        $scope.CheckAddConditionSelected = function () {
            $scope.errorMessagePopup = "";

            if ($scope.condition.DefaultValue === undefined || $scope.condition.DefaultValue === null || $scope.condition.DefaultValue === "") {
                $scope.condition.DefaultValue = null;
            } else if ($scope.condition.DefaultValue !== "" && isNaN($scope.condition.DefaultValue)) {
                $scope.errorMessagePopup = "La valeur par defaut doit être un nombre";
                return false;
            }

            if ($scope.condition.Label === undefined || $scope.condition.Label === null || $scope.condition.Label === "") {
                $scope.errorMessagePopup = "Veuillez saisir un libellé.";
                return false;
            }

            return true;
        };

        $scope.AddConditionSelected = function () {

            if ($scope.indexGroup !== -1) {

                if ($scope.lstGroups[$scope.indexGroup].ParticularConditions === undefined || $scope.lstGroups[$scope.indexGroup].ParticularConditions === null)
                    $scope.lstGroups[$scope.indexGroup].ParticularConditions = [];

                $scope.lstGroups[$scope.indexGroup].ParticularConditions.push(angular.copy($scope.condition));
                $scope.showGrp[$scope.indexGroup] = true;
                $scope.ResetForm();
            }
        };

        $scope.DeleteCondition = function (indexGroup, indexCondition, popup) {
            $scope.ResetForm();
            $scope.PopupDeleteConditionSelectedTitle = "Supprimer une condition";
            $scope.indexGroup = indexGroup;
            $scope.indexCondition = indexCondition;

            if ($scope.lstGroups[indexGroup].ParticularConditions[indexCondition].IsInTree) {
                $scope.PopupDeleteConditionSelectedText = "Impossible de supprimer cette condition car elle est actuellement utilisée dans un arbre de conditions.";
                $scope.CancelBlocked = true;
            }
            else
                $scope.PopupDeleteConditionSelectedText = "Êtes vous sûr de vouloir supprimer cette condition ?";

            popup();
        };

        $scope.DeleteConditionSelected = function () {
            if ($scope.indexCondition !== -1) {
                $scope.lstGroups[$scope.indexGroup].ParticularConditions.splice($scope.indexCondition, 1);
            }
        };

        /************* common *************************/

        $scope.viewMode = $routeParams.typeView;

        $scope.Init = function () {
            if ($scope.viewMode === "treeConditions")
                $scope.InitTreeConditions();
            else if ($scope.viewMode === "groupConditions")
                $scope.InitGroupConditions();
        };

        $scope.CheckElement = function (attr, string) {
            if (attr.toLowerCase().indexOf(string.toLowerCase()) !== -1)
                return true;
            else return false;
        };

        $scope.GetTypeFreeInputs = function () {
            conditionsService.GetTypeFreeInputs(function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else
                        $scope.errorMessage = data;
                }
                else {
                    $scope.listTypeFreeInput = data;
                }
            });
        };

        $scope.Init();
    }]);