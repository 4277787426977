AgrimarketControllers.controller('monitoringRequestController', [
	'$scope', '$rootScope', '$routeParams', '$http', '$filter', '$location', '$sce', 'colonnes', 'articleService',
function ($scope, $rootScope, $routeParams, $http, $filter, $location, $sce, colonnes, articleService) {

	if (!$rootScope.Modules.ModuleRequestPrices.Active)
		$location.path('/login');

	$scope.Init = function () {
		$scope.tableDataVisible = colonnes.data.DataVisible.split(',');
		$scope.tableDataLabels = colonnes.data.ColumnName.split(',');
		$scope.searchText = "";
		$scope.data = { reverse: true, sortKey: "RequestDate" };
		$scope.pageNo = 1;
		$scope.max = 10;
		$scope.GetUserArticles();
	};

	$scope.GetUserArticles = function() {
		articleService.GetUserArticlesWithFilter($scope.searchText, $scope.max, $scope.pageNo, $scope.data.sortKey, $scope.data.reverse, function(data, status) {
			if (status !== null) {
				if (status === 400)
					$scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
			} else {
				$scope.userArticles = data.ListVm;
				$scope.total = data.Total;
			}
		});
	};

	$scope.GetData = function (pageNo) {
		$scope.pageNo = pageNo;
		$scope.GetUserArticles();
	};

	$scope.Sort = function (sortKey, reverse) {
		$scope.data.sortKey = sortKey;
		$scope.data.reverse = reverse;
		$scope.GetUserArticles();
	};

	$scope.$watch("searchText", function (newValue, oldValue) {
		if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
			$scope.GetUserArticles();
	});

	$scope.SaveAndRedirect = function (path) {
		$location.path(path);
	};

	$scope.Init();
}
]);