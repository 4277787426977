AgrimarketServices.service('dashboardService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

    	var SendRequest = function (configfile, callback) {
    		$http(configfile)
                .success(function (data) {
                	callback(data, null, null, null);
                })
                .error(function (data, status, headers, config) {
                	callback(data, status, headers, config);
                });
    	};

    	this.GetDashboard = function (callback) {
    		var config = {
    			method: 'GET',
    			url: configurationFactory.Url['Api'] + 'Dashboard/GetDashboard'
    		};
    		SendRequest(config, callback);
    	};

    	this.PostPositionBlocs = function (newPosition, callback) {
    		var config = {
    			method: 'POST',
    			url: configurationFactory.Url['Api'] + 'Dashboard/PostPositionBlocs',
    			data: newPosition
    		};
    		SendRequest(config, callback);
    	};
    }
]);