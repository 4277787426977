AgrimarketControllers.controller('redirectOuiFieldController', ['$scope', '$rootScope', '$window', 'parametersFactory', 'authenticationService',
    function ($scope, $rootScope, $window, parametersFactory, authenticationService) {

        $scope.errorMessage = '';
        authenticationService.RedirectOnOuiField($rootScope.User.IdExt ,function (data, status) {
            if (status !== null) {
                if (status === 400) {
                    $scope.errorMessage = data[0].errors[0].Message;
                }
            } else {
                var splitParam = parametersFactory.Parameters.OuiFieldParameter.Value.split(';');
                localStorage.setItem("guid",data.Guid);
                $window.location.href = (splitParam[1] + data.Token);
            }
        });
    }
]);
