AgrimarketControllers.controller('commitmentsController', [
    '$scope', '$rootScope', '$routeParams', '$http', '$location', 'contractsService', '$filter', 'colonnesTypeCommitments', 'colonnesContractTypeCommitmentAndProduct', 'colonnesContractProduct', 'colonnesContractTypeCommitment',
    'enumFactory', 'colonnesListFixationsByIdCommitment',
    function ($scope, $rootScope, $routeParams, $http, $location, contractsService, $filter, colonnesTypeCommitments, colonnesContractTypeCommitmentAndProduct, colonnesContractProduct, colonnesContractTypeCommitment, enumFactory, colonnesListFixationsByIdCommitment) {

        if ($rootScope.ModuleEngagementALaMasse.Active) {
            $location.path('/user/commitmentsAll');
        }

        var getViewToDisplay = function () {
            $scope.errorMessage = "";

            if ($rootScope.paramBCommitmentHomePageAll) {
                $scope.viewMode = 'tableCommitmentByProductTypeCommitment';
            }

            if ($scope.viewMode === 'tableTypeCommitment') {
                $scope.listTypeCommitments = $filter('orderBy')($scope.listTypeCommitments, $scope.data.sortKey, $scope.data.reverse);

                $scope.showTitleChartTypeCommitment = false;
                $scope.showTitleChartProduct = false;
                $scope.showTitleTableProduct = false;
                $scope.showTitleTableAllProduct = false;

                $scope.GetTypeCommitment();
            }
            else if ($scope.viewMode === 'tableCommitmentByProductTypeCommitment') {
                //Affichage du titre du tableau
                if ($scope.idProductSelected !== "") {
                    $scope.labelProductSelected = $filter('filter')($scope.lstProducts, { IdProduct: Number($scope.idProductSelected) }, true)[0].ProductLabel;
                }
                if ($scope.idTypeCommitmentSelected !== "") {
                    $scope.labelTypeCommitmentSelected = $filter('filter')($scope.lstTypeCommitments, { IdTypeCommitment: Number($scope.idTypeCommitmentSelected) }, true)[0].LabelDefaultTypeCommitment;
                }

                $scope.GetContractsTypeCommitment();
            }
        };
        var init = function () {
            //Récupération de la vue incluse
            $scope.viewMode = $routeParams.commitmentView;
            $scope.loading = true;
            $scope.errorMessage = "";
            $scope.commitments = {};
            $scope.idProductSelected = "";
            $scope.idTypeCommitmentSelected = "";
            $scope.CommitsTitle = "";
            $scope.lstProducts = [];
            $scope.datahighCharts = [];
            $scope.isDisplayhighCharts = false;
            $scope.showTitleTableProduct = false;
            $scope.showTitleTableAllProduct = false;
            $scope.data = { reverse: false, sortKey: "LabelTypeCommitment" };
            $scope.showTitleChartTypeCommitment = false;
            $scope.chartTitleKey = "";
            $scope.titleDefaultChart = "";
            $scope.maxContracts = 10;
            $scope.pageNo = 1;
            $scope.dataContract = { reverse: true, sortKey: "IdContract" };
            $scope.enumTypeCommitment = enumFactory.TypeCommitment;

            $scope.tableListFixationsDataVisible = colonnesListFixationsByIdCommitment.data.DataVisible.split(',');
            $scope.tableListFixationsLibelle = colonnesListFixationsByIdCommitment.data.ColumnName.split(',');

            //Filtre produit et type engagement
            contractsService.GetFiltersCommitmentsByCropAndProductAndTypeCommitment($rootScope.crop.IdCrop, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                    }
                } else {
                    $scope.lstProducts = data.LstProductFilterVm;
                    if ($scope.lstProducts && $scope.lstProducts.length === 1) {
                        $scope.idProductSelected = $scope.lstProducts[0].IdProduct;
                    }

                    $scope.lstTypeCommitments = data.LstTypeCommitmentFilterVm;
                    if ($scope.lstTypeCommitments && $scope.lstTypeCommitments.length === 1) {
                        $scope.idTypeCommitmentSelected = $scope.lstTypeCommitments[0].IdTypeCommitment;
                    }

                    $scope.Filters();
                }
            });
        };

        $scope.Sort = function (sortKey, reverse) {
            $scope.listTypeCommitments = $filter('orderBy')($scope.listTypeCommitments, sortKey, reverse);

            $scope.data.sortKey = sortKey;
            $scope.data.reverse = reverse;
        };

        $scope.Filters = function () {
            //Si on a sélectionné un produit et un type d'engagement et que le type d'engagement est "dépôt" alors on laisse le tableau affiché et on masque le graphique. On n'affiche pas de tableau de listing de contrats d'engagement
            if ($scope.idProductSelected !== "" || $scope.idTypeCommitmentSelected !== "") {
                $scope.viewMode = 'tableCommitmentByProductTypeCommitment';
            } else {
                $scope.viewMode = 'tableTypeCommitment';
            }

            getViewToDisplay();
        };

        $scope.GetContractsTypeCommitment = function () {
            $scope.loading = true;
            contractsService.GetContractCommitmentsWithFilter($rootScope.crop.IdCrop, $scope.idProductSelected, $scope.idTypeCommitmentSelected, $scope.maxContracts, $scope.pageNo, $scope.dataContract.sortKey, $scope.dataContract.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.loading = false;
                    }
                } else {
                    $scope.listContractTypeCommitments = data.ListVm;
                    $scope.total = data.Total;

                    //Contrats d'engagements avec produit sélectionné
                    if ($scope.idProductSelected === "" && $scope.idTypeCommitmentSelected !== "") {
                        $scope.tableContractTypeCommitmentsDataVisible = colonnesContractProduct.data.DataVisible.split(',');
                        $scope.tableContractTypeCommitmentsLibelle = colonnesContractProduct.data.ColumnName.split(',');
                    }
                    else if ($scope.idProductSelected !== "" && $scope.idTypeCommitmentSelected === "") {//Contrats d'engagements avec type d'engagement sélectionné
                        $scope.tableContractTypeCommitmentsDataVisible = colonnesContractTypeCommitment.data.DataVisible.split(',');
                        $scope.tableContractTypeCommitmentsLibelle = colonnesContractTypeCommitment.data.ColumnName.split(',');
                    } else {//Contrats d'engagements avec produit et type d'engagement sélectionné
                        $scope.tableContractTypeCommitmentsDataVisible = colonnesContractTypeCommitmentAndProduct.data.DataVisible.split(',');
                        $scope.tableContractTypeCommitmentsLibelle = colonnesContractTypeCommitmentAndProduct.data.ColumnName.split(',');
                    }

                    if ($rootScope.paramBCommitmentHomePageAll) {
                        $scope.tableContractTypeCommitmentsDataVisible = colonnesContractTypeCommitmentAndProduct.data.DataVisible.split(',');
                        $scope.tableContractTypeCommitmentsLibelle = colonnesContractTypeCommitmentAndProduct.data.ColumnName.split(',');
                    }
                    $scope.loading = false;
                }
            });
        };

        $scope.GetTypeCommitment = function () {
            contractsService.GetTypeCommitmentsByCropAndProductAndTypeCommitment($rootScope.crop.IdCrop, $scope.idProductSelected, $scope.idTypeCommitmentSelected, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                    }
                } else {
                    $scope.listTypeCommitments = data;

                    // Type engagements
                    $scope.tableTypeCommitmentsDataVisible = colonnesTypeCommitments.data.DataVisible.split(',');
                    $scope.tableTypeCommitmentsLibelle = colonnesTypeCommitments.data.ColumnName.split(',');

                    //Affichage du graphique
                    if ($scope.listTypeCommitments && $scope.listTypeCommitments.length > 0) {
                        $scope.datahighCharts = [];
                        $scope.colorhighCharts = [];
                        $scope.isDisplayhighCharts = true;

                        angular.forEach($scope.listTypeCommitments, function (typeCommitment) {
                            $scope.showTitleChartTypeCommitment = true;
                            $scope.chartTitleKey = "User_Commitments_TitleChart_TypeCommitment";
                            $scope.titleDefaultChart = "Répartition par type d'engagement";
                            $scope.datahighCharts.push([typeCommitment.LabelTypeCommitment, typeCommitment.TonnageEngaged]);
                            $scope.colorhighCharts.push(typeCommitment.GraphicColor);
                        });

                    } else {
                        $scope.isDisplayhighCharts = false;
                    }
                    //Affichage du titre du tableau
                    $scope.showTitleTableAllProduct = true;
                }
            });

        };

        $scope.SortContracts = function (sortKey, reverse) {
            $scope.dataContract.sortKey = sortKey;
            $scope.dataContract.reverse = reverse;
            $scope.GetContractsTypeCommitment();
        };

        $scope.GetData = function (pageNo) {
            $scope.pageNo = pageNo;
            $scope.GetContractsTypeCommitment();
        };

        $rootScope.onRefresh = function () {
            init();
        };

        $scope.AsChangedCrop = function () {
            init();
        };

        $scope.GoToCompatibleOffer = function (idContractCommitment) {
            $location.path('/user/offers/view/' + enumFactory.TypeOffer.Ferme + '/' + idContractCommitment);
        };

        $scope.GoToListContractCommitment = function (idTypeCommitment) {
            $scope.idTypeCommitmentSelected = idTypeCommitment;
            $scope.Filters();
        };

        var getFixationsByIdContractCommitment = function (callback) {
            contractsService.GetFixationsByIdContractCommitment($scope.popupDetailFixations.idContractCommitment, $scope.popupDetailFixations.itemPerPage, $scope.popupDetailFixations.pageNo, $scope.popupDetailFixations.sortKey, $scope.popupDetailFixations.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                    }
                } else {
                    $scope.listFixations = data.ListVm;
                    $scope.popupDetailFixations.total = data.Total;
                    $scope.popupDetailFixations.TotalQty = data.QuantityCommitment;
                    $scope.popupDetailFixations.FixedQty = data.QuantityCommitmentFixed;
                    $scope.popupDetailFixations.PercentFixed = Math.round(100 * $scope.popupDetailFixations.FixedQty / $scope.popupDetailFixations.TotalQty);
                    if (callback)
                        callback();
                }
            });
        };

        $scope.GetDetail = function (idContractCommitment, openPopupDetail) {
            $scope.popupDetailFixations = {
                idContractCommitment: idContractCommitment,
                itemPerPage: 10,
                pageNo: 1,
                sortKey: "FixationDate",
                reverse: true,
            };
            getFixationsByIdContractCommitment(openPopupDetail);
        };

        $scope.GetDataDetail = function (pageNo) {
            $scope.popupDetailFixations.pageNo = pageNo;
            getFixationsByIdContractCommitment();
        };

        $scope.SortDetail = function (sortKey, reverse) {
            $scope.popupDetailFixations.sortKey = sortKey;
            $scope.popupDetailFixations.reverse = reverse;
            getFixationsByIdContractCommitment();
        };

        init();
    }
]);