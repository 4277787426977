AgrimarketServices.service('parametersService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory','enumFactory',
	function ($rootScope, $http, $location, currentUserFactory, configurationFactory, enumFactory) {

		var SendRequest = function (configfile, callback) {
			$http(configfile)
				.success(function (data) {
					callback(data, null, null, null);
				})
				.error(function (data, status, headers, config) {
					callback(data, status, headers, config);
				});
		};

		this.GetParameters = function (callback) {
			var config = {
				method: 'GET',
				url: configurationFactory.Url['Api'] + 'Parameter/GetParameters'
			};
			SendRequest(config, callback);
		};

	    this.GetParameterByKey = function(key, callback) {
		    var config = {
			    method: 'GET',
			    url: configurationFactory.Url['Api'] + 'Parameter/GetParameterByKey',
			    params: { key: key }
		    };
	        SendRequest(config, callback);
	    };

	    this.GetParametersByIdsGroup = function (idsGroupParameters,callback) {
			var config = {
				method: 'GET',
				url: configurationFactory.Url['Api'] + 'Parameter/GetParametersByIdsGroup',
				params: { idsGroupParameters: idsGroupParameters }
			};
			SendRequest(config, callback);
		};

		this.SaveParameterByKey = function (key, value, callback) {
			var config = {
				method: 'PUT',
				url: configurationFactory.Url['Api'] + 'Parameter/PutParameterByKey',
				params: { key: key, value: value }
			};
			SendRequest(config, callback);
		};

		this.SaveParametersGlobal = function (globalParameters, callback) {
			var config = {
				method: 'PUT',
				url: configurationFactory.Url['Api'] + 'Parameter/PutParametersGlobal',
				data: globalParameters
			};
			SendRequest(config, callback);
		};
		
		this.DeleteAttachement = function (idParameter, callback) {
			var config = {
				method: 'DELETE',
				url: configurationFactory.Url['Api'] + 'Parameter/DeleteAttachement',
				params: { idParameter: idParameter }
			};
			SendRequest(config, callback);
		};

		this.ShowAskAccessCodeLink = function (callback) {
			var config = {
				method: 'GET',
				url: configurationFactory.Url['Api'] + 'Parameter/ShowAskAccessCodeLink'
			};
			SendRequest(config, callback);
		};
	}]);