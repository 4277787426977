AgrimarketServices.service('billsService', ['$http', 'configurationFactory',
	function ($http, configurationFactory) {

		var SendRequest = function (configfile, callback) {
			$http(configfile)
				.success(function (data) {
					callback(data, null);
				})
				.error(function (data, status) {
					callback(data, status);
				});
		};
	
		this.GetBillsPaginateWithFilter = function (idStatusBills, startDate, endDate, idTypebill, idCompagny, searchText, max, pageNo, sortKey, reverse, callback) {
			var config = {
		        method: 'GET',
		        url: configurationFactory.Url['Api'] + 'Bill/GetBillsPaginateWithFilter',
		        params: {
					idTypebill: idTypebill,
					idStatusBills: idStatusBills,
					startDate: startDate,
					endDate: endDate,
					idCompagny: idCompagny,
		            searchText: searchText,
		            itemPerPage: max,
		            pageNo: pageNo,
		            sortKey: sortKey,
		            reverse: reverse
		        }
			};
		    SendRequest(config, callback);
		};

		this.GetBillsXls = function (idStatusBills, idTypeBill, idUser, idCompagny, startDate, endDate, searchText, idLanguage) {
            return configurationFactory.Url['Api'] + 'Bill/GetBillsXls/' +
				'?idStatusBills=' + idStatusBills +
                '&idTypeBill=' + idTypeBill +
                '&idUser=' + idUser +
                '&idCompagny=' + idCompagny +
                '&startDate=' + startDate +
                '&endDate=' + endDate +
                '&searchText=' + searchText +
                '&idLanguage=' + idLanguage;
        };

		this.GetFilters = function (callback) {
			var config = {
				method: 'GET',
				url: configurationFactory.Url['Api'] + 'Bill/GetFilters',
			};
			SendRequest(config, callback);
		};
	}]);