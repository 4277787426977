AgrimarketControllers.filter('formatMd5', function () {
    return function (value, key) {
        if (!value)
            return "";

        if (sessionStorage.getItem("hasRefresh") !== "true" && !sessionStorage.getItem("IdUser") && ((!sessionStorage.getItem("IdUserAdmin")) || (sessionStorage.getItem("IdUserAdmin") && sessionStorage.getItem("modeEdition"))) && sessionStorage.getItem("hasValidateCGU") !== "true")
            return md5(value);

        return value;
    };
});
