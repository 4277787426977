AgrimarketServices.service('accuWeatherCallService', ['$http', 'configurationFactory',
    function ($http, configurationFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null);
                })
                .error(function (data, status) {
                    callback(data, status);
                });
        };

        this.GetAccuWeatherCallsPaginate = function (startDate, endDate, itemPerPage, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'AccuWeatherCall/GetAccuWeatherCalls',
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };
            SendRequest(config, callback);
        };

        this.GetFilterAccuWeatherCall = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'AccuWeatherCall/GetFilterAccuWeatherCall',
            };
            SendRequest(config, callback);
        };

        this.GetSumMonthAccuWeatherCalls = function (startDate, endDate, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'AccuWeatherCall/GetSumMonthAccuWeatherCalls',
                params: {
                    startDate: startDate,
                    endDate: endDate
                }
            };
            SendRequest(config, callback);
        };
}]);