AgrimarketControllers.controller('manageContractsCommitmentOfTcUserController', ['$scope', '$rootScope', '$routeParams', 'configurationFactory',
    'contractsService', 'cropsService', '$location', '$sce', '$filter', 'enumFactory', 'colonnesContractsCommitmentValidated',
    function ($scope, $rootScope, $routeParams, configurationFactory, contractsService, cropsService, $location, $sce, $filter, enumFactory, colonnesContractsCommitmentValidated) {        

        var Init = function () {
            $scope.viewMode = $routeParams.typeContract;

            // Contracts engagement valider
            $scope.tableContractsCommitmentValidatedDataVisible = colonnesContractsCommitmentValidated.data.DataVisible.split(',');
            $scope.tableContractsCommitmentValidatedLibelle = colonnesContractsCommitmentValidated.data.ColumnName.split(',');

            $scope.idCropSelected = "";//$rootScope.crop.IdCrop;
            $scope.idProductSelected = "";
            $scope.dateFilter = "";
            $scope.dateSelected = "";
            $scope.selectedIdContract = "";
            $scope.selectedIdClient = "";
            $scope.idTypeCommitmentContractSelected = "";
            $scope.idCompagnySelected = "";            
            $scope.selectedClient = {};

            $scope.dataValidated = { reverse: true, sortKey: "IdContract" };
            $scope.max = 10;
            $scope.validatedPageNo = 1;

            $scope.$watch("selectedIdContract", function (newValue, oldValue) {
                if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                    $scope.getContractsCommitmentWhithFilter(1);
            });


            $scope.$watch("dateSelected", function (newValue) {
                if (newValue === "")
                    $scope.dateFilter = "";
                else if (newValue) {
                    //permet de gérer l'internalisation de tout les formats des dates possibles.
                    //Au final nous faisons en sorte de la retrouvé au format yyyy/mm/dd pour l'envoyer à l'API.
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    var dt = new Date(newValue.replace(pattern, '$3-$2-$1'));
                    var currentDate = new Date(dt.toString());
                    var converDate = moment(currentDate);
                    $scope.dateFilter = converDate.format('YYYY/MM/DD');
                }
            });

            $scope.$watch("dateFilter", function (newValue, oldValue) {
                if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                    $scope.getContractsCommitmentWhithFilter(1);
            });

            $scope.InitialiseFilters();
            
        };

        $scope.InitialiseFilters = function () {
            $scope.idCropSelected = "";
            $scope.idProductSelected = "";
            $scope.selectedIdContract = "";
            $scope.idTypeCommitmentContractSelected = "";
            $scope.selectedIdClient = "";
            $scope.dateSelected = "";

            var labelAll = $rootScope.editableObjectsFactory.EditableObjects.All.Content;

            contractsService.GetFiltersWithContractsCommitment(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    if (data) {
                        $scope.lstProducts = data.LstProductFilterVm;
                        $scope.lstTypeCommitments = data.LstTypeCommitmentFilterVm;
                        $scope.lstCrops = data.LstCropsVm;

                        if ($scope.lstProducts.length === 1)
                            $scope.idProductSelected = $scope.lstProducts[0].IdProduct;
                        else if ($scope.lstProducts.length > 1)
                            $scope.lstProducts.unshift({ IdProduct: "", ProductLabel: labelAll });

                        if ($scope.lstTypeCommitments.length === 1)
                            $scope.idTypeCommitmentContractSelected = $scope.lstTypeCommitments[0].IdTypeCommitment;
                        else if ($scope.lstTypeCommitments.length > 1)
                            $scope.lstTypeCommitments.unshift({ IdTypeCommitment: "", LabelTypeCommitment: labelAll });

                        if ($scope.lstCrops.length === 1)
                            $scope.idCropSelected = $scope.lstCrops[0].IdCrop;
                        else if ($scope.lstCrops.length > 1)
                            $scope.lstCrops.unshift({ Id: "", Label: $rootScope.editableObjectsFactory.EditableObjects.AllItems_F.Content });

                        $scope.getContractsCommitmentWhithFilter(1);
                    }
                }
            });
        };


        $scope.getContractsCommitmentWhithFilter = function (pagenum) {
            $scope.errorMessage = "";
            $scope.successMessage = "";
            if (!$scope.selectedIdContract) $scope.selectedIdContract = "";
            if (!$scope.selectedIdClient) $scope.selectedIdClient = "";
            contractsService.GetContractsCommitmentValidatedPaginate($scope.idCropSelected, $scope.idProductSelected, $scope.idTypeCommitmentContractSelected, $scope.dateFilter, $scope.selectedIdContract, $scope.selectedIdClient, $scope.idCompagnySelected, $scope.max, pagenum, $scope.dataValidated.sortKey, $scope.dataValidated.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.totalValidated = data.Total;
                    $scope.contractsCommitmentValidated = data.ListVm;

                    data.ListVm.forEach(function (contract) {
                        $scope.allContractsInView.push(contract);
                    });
                }
            });
        };        

        Init();
    }]);
