AgrimarketControllers.controller('editorialMailController', ['$scope', '$rootScope', '$routeParams', '$sce', '$filter', '$location', 'templateMailsService', 'enumFactory',
    function ($scope, $rootScope, $routeParams, $sce, $filter, $location, templateMailsService, enumFactory) {

        var init = function () {
            $scope.enumRoles = enumFactory.Roles;

            if ($rootScope.succesMessageTemplate !== undefined && $rootScope.succesMessageTemplate !== null)
                $scope.successMessage = $rootScope.succesMessageTemplate;

            $rootScope.succesMessageTemplate = "";
            $scope.errorMessage = "";
            $scope.templates = [];
            $scope.lstCompagnies = [];
            $scope.TypeTemplateSelected = { id: "" };
            $scope.CompagnySelected = { id: "" };
            $scope.LanguageSelected = { id: "" };
            $scope.enumCategoryTypeTemplateMails = enumFactory.TypeCategoryTypeTemplateMail;

            $scope.LanguageSelected = { id: $routeParams.idLanguage ? Number($routeParams.idLanguage) : $rootScope.language.IdLanguage };
            $scope.CheckLanguage();
            $scope.GetAllTypeTemplateMails();

            if (!$rootScope.ModuleEcommerce.Active) {
                $scope.viewMode = 'Other';
                $scope.clickTab("Other");
            }
            else {
                $scope.viewMode = (!$routeParams.viewMode && !$scope.viewMode) ? 'Contract' : $routeParams.viewMode;
            }
        };


        $scope.EditTemplateMail = function (typeTemplateMail, idRole) {
            switch (idRole) {
                case $scope.enumRoles.Admin:
                    if (typeTemplateMail.TemplateAdmin.Editable)
                        $location.path('/admin/editorial/editTemplateMail/' + typeTemplateMail.IdTypeTemplateMail + '/' + idRole + '/' + $scope.LanguageSelected.id + '/' + $scope.viewMode);
                    break;
                case $scope.enumRoles.User:
                    if (typeTemplateMail.TemplateAgri.Editable)
                        $location.path('/admin/editorial/editTemplateMail/' + typeTemplateMail.IdTypeTemplateMail + '/' + idRole + '/' + $scope.LanguageSelected.id + '/' + $scope.viewMode);
                    break;
                case $scope.enumRoles.TC:
                    if (typeTemplateMail.TemplateTC.Editable)
                        $location.path('/admin/editorial/editTemplateMail/' + typeTemplateMail.IdTypeTemplateMail + '/' + idRole + '/' + $scope.LanguageSelected.id + '/' + $scope.viewMode);
                    break;
                default:
            }
        };

        $scope.GetAllTypeTemplateMails = function () {
            templateMailsService.GetAllTypeTemplateMails($scope.LanguageSelected.id, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                }
                else
                    $scope.typeTemplateMails = data;
            });
        };

        $scope.SaveConfigMails = function () {
            var typeTemplateMailFiltereds = $filter('filter')($scope.typeTemplateMails, { "IdCategoryTypeTemplateMail": $scope.enumCategoryTypeTemplateMails[$scope.viewMode] }, true);
            templateMailsService.SaveConfigMails(typeTemplateMailFiltereds, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                }
                else {
                    $scope.GetAllTypeTemplateMails();
                    $scope.successMessage = "Votre configuration a bien été prise en compte.";
                }
            });
        };

        $scope.CheckLanguage = function () {
            if ($rootScope.ModuleMultilingue.Active)
                $scope.isDefaultLanguage = $filter('filter')($rootScope.languages, { IsDefault: true })[0].IdLanguage === $scope.LanguageSelected.id;
            else
                $scope.isDefaultLanguage = true;
        };

        $scope.clickTab = function (tab) {
            $scope.successMessage = "";
            $scope.errorMessage = "";
            $scope.viewMode = tab;
        };

        init();
    }]);
