AgrimarketServices.service('messengerService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

    	var SendRequest = function (configfile, callback) {
    		$http(configfile)
                .success(function (data) {
                	callback(data, null, null, null);
                })
                .error(function (data, status, headers, config) {
                	callback(data, status, headers, config);
                });
    	};

    	this.PostMessage = function (data,callback) {
    		var config = {
    			method: 'Post',
    			url: configurationFactory.Url['Api'] + 'Messenger/PostMessage',
    			data: data
    		};
    		SendRequest(config, callback);
    	};
    	//DeleteMessage
    	this.DeleteMessageReceive = function (idmessage, callback) {
    		var config = {
    			method: 'DELETE',
    			url: configurationFactory.Url['Api'] + 'Messenger/DeleteMessageReceive',
    			params: { idmessage: idmessage }
    		};
    		SendRequest(config, callback);
    	};
    	this.DeleteMessageSended = function (idmessage, callback) {
    		var config = {
    			method: 'DELETE',
    			url: configurationFactory.Url['Api'] + 'Messenger/DeleteMessageSended',
    			params: { idmessage: idmessage }
    		};
    		SendRequest(config, callback);
    	};
    	this.GetNewMessagePaginate = function (searchMail, itemPerPage, pageNo, sortKey, reverse, callback) {
    		var config = {
    		    method: 'GET',
    		    url: configurationFactory.Url['Api'] + 'Messenger/GetNewMessagePaginate',
    		    params: {
    		        searchMail: searchMail,
    		        itemPerPage: itemPerPage,
    		        pageNo: pageNo,
    		        sortKey: sortKey,
    		        reverse: reverse
    		    }
    		};
    		SendRequest(config, callback);
    	};
    	this.GetMessageSendedPaginate = function (searchMail, itemPerPage, pageNo, sortKey, reverse, callback) {
    		var config = {
    		    method: 'GET',
    			url: configurationFactory.Url['Api'] + 'Messenger/GetMessageSendedPaginate',
    			params: {
    			    searchMail: searchMail,
    			    itemPerPage: itemPerPage,
    			    pageNo: pageNo,
    			    sortKey: sortKey,
    			    reverse: reverse
    			}
    		};
    		SendRequest(config, callback);
    	};
    	this.GetNbMessage = function (callback) {
    		var config = {
    			method: 'Get',
    			url: configurationFactory.Url['Api'] + 'Messenger/GetNbMessageToRead',
    		};
    		SendRequest(config, callback);
    	};

    	this.GetMessage = function (idMessage, callback) {
    		var config = {
    			method: 'Get',
    			params: { idMessage: idMessage },
    			url: configurationFactory.Url['Api'] + 'Messenger/GetMessage',
    		};
    		SendRequest(config, callback);
    	};

    	this.GetReplyMessage = function (idMessage, callback) {
    		var config = {
    			method: 'Get',
    			params: { idMessage: idMessage },
    			url: configurationFactory.Url['Api'] + 'Messenger/GetReplyMessage',
    		};
    		SendRequest(config, callback);
    	};
    	
    }]);