AgrimarketControllers.controller('userContractsBaseMonitoringController', ['$scope', '$rootScope', '$routeParams', 'configurationFactory',
    'contractBaseService', 'cropsService', '$location', '$sce', '$filter', 'enumFactory', 'colonnesContractsBaseValidated', 'colonnesContractsBasePriced', 'colonnesContractsBaseCanceled', 'colonnesContractsBasePending',
    function ($scope, $rootScope, $routeParams, configurationFactory, contractBaseService, cropsService, $location, $sce, $filter, enumFactory, colonnesContractsBaseValidated, colonnesContractsBasePriced, colonnesContractsBaseCanceled, colonnesContractsBasePending) {

        var idTabContracts;

        var Init = function () {        
            $scope.idCropSelected = $rootScope.crop.IdCrop;
            $scope.viewMode = $routeParams.typeContract;

            $scope.max = 10;

            $scope.dataSort = { reverse: true, sortKey: "IdContractBase" };

            $scope.validatedPageNo = 1;
            $scope.pendingPageNo = 1;
            $scope.pricedPageNo = 1;
            $scope.canceledPageNo = 1;  

            $scope.LabelAll = $rootScope.editableObjectsFactory.EditableObjects.All.Content;

            $scope.colonnesValidatedVisible = colonnesContractsBaseValidated.data.DataVisible.split(',');
            $scope.colonnesValidatedLibelle = colonnesContractsBaseValidated.data.ColumnName.split(',');

            $scope.colonnesPendingVisible = colonnesContractsBasePending.data.DataVisible.split(',');
            $scope.colonnesPendingLibelle = colonnesContractsBasePending.data.ColumnName.split(',');

            $scope.colonnesPricedVisible = colonnesContractsBasePriced.data.DataVisible.split(',');
            $scope.colonnesPricedLibelle = colonnesContractsBasePriced.data.ColumnName.split(',');

            $scope.colonnesCanceledVisible = colonnesContractsBaseCanceled.data.DataVisible.split(',');
            $scope.colonnesCanceledLibelle = colonnesContractsBaseCanceled.data.ColumnName.split(',');

            $scope.titleTargetBasePricePopUp = $rootScope.editableObjectsFactory.EditableObjects.User_ContractBaseMonitoring_PopupChangeTargetPrice_Title.Content;

            $scope.selectedIdContract = "";
            $scope.dateFilter = "";

            $scope.$watch("dateSelected", function (newValue) {
                if (newValue === "")
                    $scope.dateFilter = "";
                else if (newValue) {
                    //permet de gérer l'internalisation de tout les formats des dates possibles.
                    //Au final nous faisons en sorte de la retrouvé au format yyyy/mm/dd pour l'envoyer à l'API.
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    var dt = new Date(newValue.replace(pattern, '$3-$2-$1'));
                    var currentDate = new Date(dt.toString());
                    var converDate = moment(currentDate);
                    $scope.dateFilter = converDate.format('YYYY/MM/DD');
                }
            });

            $scope.$watch("dateFilter", function (newValue, oldValue) {
                if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                    $scope.GetContractsWithFilter(1);
            });

            GetContractsFilters();
        };

        var GetContractsFilters = function () {
            $scope.loading = true;
            $scope.idProductSelected = "";
            $scope.selectedIdContract = "";            
            $scope.dateSelected = "";
            $scope.idTypeOrderSelected = "";   
            
            contractBaseService.GetFiltersWithContractsBaseByCrop($scope.idCropSelected, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    if (data) {
                        $scope.lstProducts = data.LstProductFilterVm;     
                        $scope.lstTypeOrders = data.LstTypeOrderFilterVm;   

                        if ($scope.lstProducts.length === 1)
                            $scope.idProductSelected = $scope.lstProducts[0].IdProduct;
                        else if ($scope.lstProducts.length > 1)
                            $scope.lstProducts.unshift({ IdProduct: "", ProductLabel: $scope.LabelAll });     

                        if ($scope.lstTypeOrders.length === 1)
                            $scope.idTypeOrderSelected = $scope.lstTypeOrders[0].IdTypeOrder;
                        else if ($scope.lstTypeOrders.length > 1)
                            $scope.lstTypeOrders.unshift({ IdTypeOrder: "", TypeOrderLabel: $scope.LabelAll });    

                        $scope.GetContractsWithFilter(1);

                    }
                }
            });
        };

        $scope.GetContractsWithFilter = function (pagenum) {
            $scope.loading = true;

            if ($scope.viewMode === "validated")
                contractBaseService.GetContractsBasePaginate(enumFactory.ContractState.Valide, $scope.idCropSelected, $scope.idProductSelected, $scope.dateFilter, $scope.selectedIdContract, "", "", $scope.idTypeOrderSelected, $scope.max, pagenum, $scope.dataSort.sortKey, $scope.dataSort.reverse, function (data, status) {                
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        $scope.totalValidated = data.Total;
                        $scope.contractsValidated = data.ListVm;
                        $scope.loading = false;
                    }
                });
            else if ($scope.viewMode === "priced")
                contractBaseService.GetContractsBasePaginate(enumFactory.ContractState.AttenteValidation, $scope.idCropSelected, $scope.idProductSelected, $scope.dateFilter, $scope.selectedIdContract, "", "", $scope.idTypeOrderSelected, $scope.max, pagenum, $scope.dataSort.sortKey, $scope.dataSort.reverse, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        $scope.totalPriced = data.Total;
                        $scope.contractsPriced = data.ListVm;
                        $scope.loading = false;
                    }
                });
            else if ($scope.viewMode === "canceled")
                contractBaseService.GetContractsBasePaginate(enumFactory.ContractState.Annulé, $scope.idCropSelected, $scope.idProductSelected, $scope.dateFilter, $scope.selectedIdContract, "", "", $scope.idTypeOrderSelected,$scope.max, pagenum, $scope.dataSort.sortKey, $scope.dataSort.reverse, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        $scope.totalCanceled = data.Total;
                        $scope.contractsCanceled = data.ListVm;
                        $scope.loading = false;
                    }
                });
            else if ($scope.viewMode === "pending")
                contractBaseService.GetContractsBasePaginate(enumFactory.ContractState.AttenteDernierComposant, $scope.idCropSelected, $scope.idProductSelected, $scope.dateFilter, $scope.selectedIdContract, "", "", $scope.idTypeOrderSelected, $scope.max, pagenum, $scope.dataSort.sortKey, $scope.dataSort.reverse, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        $scope.totalPending = data.Total;
                        $scope.contractsPending = data.ListVm;
                        $scope.loading = false;
                    }
                });
        };

        $scope.Sort = function (sortKey, reverse) {
            $scope.dataSort.sortKey = sortKey;
            $scope.dataSort.reverse = reverse;
            $scope.GetContractsWithFilter(1);
        };

        $scope.CancelContractPopUp = function (contractSelected, index, showPopUpContract) {
            $scope.PopUpMessageDeleteContract = "";
            $scope.MessageDeleteContractBaseTargetPrice = $rootScope.editableObjectsFactory.EditableObjects.MessageDeleteContractBaseTargetPrice.Content;
            $scope.CanceledContract = $rootScope.editableObjectsFactory.EditableObjects.CanceledContract.Content;

            $scope.PopupCanceledContractsSelectedTitle = $scope.CanceledContract + " " + contractSelected.IdContractBase;

            if (contractSelected !== undefined)
                $scope.PopUpMessageDeleteContract = $scope.MessageDeleteContractBaseTargetPrice;

            showPopUpContract(contractSelected);
        };

        $scope.CancelContract = function (element) {
            contractBaseService.UpdateContractBase(element, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.successMessage = $scope.CancelContractSuccessMessage;
                    GetContractsFilters();
                }
            });
        };

        $scope.ChangeTargetBasePricePopUp = function (currentContract, index, openPopUp) {
            $scope.selectedContract = currentContract;
            $scope.targetBasePriceErrorMessage = "";
            $scope.ActionType = "edit";
            openPopUp();
        };

        $scope.ChkTargetBasePriceIsValid = function () {
            $scope.targetBasePriceErrorMessage = "";
            $scope.errorMessage = "";
            $scope.successMessage = "";
            $scope.ErrorTargetPriceMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_UserContractsBaseMonitoring_MessageTargetPrice.Content;
            $scope.ErrorPriceMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_UserContractsBaseMonitoring_BasePriceMessage.Content;
            $scope.TargetPriceCompareErrorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_UserContractsBaseMonitoring_TargetBasePriceCompareMessage.Content;
            $scope.TargetPriceCompareErrorMessage2 = $rootScope.editableObjectsFactory.EditableObjects.Currency.Content;
            $scope.ChangeTargetPriceSuccessMessage = $rootScope.editableObjectsFactory.EditableObjects.Success_UserContractsBaseMonitoring_ChangeTargetBasePriceMessage.Content;
            $scope.CancelContractSuccessMessage = $rootScope.editableObjectsFactory.EditableObjects.Success_UserContractsBaseMonitoring_CancelContractBaseMessage.Content;


            if (!$scope.selectedContract.hasOwnProperty("NewTargetBasePrice") || $scope.selectedContract.NewTargetBasePrice === null) {
                $scope.targetBasePriceErrorMessage = $scope.ErrorTargetPriceMessage;
                $scope.selectedContract.NewTargetBasePrice = null;
                return false;
            } else {
                $scope.selectedContract.NewTargetBasePrice = $scope.selectedContract.NewTargetBasePrice.replace(",", ".");
                if (!parseFloat($scope.selectedContract.NewTargetBasePrice)) {
                    $scope.targetBasePriceErrorMessage = $scope.ErrorPriceMessage;
                    return false;
                } else {
                    $scope.selectedContract.NewTargetBasePrice = Number($scope.selectedContract.NewTargetBasePrice);
                }
                if ($scope.selectedContract.NewTargetBasePrice <= $scope.selectedContract.OfferBasePriceUpdated) {
                    $scope.targetBasePriceErrorMessage = $scope.TargetPriceCompareErrorMessage + " " + $scope.selectedContract.OfferBasePriceUpdated + " " + $scope.TargetPriceCompareErrorMessage2;
                    $scope.selectedContract.NewTargetBasePrice = null;
                    return false;
                }
            }
            return true;
        };

        $scope.ChangeTargetBasePrice = function () {
            contractBaseService.UpdateContractBase($scope.selectedContract, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.successMessage = $scope.ChangeTargetPriceSuccessMessage;
                    GetContractsFilters();
                }
            });
        };

        $scope.HistoContractPopUp = function (currentContract, openPopUpHisto) {
            $scope.ModalTitleContractBaseHisto = $rootScope.editableObjectsFactory.EditableObjects.ModalTitleContractBaseHisto.Content;
            contractBaseService.GetHistoContractBase(currentContract.IdInit, currentContract.IdCrop, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.lstHistoContract = data;
                    $scope.ModalTitle = $scope.ModalTitleContractBase;
                    openPopUpHisto();
                }
            });
        };

        $scope.GoToSubscribe = function (contract, idTypeOrder) {
            $rootScope.idOfferSelected = contract.IdOffer;
            $rootScope.idExpirySelected = contract.IdExpiry;
            $rootScope.idTypeOrderSelected = idTypeOrder;
            $rootScope.idTypeCommitmentSelected = null;
            $location.path('/user/subscribe/contractFirm//2/'+contract.IdContractBase);
        };

        $rootScope.onRefresh = function () {
            Init();
        };

        $scope.AsChangedCrop = function () {
            Init();
        };

        Init();
    }]);
 