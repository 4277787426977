/*
*   Directive d'ouverture de pop up contenant un formulaire
*   3 fonctions sont nécéssaires:
*       -popupsubmitOpen: ouvre la modal
*       -popupsubmitCheck: vérifie les champs du formulaire
*       -popupsubmitSave: enregistre la saisie de l'utilisateur si les champs du formulaire sont valides
*   Le return s'occupe d'intégrer le bouton de submit qui se trouve dans une vue partielle
*   Ceci est fait dans le but de ne pas fermer la modal au clic du bouton submit, si au moins un des champs n'est pas valide
*/

AgrimarketDirectives.directive('popupSubmit', function () {
	return {
		link: function (scope, element, attrs) {

			scope[attrs.popupSubmit] = function () {
				if (attrs.popupsubmitCheck && !scope[attrs.popupsubmitCheck]()) {
					return;
				} else {
					var promise = scope[attrs.popupsubmitSave]();
					if (promise !== undefined && promise !== null)
						promise.done(function (close) {
							if (close)
								element.dialog("close");
						});
					else
						element.dialog("close");
				}

			};

			scope[attrs.popupCancel] = function () {
				element.unbind('dialogclose');
				element.dialog("close");
				if (attrs.confirmCancelFunction !== undefined && attrs.confirmCancelFunction !== null) {
					scope[attrs.confirmCancelFunction]();
				}

			};

			scope[attrs.popupsubmitOpen] = function () {
				element.dialog({
					resizable: false,
					modal: true,
					title: (scope[attrs.popupTitle] !== undefined ? scope[attrs.popupTitle] : attrs.popupTitle),
					width: attrs.popupsubmitWidth,
				});
			};

			scope.$on('$destroy', function () {
			    if (element.is(':ui-dialog')) {
			        element.dialog('destroy').remove();
			    }
			});
		}
	};
});