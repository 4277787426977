AgrimarketServices.service('coverParametersService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

    	var SendRequest = function (configfile, callback) {
    		$http(configfile)
			    .success(function (data) {
			    	callback(data, null, null, null);
			    })
			    .error(function (data, status, headers, config) {
			    	callback(data, status, headers, config);
			    });
    	};

    	this.GetCoverParameters = function (callback) {
    		var config = {
    			method: 'GET',
                url: configurationFactory.Url['Api'] + 'CoverParameter/GetCoverParameters'
    		};
    		SendRequest(config, callback);
    	};

        this.SaveCoverParameter = function (coverParameter, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'CoverParameter/SaveCoverParameter',
                data: coverParameter
            };
            SendRequest(config, callback);
        };

    }]);