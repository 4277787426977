AgrimarketControllers.controller('editorialMobileController', ['$scope', '$rootScope', '$routeParams', '$sce', '$filter', '$http', '$location', 'editableObjectsService', 'enumFactory', 'configurationFactory',
    function ($scope, $rootScope, $routeParams, $sce, $filter, $http, $location, editableObjectsService, enumFactory, configurationFactory) {

        $scope.urlMobile = $sce.trustAsResourceUrl(configurationFactory.Url['Mobile'] + '/loginEditionMode?idUser=' + $rootScope.DefaultUserEditionMode);

        window.addEventListener("message", receiveMessage, false);
        function receiveMessage(event) {
            if (event.origin !== configurationFactory.Url['Mobile'])
                return;

            $scope.openPopEditableMobile(event.data);
        }

        //popup edition mobile
        $scope.openPopEditableMobile = function (key) {
            $scope.editableErrorMessage = "";

            $scope.editableObject = angular.copy($rootScope.editableObjectsFactory.getItemByKey(key));
            if ($scope.editableObject === undefined)
                $scope.editableObject = { 'IdEditableObject': 0, 'Key': key, 'IsVisible': true, 'IdTypeEditableObject': enumFactory.IdTypeEditableObject.Texte, 'Content': textDefault };
            $scope.mobileEditableObjectKey = key;
            $scope.$apply();

            var titlePopUp =  angular.copy($scope.editableObject.Content);

            $("#popEditableMobile").css("display", "block");
            $("#popEditableMobile").dialog({
                title: "Edition du champ : " + titlePopUp,
                modal: false,
                create: function () {
                    $(this).closest('div.ui-dialog')
                        .find('.ui-dialog-titlebar-close')
                        .click(function (e) {
                            $("#dialog-message").empty();
                            $scope.ClosePopEditableMobile(true);
                            $scope.$apply();
                            e.preventDefault();

                        });
                }
            });
        };

        $scope.ClosePopEditableMobile = function (cancel) {
            $scope.editableObject = {};
            if (cancel) {
                if ($scope.editableObjectBeforeChange !== undefined) {
                    $scope.editableObject.Content = $scope.editableObjectBeforeChange.Content;
                    $scope.editableObject.IsVisible = $scope.editableObjectBeforeChange.IsVisible;
                }
            } 
            $("#popEditableMobile").dialog("close");
        };

        $scope.SaveDataPopEditableMobile = function () {
            var dataToApi = {
                IdEditableObject: $scope.editableObject.IdEditableObject,
                IdTypeEditableObject: $scope.editableObject.IdTypeEditableObject,
                Content: $scope.editableObject.Content,
                Key: $scope.mobileEditableObjectKey,
                IsVisible: $scope.editableObject.IsVisible
            };
            editableObjectsService.SaveEditableObject(dataToApi, function (data, status) {
                if (status !== null) {
                    if (status == 400) {
                        $scope.editableErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    }
                } else {
                    $rootScope.editableObjectsFactory.EditableObjects[$scope.mobileEditableObjectKey] = { 'IdEditableObject': data.IdEditableObject, 'Content': data.Content, 'IsVisible': data.IsVisible, 'ContentDefault': data.ContentDefault, 'IdTypeEditableObject': data.IdTypeEditableObject };
                    var iframe = document.getElementById('iframeMobile');
                    if (iframe) {
                        iframe.contentWindow.postMessage($scope.mobileEditableObjectKey + '|' + $scope.editableObject.Content, '*');
                    }
                    $scope.ClosePopEditableMobile(false);
                }
            });
        };

    }]);
