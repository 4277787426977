AgrimarketControllers.controller('messengerController', ['$scope', '$rootScope', '$routeParams', '$sce', '$filter', '$http', '$location', '$window', 'userService', 'enumFactory', 'messengerService', 'currentUserFactory', 'configurationFactory', 'parametersService', 'parametersFactory', 'userTableService', 'colonnesMessageSended', 'colonnesMessageReceived', '$route',
    function ($scope, $rootScope, $routeParams, $sce, $filter, $http, $location, $window, userService, enumFactory, messengerService, currentUserFactory, configurationFactory, parametersService, parametersFactory, userTableService, colonnesMessageSended, colonnesMessageReceived, $route) {

        $scope.errorMessage = "";
        $scope.successMessage = "";
        $scope.neutralMessage = "";
        $scope.request = {};
        $scope.request.searchMail = "";
        $scope.iCurrent = 0;
        $scope.reply = false;
        $scope.newMessage = {};
        $scope.selectedClient = {};
        $scope.newMessage.Receivers = [];
        $scope.newMessage.Attachments = [];
        
        $scope.newMessage.IdSender = currentUserFactory.User.IdUser;
        $scope.lstDestinataire = [];
        $scope.lstAttachment = [];
        $scope.lignes = "";
        $scope.entete = "";
        $scope.isUseCereals = false;
        $scope.viewMode = "";
        $scope.message = { max: null, pageNo: null };

       $scope.GetMessageSended = function () {
           messengerService.GetMessageSendedPaginate($scope.request.searchMail, $scope.message.max, $scope.message.pageNo, $scope.dataMessageSended.sortKey, $scope.dataMessageSended.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.totalMessage = data.Total;
                    $scope.lstMessage = data.ListVm;
                }
            });
       };

       $scope.GetNewMessage = function () {
           messengerService.GetNewMessagePaginate($scope.request.searchMail, $scope.message.max, $scope.message.pageNo, $scope.dataMessageReceived.sortKey, $scope.dataMessageReceived.reverse, function (data, status) {
               if (status !== null) {
                   if (status === 400)
                       $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
               } else {
                   $scope.totalMessage = data.Total;
                   $scope.lstMessage = data.ListVm;
               }
           });
       };


       $scope.$watch("request.searchMail", function (newValue, oldValue) {
           if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
               if ($scope.viewMode === "sended") {
                   $scope.GetMessageSended();
               } else if ($scope.viewMode === "reception") {
                   $scope.GetNewMessage();
               }
       });

        var init = function () {
            $scope.message.max = 10;
            $scope.message.pageNo = 1;
            switch ($location.$$path) {
                case '/common/messenger/newmessage':
                case '/common/messenger/newmessage/' +$routeParams.IdMessage:
                    $scope.viewMode = "newmessage";
                    $scope.iCurrent = 1;
                    if (enumFactory.Roles.User == currentUserFactory.User.IdRole) {
                        parametersService.GetParameters(function (dataParam, statusParam) {
                            if (statusParam !== undefined && statusParam !== null) {
                                if (status == 400) {
                                    $scope.errorMessage = dataParam[0].errors[0].message;
                                } else {
                                    $scope.errorMessage = statusParam;
                                }
                            } else {
                                parametersFactory.Parameters = dataParam;
                                var destinataires = parametersFactory.Parameters.CommonMessenger.PossibleLabels.split(';');
                                switch (destinataires[parseInt(parametersFactory.Parameters.CommonMessenger.Value) - 1]) {
                                    case "TC":
                                        $scope.isUseCereals = false;
                                        if (currentUserFactory.User.TC) {
                                            $scope.lstDestinataire = currentUserFactory.User.TC;
                                        } else {
                                            $scope.neutralMessage = "Vous n'avez pas de TC ";
                                        }
                                        break;
                                    case "service céréales":
                                        $scope.isUseCereals = true;
                                        $scope.lstDestinataire.push({ "IdUser": 0, "FullName": 'Service céréales' });
                                        $scope.AddReceiver($scope.lstDestinataire[0]);
                                        break;
                                    default:
                                        $scope.errorMessage = "the recipients of farmers's message it's not available in this context";
                                        break;
                                }
                            }
                        });
                    }

                    if (enumFactory.Roles.TC == currentUserFactory.User.IdRole) {
                        $scope.lstDestinataire = $rootScope.lstClientsFromTc;
                    }
                    if (enumFactory.Roles.Admin == currentUserFactory.User.IdRole) {
                        if ($rootScope.lstUsersToMail && $rootScope.lstUsersToMail.length > 0) {
                            $rootScope.lstUsersToMail.forEach(function (elem) {
                                $scope.AddReceiver(elem);
                            });
                        } else
                            userService.GetAllUser(function (data, status) {
                                if (status === null) {
                                    data.GroupContacts.forEach(function (elem) {
                                        elem.Type = "GROUP";
                                        $scope.lstDestinataire.push(elem);
                                    });
                                    data.Users.forEach(function (elem) {
                                        elem.Type = "USER";
                                        $scope.lstDestinataire.push(elem);
                                    });
                                    return true;
                                } else if (status == 400) {
                                    $scope.errorMessage = data[0].errors[0].Message;
                                    return false;
                                } else {
                                    $scope.errorMessage = status;
                                    return false;
                                }
                            });
                    }

                    if ($routeParams.IdMessage) {
                        $scope.neutralMessage = "";
                        messengerService.GetReplyMessage($routeParams.IdMessage, function (data) {
                            if (status === undefined || status === null || status === "") {
                                $scope.newMessage.Subject = "RE: " + data.Subject;
                                $scope.newMessage.Content = "\n\n\n";
                                $scope.newMessage.Content += "De : " + data.Sender.FullName + "\n";
                                $scope.newMessage.Content += "Envoyé : " + $filter('date')(data.DateSend, "dd/MM/yyyy HH:mm:ss") + "\n";
                                $scope.newMessage.Content += "À : " + $rootScope.User.LastName + " " + $rootScope.User.FirstName + "\n\n";
                                $scope.newMessage.Content += data.Content;

                                $scope.newMessage.Receivers.push({ "IdReceiver": data.Sender.IdUser, "FullName": data.Sender.FullName });
                            } else if (status == 400) {
                                $scope.errorMessage = data[0].errors[0].Message;
                                return false;
                            } else {
                                $scope.errorMessage = status;
                                return false;
                            }

                        });


                    }

                    break;
                case '/common/messenger/reception/':
                    $scope.viewMode = "reception";
                    $scope.iCurrent = 0;
                    $rootScope.lstUsersToMail = [];

                    $scope.tableMessageReceivedDataVisible = colonnesMessageReceived.data.DataVisible.split(',');
                    $scope.tableMessageReceivedLibelle = colonnesMessageReceived.data.ColumnName.split(',');
                    $scope.dataMessageReceived = { reverse: true, sortKey: "IdMessage" };
                    $scope.GetNewMessage();

                    break;
                case '/common/messenger/sended/':
                    $scope.viewMode = "sended";
                    $scope.iCurrent = 2;
                    $rootScope.lstUsersToMail = [];

                    $scope.tableMessageSendedDataVisible = colonnesMessageSended.data.DataVisible.split(',');
                    $scope.tableMessageSendedLibelle = colonnesMessageSended.data.ColumnName.split(',');
                    $scope.dataMessageSended = { reverse: true, sortKey: "IdMessage" };
                    $scope.GetMessageSended();

                    break;
                case '/common/messenger/read/' +$routeParams.IdMessage:
                    $scope.viewMode = "read";
                    $scope.reply = true;

                    messengerService.GetMessage($routeParams.IdMessage, function (data) {
                        if (status === undefined || status === null || status === "") {
                            $scope.messageRead = data;
                            if ($scope.messageRead.Sender.IdUser == $rootScope.User.IdUser)
                                $scope.iCurrent = 2;
                            else
                                $scope.iCurrent = 0;
                            majNbMsg();
                        } else if (status == 400) {
                            $scope.errorMessage = data[0].errors[0].Message;
                            return false;
                        } else {
                            $scope.errorMessage = status;
                            return false;
                        }
                        // ReSharper disable once NotAllPathsReturnValue
                    });

                    break;
            }
        };

        $scope.SortMessageSended = function (sortKey, reverse) {
            if ($scope.viewMode === "sended") {
                $scope.dataMessageSended.sortKey = sortKey;
                $scope.dataMessageSended.reverse = reverse;
                $scope.GetMessageSended();

            } else if ($scope.viewMode === "reception") {
                $scope.dataMessageReceived.sortKey = sortKey;
                $scope.dataMessageReceived.reverse = reverse;
                $scope.GetNewMessage();
            }
        };

        $scope.GetData = function (pageNo) {
            $scope.message.pageNo = pageNo;

            if ($scope.viewMode === "sended") {
                $scope.GetMessageSended();
            }
            else if ($scope.viewMode === "reception") {
                $scope.GetNewMessage();
            }
        };

        function checkMessage() {
            resetTexts();
            if (!$scope.newMessage.Receivers || $scope.newMessage.Receivers.length < 1) {
                $scope.errorMessage = "Veuillez sélectionner au moins un destinataire";
                return false;
            }
            if (!$scope.newMessage.Subject) {
                $scope.errorMessage = "Veuillez renseigner un sujet pour ce message";
                return false;
            }
            if (!$scope.newMessage.Content) {
                $scope.errorMessage = "Veuillez écrire un contenu pour ce message";
                return false;
            }
            return true;
        }
        $scope.AddReceiver = function (obj) {
            $scope.resetInput = 1;
            if (obj.Type == "GROUP") {
                obj.UserLights.forEach(function (data) {
                    if (!$filter('filter')($scope.newMessage.Receivers, { "IdReceiver": parseInt(data.IdUser) }, true)[0])
                        $scope.newMessage.Receivers.push({ "IdReceiver": data.IdUser, "FullName": data.FullName });
                });
            } else {
                if (!$filter('filter')($scope.newMessage.Receivers, { "IdReceiver": parseInt(obj.IdUser) }, true)[0])
                    $scope.newMessage.Receivers.push({ "IdReceiver": obj.IdUser, "FullName": obj.FullName });
            }
            setTimeout(function () {
                $scope.resetInput = 0;
            }, 200);

        };
        $scope.DeleteReceiver = function (index) {
            $scope.newMessage.Receivers.splice(index, 1);
        };

        $scope.PostMessage = function () {
            if (!checkMessage()) return false;

            messengerService.PostMessage($scope.newMessage, function (data, status) {
                resetTexts();
                if (status === null) {
                    initMessage();
                    $scope.successMessage = "Votre message a bien été transmis";
                    $rootScope.lstUsersToMail = [];
                    return true;
                } else if (status == 400) {
                    $scope.errorMessage = data[0].errors[0].Message;
                    return false;
                } else {
                    $scope.errorMessage = status;
                    return false;
                }
            });
        };
        $scope.saveAttachement = function (idAttachement) {
            $scope.newMessage.Attachments.push({ "IdAttachment": idAttachement });
        };
        $scope.DeleteAttachement = function (index) {
            $scope.newMessage.Attachments.splice(index, 1);
        };

        $scope.DeleteMessage = function (item) {
            resetTexts();
            if ($scope.iCurrent === 0)//suppression de "message reçu"
                messengerService.DeleteMessageReceive(item.IdMessage, function (data, status) {
                    if (status === null) {
                        $scope.lstMessage = data;
                        $scope.total = data.length;
                        $scope.successMessage = "Votre message a bien été supprimé";
                        majNbMsg();
                        $scope.GetNewMessage();
                    } else if (status == 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        return false;
                    } else {
                        $scope.errorMessage = status;
                        return false;
                    }
                });
            if ($scope.iCurrent == 2)//suppression de "message envoyé"
                messengerService.DeleteMessageSended(item.IdMessage, function (data, status) {
                    if (status === null) {
                        $scope.lstMessage = data;
                        $scope.total = data.length;
                        $scope.successMessage = "Votre message a bien été supprimé";
                        $scope.GetMessageSended();
                    } else if (status == 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        return false;
                    } else {
                        $scope.errorMessage = status;
                        return false;
                    }
                });
        };

        $scope.Delete = function () {
            if ($scope.iCurrent == 2) {
                messengerService.DeleteMessageSended($scope.messageRead.IdMessage, function (data, status) {
                    if (status === null) {
                        $location.path('/common/messenger/sended/');
                    } else if (status == 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        return false;
                    } else {
                        $scope.errorMessage = status;
                        return false;
                    }
                });
            } else {
                messengerService.DeleteMessageReceive($scope.messageRead.IdMessage, function (data, status) {
                    if (status === null) {
                        $location.path('/common/messenger/reception/');
                    } else if (status == 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        return false;
                    } else {
                        $scope.errorMessage = status;
                        return false;
                    }

                });
            }
        };
     
        $scope.ReadMessage = function (message) {
            $location.path('/common/messenger/read/' + message.IdMessage);
        };

        $scope.Reply = function (message) {
            $location.path('/common/messenger/newmessage/' + message.IdMessage);
        };

        $scope.Cancel = function () {
            if ($scope.iCurrent == 2)
                $location.path('/common/messenger/sended/');
            else
                $location.path('/common/messenger/reception/');
        };

        $scope.GetAttachment = function (p) {
            $window.open(configurationFactory.Url['Api'] + 'Upload/GetAttachment?p=' + p, '_self');
        };

        function resetTexts() {
            $scope.successMessage = "";
            $scope.errorMessage = "";
        }
        function initMessage() {
            if (!$scope.isUseCereals) {
                $scope.newMessage = {};
                $scope.newMessage.Receivers = [];
            }

            $scope.searchTextClientFromTc = "";
            $scope.selectedClient = {};
            $scope.newMessage.Content = "";
            $scope.newMessage.Subject = "";
            $scope.newMessage.Attachments = [];
            $scope.lstAttachment = [];
            $scope.newMessage.IdSender = currentUserFactory.User.IdUser;
        }

        /* ne pas oublier d'apeller la methode a la lecture d'un message */
        function majNbMsg() {
            messengerService.GetNbMessage(function (data, status) {
                $rootScope.nbmessage = data;
            });
        }
        $rootScope.onRefresh = function () {
            init();
            initMessage();
        };
        init();
    }]);
