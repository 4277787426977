AgrimarketControllers.controller('closeDayController', ['$scope', '$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory', 'closeDayService', '$filter', 'parametersService', '$q',
	function ($scope, $rootScope, $http, $location, currentUserFactory, configurationFactory, closeDayService, $filter, parametersService, $q) {

	    $rootScope.onRefresh = function () {
	        $scope.GetCloseDays();
	    };

	    $scope.Init = function () {
	        $scope.errorMessage = $scope.validationMessage = "";
	        $scope.GetCloseDays();
	        $scope.GetOpenDaysSalesUser();
	    };

	    $scope.GetCloseDays = function () {
	        closeDayService.GetCloseDays(function (data, status) {
	            if (status !== null) {
	                if (status === 400)
	                    $scope.message = data[0].errors[0].Message;
	            } else
	                $scope.closeDays = data;
	        });
	    };

	    $scope.GetOpenDaysSalesUser = function () {
	        parametersService.GetParameterByKey("OpenDaysSalesUser", function (data, status) {
	            if (status !== null) {
	                if (status === 400)
	                    $scope.message = data[0].errors[0].Message;
	            } else {
	                if (data && data.PossibleLabels && data.Value && data.Value.length === 7) {
	                    $scope.OpenDaysSalesUser = [];
	                    data.PossibleLabels.split(',').forEach(function (item, index) {
	                        var day = {
	                            Id: null,
	                            Label: null,
	                            Value: false
	                        };
	                        day.Id = index + 1;
	                        day.Label = item.trim();
	                        if (Number(data.Value.substring(index, index + 1)))
	                            day.Value = Number(data.Value.substring(index, index + 1)) === 1;

	                        $scope.OpenDaysSalesUser.push(day);
	                    });
	                }
	            }
	        });
	    };

	    function ConvertChoixDaysToString() {
	        var deferred = $q.defer();
	        var valueString = "";
	        $scope.OpenDaysSalesUser.forEach(function (item) {
	            if (item.Value)
	                valueString += "1";
	            else
	                valueString += "0";
	        });
	        deferred.resolve(valueString);
	        return deferred.promise;
	    }

	    $scope.SaveChoixDayOpen = function () {
	        ConvertChoixDaysToString().then(function (value) {
	            parametersService.SaveParameterByKey("OpenDaysSalesUser", value, function (data, status) {
	                if (status !== null) {
	                    if (status === 400)
	                        $scope.message = data[0].errors[0].Message;
	                }
	            });
	        });
	    };
	    $scope.AddCloseDay = function (openPopUp, titreModal) {
	        $scope.closeDay = null;
	        $scope.errorMessage = $scope.validationMessage = "";
	        $scope.ModalTitle = titreModal;
	        $scope.ActionType = "new";
	        openPopUp();
	    };

	    $scope.EditCloseDay = function (closeDay, openPopUp, titreModal) {
	        $scope.errorMessage = $scope.validationMessage = "";
	        $scope.closeDay = angular.copy(closeDay);
	        $scope.ModalTitle = titreModal;
	        $scope.ActionType = "edit";
	        openPopUp();
	    };

	    $scope.CheckCloseDay = function () {
	        $scope.errorMessage = $scope.validationMessage = "";

	        if (($scope.closeDay === undefined || $scope.closeDay === null) || ($scope.closeDay !== undefined && $scope.closeDay !== null) && ($scope.closeDay.Date === "" || $scope.closeDay.DateString === "" || $scope.closeDay.DateString === undefined || $scope.closeDay.DateString === null || $scope.closeDay.Label === "" || $scope.closeDay.Label === undefined || $scope.closeDay.Label === null))
	            $scope.errorMessage += "Veuillez renseigner tous les champs.";
	        else {
	            var dateExist = $filter('filter')($scope.closeDays, { DateString: $filter('date')($scope.closeDay.DateString, "dd/MM/yyyy") })[0];
	            if (dateExist !== undefined && dateExist !== null && dateExist.IdCloseDay != $scope.closeDay.IdCloseDay)
	                $scope.errorMessage += "Cette date est déjà attribuée à '" + dateExist.Label + "'.";
	        }
	        if ($scope.errorMessage !== "")
	            return false;
	        else
	            return true;
	    };

	    $scope.SaveCloseDay = function () {
	        if ($scope.ActionType == "new") {
	            closeDayService.AddCloseDay($scope.closeDay, function (data, status) {
	                if (status !== null) {
	                    if (status === 400)
	                        $scope.message = data[0].errors[0].Message;
	                } else {
	                    $scope.validationMessage = "Votre jour fermé a bien été pris en compte.";
	                    $scope.closeDays = data;
	                }
	            });
	        } else if ($scope.ActionType == "edit") {
	            closeDayService.EditCloseDay($scope.closeDay, function (data, status) {
	                if (status !== null) {
	                    if (status === 400)
	                        $scope.message = data[0].errors[0].Message;
	                } else {
	                    $scope.closeDays = data;
	                    $scope.validationMessage = "Vos modifications ont bien été prises en compte.";
	                }
	            });
	        }
	    };

	    $scope.DeleteCloseDay = function (param) {
	        $scope.errorMessage = $scope.validationMessage = "";
	        closeDayService.DeleteCloseDay(param.IdCloseDay, function (data, status) {
	            if (status !== null) {
	                if (status === 400)
	                    $scope.message = data[0].errors[0].Message;
	            } else {
	                $scope.closeDays.splice(param.Index, 1);
	            }
	        });
	    };
	    $scope.Init();
	}]);