AgrimarketServices.service('typeFixationsService', ['$http', 'configurationFactory',
    function ($http, configurationFactory) {

    	var SendRequest = function (configfile, callback) {
    		$http(configfile)
			    .success(function (data) {
			    	callback(data, null);
			    })
			    .error(function (data, status) {
			    	callback(data, status);
			    });
    	};

        this.PutTypeFixationLabelAction = function (lstTypeFixationVm, callback) {
    	    var config = {
    	        method: 'PUT',
                url: configurationFactory.Url['Api'] + 'TypeFixation/PutTypeFixationLabelAction',
                data: lstTypeFixationVm
    	    };
    	    SendRequest(config, callback);
    	};

    }]);