AgrimarketControllers.controller('editTemplateContractController', ['$scope', '$rootScope', '$routeParams', '$sce', '$filter', 'productsService', '$location', 'templatesService', 'languageService', 'enumFactory', 'configurationFactory', '$log', 'tagsService', 'compagnyService',
    function ($scope, $rootScope, $routeParams, $sce, $filter, productsService, $location, templatesService, languageService, enumFactory, configurationFactory, $log, tagsService, compagnyService) {
        var idtemplate = $routeParams.idtemplate;
        var Init = function () {
            $scope.errorMessage = "";
            $scope.tagselected = "";
            $scope.Template = {};
            $scope.Template.Content = "";
            $scope.disabledChangeType = false;
            $scope.disabledChangeCompagny = false;
            $scope.txtBtnSave = "";
            $scope.newTemplate = false;
            $rootScope.succesMessageTemplate = "";
            $scope.IsMultilingue = $rootScope.ModuleMultilingue.Active;
            $scope.inputProductLabel = $rootScope.globalLabels.AllItems;
            $scope.enumTypeTemplate = enumFactory.TypeTemplates;

            if ($rootScope.ModuleMultipleCompagnies.Active) {
                compagnyService.GetAll(function (data, status) {
                    if (status === null)
                        $scope.lstCompagnies = data;
                    else if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else {
                        $scope.errorMessage = status;
                    }
                });
            }

            if (!$scope.IsMultilingue)
                SetDefautlLanguage();

            if (idtemplate === undefined || idtemplate === null || idtemplate == -1) { //nouveau template
                $scope.txtBtnSave = "Créer";
                $scope.blocTitle = "Ajouter un nouveau template de contrat";
                $scope.disabledChangeType = false;
                $scope.disabledChangeCompagny = false;
                $scope.newTemplate = true;
                getProducts();
            } else { // modifier template
                $scope.txtBtnSave = "Modifier";
                $scope.blocTitle = "Modifier un template de contrat";
                $scope.disabledChangeType = true;
                $scope.disabledChangeCompagny = true;
                getProducts(getTemplate);
            }
            $scope.GetTypeTemplate();
            $scope.GetLanguages();
        };

        $scope.GetTags = function () {
            if ($scope.Template.IdTypeTemplate !== undefined && $scope.Template.IdTypeTemplate !== null && $scope.Template.IdTypeTemplate !== "") {
                tagsService.GetTagsByIdTypeTemplate($scope.Template.IdTypeTemplate, function (data, status) {
                    if (status !== null) {
                        if (status === 400) {
                            $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                        }
                    } else {
                        $scope.lstTags = data;
                    }
                });
            } else {
                $scope.lstTags = [];
            }
        };

        $scope.AddTag = function () {
            CKEDITOR.instances.txtTemplate.insertHtml($scope.tagselected);
        };

        $scope.GetTypeTemplate = function () {
            templatesService.GetAllTypeTemplate(function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    }
                } else {
                    $scope.TypeTemplates = data;
                }
            });
        };

        $scope.GetLanguages = function () {
            languageService.GetLanguages(function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    }
                } else {
                    $scope.Languages = data;
                }
            });
        };

        var SetDefautlLanguage = function () {
            languageService.GetDefaultLanguage(function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    }
                } else {
                    $scope.Template.IdLanguage = data.IdLanguage;
                }
            });
        };

        $scope.Cancel = function () {
            $location.path('/admin/editorial/view/contracts');
        };

        $scope.Save = function () {
            $scope.errorMessage = "";
            if (!$scope.CheckTemplate()) return;

            templatesService.SaveTemplate($scope.Template, function (data, status) {
                if (status !== null) {
                    if (status == 400) {
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    }
                } else {
                    if ($scope.txtBtnSave === "Modifier")
                        $rootScope.succesMessageTemplate = "Vos modifications ont bien été prises en compte.";
                    else
                        $rootScope.succesMessageTemplate = "Votre template a bien été pris en compte.";
                    $location.path('/admin/editorial/view/contracts');
                }
            });
        };

        $scope.Overview = function () {
            $scope.errorMessage = "";
            templatesService.OverviewTemplate($scope.Template, function (data, status) {
                if (status !== null) {
                    if (status == 400) {
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    }
                } else {
                    fetch('data:application/pdf;base64,' + data.slice(1, -1))
                        .then(function (response) {
                            return response.blob();
                        })
                        .then(function (blob) {
                            window.open(URL.createObjectURL(blob));
                        });
                }
            });
        };

        $scope.CheckTemplate = function () {
            $scope.errorMessage = "";
            if ($scope.Template.Label === undefined || $scope.Template.Label === null || $scope.Template.Label.trim() === "") {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Template_Error_NotAllElements.Content;
                return false;
            }
            if ($scope.Template.IdTypeTemplate === undefined || $scope.Template.IdTypeTemplate === null || $scope.Template.IdTypeTemplate === "") {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Template_Error_NotAllElements.Content;
                return false;
            }
            if ($scope.Template.Content === undefined || $scope.Template.Content === null || $scope.Template.Content.trim() === "") {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Template_Error_NotAllElements.Content;
                return false;
            }

            if ($scope.IsMultilingue && ($scope.Template.IdLanguage === undefined || $scope.Template.IdLanguage === null)) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Template_Error_NotAllElements.Content;
                return false;
            }

            if ($rootScope.ModuleMultipleCompagnies.Active && !$scope.Template.IdCompagny) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Template_Error_NotAllElements.Content;
                return false;
            }

            if ($scope.Template.IdTypeTemplate === $scope.enumTypeTemplate.BlankContract && idsProductsSelected.length === 0) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Template_Error_NotAllElements.Content;
                return false;
            }
            else
                $scope.Template.ListProductIdsSelected = idsProductsSelected;


            $scope.Template.Content = $scope.Template.Content.split('&nbsp;}}').join(" }}"); // Nettoyer le tag après modification.
            for (var i = 0; i < $scope.Template.Content.length; i++) {
                if ($scope.Template.Content[i] == "{" && $scope.Template.Content[i + 1] == "{") {
                    var key = "{{";
                    for (var j = i + 2; i < $scope.Template.Content.length; j++) {

                        if ($scope.Template.Content[j] == "}" && $scope.Template.Content[j + 1] == "}") {
                            key += "}}";

                            if (key.indexOf("&nbsp;") !== -1) { key = key.replace("&nbsp;", " "); }
                            if (key.indexOf('@') !== -1) {
                                var params = key.substring(key.indexOf('@') + 1, key.indexOf(' }}'));
                                var isNumber = params.match("[0-9]+");

                                if (params === 'X' || isNumber === null) {
                                    $scope.errorMessage = "Une des balises insérée nécessite une valeur numérique : " + key + " Remplacez 'X' par un numéro.";
                                    return false;
                                }

                                key = key.replace(params, 'X');
                            }

                            if ($filter('filter')($scope.lstTags, { "KeyTag": key }, true)[0] === undefined) {
                                $scope.errorMessage = "Une des balises insérée n'existe pas pour ce template : " + key;
                                return false;
                            }
                            break;
                        }
                        key += $scope.Template.Content[j];
                    }
                }
            }
            return true;
        };

        $scope.UploadImage = function (data) {
            CKEDITOR.instances.txtTemplate.insertHtml("<img src='" + $filter('uploadLinkInline')(data.PrivateLabel) + "'>");
        };

        $scope.clickOnProductElement = function (idProduct, checked) {
            $scope.products.forEach(function (product) {
                if (idProduct === -1) product.checked = !checked; // Si tous
                else {
                    if (product.IdProduct === -1) product.checked = false;
                    if (idProduct == product.IdProduct) product.checked = !checked;
                }
            });

            selectIdsProducts();
        };

        var getTemplate = function () {
            templatesService.GetTemplateById(idtemplate, function (data, status) {
                if (status !== null) {
                    if (status == 400) {
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    }
                } else {
                    $scope.Template = data;

                    $scope.products.forEach(function (p) {
                        p.checked = $scope.Template.ListProductIdsSelected.indexOf(p.IdProduct) >= 0;
                    });

                    // Si il y a autant de produits en bdd que dans le template, alors on coche le Tous
                    if ($scope.Template.ListProductIdsSelected.length === $scope.products.length - 1) {
                        $scope.products[0].checked = true;
                    }

                    selectIdsProducts();
                    $scope.GetTags();
                }
            });
        };

        var selectIdsProducts = function () {
            idsProductsSelected = [];
            var productAll = $filter('filter')($scope.products, { IdProduct: -1 }, true)[0];
            $scope.inputProductLabel = productAll.checked ? productAll.Label : "Sélectionnez les produits";

            $scope.products.forEach(function (product) {
                if (product.checked && product.IdProduct !== -1) {
                    idsProductsSelected.push(product.IdProduct);
                    if ($scope.inputProductLabel !== productAll.Label)
                        if ($scope.inputProductLabel === "Sélectionnez les produits")
                            $scope.inputProductLabel = product.Label;
                        else
                            $scope.inputProductLabel += ", " + product.Label;
                }
            });
        };

        function getProducts(callback) {
            productsService.GetAllProductsLight(function (data, status) {
                if (status !== null) {
                    if (status == 400) {
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    }
                } else {
                    $scope.products = data;
                    $scope.products.splice(0, 0, { Label: $rootScope.globalLabels.AllItems, IdProduct: -1 });

                    if (callback)
                        callback();
                    else {
                        $scope.products.forEach(function (p) {
                            p.checked = true;
                        });
                        selectIdsProducts();
                    }
                }
            });
        }

        $rootScope.onRefresh = function () {
            Init();
        };

        Init();
    }]);
