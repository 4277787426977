AgrimarketServices.service('routeService', ['$location',
    function($location) {
        this.locationByParam = function(paramValue) {

            switch (paramValue) {
                case 'outstandings':
                    $location.path("/user/accounts/outstandings");
                    break;
                case 'contributions-marketinfos':
                    $location.path("/user/contributions/");
                    break;
                case 'contributions':
                    $location.path("/user/contributions/");
                    break;
                case 'marketinfos':
                    $location.path("/user/marketinfos/").search({});
                    break;
                case 'asking':
                    $location.path("/user/supply/request/asking");
                    break;
                case 'offers':
                    $location.path("/user/offers/view/1");
                    break;
                case 'commitmentoffers':
                    $location.path("/user/offers/view/2");
                    break;
                case 'commitments':
                    $location.path("/user/commitments/view/tableTypeCommitment");
                    break;
                case 'validatedcontracts':
                    $location.path("/user/contracts/firm/view/validated");
                    break;
                case 'pricedcontracts':
                    $location.path("/user/contracts/firm/view/priced");
                    break;
                case 'canceledcontracts':
                    $location.path("/user/contracts/firm/view/canceled");
                    break;
                case 'commitmentcontracts':
                    $location.path("/user/contracts/commitment/view/validated");
                    break;
                case 'surfaceCommitmentcontracts':
                    $location.path("/user/contracts/surfaceCommitment/view/validated");
                    break;
                case 'siteUnavailable':
                    $location.path("/siteUnavailable");
                    break;
                case 'UnsubscribeNewsMarketInfo':
                    $location.path("/UnsubscribeNewsMarketInfo");
                    break;
                case 'disableSubscription':
                    $location.path("/disableSubscription");
                    break;
                default:
                    break;
            }
        };
    }
]);