AgrimarketServices.service('elementInfoMarketHomePageQuoteService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
	function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

	    var SendRequest = function (configfile, callback) {
	        $http(configfile)
				.success(function (data) {
				    callback(data, null);
				})
				.error(function (data, status) {
				    callback(data, status);
				});
	    };
	    this.GetAllElementInfoMarketHomePageQuotes = function (callback) {
	        var config = {
	            method: 'GET',
	            url: configurationFactory.Url['Api'] + 'ElementInfoMarketHomePageQuote/GetAllElementInfoMarketHomePageQuotes'
	        };
	        SendRequest(config, callback);
	    };

	    this.SaveListElementInfoMarketHomePageQuotes = function (lstElementInfoMarketHomePageQuotes, callback) {
	        var config = {
	            method: 'POST',
	            data: lstElementInfoMarketHomePageQuotes,
	            url: configurationFactory.Url['Api'] + 'ElementInfoMarketHomePageQuote/SaveListElementInfoMarketHomePageQuotes'
	        };
	        SendRequest(config, callback);
	    };
	}]);