/*
*   Directive de datetimepicker
*
*/

AgrimarketDirectives.directive('timepicker', function ($parse) {
	return function (scope, element, attrs) {
		//if (!Modernizr.inputtypes.time) {
				element.attr('type', 'text');
			scope.$watch('$viewContentLoaded', function () {

				$(function () {
					element.timepicker({
						minuteText: 'Minute',
						secondText: 'Seconde',
						hourText: 'Heure',
						timeSeparator: ':',
						defaultTime: '00:00:00',
						stepMinute: 1,
						stepSecond: 1,
						showHour: attrs.timepickerHour === "true" ? true : false,
						showMinute: attrs.timepickerMin === "true" ? true : false,
						showSecond: attrs.timepickerSec === "true" ? true : false,
						timeText: 'Time',
						timeFormat: attrs.timepickerFormat,
						timeOnlyTitle: attrs.timepickerHour === "true" ? 'Sélectionnez un horaire' : 'Sélectionnez un Délai',
						onSelect: function (time) {
							var getter = $parse(attrs.timepickerTarget);
							var setter = getter.assign; //get value
							setter(scope, time); //set value
						}
					});

				});


			});
		//} 

		if (element.val() === "") {
			element.val("00:00:00");
		}
	};
});
