AgrimarketFactories.factory("editableObjectsFactory", ['enumFactory',
	function (enumFactory) {
		return {
			EditableObjects: null,
			"icoVisible": false,
			"getItemByKey": function (key) {
				return this.EditableObjects[key];
			},
			"getIsVisibleByKey": function (key) {
				if (this.EditableObjects[key] !== undefined)
					return this.EditableObjects[key].IsVisible;
				else
					return true;
			},
			"getContentByKey": function (key) {
				if (this.EditableObjects[key] !== undefined) {
					switch(this.EditableObjects[key].IdTypeEditableObject) {
						case enumFactory.IdTypeEditableObject.ButtonCancel:
                            return '<span class="icofont-close-circled"></span>' +this.EditableObjects[key].Content;
						case enumFactory.IdTypeEditableObject.ButtonConfirm:
                            return '<span class="icofont-check-circled"></span>' + this.EditableObjects[key].Content;
						default:
							return this.EditableObjects[key].Content;
					}
				}					
				else
					return undefined;
			},
			"getIdTypeEditableObjectByKey": function (key) {
				if (this.EditableObjects[key] !== undefined)
					return this.EditableObjects[key].IdTypeEditableObject;
				else {
					return undefined;
				}
			},


		};
	}]);