AgrimarketServices.service('categoryInfoMarketService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
	function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

		var SendRequest = function (configfile, callback) {
			$http(configfile)
				.success(function (data) {
					callback(data, null);
				})
				.error(function (data, status) {
					callback(data, status);
				});
		};

		this.GetCategoryInfosMarket = function (callback) {
			var config = {
				method: 'GET',
				url: configurationFactory.Url['Api'] + 'CategoryInfoMarket/GetCategoryInfoMarket'
			};
			SendRequest(config, callback);
		};

		this.SaveCategoryInfoMarket = function (lstCategoriesInfoMarket, callback) {
		    var config = {
		        method: 'POST',
		        data :lstCategoriesInfoMarket,
		        url: configurationFactory.Url['Api'] + 'CategoryInfoMarket/SaveCategoryInfoMarket'
		    };
		    SendRequest(config, callback);
        };

        this.SaveGroupContactsOfCategoryInfoMarket = function (idCategoryInfoMarket, lstIdGroupContacts, callback) {
            var config = {
                method: 'POST',
                data: lstIdGroupContacts,
                params: {
                    idCategoryInfoMarket: idCategoryInfoMarket
                },
                url: configurationFactory.Url['Api'] + 'CategoryInfoMarket/SaveGroupContactsOfCategoryInfoMarket'
            };
            SendRequest(config, callback);
        };

	}]);