AgrimarketControllers.controller('editableController', ['$scope', '$http', '$location', '$routeParams', 'configurationFactory', '$rootScope', 'editableObjectsService', 'enumFactory', 'userTableService', 'typeOrdersService', 'elementInfoMarketHomePagesService', '$sce', '$filter', '$compile', '$timeout', 'typeFixationsService',
    function ($scope, $http, $location, $routeParams, configurationFactory, $rootScope, editableObjectsService, enumFactory, userTableService, typeOrdersService, elementInfoMarketHomePagesService, $sce, $filter, $compile, $timeout, typeFixationsService) {

        //popup simple
        $rootScope.openPopEditable = function (key, textDefault) {
            if (!$rootScope.modeEdition) {
                return;
            }
            $scope.editableErrorMessage = "";
            $rootScope.editableObject = $rootScope.editableObjectsFactory.getItemByKey(key);
            if ($rootScope.editableObject === undefined)
                $rootScope.editableObject = { 'IdEditableObject': 0, 'Key': key, 'IsVisible': true, 'IdTypeEditableObject': enumFactory.IdTypeEditableObject.Texte, 'Content': textDefault };
            $rootScope.editableObjectBeforeChange = angular.copy($rootScope.editableObjectsFactory.getItemByKey(key));
            var titlePopUp = $rootScope.editableObjectBeforeChange ? $rootScope.editableObjectBeforeChange.Content : textDefault;
            $("#popEditable").css("display", "block");
            $("#popEditable").dialog({
                title: "Edition du champ : " + titlePopUp,
                modal: false,
                create: function () {
                    $(this).closest('div.ui-dialog')
                        .find('.ui-dialog-titlebar-close')
                        .click(function (e) {
                            $scope.ClosePopEditable(true);
                            $scope.$apply();
                            e.preventDefault();
                        });
                }
            });
        };

        $scope.SaveDataPopEditable = function () {
            var dataToApi = {
                IdEditableObject: $rootScope.editableObject.IdEditableObject,
                IdTypeEditableObject: $rootScope.editableObject.IdTypeEditableObject,
                Content: $rootScope.editableObject.Content,
                Key: $rootScope.editableObject.Key,
                IsVisible: $rootScope.editableObject.IsVisible
            };
            editableObjectsService.SaveEditableObject(dataToApi, function (data, status) {
                if (status !== null) {
                    if (status == 400) {
                        $scope.editableErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    }
                } else {
                    $rootScope.editableObjectsFactory.EditableObjects[data.Key] = { 'IdEditableObject': data.IdEditableObject, 'Content': data.Content, 'IsVisible': data.IsVisible, 'ContentDefault': data.ContentDefault, 'IdTypeEditableObject': data.IdTypeEditableObject };
                    $scope.ClosePopEditable(false);
                }
            });
        };

        $scope.ClosePopEditable = function (cancel) {
            if (cancel) {
                if ($rootScope.editableObjectBeforeChange !== undefined) {
                    $rootScope.editableObject.Content = $rootScope.editableObjectBeforeChange.Content;
                    $rootScope.editableObject.IsVisible = $rootScope.editableObjectBeforeChange.IsVisible;
                }
            }
            $("#popEditable").dialog("close");
        };
        //fin popupsimple

        //popup titre graphique
        $rootScope.openPopEditableGraph = function (key, textDefault) {
            if (!$rootScope.modeEdition) {
                return;
            }
            $scope.editableErrorMessage = "";
            $rootScope.editableObject = $rootScope.editableObjectsFactory.getItemByKey(key);
            if ($rootScope.editableObject === undefined)
                $rootScope.editableObject = { 'IdEditableObject': 0, 'Key': key, 'IsVisible': true, 'IdTypeEditableObject': enumFactory.IdTypeEditableObject.Texte, 'Content': textDefault };
            $rootScope.editableObjectBeforeChange = angular.copy($rootScope.editableObjectsFactory.getItemByKey(key));
            var titlePopUp = $rootScope.editableObjectBeforeChange ? $rootScope.editableObjectBeforeChange.Content : textDefault;
            $("#popEditableGraph").css("display", "block");
            $("#popEditableGraph").dialog({
                title: "Edition du champ : " + titlePopUp,
                modal: false,
                create: function () {
                    $(this).closest('div.ui-dialog')
                        .find('.ui-dialog-titlebar-close')
                        .click(function (e) {
                            $scope.ClosePopEditableGraph(true);
                            $scope.$apply();
                            e.preventDefault();
                        });
                }
            });
        };

        $scope.SaveDataPopEditableGraph = function () {
            var dataToApi = {
                IdEditableObject: $rootScope.editableObject.IdEditableObject,
                IdTypeEditableObject: $rootScope.editableObject.IdTypeEditableObject,
                Content: $rootScope.editableObject.Content,
                Key: $rootScope.editableObject.Key,
                IsVisible: $rootScope.editableObject.IsVisible
            };
            editableObjectsService.SaveEditableObject(dataToApi, function (data, status) {
                if (status !== null) {
                    if (status == 400) {
                        $scope.editableErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    }
                } else {
                    $rootScope.editableObjectsFactory.EditableObjects[data.Key] = { 'IdEditableObject': data.IdEditableObject, 'Content': data.Content, 'IsVisible': data.IsVisible, 'ContentDefault': data.ContentDefault, 'IdTypeEditableObject': data.IdTypeEditableObject };
                    $scope.ClosePopEditableGraph(false);
                    window.location.reload();
                }
            });
        };

        $scope.ClosePopEditableGraph = function (cancel) {
            if (cancel) {
                if ($rootScope.editableObjectBeforeChange !== undefined) {
                    $rootScope.editableObject.Content = $rootScope.editableObjectBeforeChange.Content;
                    $rootScope.editableObject.IsVisible = $rootScope.editableObjectBeforeChange.IsVisible;
                }
            }
            $("#popEditableGraph").dialog("close");
        };
        //fin titre graphique

        //popup super table directive
        $rootScope.openPopEditableTable = function (idDefaultTable) {
            if (!$rootScope.modeEdition) {
                return;
            }
            $scope.editableTableErrorMessage = "";
            $("#popEditableTable").css("display", "block");
            $("#popEditableTable").dialog({
                title: $rootScope.globalLabels.Edit_Table,
                modal: false,
                width: '50%',
                position: { my: "center", at: "center", of: window },
                create: function () {
                    $(this).closest('div.ui-dialog')
                        .find('.ui-dialog-titlebar-close')
                        .click(function (e) {
                            $scope.ClosePopEditableTable(true);
                            $scope.$apply();
                            e.preventDefault();
                        });
                }
            });

            var initPopEditableTableScope = function () {
                $scope.idDefaultTable = idDefaultTable;
                userTableService.GetTableById(idDefaultTable, function (data, status) {
                    if (status !== null) {
                        if (status == 400)
                            $scope.editableTableErrorMessage = data[0].errors[0].Message;
                        else
                            $scope.editableTableErrorMessage = data;
                    } else {
                        setScopeColumns(data);
                    }
                });
            };

            initPopEditableTableScope();
        };

        $scope.SaveDataPopEditableTable = function () {
            if (checkDataEditableTable()) {
                var columns = angular.copy($scope.columns);
                angular.forEach(columns, function (column, index) {
                    column.order = Number(column.order);
                    column.label = $scope.oldColumns[index].order + "#" + column.label;
                });
                
                var columnsFiltered = $filter('filter')(columns, { isVisible: true });
                columnsFiltered = $filter('orderBy')(columnsFiltered, 'order');

                var dataVisible = '';
                var columnName = '';
                columnsFiltered.forEach(function (item) {
                    dataVisible += item.databaseVar + ',';
                    columnName += item.label + ',';
                });
                if (dataVisible.length > 0)
                    dataVisible = dataVisible.slice(0, -1);
                if (columnName.length > 0)
                    columnName = columnName.slice(0, -1);

                var dataToApi = {
                    IdDefaultTable: $scope.idDefaultTable,
                    DataVisible: dataVisible,
                    ColumnName: columnName
                };

                userTableService.PutColumn(dataToApi, function (data, status) {
                    if (status !== null) {
                        if (status == 400) {
                            $scope.editableTableErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                        }
                    } else {
                        setScopeColumns(data);
                        $scope.ClosePopEditableTable();
                        window.location.reload();

                    }
                });
            }
        };

        $scope.ClosePopEditableTable = function () {
            $("#popEditableTable").dialog("close");
        };

        var checkDataEditableTable = function () {
            var columns = $filter('filter')($scope.columns, { isVisible: true });
            if (columns.length === 0) {
                $scope.editableTableErrorMessage = "Il faut au moins rendre une colonne visible.";
                return false;
            }
            return true;
        };

        var setScopeColumns = function (data) {
            var tabColumnNameDefault = data.ColumnNameDefault.split(",");
            var tabDataVisibleDefault = data.DataVisibleDefault.split(",");
            var tabDataVisible = data.DataVisible.split(",");
            var tabColumnName = data.ColumnName.split(",");
            $scope.columns = [];

            tabDataVisibleDefault.forEach(function (item, index) {
                var indexLabel = tabDataVisible.indexOf(item);
                var indexLabelDefault = tabDataVisibleDefault.indexOf(item);
                var label = "";
                if (indexLabel !== -1) {
                    label = tabColumnName[indexLabel];
                    labelDefault = tabColumnNameDefault[indexLabelDefault];
                } else {
                    label = tabColumnNameDefault[index];
                    labelDefault = tabColumnNameDefault[indexLabelDefault];
                }
                var column = { labelDefault: labelDefault, label: label, databaseVar: item, isVisible: tabDataVisible.indexOf(tabDataVisibleDefault[index]) !== -1 };
                
                if (tabDataVisible.indexOf(tabDataVisibleDefault[index]) !== -1) {
                    column.order = "" + (tabDataVisible.indexOf(tabDataVisibleDefault[index]) + 1);
                }

                $scope.columns.push(column);
            });

            $scope.oldColumns = angular.copy($scope.columns);
        };
        //fin popup super table directive

        //popup super menu directive
        $rootScope.openPopEditableMenu = function (key) {
            if (!$rootScope.modeEdition) {
                return;
            }
            $scope.editableMenuErrorMessage = "";
            //$("#popEditableMenu").css("display", "block");
            var initPopEditableMenucope = function () {
                $scope.key = key;
                editableObjectsService.GetEditableMenuByKey(key, function (data, status) {
                    if (status !== null) {
                        if (status == 400)
                            $scope.editableMenuErrorMessage = data[0].errors[0].Message;
                        else
                            $scope.editableMenuErrorMessage = data;
                    } else {
                        $scope.menus = data;
                        $timeout(function () {
                            $("#popEditableMenu").dialog({
                                title: "Modification des menus ",
                                modal: false,
                                width: '50%',
                                position: { my: "center", at: "center", of: window },
                                create: function () {
                                    $(this).closest('div.ui-dialog')
                                        .find('.ui-dialog-titlebar-close')
                                        .click(function (e) {
                                            $scope.ClosePopEditableMenu(true);
                                            $scope.$apply();
                                            e.preventDefault();
                                        });
                                }
                            });
                        }, 0);

                    }
                });
            };

            initPopEditableMenucope();
        };

        var checkDataEditableMenu = function () {
            var columns = $filter('filter')($scope.menus, { Visible: true });
            if (columns.length === 0) {
                $scope.editableMenuErrorMessage = "Il faut au moins rendre une colonne visible.";
                return false;
            }
            return true;
        };

        $scope.SaveDataPopEditableMenu = function () {
            if (checkDataEditableMenu()) {
                var menus = $filter('orderBy')($scope.menus, 'Position');
                var data = [];

                menus.forEach(function (item) {
                    data.push({
                        Key: item.Key,
                        IdEditableObject: item.IdEditableObject,
                        Position: item.Position,
                        IsVisible: item.Visible,
                        Content: item.Label
                    });

                    if (item.PagesEditableVms.length) {
                        item.PagesEditableVms.forEach(function (subMenu) {
                            data.push({
                                Key: subMenu.Key,
                                IdEditableObject: subMenu.IdEditableObject,
                                Position: subMenu.Position,
                                IsVisible: subMenu.Visible,
                                Content: subMenu.Label
                            });
                        });
                    }
                });



                editableObjectsService.SaveEditableObjects(data, function (data, status) {
                    if (status !== null) {
                        if (status == 400) {
                            $scope.editableMenuErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                        }
                    } else {
                        $scope.ClosePopEditableMenu();
                        window.location.reload();

                    }
                });
            }
        };

        $scope.ClosePopEditableMenu = function () {
            $("#popEditableMenu").dialog("close");
        };
        //fin popup super menu directive

        //popup enum directive
        $rootScope.openPopEditableEnum = function (key) {
            if (!$rootScope.modeEdition) {
                return;
            }
            $scope.editableEnumErrorMessage = "";
            $("#popEditableEnum").css("display", "block");
            $("#popEditableEnum").dialog({
                title: $rootScope.globalLabels.Edit_Actions,
                modal: false,
                width: '50%',
                position: { my: "center", at: "center", of: window },
                create: function () {
                    $(this).closest('div.ui-dialog')
                        .find('.ui-dialog-titlebar-close')
                        .click(function (e) {
                            $scope.ClosePopEditableEnum(true);
                            $scope.$apply();
                            e.preventDefault();
                        });
                }
            });
            $scope.enumKey = key;
            var enumObjects = [];
            if (key === "Action" || key === "ActionOption" || key === "CommitmentAction" || key === "ProductAndTypeCommitAction" || key === "UsersOfTcActions" || key === "OfferActions") {
                angular.forEach(Object.keys(enumFactory[key]), function (enumKey) {
                    var enumObject = { text: enumFactory[key][enumKey].ContentDefault, key: enumFactory[key][enumKey].EditableKey, type: enumFactory[key][enumKey].ContentDefault };
                    enumObjects.push(enumObject);
                });
            }
            else if (key === "TypeOrders") {
                angular.forEach(Object.keys(enumFactory[key]), function (enumKey) {
                    var enumObject = { type: enumFactory[key][enumKey].Label, text: enumFactory[key][enumKey].LabelAction, id: enumFactory[key][enumKey].IdTypeOrder };
                    enumObjects.push(enumObject);
                });
            }
            else if (key === "TypeFixations") {
                angular.forEach(Object.keys(enumFactory[key]), function (enumKey) {
                    var enumObject = { type: enumFactory[key][enumKey].Label, text: enumFactory[key][enumKey].LabelAction, id: enumFactory[key][enumKey].IdTypeFixation };
                    enumObjects.push(enumObject);
                });
            }
            else if (key === "SubscribeActions") {
                angular.forEach(Object.keys(enumFactory[key]), function (enumKey) {
                    var enumObject = { type: enumFactory[key][enumKey].LabelDefault, text: enumFactory[key][enumKey].Label, id: enumFactory[key][enumKey].IdTypeOrder, idTypeFixation: enumFactory[key][enumKey].IdTypeFixation };
                    enumObjects.push(enumObject);
                });
            }
            $scope.enumObjects = enumObjects;
        };


        $scope.SaveDataPopEditableEnum = function () {
            var texts = $filter('filter')($scope.enumObjects, function (item) { return !item.text; });
            if (texts.length > 0) {
                $scope.editableEnumErrorMessage = "Il faut saisir tous les textes.";
                return false;
            }

            var data = [];
            if ($scope.enumKey === "Action" || $scope.enumKey === "ActionOption" || $scope.enumKey === "CommitmentAction" || $scope.enumKey === "ProductAndTypeCommitAction" || $scope.enumKey === "UsersOfTcActions" || $scope.enumKey === "OfferActions") {
                angular.forEach($scope.enumObjects, function (enumObject) {
                    var dto = { Content: enumObject.text, Key: enumObject.key };
                    data.push(dto);
                });
                editableObjectsService.UpdateContentEditableObject(data, function (data, status) {
                    if (status !== null) {
                        if (status === 400) {
                            $scope.editableEnumErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                        }
                    } else {
                        $scope.ClosePopEditableEnum();
                        window.location.reload();
                    }
                });
            }
            else if ($scope.enumKey === "TypeOrders") {
                angular.forEach($scope.enumObjects, function (enumObject) {
                    var dto = { TypeOrderLabel: enumObject.type, LabelAction: enumObject.text, IdTypeOrder: enumObject.id };
                    data.push(dto);
                });

                typeOrdersService.PutTypeOrderLabelAction(data, function (data, status) {
                    if (status !== null) {
                        if (status === 400) {
                            $scope.editableEnumErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                        }
                    } else {
                        $scope.ClosePopEditableEnum();
                        window.location.reload();
                    }
                });
            }
            else if ($scope.enumKey === "TypeFixations") {
                angular.forEach($scope.enumObjects, function (enumObject) {
                    var dto = { IdTypeFixation: enumObject.id, Label: enumObject.text };
                    data.push(dto);
                });

                typeFixationsService.PutTypeFixationLabelAction(data, function (data, status) {
                    if (status !== null) {
                        if (status === 400) {
                            $scope.editableEnumErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                        }
                    } else {
                        $scope.ClosePopEditableEnum();
                        window.location.reload();
                    }
                });
            }
            else if ($scope.enumKey === "SubscribeActions") {
                angular.forEach($scope.enumObjects, function (enumObject) {
                    var dto = { IdTypeOrder: enumObject.id, IdTypeFixation: enumObject.idTypeFixation, TypeOrderLabel: enumObject.type, LabelAction: enumObject.text };
                    data.push(dto);
                });

                typeOrdersService.PutSubscribeActionLabels(data, function (data, status) {
                    if (status !== null) {
                        if (status === 400) {
                            $scope.editableEnumErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                        }
                    } else {
                        $scope.ClosePopEditableEnum();
                        window.location.reload();
                    }
                });
            }
            return true;
        };

        $scope.ClosePopEditableEnum = function () {
            $("#popEditableEnum").dialog("close");
        };
        //fin popup enum directive

        //popup block directive
        $rootScope.openPopEditableBlock = function () {
            if (!$rootScope.modeEdition) {
                return;
            }
            $scope.editableBlockErrorMessage = "";
            $("#popEditableBlock").css("display", "block");
            $("#popEditableBlock").dialog({
                title: $rootScope.globalLabels.Edit_Blocks,
                modal: false,
                width: '50%',
                position: { my: "center", at: "center", of: window },
                create: function () {
                    $(this).closest('div.ui-dialog')
                        .find('.ui-dialog-titlebar-close')
                        .click(function (e) {
                            $scope.ClosePopEditableBlock(true);
                            $scope.$apply();
                            e.preventDefault();
                        });
                }
            });
            var blockObjects = [];

            if ($rootScope.ModuleHashtagri.Active) {
                angular.forEach(Object.keys(enumFactory['ElementsInfoMarketHomePage']), function (blockKey) {
                    var blockObject = { typeBloc: enumFactory['ElementsInfoMarketHomePage'][blockKey].LabelDefault, text: enumFactory['ElementsInfoMarketHomePage'][blockKey].Label, id: enumFactory['ElementsInfoMarketHomePage'][blockKey].IdElementInfoMarketHomePage, typeObject: 'ElementInfoMarketHomePages' };
                    blockObjects.push(blockObject);
                });
            }

            //On ajoute le titre actualité
            var blockObject = { typeBloc: $rootScope.editableObjectsFactory.EditableObjects.NewsTitle.ContentDefault, text: $rootScope.editableObjectsFactory.EditableObjects.NewsTitle.Content, id: $rootScope.editableObjectsFactory.EditableObjects.NewsTitle.IdEditableObject, typeObject: 'EditableObjects' };
            blockObjects.push(blockObject);

            //On ajoute le titre carousel
            var blockObjectCarousel = { typeBloc: $rootScope.editableObjectsFactory.EditableObjects.CarouselTitle.ContentDefault, text: $rootScope.editableObjectsFactory.EditableObjects.CarouselTitle.Content, id: $rootScope.editableObjectsFactory.EditableObjects.CarouselTitle.IdEditableObject, typeObject: 'EditableObjects' };
            blockObjects.push(blockObjectCarousel);
            $scope.blockObjects = blockObjects;
        };

        $scope.SaveDataPopEditableBlock = function () {
            var texts = $filter('filter')($scope.blockObjects, function (item) { return !item.text; });
            if (texts.length > 0) {
                $scope.editableBlockErrorMessage = "Il faut saisir tous les textes.";
                return false;
            }

            var dataElementInfoMarketHomePages = [];
            var dataEditableObjects = [];
            angular.forEach($scope.blockObjects, function (blockObject) {
                if (blockObject.typeObject === "ElementInfoMarketHomePages") {
                    var dataToPush = { IdElementInfoMarketHomePage: blockObject.id, Label: blockObject.text, LabelDefault: blockObject.typeBloc };
                    dataElementInfoMarketHomePages.push(dataToPush);
                }
                if (blockObject.typeObject === "EditableObjects") {
                    var dataToPushEditableObject = {
                        IdEditableObject: blockObject.id,
                        Content: blockObject.text,
                    };
                    dataEditableObjects.push(dataToPushEditableObject);
                }

            });

            elementInfoMarketHomePagesService.PutElementInfoMarketHomePageLabel(dataElementInfoMarketHomePages, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.editableBlockErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    }
                } else {
                    editableObjectsService.UpdateContentEditableObject(dataEditableObjects, function (data, status) {
                        if (status !== null) {
                            if (status == 400) {
                                $scope.editableBlockErrorMessage += $sce.trustAsHtml(data[0].errors[0].Message);
                            }
                        } else {
                            $scope.ClosePopEditableBlock();
                            window.location.reload();
                        }
                    });
                }
            });
            return true;
        };

        $scope.ClosePopEditableBlock = function () {
            $("#popEditableBlock").dialog("close");
        };
        //fin popup block directive

    }]);