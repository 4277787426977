var isFixed = false;
var angularFilter;
AgrimarketDirectives.directive('euronextgraph', ['$filter', function ($filter) {
	angularFilter = $filter('filter');

	Highcharts.setOptions(Highcharts.theme);

	return function (scope, iElement, iAttrs) {

		var GenerateEuronextSerie = function (euronextSeries) {
			var serietmp = { graphSettle: [], graphVolume: [] };
			euronextSeries.forEach(function (item) {
				var jour = parseInt(item.Date.split('/')[0]);
				var mois = parseInt(item.Date.split('/')[1]);
				var annee = parseInt("20" + item.Date.split('/')[2]);
				var date = new Date(annee, mois - 1, jour);
				if (iAttrs.type == 'line')
					serietmp.graphSettle.push({
						x: Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
						y: item.Settle
					});
				else
					serietmp.graphSettle.push([Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()), item.Open, item.High, item.Low, item.Settle]);

				serietmp.graphVolume.push({
					x: Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
					y: item.Voltt
				});
			});
			return serietmp;
		};

		scope[iAttrs.showSerie] = function (newType) {
			iAttrs.type = newType;
			drawChart(GenerateEuronextSerie(scope.series));
		};

		var drawChart = function (series) {
			$("#" + iAttrs.id).highcharts('StockChart', {
				chart: {
					height: 510,
					marginTop: 10,
					marginLeft: 20
				},
				rangeSelector: {
					allButtonsEnabled: true,
					selected: 4,
					inputDateFormat: "%d/%m/%Y",
					inputEditDateFormat: "%d/%m/%Y",
					inputDateParser: function (value) {
						value = value.split('/');
						return Date.UTC(
						  parseInt(value[2]),
						  parseInt(value[1]) - 1,
						  parseInt(value[0])
						);
					}
				},
				credits: {
					enabled: false
				},
				title: {
					text: iAttrs.namechart
				},
				yAxis: [{
					title: {
						text: 'Cours'
					},
					height: 200,
					offset: 0,
					lineWidth: 2,
				}, {
					title: {
						text: 'Volume'
					},
					top: 300,
					height: 100,
					offset: 0,
					lineWidth: 2,
				}],
				series: [{
					name: 'Cours Euronext',
					type: iAttrs.type,
					data: series.graphSettle,
					yAxis: 0,
					tooltip: {
						valueSuffix: ' €/T'
					}
				}, {
					name: 'Volume',
					type: 'column',
					data: series.graphVolume,
					yAxis: 1,
					tooltip: {
						valueSuffix: ' lot(s)'
					}
				}]
			});
		};

		scope.$watch(iAttrs.euronext, function (newValue) {
			if (newValue === undefined || newValue === null || newValue.length === 0) return;
			scope.series = newValue;
			drawChart(GenerateEuronextSerie(newValue));
		});
	};
}]);