AgrimarketServices.service('authenticationService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    'versionService',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory, versionService) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null, null, null);
                })
                .error(function (data, status, headers, config) {
                    callback(data, status, headers, config);
                });
        };

        this.LoginForBackdoor = function (login, password, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url.Api + 'Authenticate/Authenticate',
                params: { login: login, password: password }
            };
            SendRequest(config, callback);
        };

        var versionSite = versionService.webVersion;

        this.Login = function (login, password, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url.Api + 'Authenticate/AuthenticateUpdate',
                data: { Login: login, Password: password, UserAgent: window.navigator.userAgent, Country: navigator.language.slice(0,2), VersionSite: versionSite}
            };
            SendRequest(config, callback);
        };

        this.LoginByToken = function (token, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url.Api + 'Authenticate/AuthenticateByToken',
                data: { Token: token }
            };
            SendRequest(config, callback);
        };

        this.LoginEditionMode = function (idUser, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url.Api + 'Authenticate/AuthenticateEditionMode',
                data: { IdUser: idUser }
            };
            SendRequest(config, callback);
        };

        this.SendResetPasswordMail = function (login, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Authenticate/RequestResetPassword',
                data: { login: login }
            };
            SendRequest(config, callback);
        };

        this.ResetPassword = function (keyPassword, newPassword, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Authenticate/ResetPassword',
                params: { keyPassword: keyPassword, newPassword: newPassword }
            };
            SendRequest(config, callback);
        };

        this.LoginByConnectorOrUAC = function (loginTiers, jeton, adr, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url.Api + 'Authenticate/AuthenticateByConnectorOrUAC',
                params: { login: loginTiers, jeton: jeton, adr: adr }
            };
            SendRequest(config, callback);
        };

        this.LoginByConnectorForCerelio = function (loginTiers, jeton, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url.Api + 'Authenticate/AuthenticateByConnectorForCerelio',
                params: { login: loginTiers, jeton: jeton }
            };
            SendRequest(config, callback);
        };

        this.LoginByConnectorSso = function (token, param, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url.Api + 'Authenticate/AuthenticateByConnectorSso',
                params: { token: token, param: param }
            };
            SendRequest(config, callback);
        };

        this.LoginByConnectorWithUrlReferrer = function (referrer, param, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url.Api + 'Authenticate/AuthenticateByConnectorWithUrlReferrer',
                params: { referrer: referrer, param: param }
            };
            SendRequest(config, callback);
        };

        this.LoginByConnectorWithGuid = function (guid, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url.Api + 'Authenticate/AuthenticateByConnectorWithGuid',
                params: { guid: guid }
            };
            SendRequest(config, callback);
        };

        this.ConnectorLoginHash = function (param, hash, idLanguage, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url.Api + 'Authenticate/AuthenticateByConnectorLoginHash',
                params: { param: param, hash: hash, idLanguage: idLanguage }
            };
            SendRequest(config, callback);
        };

        this.SendMailAskAccessCode = function (firstname, name, email, nTva, socialReason, siret, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Authenticate/SendMailAskAccessCode',
                params: { firstname: firstname, name: name, email: email, nTva: nTva, socialReason: socialReason, siret: siret }
            };
            SendRequest(config, callback);
        };

        this.RedirectOnOuiField = function (idExt, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url.Api + 'Authenticate/RedirectOnOuiField',
                params: { idExt: idExt }
            };
            SendRequest(config, callback);
        };

        this.GetSynoaCultureUrlWithParameters = function (idExt, ip, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url.Api + 'Authenticate/GetSynoaCultureUrlWithParameters',
                params: { ip: ip, idExt: idExt }
            };
            SendRequest(config, callback);
        };

        this.GetEditableObjectForResetPassword = function (keyPassword, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url.Api + 'Authenticate/GetEditableObjectForResetPassword',
                params: { keyPassword: keyPassword }
            };
            SendRequest(config, callback);
        };

        this.GetEditableObjectsForLoginPage = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url.Api + 'Authenticate/GetEditableObjectsForLoginPage',
            };
            SendRequest(config, callback);
        };

        this.GetUrlWithTokenForDashboardsInCeremis = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url.Api + 'Authenticate/GetUrlWithTokenForDashboardsInCeremis'
            };
            SendRequest(config, callback);
        };

        this.SelectExploitation = function (idExploitation, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Authenticate/SelectExploitation',
                params: { idExploitation: idExploitation }
            };
            SendRequest(config, callback);
        };
    }]);