AgrimarketControllers.controller('contractsBuiltPriceMonitoringController', ['$scope', '$rootScope', '$routeParams', '$http', '$filter', '$location', "currentUserFactory", 'configurationFactory',
    'contractsService', 'cropsService', 'athenaOrdersService', '$log', '$timeout', '$sce', 'parametersFactory', 'enumFactory', 'userTableService', 'colonnesContractsSubscription', 'colonnesContractsObservation', 'colonnesContractsFixed','colonnesContractsCanceled',
    function ($scope, $rootScope, $routeParams, $http, $filter, $location, currentUserFactory, configurationFactory, contractsService, cropsService, athenaOrdersService, $log, $timeout, $sce,
        parametersFactory, enumFactory, userTableService, colonnesContractsSubscription, colonnesContractsObservation, colonnesContractsFixed, colonnesContractsCanceled) {


        var init = function () {            

            // Contracts souscription
            $scope.tableContractsSubscriptionDataVisible = colonnesContractsSubscription.data.DataVisible.split(',');
            $scope.tableContractsSubscriptionLibelle = colonnesContractsSubscription.data.ColumnName.split(',');

             // Contracts observation
            $scope.tableContractsObservationDataVisible = colonnesContractsObservation.data.DataVisible.split(',');
            $scope.tableContractsObservationLibelle = colonnesContractsObservation.data.ColumnName.split(',');

             // Contracts fixés
            $scope.tableContractsFixedDataVisible = colonnesContractsFixed.data.DataVisible.split(',');
            $scope.tableContractsFixedLibelle = colonnesContractsFixed.data.ColumnName.split(',');

             // Contracts annulés
            $scope.tableContractsCanceledDataVisible = colonnesContractsCanceled.data.DataVisible.split(',');
            $scope.tableContractsCanceledLibelle = colonnesContractsCanceled.data.ColumnName.split(',');

            $scope.viewMode = $routeParams.typeContract;

            $scope.max = 10;

            $scope.subscriptionPageNo = 1;            
            $scope.observationPageNo = 1;  
            $scope.fixedPageNo = 1;  
            $scope.canceledPageNo = 1;  
            
            $scope.data = { reverse: true, sortKey: "IdContract" }; 
     
            $scope.idCropSelected = Number(parametersFactory.Parameters.DefaultCrop.Value);
            $scope.idProductSelected = "";
            $scope.dateFilter = "";         
            $scope.dateSelected = "";
            $scope.selectedClient = {};                                    

            var refreshUsers = null; // On crée un timer car si on tape trop vite dans le champ de recherche le listing ne correspond pas au nom recherché
            $scope.$watch("selectedIdClient", function (newValue, oldValue) {
                if (newValue && oldValue != newValue) {
                    if (newValue.length >= 3 || newValue.length < oldValue.length) {
                        if (refreshUsers === null)
                            refreshUsers = setTimeout(function () { $scope.GetData(); }, 800);
                        else {
                            clearTimeout(refreshUsers);
                            refreshUsers = setTimeout(function () { $scope.GetData(); }, 800);
                        }
                    }
                }
            });

            $scope.$watch("dateSelected", function (newValue) {
                if (newValue === "")
                    $scope.dateFilter = "";
                else if (newValue) {
                    //permet de gérer l'internalisation de tout les formats des dates possibles.
                    //Au final nous faisons en sorte de la retrouvé au format yyyy/mm/dd pour l'envoyer à l'API.
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    var dt = new Date(newValue.replace(pattern, '$3-$2-$1'));
                    var currentDate = new Date(dt.toString());
                    var converDate = moment(currentDate);
                    $scope.dateFilter = converDate.format('YYYY/MM/DD');
                }
            });

            $scope.$watch("dateFilter", function (newValue, oldValue) {
                if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                    $scope.GetData();
            });
            
            GetCropsWithContracts();            
        };

        //Initialisation des filtres généraux
        var GetCropsWithContracts = function () {
            cropsService.GetCropsWithContractsBuiltPrice( enumFactory.TypeContract.PrixConstruit, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    if (data) {
                        $scope.crops = data;
                        var defaultCropInCrops = $filter('filter')($scope.crops, { IdCrop: $scope.idCropSelected })[0] !== undefined;
                        if (!defaultCropInCrops && $scope.crops.length > 0)
                            $scope.idCropSelected = $scope.crops[0].IdCrop;
                        $scope.GetFiltersWithContracts();
                    }
                }
            });
        };

        $scope.GetFiltersWithContracts = function () {
            $scope.loading = true;
            $scope.idProductSelected = "";            
            $scope.selectedIdContract = "";
            $scope.dateSelected = "";
            $scope.selectedIdClient = "";   
            contractsService.GetFiltersWithContractsBuiltPriceByCrop($scope.idCropSelected, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    if (data) {
                        $scope.lstProducts = data.LstProductFilterVm;                        
                        if ($scope.lstProducts.length === 1)
                            $scope.idProductSelected = $scope.lstProducts[0].IdProduct;
                        else if ($scope.lstProducts.length > 1 && $filter('filter')($scope.lstProducts, { IdProduct: "", ProductLabel: "Tous" }).length === 0)
                            $scope.lstProducts.unshift({ IdProduct: "", ProductLabel: "Tous" });                                                                                           
                    }
                }
            });
            $scope.GetData();
        };             
          
        $scope.GetData = function () {
            if (!$scope.selectedIdContract) $scope.selectedIdContract = "";
            if (!$scope.selectedIdClient) $scope.selectedIdClient = "";

            if ($scope.viewMode === "subscription") {
                $scope.GetContractsBuiltPriceSubscriptionWithFilter(1);
            }
            else if ($scope.viewMode === "observation") {
                $scope.GetContractsBuiltPriceObservationWithFilter(1);
            }
            else if ($scope.viewMode === "fixed") {                
                $scope.GetContractsBuiltPriceFixedWithFilter(1);
            }
            else if ($scope.viewMode === "canceled") {                
                $scope.GetContractsBuiltPriceCanceledWithFilter(1);
            }                        
        };     

        $scope.GetContractsBuiltPriceSubscriptionWithFilter = function (pagenum) {
            $scope.loading = true;
            $scope.errorMessageContractBuiltPriceSubscription = "";            
            contractsService.GetContractsBuiltPricePaginate($scope.idCropSelected, $scope.idProductSelected, $scope.dateFilter, $scope.selectedIdContract, $scope.selectedIdClient, enumFactory.ContractState.PrixConstruitSouscrit, $scope.max, pagenum, $scope.data.sortKey, $scope.data.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessageContractBuiltPriceSubscription = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.totalSubscription = data.Total;
                    $scope.ContractsSubscription = data.ListVm;
                    $scope.loading = false;
                }
            });
        }; 

        $scope.GetContractsBuiltPriceObservationWithFilter = function (pagenum) {
            $scope.loading = true;
            $scope.errorMessageContractBuiltPriceObservation = "";            
            contractsService.GetContractsBuiltPricePaginate($scope.idCropSelected, $scope.idProductSelected, $scope.dateFilter, $scope.selectedIdContract, $scope.selectedIdClient, enumFactory.ContractState.PrixConstruitEnCoursCalcul, $scope.max, pagenum, $scope.data.sortKey, $scope.data.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessageContractBuiltPriceObservation = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.totalObservation = data.Total;
                    $scope.ContractsObservation = data.ListVm;
                    $scope.loading = false;
                }
            });
        }; 

        $scope.GetContractsBuiltPriceFixedWithFilter = function (pagenum) {
            $scope.loading = true;
            $scope.errorMessageContractBuiltPriceFixed = "";            
            contractsService.GetContractsBuiltPricePaginate($scope.idCropSelected, $scope.idProductSelected, $scope.dateFilter, $scope.selectedIdContract, $scope.selectedIdClient, enumFactory.ContractState.Valide, $scope.max, pagenum, $scope.data.sortKey, $scope.data.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessageContractBuiltPriceFixed = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.totalFixed = data.Total;
                    $scope.ContractsFixed = data.ListVm;
                    $scope.loading = false;
                }
            });
        }; 
        
        $scope.GetContractsBuiltPriceCanceledWithFilter = function (pagenum) {
            $scope.loading = true;
            $scope.errorMessageContractBuiltPriceCanceled = ""; 
            $scope.errorMessageContractBuiltPriceContractSubscription = "";      
            contractsService.GetContractsBuiltPricePaginate($scope.idCropSelected, $scope.idProductSelected, $scope.dateFilter, $scope.selectedIdContract, $scope.selectedIdClient, enumFactory.ContractState.Annulé, $scope.max, pagenum, $scope.data.sortKey, $scope.data.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessageContractBuiltPriceCanceled = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.totalCanceled = data.Total;
                    $scope.ContractsCanceled = data.ListVm;
                    $scope.loading = false;
                }
            });
        };    
          
        $scope.ShowPopUpEditBuiltPriceContract = function (contract, openPopUpEditedBuiltPriceContract) {
            $scope.contractSelected = contract;
            $scope.contractSelected.MessageInfo = $sce.trustAsHtml($rootScope.editableObjectsFactory.EditableObjects.Admin_ContractBuiltPriceContract_Subscription_PopupEditedBuiltPriceContract_Label_NumContract.Content + $scope.contractSelected.IdContract + $rootScope.editableObjectsFactory.EditableObjects.Admin_ContractBuiltPriceContract_Subscription_PopupEditedBuiltPriceContract_Label_Of.Content +  $scope.contractSelected.UserFullName);
            $scope.contractSelected.NewQuantity = contract.Quantity;
            $scope.editedBuiltPriceContractErrorMessage = "";
            openPopUpEditedBuiltPriceContract();
        };

        $scope.CheckEditedBuiltPriceContract = function () {
            if (!$scope.contractSelected.NewQuantity || $scope.contractSelected.NewQuantity <= 0)
            {
                $scope.editedBuiltPriceContractErrorMessage = $sce.trustAsHtml($rootScope.editableObjectsFactory.EditableObjects.Error_AdminEditQuantityContractBuiltPrices_Quantity_Min.Content);
                return false;
            }
            if ($scope.contractSelected.NewQuantity >= $scope.contractSelected.Quantity)
            {
                $scope.editedBuiltPriceContractErrorMessage = $sce.trustAsHtml($rootScope.editableObjectsFactory.EditableObjects.Error_AdminEditQuantityContractBuiltPrices_Quantity.Content);
                return false;
            }
            return true;
        };

        $scope.ConfirmEditedBuiltPriceContract = function () {
            $scope.loading = true;
            contractsService.EditedBuiltPriceContract($scope.contractSelected.IdContract, $scope.contractSelected.NewQuantity, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessageContractBuiltPriceContractSubscription = $sce.trustAsHtml(data[0].errors[0].Message);
                    }
                } else {
                    $scope.GetData();
                    $scope.loading = false;
                }
            });
        };

        $scope.Sort = function (sortKey, reverse) {
            $scope.data.sortKey = sortKey;
            $scope.data.reverse = reverse;
            $scope.GetData();
        };
     
        init();
    }]);
