AgrimarketControllers.controller('userContractsFirmMonitoringController', ['$scope', '$rootScope', '$routeParams', 'configurationFactory',
    'contractsService', 'cropsService', '$location', '$sce', '$filter', 'enumFactory', 'colonnesValidated', 'colonnesPriced', 'colonnesCanceled', 'colonnesPending', 'colonnesDelayedSigning','parametersFactory','currentUserFactory',
    function ($scope, $rootScope, $routeParams, configurationFactory, contractsService, cropsService, $location, $sce, $filter, enumFactory, colonnesValidated, colonnesPriced, colonnesCanceled, colonnesPending, colonnesDelayedSigning, parametersFactory, currentUserFactory) {

        var idTabContracts;

        var Init = function () {
            // Contracts validés
            $scope.tableValidatedDataVisible = colonnesValidated.data.DataVisible.split(',');
            $scope.tableValidatedLibelle = colonnesValidated.data.ColumnName.split(',');

            // Contracts en attente
            $scope.tablePendingDataVisible = colonnesPending.data.DataVisible.split(',');
            $scope.tablePendingLibelle = colonnesPending.data.ColumnName.split(',');

            // Contracts en attente de signature
            $scope.tableDelayedSigningDataVisible = colonnesDelayedSigning.data.DataVisible.split(',');
            $scope.tableDelayedSigningLibelle = colonnesDelayedSigning.data.ColumnName.split(',');

            // Contracts prix objectif
            $scope.tablePricedDataVisible = colonnesPriced.data.DataVisible.split(',');
            $scope.tablePricedLibelle = colonnesPriced.data.ColumnName.split(',');

            // Contracts annulés
            $scope.tableCanceledDataVisible = colonnesCanceled.data.DataVisible.split(',');
            $scope.tableCanceledLibelle = colonnesCanceled.data.ColumnName.split(',');

            // Gestion du trie
            $scope.data = { reverse: true, sortKey: "IdContract", pageNo: 1 };

            $scope.max = 10;
            $scope.viewMode = $routeParams.typeContract;
            $scope.idProductSelected = "";
            $scope.idTypeOrderSelected = "";
            $scope.idTypeFixationSelected = "";
            $scope.dateSelected = "";
            $scope.idCropSelected = $rootScope.crop.IdCrop;
            $scope.titleTargetPricePopUp = $rootScope.editableObjectsFactory.EditableObjects.User_ContractFirmMonitoring_PopupChangeTargetPrice_Title.Content;
            $scope.moduleElectronicSignatureIsActive = $rootScope.ModuleElectronicSignature.Active;
            $scope.moduleYousignIsActive = $rootScope.ModuleSignatureElectroniqueYouSign.Active;
            $scope.bActivateContractsValidation = parametersFactory.Parameters.bActivateContractsValidation.Value === "1";

            if ($scope.viewMode === "validated")
                idTabContracts = enumFactory.TabContract.Validated;
            else if ($scope.viewMode === "priced")
                idTabContracts = enumFactory.TabContract.Priced;
            else if ($scope.viewMode === "canceled")
                idTabContracts = enumFactory.TabContract.Canceled;
            else if ($scope.viewMode === "pending")
                idTabContracts = enumFactory.TabContract.Pending;
            else if ($scope.viewMode === "delayedSigning")
                idTabContracts = enumFactory.TabContract.delayedSigning;

            GetContracts();
        };

        $scope.$watch("dateFilter", function (newValue, oldValue) {
            if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                $scope.GetContractsWithFilter();
        });

        $scope.$watch("dateSelected", function (newValue) {
            if (newValue === "")
                $scope.dateFilter = "";
            else if (newValue) {
                //permet de gérer l'internalisation de tout les formats des dates possibles.
                //Au final nous faisons en sorte de la retrouvé au format dd/mm/yyyy.
                var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                var dt = new Date(newValue.replace(pattern, '$3-$2-$1'));
                var currentDate = new Date(dt.toString());
                var converDate = moment(currentDate);
                $scope.dateFilter = converDate.format('YYYY/MM/DD');
            }
        });

        var GetFiltersWithContracts = function () {
            $scope.loading = true;
            $scope.idProductSelected = "";
            $scope.selectedIdContract = "";
            $scope.idTypeOrderSelected = "";
            $scope.idTypeFixationSelected = "";
            $scope.dateSelected = "";
            $scope.All = $rootScope.editableObjectsFactory.EditableObjects.All.Content;
            contractsService.GetFiltersWithUserContractsByCropAndTabContract(idTabContracts, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    if (data) {
                        $scope.lstProducts = data.LstProductFilterVm;
                        $scope.lstTypeOrders = data.LstTypeOrderFilterVm;
                        $scope.lstTypeFixations = data.LstTypeFixationFilterVm;

                        if ($scope.lstProducts.length === 1)
                            $scope.idProductSelected = $scope.lstProducts[0].IdProduct;
                        else if ($scope.lstProducts.length > 1)
                            $scope.lstProducts.unshift({ IdProduct: "", ProductLabel: $scope.All });

                        if ($scope.lstTypeOrders.length === 1)
                            $scope.idTypeOrderSelected = $scope.lstTypeOrders[0].IdTypeOrder;
                        else if ($scope.lstTypeOrders.length > 1 && $filter('filter')($scope.lstTypeOrders, { IdTypeOrder: "", TypeOrderLabel: $scope.All }).length === 0)
                            $scope.lstTypeOrders.unshift({ IdTypeOrder: "", TypeOrderLabel: $scope.All });

                        if ($scope.lstTypeFixations.length === 1)
                            $scope.idTypeFixationSelected = $scope.lstTypeFixations[0].Id;
                        else if ($scope.lstTypeFixations.length > 1)
                            $scope.lstTypeFixations.unshift({ Id: "", Label: $scope.All });

                        $scope.GetContractsWithFilter();

                    }
                }
            });
        };

        var GetDefaultCrop = function (callback) {
            cropsService.GetDefaultCrop(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    if (data !== null) {
                        $scope.idCropSelected = data.IdCrop;
                        callback();
                    }
                }
            });
        };

        var GetContracts = function () {
            if (!$scope.idCropSelected) //Si on a pas de récolte de sélectionnée on prend celle par défaut
                GetDefaultCrop(GetContracts());
            else
                GetFiltersWithContracts();
        };

        $scope.GetContractsWithFilter = function () {
            $scope.loading = true;
            $scope.selectedNameOffer = "";
            $scope.idTypeContractSelected = null;
            if (!$scope.selectedIdContract) $scope.selectedIdContract = "";

            if ($scope.viewMode === "validated")
                contractsService.GetUserValidatedContractsPaginate($scope.idCropSelected, $scope.idProductSelected, $scope.idTypeOrderSelected, $scope.dateFilter, $scope.selectedIdContract, "", $scope.max, $scope.data.pageNo, $scope.data.sortKey, $scope.data.reverse, $scope.selectedNameOffer, $scope.idTypeContractSelected, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        $scope.total = data.Total;
                        $scope.contracts = data.ListVm;
                        $scope.loading = false;
                    }
                });
            else if ($scope.viewMode === "priced")
                contractsService.GetUserPricedContractsPaginate($scope.idCropSelected, $scope.idProductSelected, $scope.idTypeOrderSelected, $scope.dateFilter, $scope.selectedIdContract, "", $scope.idTypeFixationSelected, $scope.max, $scope.data.pageNo, $scope.data.sortKey, $scope.data.reverse, $scope.selectedNameOffer, $scope.idTypeContractSelected, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        $scope.total = data.Total;
                        $scope.contracts = data.ListVm;
                        $scope.loading = false;
                    }
                });
            else if ($scope.viewMode === "canceled")
                contractsService.GetUserCanceledContractsPaginate($scope.idCropSelected, $scope.idProductSelected, $scope.idTypeOrderSelected, $scope.dateFilter, $scope.selectedIdContract, "", $scope.max, $scope.data.pageNo, $scope.data.sortKey, $scope.data.reverse, $scope.selectedNameOffer, $scope.idTypeContractSelected, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        $scope.total = data.Total;
                        $scope.contracts = data.ListVm;
                        $scope.loading = false;
                    }
                });
            else if ($scope.viewMode === "pending")
                contractsService.GetUserPendingContractsPaginate($scope.idCropSelected, $scope.idProductSelected, $scope.idTypeOrderSelected, $scope.dateFilter, $scope.selectedIdContract, "", $scope.max, $scope.data.pageNo, $scope.data.sortKey, $scope.data.reverse, $scope.selectedNameOffer, $scope.idTypeContractSelected, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        $scope.total = data.Total;
                        $scope.contracts = data.ListVm;
                        $scope.loading = false;
                    }
                });
            else if ($scope.viewMode === "delayedSigning")
                contractsService.GetUserDelayedSigningContractsPaginate($scope.idCropSelected, $scope.idProductSelected, $scope.dateFilter, "", $scope.max, $scope.data.pageNo, $scope.data.sortKey, $scope.data.reverse, $scope.selectedNameOffer, $scope.idTypeContractSelected, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        $scope.total = data.Total;
                        $scope.contracts = data.ListVm;
                        $scope.loading = false;
                    }
                });
        };

        $scope.CancelContractPopUp = function (contractSelected, index, showPopUpContract) {
            $scope.PopUpMessageDeleteContract = "";
            $scope.MessageDeleteContractFirmTargetPrice = $rootScope.editableObjectsFactory.EditableObjects.MessageDeleteContractFirmTargetPrice.Content;
            $scope.CanceledContract = $rootScope.editableObjectsFactory.EditableObjects.CanceledContract.Content;

            $scope.PopupCanceledContractsSelectedTitle = $scope.CanceledContract + " " + contractSelected.IdContract;

            if (contractSelected !== undefined)
                $scope.PopUpMessageDeleteContract = $scope.MessageDeleteContractFirmTargetPrice;

            showPopUpContract(contractSelected);
        };

        $scope.ChangeTargetPricePopUp = function (currentContract, index, openPopUp) {
            contractsService.GetContractTargetPrice(currentContract.IdContract, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.selectedContract = data;
                    $scope.targetPriceErrorMessage = "";
                    $scope.ActionType = "edit";
                    openPopUp();
                }
            });
        };

        $scope.HistoContractPopUp = function (currentContract, openPopUpHisto) {
            $scope.ModalTitleContract = $rootScope.editableObjectsFactory.EditableObjects.ModalTitleContract.Content;
            contractsService.GetHistoContract(currentContract.IdInit, currentContract.IdCrop, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.lstHistoContract = data;
                    $scope.ModalTitle = $scope.ModalTitleContract;
                    openPopUpHisto();
                }
            });
        };

        $scope.ChkTargetPriceIsValid = function () {
            $scope.errorMessage = "";
            $scope.successMessage = "";
            $scope.paramAuthorizeTargetPriceUnderOfferPrice = parametersFactory.Parameters.bAuthorizeTargetPriceUnderOfferPrice.Value === "1";
            $scope.targetPriceErrorMessage = contractsService.ChkTargetPriceIsValid($scope.selectedContract, $scope.selectedContract.OfferPriceUpdated, $scope.paramAuthorizeTargetPriceUnderOfferPrice);

            if ($scope.targetPriceErrorMessage !== null)
                return false;

                $scope.selectedContract.NewTargetPrice = Number($scope.selectedContract.NewTargetPrice);

            return true;
        };

        $scope.ChangeTargetPrice = function () {
            var contractToSend = {
                IdContract: $scope.selectedContract.IdContract,
                NewTargetPrice: $scope.selectedContract.NewTargetPrice,
                NewQuantity: $scope.selectedContract.NewQuantity
            };

            contractsService.UpdateContract(contractToSend, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.successMessage = $scope.ChangeTargetPriceSuccessMessage;
                    GetContracts();
                }
            });

        };

        $scope.CancelContract = function (element) {
            contractsService.UpdateContract(element, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.successMessage = $scope.CancelContractSuccessMessage;
                    GetContracts();
                }
            });
        };

        $scope.Sort = function (sortKey, reverse) {
            $scope.data.sortKey = sortKey;
            $scope.data.reverse = reverse;
            $scope.GetContractsWithFilter();
        };

        $scope.GetData = function (pageNo) {
            $scope.data.pageNo = pageNo;
            $scope.GetContractsWithFilter();
        };

        $scope.GoToSubscribe = function (contract, idTypeOrder) {
            $rootScope.idOfferSelected = contract.IdOffer;
            $rootScope.idExpirySelected = contract.IdOfferExpiry;
            $rootScope.idTypeOrderSelected = idTypeOrder;
            $rootScope.idTypeCommitmentSelected = null;
            $location.path('/user/subscribe/contractBase//' + contract.IdContract);
        };

        $scope.GoToSubscribeDelayedSigning = function (contract) {
            $rootScope.isTcSigningForUser = currentUserFactory.User.IdRole === enumFactory.Roles.TC;
            $rootScope.goToDelayedSigning = true;
            $rootScope.idContractForDelayedSigning = contract.IdContract;
            if (contract.IdTypeContract == enumFactory.TypeContract.Ferme)
                $location.path('/user/subscribe/contractFirm/');
            else if (contract.IdTypeContract == enumFactory.TypeContract.ContratVierge)
                $location.path('/user/subscribe/contractBlank/');
        };

        $rootScope.onRefresh = function () {
            Init();
        };

        $scope.AsChangedCrop = function () {
            Init();
        };

        Init();
    }]);
