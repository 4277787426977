AgrimarketControllers.controller('comMarketInfoController', ['$scope', '$rootScope', '$routeParams', '$sce', '$filter', '$http', '$location', 'enumFactory', 'communicationMarketInfoService', 'colonnesCommunicationMarketInfo', '$q',
function ($scope, $rootScope, $routeParams, $sce, $filter, $http, $location, enumFactory, communicationMarketInfoService, colonnesCommunicationMarketInfo, $q) {

        $scope.enumRoles = enumFactory.Roles;

        var init = function () {
            $scope.FirstLoad = true;
            $scope.tableCommunicationMarketInfoDataVisible = colonnesCommunicationMarketInfo.data.DataVisible.split(',');
            $scope.tableCommunicationMarketInfoLibelle = colonnesCommunicationMarketInfo.data.ColumnName.split(',');
            $scope.maxCommunicationMarketInfo = 10;
            $scope.communicationMarketInfoPageNo = 1;
            $scope.dataCommunicationMarketInfo = {};
            $scope.dataCommunicationMarketInfo.reverse = false;
            $scope.dataCommunicationMarketInfo.sortKey = "Label";
            $scope.idCommunicationMarketInfoSelected = "";
            $scope.searchLabelCommunicationMarketInfotring = "";
            $scope.GetAllCommunicationMarketInfos();
            $scope.msgConf = $routeParams.msgConf;
        };

        $scope.$watch('searchLabelCommunicationMarketInfotring', function (newValue, oldValue) {
            if (!$scope.FirstLoad && ((!newValue) || newValue.length >= 3 && !$scope.idCommunicationMarketInfoSelected)) {
                $scope.idCommunicationMarketInfoSelected = "";
                $scope.FilterCommunicationMarketInfo();
            }
        });

        $scope.GetAllCommunicationMarketInfos = function () {
            communicationMarketInfoService.GetAllCommunicationMarketInfoPaginate($scope.idCommunicationMarketInfoSelected, $scope.searchLabelCommunicationMarketInfotring, $scope.maxCommunicationMarketInfo, $scope.communicationMarketInfoPageNo, $scope.dataCommunicationMarketInfo.sortKey, $scope.dataCommunicationMarketInfo.reverse, function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.lstCommunicationMarketInfo = data.ListVm;
                    $scope.totalCommunicationMarketInfo = data.Total;
                    $scope.FirstLoad = false;
                }
            });
        };

        $scope.Sort = function (sortKey, reverse) {
            $scope.msgConf = null;
            $scope.dataCommunicationMarketInfo.sortKey = sortKey;
            $scope.dataCommunicationMarketInfo.reverse = reverse;
            $scope.GetAllCommunicationMarketInfos();
        };

        $scope.GetData = function (pageNo) {
            $scope.msgConf = null;
            $scope.communicationMarketInfoPageNo = pageNo;
            $scope.GetAllCommunicationMarketInfos();
        };

        $scope.GetCommunicationMarketInfoSelected = function (contentSelected) {
            if (contentSelected) {
                $scope.idCommunicationMarketInfoSelected = contentSelected.originalObject.Id;
                $scope.FilterCommunicationMarketInfo();
            }
        };

        $scope.SearchCommunicationMarketInfoByAutoComplete = function (searchString) {
            var defered = $q.defer();
            communicationMarketInfoService.GetItemsCommunicationMarketInfoByAutoComplete(searchString, function (data, status) {
                if (status !== null) {
                    if (status == 400) {
                        defered.reject(status);
                    } 
                } else {
                    defered.resolve(data);
                }

            });
            return defered.promise;
        };

        $scope.FilterCommunicationMarketInfo = function () {
            $scope.errorMessageContentInfosMarket = "";
            $scope.GetAllCommunicationMarketInfos();
            $scope.msgConf = null;
        };

        $scope.DeleteComMarketInfo = function (idCommunicationMarketInfo) {
            $scope.errorMessage = "";
            $scope.successMessage = "";
            communicationMarketInfoService.DeleteCommunicationMarketInfo(idCommunicationMarketInfo, $scope.idCommunicationMarketInfoSelected, $scope.searchLabelCommunicationMarketInfotring, $scope.maxCommunicationMarketInfo, $scope.communicationMarketInfoPageNo, $scope.dataCommunicationMarketInfo.sortKey, $scope.dataCommunicationMarketInfo.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } 
                } else {
                    $scope.successMessage = "La communication a bien été supprimé.";
                    $scope.lstCommunicationMarketInfo = data.ListVm;
                    $scope.totalCommunicationMarketInfo = data.Total;
                }
            });
        };

        init();
    }]);
