AgrimarketControllers.filter('roundByDecimalAndConverter', function () {
    return function (data, nbdecimal, converterCoeff) {
        if (typeof (data) === 'undefined' || data === null || typeof (nbdecimal) === 'undefined') {
            return null;
        }
        if (typeof (converterCoeff) !== 'undefined' && converterCoeff)
            data = data * converterCoeff;

        if (!nbdecimal && nbdecimal !== 0)
            nbdecimal = 2;

        var factory = Math.pow(10, nbdecimal);
        return Math.round(data * factory) / factory;
    };
});
