AgrimarketControllers.controller('infoMarketHashtagriController', ['$scope', '$rootScope', '$routeParams', '$sce', '$filter', '$location', 'categoryInfoMarketService', 'elementInfoMarketHomePageService', 'elementInfoMarketHomePageQuoteService', 'enumFactory','quoteCashHashtagriService', 'quoteFutureHashtagriService', 'blocDispositionService', 'writingContentInfoMarketService', '$q','groupContactService',
    function ($scope, $rootScope, $routeParams, $sce, $filter, $location, categoryInfoMarketService, elementInfoMarketHomePageService, elementInfoMarketHomePageQuoteService, enumFactory, quoteCashHashtagriService, quoteFutureHashtagriService, blocDispositionService, writingContentInfoMarketService, $q, groupContactService) {

        $scope.initLstGroupContactAvailables = [];
        $scope.tmpGroupContacts1 = [];
        $scope.tmpGroupContacts2 = [];

        $scope.lstCatInfoMarket = [];
        $scope.lstElementInfoMarketHomePage = [];
        $scope.lstNumDispositionBlocHashtagri = [];

        $scope.lstProductsWSD = [];
        $scope.lstProductsWSDSelected = [];

        $scope.lstMarketWSD = [];
        $scope.lstMarketExpiriesWSDSelected = [];
        $scope.lstMarketExpiriesVM = [];

        $scope.ParamNumDispositionBlocHashtagri = {};

        $scope.counterCatInfoMarketActif = 0;
        $scope.filterMarketExpiries = {};
        $scope.filterProductWSD = {};
        $scope.paramsQuotesFuture = {};
        $scope.paramsQuotesCash = {};

        var newProductTextSelected = {
            IDProducts: 0,
            LabelledProducts: 'Sélectionnez',
            MarketsWsd: []
        };

        var newMarketTextSelected = {
            IdMarkets: 0,
            LabelledMarket: 'Sélectionnez',
            ListExpiryVm: []
        };

        $scope.idQuotesInfoMarketHomePage = enumFactory.ElementsInfoMarketHomePage.Quotes.IdElementInfoMarketHomePage;

        $scope.contentBlocDispositions = enumFactory.ContentBlocDispositions;
        $scope.viewMode = $routeParams.typeView;

        $scope.lstPopupQuotes = [];
        
        /**************** Propriétés Contenu OS   *********************/

        $scope.filterValue = {
            FirstLoad: true,
            ItemsPerPage: 10,
            SortKey: "",
            Reverse: false,
            CurrentPageNo: 1,
            IdWritingContentInfoMarketSelected: "",
            SearchContentString: ""
        };

        /**************** Fin Propriétés Contenu OS   *********************/

        $scope.ResetMessages = function () {

            $scope.catInfoMarketerrorMessage = "";
            $scope.catInfoMarketsuccessMessage = "";

            $scope.homePageInfoMarketerrorMessage = "";
            $scope.homePageInfoMarketsuccessMessage = "";

            $scope.quotesDisplayErrorMessage = "";
            $scope.quotesDisplayeSuccessMessage = "";

            $scope.quotesCashErrorMessage = "";
            $scope.quotesCashSuccessMessage = "";

            $scope.quotesFutureErrorMessage = "";
            $scope.quotesFutureSuccessMessage = "";

            $rootScope.successMessageContentInfosMarket = $scope.successMessageContentInfosMarket = "";
            $scope.errorMessageContentInfosMarket = "";
        };
        /*
        * ONGLET CONTENU
        *
        *
        */

        $scope.GetCategoryInfosMarket = function () {
            categoryInfoMarketService.GetCategoryInfosMarket(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else
                        $scope.errorMessage = data;
                } else {
                    $scope.lstCatInfoMarket = data;

                    $scope.lstCatInfoMarket.forEach(function (element) {
                        element.lstGroupContactAvailables = angular.copy($scope.initLstGroupContactAvailables);
                        $.each(element.GroupContacts, function (index, item) {
                            var pos = element.lstGroupContactAvailables.map(function (e) { return e.IdGroupContact; }).indexOf(item.IdGroupContact);
                            element.lstGroupContactAvailables.splice(pos, 1);
                        });
                    });

                    $scope.lstCatInfoMarket.forEach(function (item) {
                        if (item.IsActif) {
                            $scope.counterCatInfoMarketActif++;
                        }
                    });
                }
            });
        };

        $scope.GetElementInfoMarketHomePage = function () {
            elementInfoMarketHomePageService.GetElementInfoMarketHomePage(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else
                        $scope.errorMessage = data;
                }
                else {
                    $scope.lstElementInfoMarketHomePage = data;
                }
            });
        };

        function checkIsUniqueOrder(collection, cible) {
            var filterCheck = angular.copy(collection);
            var isOk = true;
            filterCheck.forEach(function (item) {
                if (item.IsActif && item.OrderDisplay && item.IdCategoryInfoMarket !== cible.IdCategoryInfoMarket) {
                    if (item.OrderDisplay === cible.OrderDisplay) {
                        isOk = false;
                        return;
                    }
                }
            });
            return isOk;
        }

        $scope.DisplayOrderInput = function (item) {
            if (!item.IsActif) {
                item.OrderDisplay = null;
                $scope.counterCatInfoMarketActif--;
            } else {
                $scope.counterCatInfoMarketActif++;
            }
        };

        $scope.AllowOneChoice = function (idElementInfoMarketHomePageActif) {
            $scope.lstElementInfoMarketHomePage.forEach(function (item) {
                
                if (item.IdElementInfoMarketHomePage === idElementInfoMarketHomePageActif) {
                    if (item.IsActif === true) {
                        item.IsActif = true;
                    } 
                    else {
                        item.IsActif = false;
                    }
                } 
                else
                    item.IsActif = false;
            });
        };

        $scope.CheckParamCategoriesInfoMarket = function () {
            var error;
            $scope.ResetMessages();
            $scope.lstCatInfoMarket.forEach(function (item) {
                if (item.IsActif && item.OrderDisplay) {
                    if (item.OrderDisplay > $scope.lstCatInfoMarket.length || item.OrderDisplay > $scope.counterCatInfoMarketActif) {
                        error = "Un ou plusieurs emplacement(s) ne sont pas ordonné(s) correctement";
                    }
                    if (item.OrderDisplay <= 0) {
                        error = "Les emplacements doivent être supérieur à zéro";
                    }

                    if (!checkIsUniqueOrder($scope.lstCatInfoMarket, item)) {
                        error = "Un ou plusieurs emplacement(s) ne sont pas ordonné(s) correctement";
                    }
                }
            });
            if ((error)) {
                $scope.catInfoMarketerrorMessage = $sce.trustAsHtml(error);
                return false;
            } else {
                $scope.SaveCatInfoMarketParameters();
                return true;
            }
        };

        $scope.SaveCatInfoMarketParameters = function () {
            categoryInfoMarketService.SaveCategoryInfoMarket($scope.lstCatInfoMarket, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.catInfoMarketerrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.catInfoMarketsuccessMessage = "Modifications enregistrées";
                }
            });
        };

        $scope.SaveHomePageInfoMarketParameters = function () {
            $scope.ResetMessages();
            elementInfoMarketHomePageService.SaveElementInfoMarketHomePage($scope.lstElementInfoMarketHomePage, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.homePageInfoMarketerrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.homePageInfoMarketsuccessMessage = "Modifications enregistrées";
                }
            });
        };

        $scope.OpenAndInitPopupCategVisibilities = function (openPopupCategVisibilitiesFn, categ) {
            $scope.popupCategVisibilitiesErrorMessage = "";

            $scope.currentCategBeforeChanged = angular.copy(categ);
            $scope.currentCateg = categ;

            openPopupCategVisibilitiesFn();
        };

        $scope.SavePopupCategVisibilities = function () {
            var lstIdGroupContacts = [];
            $scope.currentCateg.GroupContacts.forEach(function (element) {
                lstIdGroupContacts.push(element.IdGroupContact);
            });

            categoryInfoMarketService.SaveGroupContactsOfCategoryInfoMarket($scope.currentCateg.IdCategoryInfoMarket, lstIdGroupContacts, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.popupCategVisibilitiesErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                        $scope.openPopupCategVisibilities();
                    }
                } else {

                    $scope.popupCategVisibilitiesErrorMessage = "";
                }
            });

        };

        $scope.CancelPopupCategVisibilies = function () {
            $scope.currentCateg.lstGroupContactAvailables = $scope.currentCategBeforeChanged.lstGroupContactAvailables;
            $scope.currentCateg.GroupContacts = $scope.currentCategBeforeChanged.GroupContacts;
        };

        $scope.toogleAllElementsLeft = function () {
            $scope.allCategVisibilitiesLeft = !$scope.allCategVisibilitiesLeft;
            $.each($scope.currentCateg.lstGroupContactAvailables, function (index, item) {
                item.checked = $scope.allCategVisibilitiesLeft;
            });
        };

        $scope.toogleAllElementsRight = function () {
            $scope.allCategVisibilitiesRight = !$scope.allCategVisibilitiesRight;
            $.each($scope.currentCateg.GroupContacts, function (index, item) {
                item.checked = $scope.allCategVisibilitiesRight;
            });
        };

        $scope.ListToSelectedGroupCategVisibilities = function () {
            $scope.allCategVisibilitiesLeft = false;
            $scope.allCategVisibilitiesRight = false;

            // On envoi tous les éléments sélectionnés dans la liste de droite
            $.each($scope.currentCateg.lstGroupContactAvailables, function (index, item) {
                if (item.checked) {
                    item.checked = false;
                    $scope.currentCateg.GroupContacts.push(item);
                }
            });

            // On supprime de la liste de gauche, tous les éléments envoyés
            $.each($scope.currentCateg.GroupContacts, function (index, item) {
                var indexItem = $scope.currentCateg.lstGroupContactAvailables.indexOf(item);
                if (indexItem !== -1)
                    $scope.currentCateg.lstGroupContactAvailables.splice(indexItem, 1);
            });
        };

        $scope.SelectedToListGroupCategVisibilities = function () {
            $scope.allCategVisibilitiesLeft = false;
            $scope.allCategVisibilitiesRight = false;

            // On envoi tous les éléments sélectionnés dans la liste de gauche
            $.each($scope.currentCateg.GroupContacts, function (index, item) {
                if (item.checked) {
                    item.checked = false;
                    $scope.currentCateg.lstGroupContactAvailables.push(item);
                }
            });

            // On supprime de la liste de droite, tous les éléments envoyés
            $.each($scope.currentCateg.lstGroupContactAvailables, function (index, item) {
                var indexItem = $scope.currentCateg.GroupContacts.indexOf(item);
                if (indexItem !== -1)
                    $scope.currentCateg.GroupContacts.splice(indexItem, 1);
            });
        };

        /*
         * ONGLET COTATIONS BLOC DISPOSITION
         *
         *
         */

        $scope.GetBlocDispositions = function () {
            blocDispositionService.GetBlocDispositions(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.quotesDisplayErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.lstBlocDispositions = data;
                }
            });
        };

        $scope.SaveBlocDispositions = function () {
            $scope.ResetMessages();
            blocDispositionService.SaveBlocDispositions($scope.lstBlocDispositions, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.quotesDisplayErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.quotesDisplayeSuccessMessage = "Modifications enregistrées";
                }
            });
        };

        $scope.OpenAndInitPopupContentBlocDispositions = function (openPopupContentBlocDispositionsFn, bloc) {
            $scope.popupContentBlocDispositionsErrorMessage = "";

            blocDispositionService.GetContentBlocDispositionById(bloc.IdContentBlocDisposition,function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.popupContentBlocDispositionsErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.currentContentBlocDisposition = data;

                    $scope.currentContentBlocDisposition.lstGroupContactAvailables = angular.copy($scope.initLstGroupContactAvailables);
                    $.each($scope.currentContentBlocDisposition.GroupContacts, function (index, item) {
                       
                        var pos = $scope.currentContentBlocDisposition.lstGroupContactAvailables.map(function (e) { return e.IdGroupContact; }).indexOf(item.IdGroupContact);
                        $scope.currentContentBlocDisposition.lstGroupContactAvailables.splice(pos, 1);
                    });

                }
            });

            openPopupContentBlocDispositionsFn();
        };

        $scope.ListToSelectedGroupContentBlocDispositions = function () {
            $scope.allContentBlocDispositionsLeft = false;
            $scope.allContentBlocDispositionsRight = false;

            $.each($scope.currentContentBlocDisposition.lstGroupContactAvailables, function (index, item) {
                if (item.checked) {
                    item.checked = false;
                    $scope.currentContentBlocDisposition.GroupContacts.push(item);
                }
            });

            $.each($scope.currentContentBlocDisposition.GroupContacts, function (index, item) {
                var indexItem = $scope.currentContentBlocDisposition.lstGroupContactAvailables.indexOf(item);
                if (indexItem !== -1)
                    $scope.currentContentBlocDisposition.lstGroupContactAvailables.splice(indexItem, 1);
            });
        };

        $scope.SelectedToListGroupContentBlocDispositions = function () {
            $scope.allContentBlocDispositionsLeft = false;
            $scope.allContentBlocDispositionsRight = false;

            // On envoi tous les éléments sélectionnés dans la liste de gauche
            $.each($scope.currentContentBlocDisposition.GroupContacts, function (index, item) {
                if (item.checked) {
                    item.checked = false;
                    $scope.currentContentBlocDisposition.lstGroupContactAvailables.push(item);
                }
            });

            // On supprime de la liste de droite, tous les éléments envoyés
            $.each($scope.currentContentBlocDisposition.lstGroupContactAvailables, function (index, item) {
                var indexItem = $scope.currentContentBlocDisposition.GroupContacts.indexOf(item);
                if (indexItem !== -1)
                    $scope.currentContentBlocDisposition.GroupContacts.splice(indexItem, 1);
            });
        };

        $scope.toogleAllElementsLeftBloc = function () {
            $scope.allContentBlocDispositionsLeft = !$scope.allContentBlocDispositionsLeft;
            $.each($scope.currentContentBlocDisposition.lstGroupContactAvailables, function (index, item) {
                item.checked = $scope.allContentBlocDispositionsLeft;
            });
        };

        $scope.toogleAllElementsRightBloc = function () {
            $scope.allContentBlocDispositionsRight = !$scope.allContentBlocDispositionsRight;
            $.each($scope.currentContentBlocDisposition.GroupContacts, function (index, item) {
                item.checked = $scope.allContentBlocDispositionsRight;
            });
        };

        $scope.SavePopupContentBlocDispositions = function () {
            var lstIdGroupContacts = [];
            $scope.currentContentBlocDisposition.GroupContacts.forEach(function (element) {
                lstIdGroupContacts.push(element.IdGroupContact);
            });

            blocDispositionService.SaveGroupContactsOfContentBlocDisposition($scope.currentContentBlocDisposition.IdContentBlocDisposition, lstIdGroupContacts, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.popupContentBlocDispositionsErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                        $scope.openPopupContentBlocDispositions();
                    }
                } else {

                    $scope.popupContentBlocDispositionsErrorMessage = "";
                }
            });

        };
        /*
        * ONGLET COTATIONS BLOC PHYSIQUE
        *
        *
        */
        $scope.GetCashMarketsFromWsdApi = function (callbackFn) {
            quoteCashHashtagriService.GetCashMarketsFromWsdApi(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.quotesCashErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.lstProductsWSD = data;

                    if ($filter('filter')($scope.lstProductsWSD, { IDProducts: newProductTextSelected.IDProducts }, true)[0] === undefined)
                        $scope.lstProductsWSD.unshift(newProductTextSelected);

                    $scope.filterProductWSD.itemCash = $scope.lstProductsWSD[0];
                    callbackFn();
                }
            });
        };

        $scope.GetCashMarketsSelected = function () {
            quoteCashHashtagriService.GetCashMarketsSelected(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.quotesCashErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.lstProductsWSDSelected = data;
                    $scope.paramsQuotesCash.ShowDetail = true;
                }
            });
        };

        function checkIsProductAllReadySelected(collection, cible) {
            var filterCheck = angular.copy(collection);
            var isExist = false;
            filterCheck.forEach(function (item) {
                if (item.IDProducts === cible.IDProducts) {
                    isExist = true;
                    return;
                }
            });
            return isExist;
        }

        $scope.AddProductWSD = function () {
            $scope.ResetMessages();
            if ($scope.filterProductWSD.itemCash && $scope.filterProductWSD.itemCash.IDProducts > 0) {
                if (!checkIsProductAllReadySelected($scope.lstProductsWSDSelected, $scope.filterProductWSD.itemCash)) {
                    if ($scope.filterProductWSD.itemCash.MarketsWsd) {
                        $scope.lstProductsWSDSelected.push($scope.filterProductWSD.itemCash);
                    }
                }
                if ($filter('filter')($scope.lstProductsWSDSelected, { IDProducts: $scope.filterProductWSD.itemCash.IDProducts }, true)[0] !== undefined) {
                    var productSelected = $filter('filter')($scope.lstProductsWSDSelected, { IDProducts: $scope.filterProductWSD.itemCash.IDProducts }, true)[0];
                    if (!productSelected.showCashDetail)
                        productSelected.showCashDetail = true;
                }
            }
        };

        $scope.SaveCashMarkets = function () {
            $scope.ResetMessages();
            if ($scope.lstProductsWSDSelected) {
                quoteCashHashtagriService.SaveCashMarkets($scope.lstProductsWSDSelected, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.quotesCashErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        $scope.RefreshView('C');
                    }
                });
            }
        };

        /*
       * ONGLET COTATIONS BLOC FUTURE
       *
       *
       */
        $scope.GetFutureMarketsExpiriesFromWsdApi = function (callbackFn) {
            quoteFutureHashtagriService.GetFutureMarketsExpiriesFromWsdApi(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.quotesFutureErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.lstMarketWSD = data;
                    if ($filter('filter')($scope.lstMarketWSD, { IdMarkets: newMarketTextSelected.IdMarkets }, true)[0] === undefined)
                        $scope.lstMarketWSD.unshift(newMarketTextSelected);

                    $scope.filterMarketExpiries.IdMarket = newMarketTextSelected.IdMarkets;
                    callbackFn();
                }
            });
        };

        $scope.GetFutureMarketExpiriesSelected = function () {
            quoteFutureHashtagriService.GetFutureMarketExpiriesSelected(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.quotesFutureErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.lstMarketExpiriesWSDSelected = data;
                    $scope.paramsQuotesFuture.ShowDetail = true;
                }
            });
        };

        $scope.AddMarketExipryWSD = function () {
            $scope.ResetMessages();
            if ($scope.filterMarketExpiries.IdMarket && $scope.filterMarketExpiries.expiry && $scope.filterMarketExpiries.expiry.IdExpiry > 0) {
                if ($filter('filter')($scope.lstMarketExpiriesWSDSelected, { IdMarkets: $scope.filterMarketExpiries.IdMarket }, true)[0] !== undefined) {
                    var marketExpiriesSelectedFind = $filter('filter')($scope.lstMarketExpiriesWSDSelected, { IdMarkets: $scope.filterMarketExpiries.IdMarket }, true)[0];
                    if (!marketExpiriesSelectedFind.showFutureDetail)
                        marketExpiriesSelectedFind.showFutureDetail = true;
                    if ($filter('filter')(marketExpiriesSelectedFind.ListExpiryVm, { IdExpiry: $scope.filterMarketExpiries.expiry.IdExpiry }, true)[0] === undefined) {
                        $filter('filter')($scope.lstMarketExpiriesWSDSelected, { IdMarkets: $scope.filterMarketExpiries.IdMarket }, true)[0].ListExpiryVm.push($scope.filterMarketExpiries.expiry);
                    }
                } else {
                    var newMarketExipries = {
                        IdMarkets: $scope.filterMarketExpiries.IdMarket,
                        LabelledMarket: $filter('filter')($scope.lstMarketWSD, { IdMarkets: $scope.filterMarketExpiries.IdMarket }, true)[0].LabelledMarket,
                        ListExpiryVm: []
                    };
                    newMarketExipries.ListExpiryVm.push($scope.filterMarketExpiries.expiry);
                    newMarketExipries.showFutureDetail = true;
                    $scope.lstMarketExpiriesWSDSelected.push(newMarketExipries);
                }
            }
        };

        $scope.DeleteExpirySelected = function (idMarket, idExpiry) {
            $scope.ResetMessages();
            if (idMarket && idExpiry && idMarket > 0 && idExpiry > 0) {
                if ($filter('filter')($scope.lstMarketExpiriesWSDSelected, { IdMarkets: idMarket }, true)[0] !== undefined) {
                    $filter('filter')($scope.lstMarketExpiriesWSDSelected, { IdMarkets: idMarket }, true)[0].ListExpiryVm.forEach(function (item, index) {
                        if (item.IdExpiry === idExpiry) {
                            $filter('filter')($scope.lstMarketExpiriesWSDSelected, { IdMarkets: idMarket }, true)[0].ListExpiryVm.splice(index, 1);
                            return;
                        }
                    });

                    if ($filter('filter')($scope.lstMarketExpiriesWSDSelected, { IdMarkets: idMarket }, true)[0] !== undefined) {
                        if ($filter('filter')($scope.lstMarketExpiriesWSDSelected, { IdMarkets: idMarket }, true)[0].ListExpiryVm.length === 0)
                            $scope.lstMarketExpiriesWSDSelected.forEach(function (item, indexMarket) {
                                if (item.IdMarkets === idMarket) {
                                    $scope.lstMarketExpiriesWSDSelected.splice(indexMarket, 1);
                                    return;
                                }
                            });
                    }
                }
            }
        };

        $scope.DeletePanelSelected = function (id, locat) {
            $scope.ResetMessages();
            if (id && id > 0) {
                if (locat === 'F') {
                    $scope.lstMarketExpiriesWSDSelected.forEach(function (item, indexMarket) {
                        if (item.IdMarkets === id) {
                            $scope.lstMarketExpiriesWSDSelected.splice(indexMarket, 1);
                            return;
                        }
                    });
                } else if (locat === 'C') {
                    $scope.lstProductsWSDSelected.forEach(function (item, indexProduct) {
                        if (item.IDProducts === id) {
                            $scope.lstProductsWSDSelected.splice(indexProduct, 1);
                            return;
                        }
                    });
                }
            }
        };

        $scope.SaveFutureMarketsExpiries = function () {
            $scope.ResetMessages();
            if ($scope.lstMarketExpiriesWSDSelected) {
                quoteFutureHashtagriService.SaveFutureMarketsExpiries($scope.lstMarketExpiriesWSDSelected, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.quotesFutureErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        $scope.RefreshView('F');
                    }
                });
            }
        };

        $scope.InitExpiries = function () {
            $scope.filterMarketExpiries.expiry = null;
            $scope.filterMarketExpiries.init = false;
            $scope.lstMarketExpiriesVM = [];

            var newExpiryTextSelected = {
                IdExpiry: 0,
                LabelledExpiry: 'Sélectionnez'
            };

            if ($scope.filterMarketExpiries.IdMarket && $scope.filterMarketExpiries.IdMarket > 0) {
                if ($filter('filter')($scope.lstMarketWSD, { IdMarkets: $scope.filterMarketExpiries.IdMarket }, true)[0] !== undefined) {
                    $scope.lstMarketExpiriesVM = $filter('filter')($scope.lstMarketWSD, { IdMarkets: $scope.filterMarketExpiries.IdMarket }, true)[0].ListExpiryVm;
                }
            }

            if ($filter('filter')($scope.lstMarketExpiriesVM, { IdExpiry: newExpiryTextSelected.IdExpiry }, true)[0] === undefined)
                $scope.lstMarketExpiriesVM.unshift(newExpiryTextSelected);

            $scope.filterMarketExpiries.expiry = newExpiryTextSelected;
        };

        var resetPopupQuotes = function () {
            $scope.lstPopupQuotes = [];
            for (var i = 0; i < 3; i++) {
                var popupQuoteToPush = { TypeLocatSelected: '', ProductWsdSelected: null, ExpiryWsdSelected: null, MarketCashWsdSelected: null, MarketFutureWsdSelected: null };
                $scope.lstPopupQuotes.push(popupQuoteToPush);
            }
        };

        $scope.ResetSubListsByTypeLocatPopupQuotes = function (index) {
            $scope.lstPopupQuotes[index].ProductWsdSelected = $scope.lstProductsWSD[0];
            $scope.lstPopupQuotes[index].MarketFutureWsdSelected = $scope.lstMarketWSD[0];
            $scope.lstPopupQuotes[index].ExpiryWsdSelected = { LabelledExpiry: "Sélectionnez", IdExpiry: "" };
            $scope.lstPopupQuotes[index].MarketCashWsdSelected = { MarketNameFR: "Sélectionnez", IdMarket: "" };
        };

        var setValuesPopupQuotes = function () {
            $scope.lstTypeLocats = [{ Label: 'Aucun', Value: '' }, { Label: 'Future', Value: 'F' }, { Label: 'Physique', Value: 'C' }];
            $scope.GetCashMarketsFromWsdApi(function callback() { $scope.GetFutureMarketsExpiriesFromWsdApi($scope.GetPopupQuotes); });
        };

        $scope.OpenAndInitPopupQuotes = function (openPopupQuotesFn) {
            $scope.popupQuotesErrorMessage = "";
            resetPopupQuotes();
            setValuesPopupQuotes();
            openPopupQuotesFn();
        };

        $scope.GetPopupQuotes = function () {
            elementInfoMarketHomePageQuoteService.GetAllElementInfoMarketHomePageQuotes(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else
                        $scope.errorMessage = data;
                } else {
                    for (var i = 0; i < 3; i++) {
                        $scope.ResetSubListsByTypeLocatPopupQuotes(i);
                    }

                    for (var iData = 0; iData < data.length; iData++) {
                        $scope.lstPopupQuotes[iData].TypeLocatSelected = data[iData].TypeLocat;
                        switch (data[iData].TypeLocat) {
                            case 'F':
                                var marketFutureInList = $filter('filter')($scope.lstMarketWSD, { IdMarkets: data[iData].IdMarketWSD }, true)[0];
                                if (marketFutureInList) {
                                    $scope.toggleToNextExpiryChecked = data[iData].ToggleToNextExpiry;
                                    $scope.lstPopupQuotes[iData].MarketFutureWsdSelected = marketFutureInList;
                                    var expiryInList = $filter('filter')(marketFutureInList.ListExpiryVm, { IdExpiry: data[iData].IdExpiryWSD }, true)[0];
                                    if (expiryInList) {
                                        $scope.lstPopupQuotes[iData].ExpiryWsdSelected = expiryInList;
                                    }
                                }
                                break;
                            case 'C':
                                var productInList = $filter('filter')($scope.lstProductsWSD, { IDProducts: data[iData].IdProductWSD }, true)[0];
                                if (productInList) {
                                    $scope.lstPopupQuotes[iData].ProductWsdSelected = productInList;
                                    var marketCashInList = $filter('filter')(productInList.MarketsWsd, { IdMarket: data[iData].IdMarketWSD }, true)[0];
                                    if (marketCashInList) {
                                        $scope.lstPopupQuotes[iData].MarketCashWsdSelected = marketCashInList;
                                    }
                                }
                                break;
                            default:
                        }
                    }
                }
            });
        };

        $scope.SavePopupQuotes = function () {
            var error = $scope.CheckPopupQuotesBeforeSave();

            if (error !== "") {
                $scope.popupQuotesErrorMessage = error;
                return false;
            } else {
                var lstElementInfoMarketHomePageQuotes = [];
                for (var i = 0; i < $scope.lstPopupQuotes.length; i++) {
                    if ($scope.lstPopupQuotes[i].TypeLocatSelected === 'C') {
                        var elementInfoMarketHomePageQuoteC = {
                            TypeLocat: $scope.lstPopupQuotes[i].TypeLocatSelected,
                            IdProductWSD: $scope.lstPopupQuotes[i].ProductWsdSelected.IDProducts,
                            IdMarketWSD: $scope.lstPopupQuotes[i].MarketCashWsdSelected.IdMarket,
                            OrderPosition: i + 1
                        };
                        lstElementInfoMarketHomePageQuotes.push(elementInfoMarketHomePageQuoteC);
                    } else if ($scope.lstPopupQuotes[i].TypeLocatSelected === 'F') {
                        var elementInfoMarketHomePageQuoteF = {
                            TypeLocat: $scope.lstPopupQuotes[i].TypeLocatSelected,
                            IdExpiryWSD: $scope.lstPopupQuotes[i].ExpiryWsdSelected.IdExpiry,
                            IdMarketWSD: $scope.lstPopupQuotes[i].MarketFutureWsdSelected.IdMarkets,
                            OrderPosition: i + 1,
                            ToggleToNextExpiry: $scope.toggleToNextExpiryChecked
                        };
                        lstElementInfoMarketHomePageQuotes.push(elementInfoMarketHomePageQuoteF);
                    }
                }

                elementInfoMarketHomePageQuoteService.SaveListElementInfoMarketHomePageQuotes(lstElementInfoMarketHomePageQuotes, function (data, status) {
                    if (status !== null) {
                        if (status === 400) {
                            $scope.popupQuotesErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                            $scope.openPopupQuotes();
                        }
                    } else {
                        $scope.popupQuotesErrorMessage = "";
                    }
                });
            }
        };

        $scope.CheckPopupQuotesBeforeSave = function () {
            var error = "";
            $scope.lstPopupQuotes.forEach(function (item, index) {
                if (item.TypeLocatSelected === 'C' && (!item.ProductWsdSelected || item.ProductWsdSelected.IDProducts === 0 || !item.MarketCashWsdSelected || !item.MarketCashWsdSelected.IdMarket)) {
                    error += "Veuillez saisir le produit et la parité pour la cotation n°" + (index + 1) + "<br />";
                } else if (item.TypeLocatSelected === 'F' && (!item.MarketFutureWsdSelected || item.MarketFutureWsdSelected.IdMarkets === 0 || !item.ExpiryWsdSelected || !item.ExpiryWsdSelected.IdExpiry)) {
                    error += "Veuillez saisir le produit et l'échéance pour la cotation n°" + (index + 1) + "<br />";
                }
            });
            if (error) {
                error = $sce.trustAsHtml(error);
            }
            return error;
        };

        /*
        * ONGLET CONTENU OS
        *
        *
        */

        if (!$rootScope.ModuleHashtagri.Active)
            $location.path('/admin/parameters/global');

        if ($rootScope.successMessageContentInfosMarket)
            $scope.successMessageContentInfosMarket = $scope.successMessageContentInfosMarket;

        $scope.AddContentInfoMarket = function () {
            $location.path('/admin/contents/infoMarketHashtagri/saveContentInfoMarket/-1');
        };

        $scope.UpDateContentInfoMarket = function (idWritingContentInfoMarket) {
            $location.path('/admin/contents/infoMarketHashtagri/saveContentInfoMarket/' + idWritingContentInfoMarket);
        };

        $scope.GetWritingContentInfoMarketSelected = function (contentSelected) {
            if (contentSelected) {
                $scope.filterValue.IdWritingContentInfoMarketSelected = contentSelected.originalObject.Id;
                $scope.FilterWritingContentInfoMarket();
            }
        };

        $scope.SearchContentByAutoComplete = function (searchString, timeoutPromise) {
            var defered = $q.defer();
            writingContentInfoMarketService.GetItemsContentsByAutoComplete(searchString, timeoutPromise, function (data, status) {
                if (status !== null) {
                    if (status == 400) {
                        defered.reject(status);
                    } 

                } else {
                    defered.resolve(data);
                }

            });
            return defered.promise;
        };

        $scope.FilterWritingContentInfoMarket = function () {
            $scope.errorMessageContentInfosMarket = "";
            LoadWritingContentInfoMarket();

        };

        $scope.GetData = function (pageNo) {
            $scope.filterValue.CurrentPageNo = pageNo;
            $scope.FilterWritingContentInfoMarket();
        };

        $scope.TabSortKey = function (Property) {
            $scope.filterValue.Reverse = !$scope.filterValue.Reverse;
            $scope.filterValue.SortKey = Property;
            if (!$scope.filterValue.FirstLoad) {
                $scope.FilterWritingContentInfoMarket();
            }
        };

        $scope.OpenPopUpDeleteContent = function(showPopup, idWritingContentInfoMarket) {
            $rootScope.successMessageContentInfosMarket = $scope.successMessageContentInfosMarket = "";
            $scope.errorMessageContentInfosMarket = "";
            $scope.idWritingContentInfoMarketToDeleted = idWritingContentInfoMarket;
            showPopup();
        };

        $scope.DeleteWritingContentInfoMarket = function() {
            if ($scope.idWritingContentInfoMarketToDeleted)
                writingContentInfoMarketService.DeleteContentInfoMarket($scope.idWritingContentInfoMarketToDeleted, function(data, status) {
                    if (status !== null) {
                        if (status == 400) {
                            $scope.errorMessageContentInfosMarket = data[0].errors[0].Message;
                        } 
                    } else {
                        $scope.successMessageContentInfosMarket = "Le contenu a bien été supprimé.";
                        $scope.idWritingContentInfoMarketToDeleted = "";
                        $scope.FilterWritingContentInfoMarket();
                    }
                    return true;
                });
        };

        $scope.$watch('filterValue.SearchContentString', function (newValue, oldValue) {
            if (!$scope.filterValue.FirstLoad && ((!newValue) || newValue.length >= 3 && !$scope.filterValue.IdWritingContentInfoMarketSelected)) {
                $scope.filterValue.IdWritingContentInfoMarketSelected = "";
                $scope.FilterWritingContentInfoMarket();
            }
        });

        var LoadWritingContentInfoMarket = function () {
            writingContentInfoMarketService.GetContentsInfoMarketByFilter($scope.filterValue.IdWritingContentInfoMarketSelected, $scope.filterValue.SearchContentString, $scope.filterValue.ItemsPerPage, $scope.filterValue.CurrentPageNo, $scope.filterValue.SortKey, $scope.filterValue.Reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessageContentInfosMarket = data[0].errors[0].Message;
                    }
                } else {
                    $scope.writingContentsInfoMarket = data.WritingContentsInfoMarketVm;
                    $scope.totalContents = data.TotalItem;
                    $scope.filterValue.FirstLoad = false;
                }
            });
        };

        //COMMON
        $scope.RefreshView = function (locat) {
            switch (locat) {
                case 'F':
                    $scope.lstMarketExpiriesWSDSelected.forEach(function (market, index) {
                        if (market.ListExpiryVm.length === 0)
                            $scope.lstMarketExpiriesWSDSelected.splice(index, 1);
                    });
                    $scope.quotesFutureSuccessMessage = "Modifications enregistrées";
                    break;
                case 'C':
                    $scope.lstProductsWSDSelected.forEach(function (product, index) {
                        var counterPariteChecked = 0;
                        product.MarketsWsd.forEach(function (market, indexMarket) {
                            if (market.IsSelected)
                                counterPariteChecked++;
                        });
                        if (counterPariteChecked === 0)
                            $scope.lstProductsWSDSelected.splice(index, 1);
                    });
                    $scope.quotesCashSuccessMessage = "Modifications enregistrées";
                    break;
                default:
            }
        };

        var Init = function () {

            groupContactService.GetAll(function (data, status) {
                if (status === null) {
                    $scope.initLstGroupContactAvailables = data;
                } else if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
            });

            if ($scope.viewMode === "contentsInfoMarket") {
                $rootScope.successMessageContentInfosMarket = $scope.successMessageContentInfosMarket = "";
                $scope.GetCategoryInfosMarket();
                $scope.GetElementInfoMarketHomePage();
                resetPopupQuotes();
            } else if ($scope.viewMode === "quotesInfoMarket") {
                $rootScope.successMessageContentInfosMarket = $scope.successMessageContentInfosMarket = "";
                $scope.showCashDetail = false;
                $scope.filterMarketExpiries.init = true;
                $scope.GetCashMarketsFromWsdApi(function () { });
                $scope.GetCashMarketsSelected();
                $scope.GetBlocDispositions();
                $scope.GetFutureMarketsExpiriesFromWsdApi(function () { });
                $scope.GetFutureMarketExpiriesSelected();

            } else if ($scope.viewMode === "writingContentInfoMarket") {
                LoadWritingContentInfoMarket();
            }
        };


        Init(false);
    }]);
