AgrimarketServices.service('cropsService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
	function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

		var SendRequest = function (configfile, callback) {
			$http(configfile)
				.success(function (data) {
					callback(data, null);
				})
				.error(function (data, status) {
					callback(data, status);
				});
		};

		this.GetDefaultCrop = function (callback) {
			var config = {
				method: 'GET',
				url: configurationFactory.Url['Api'] + 'Crop/GetDefaultCrop'
			};
			SendRequest(config, callback);
		};

		this.GetCrops = function (callback) {
			var config = {
				method: 'GET',
				url: configurationFactory.Url['Api'] + 'Crop/GetCrops'
			};
			SendRequest(config, callback);
		};

		this.GetCropsVisible = function (callback) {
			var config = {
				method: 'GET',
				url: configurationFactory.Url['Api'] + 'Crop/GetCropsVisible'
			};
			SendRequest(config, callback);
		};
		
		this.GetCropsInOffers = function (callback) {
			var config = {
				method: 'GET',
				url: configurationFactory.Url['Api'] + 'Crop/GetCropsInOffers'
			};
			SendRequest(config, callback);
		};
		
		this.GetCropsWithContracts = function (idTypeContract, callback) {
			var config = {
				method: 'GET',
				url: configurationFactory.Url['Api'] + 'Crop/GetCropsWithContracts',
				params: { idTypeContract: idTypeContract }
			};
			SendRequest(config, callback);
        };

        this.GetCropsWithContractsBase = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Crop/GetCropsWithContractsBase'
            };
            SendRequest(config, callback);
        };

        this.GetCropsWithContractsOption = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Crop/GetCropsWithContractsOption'
            };
            SendRequest(config, callback);
        };

        this.GetCropsWithContractsBuiltPrice = function (idTypeContract, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Crop/GetCropsWithContractsBuiltPrice',
				params: { idTypeContract: idTypeContract }
            };
            SendRequest(config, callback);
        };

		this.SaveCrops = function (data, callback) {
			var config = {
				method: 'POST',
				url: configurationFactory.Url['Api'] + 'Crop/PostCrop',
				data: data
			};
			SendRequest(config, callback);
		};

	}]);