AgrimarketServices.service('contributionsService', ['$http', 'configurationFactory',
	function ($http, configurationFactory) {

		var SendRequest = function (configfile, callback) {
			$http(configfile)
				.success(function (data) {
					callback(data, null);
				})
				.error(function (data, status) {
					callback(data, status);
				});
		};
	
		this.GetContributionsPaginateWithFilter = function (startDate, endDate, idCrop, idProduct, idCompagny, idOperation, searchText, max, pageNo, sortKey, reverse, callback) {
		    var config = {
		    	method: 'GET',
		    	params: {
					idCrop: idCrop,
					startDate: startDate,
					endDate: endDate,
					idProduct: idProduct,
					idCompagny: idCompagny,
		    	    idOperation: idOperation,
		    	    searchText: searchText,
		    	    itemPerPage: max,
		    	    pageNo: pageNo,
		    	    sortKey: sortKey,
		    	    reverse: reverse
		    	},
		    	url: configurationFactory.Url['Api'] + 'Contribution/GetContributionsPaginateWithFilter',
		    };
		    SendRequest(config, callback);
		};

        this.GetContributionsXls = function (idCrop, idOperation, idUser, productSelected, idCompagny, startDate, endDate, searchText, idLanguage) {
            return configurationFactory.Url['Api'] + 'Contribution/GetContributionsXls/' +
                '?idCrop=' + idCrop +
                '&idOperation=' + idOperation +
                '&idUser=' + idUser +
                '&productSelected=' + productSelected +
                '&idCompagny=' + idCompagny +
                '&startDate=' + startDate +
                '&endDate=' + endDate +
                '&searchText=' + searchText +
                '&idLanguage=' + idLanguage;
        };

		this.GetTypeOperations = function (startDate, endDate, idCrop, idProduct, idCompagny, callback) {
		    var config = {
		        method: 'GET',
		        params: {
					idCrop: idCrop,
					startDate: startDate,
					endDate: endDate,
					idProduct: idProduct,
					idCompagny: idCompagny
		        },
		        url: configurationFactory.Url['Api'] + 'Contribution/GetTypeOperations',
		    };
		    SendRequest(config, callback);
		};
	    
		this.GetFiltersByIdCrop = function (idCrop, callback) {
		    var config = {
		        method: 'GET',
		        params: {
		            idCrop: idCrop
		        },
				url: configurationFactory.Url['Api'] + 'Contribution/GetFiltersByIdCrop',
		    };
		    SendRequest(config, callback);
		};
	}]);