AgrimarketControllers.controller('DisconnectController', ['$scope', '$rootScope', '$http', '$location', 'currentUserFactory',
    function ($scope, $rootScope, $http, $location, currentUserFactory) {

        $rootScope.currentUserRole = '';
        currentUserFactory.User = null;
        currentUserFactory.Connection = null;
        $rootScope.isLogged = false;
        $rootScope.loading = false;
        $rootScope.$broadcast("$destroy");
        if (sessionStorage.getItem("LOG_AVIV") && sessionStorage.getItem("ConnectedToBackdoor")) {
            sessionStorage.clear();
            $location.path("/backdoor");
        } else {
            sessionStorage.clear();
            $location.path('/login');
        }
    }]);
