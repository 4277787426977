AgrimarketDirectives.directive('agrimarketEditable', function ($compile) {
	return {
		transclude: false,
		replace: false,
		restrict: 'EA',
		compile: function (elm, attrs) {
			var key = attrs.agrimarketEditableKey;
			var contentAction = attrs.agrimarketEditableContentAction;
            var href = attrs.agrimarketEditableHref;
            var icoAlterClass = attrs.agrimarketEditableIcoAlterClass;
			elm.removeAttr('agrimarket-editable');
			elm.removeAttr('agrimarket-editable-key');
			elm.removeAttr('agrimarket-editable-content-action');
            elm.removeAttr('agrimarket-editable-href');
            elm.removeAttr('agrimarket-editable-ico-alter-class');
			elm.attr('editable-content-body', "editableObjectsFactory.getContentByKey('" + key + "')");
			elm.attr('editable-content-action', contentAction);
			elm.attr('editable-content-visible', 'editableObjectsFactory.getIsVisibleByKey(\'' + key + '\')');
			elm.attr('editable-ico-visible', 'modeEdition');
			elm.attr('editable-ico-action', "openPopEditable('" + key + "')");
			elm.attr('editable-key', key);
            elm.attr('editable-href', href);
            elm.attr('editable-ico-alter-class', icoAlterClass);
			elm.attr('editable','');
			var fn = $compile(elm);
			return function (scope) {
				fn(scope);
			};
		}
	};
});