AgrimarketDirectives.directive('drawpiechart', ['$rootScope', function ($rootScope) {
    return {
        restrict: 'EA',
        scope: {
            chartdata: '=',
            titlekey: '=',
            titledefault: '=',
            chartype: '=',
            chartcolor: '=',
            renderto: "=dataRenderTo"
        },
        link: function (scope, element, attrs) {
            scope.$watch('chartdata', function (n, o) {
                if (n) {
                    drawPlot();
                }
            }, true);
            var drawPlot = function () {
                var chart;
                var color;

                if (!scope.chartcolor || scope.chartcolor.length === 0) {
                    color = ['#bb5bd6', '#269cdd', '#f3b234', '#FFB6C1'];
                } else {
                    color = scope.chartcolor;
                }

                jQuery(function () {
                    chart = new Highcharts.Chart({
                        chart: {
                            plotBackgroundColor: null,
                            plotBorderWidth: null,
                            plotShadow: false,
                            plotBorderHeight: null,
                            renderTo: element[0],
                            height: 250,
                            spacing: [0, 0, 0, 0],
                            margin: [25, 0, 25, 0]
                        },
                        legend: {
                            margin: 25
                        },
                        title: {
                            text: $rootScope.editableObjectsFactory.getContentByKey(scope.titlekey, scope.titledefault),
                            style: {
                                fontSize: 13,
                                fontWeight: 'bold',
                                color: '#506687'
                            }
                        },
                        credits: {
                            enabled: false
                        },
                        tooltip: {
                            formatter: function() {
                                return '<b>' + (Math.round(this.percentage * 10) / 10) + ' %</b>';
                            }
                        },
                        exporting: {
                            enabled: false
                        },
                        plotOptions: {
                            pie: {
                                allowPointSelect: true,
                                cursor: 'pointer',
                                borderWidth: 3,
                                borderColor: '#FFF',
                                shadow: {
                                    enabled: true,
                                    offsetX: 0,
                                    offsetY: 0,
                                    width: 5,
                                    opacity: 0.1
                                },
                                colors: color,
                                dataLabels: {
                                    enabled: true,
                                    distance: 10,
                                    format: '{point.percentage:.1f} %',
                                    style: {
                                        color: 'black'
                                    }
                                },
                                showInLegend: true
                            }
                        },
                        series: [
                            {
                                type: scope.chartype,
                                data: scope.chartdata
                            }
                        ]
                    });
                });
            };
        }
    };
}]);

AgrimarketDirectives.directive('drawlinechart', function () {
    return {
        restrict: 'EA',
        scope: {
            chartdata: '=',
            title: '=',
            type: '=chartType',
            renderto: "=dataRenderTo",
            chartunit: '=chartUnit',
            animchart: '=animChart',
            idsource: '=idSource',
            chartLabels: '=' // chartLabels = true => affiche les axes X et Y
        },
        link: function (scope, element, attrs) {

            var params = {
                margin: [0, 0, 0, 0],
                labelsEnabled: false,
                axisLineWidth: 0
            };

            if (scope.chartLabels) {
                params = {
                    margin: undefined,
                    labelsEnabled: true,
                    axisLineWidth: 1
                };
            }

            scope.$watch('chartdata', function (n, o) {

                if (n) {
                    drawChart(params);
                }
            }, true);

            var drawChart = function (params) {
                var chart;
                jQuery(function () {
                    chart = new Highcharts.Chart({
                        chart: {
                            type: 'line',
                            renderTo: element[0],
                            width: $(this).parents('.accordion-content').outerWidth(),
                            height: 122,
                            borderRadius: 0,
                            margin: params.margin
                        },
                        credits: {
                            enabled: false
                        },
                        legend: {
                            enabled: false
                        },
                        title: {
                            text: scope.title,
                            style: {
                                fontSize: 13,
                                fontWeight: 'bold',
                                color: '#506687'
                            }
                        },
                        xAxis: {
                            title: {
                                text: null
                            },
                            labels: {
                                enabled: params.labelsEnabled,
                                formatter: function () {
                                    return Highcharts.dateFormat('%e/%m/%Y',
                                        new Date(this.value));
                                },
                                style: {
                                    fontSize: '9px'
                                }
                            },
                            tickWidth: params.axisLineWidth,
                            lineWidth: params.axisLineWidth,
                            gridLineWidth: 0
                        },
                        yAxis: {
                            title: {
                                text: null
                            },
                            labels: {
                                enabled: params.labelsEnabled,
                                style: {
                                    fontSize: '9px'
                                }
                            },
                            tickWidth: params.axisLineWidth,
                            lineWidth: params.axisLineWidth,
                            gridLineWidth: 0
                        },
                        plotOptions: {
                            series: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        tooltip: {
                            formatter: function () {
                                return Highcharts.dateFormat('%e/%m/%Y',
                                    new Date(this.x)) + "<br/>" + "Cotation : " + this.y + " " + scope.chartunit;
                            }

                        },
                        series: [
                            {
                                name: 'Cotation',
                                data: scope.chartdata
                            }
                        ]

                    });

                    if (scope.animchart) {
                        animchart();
                    }

                });
            };

            var animchart = function() {
                var _this = $('#' + scope.idsource),
                    grafContainer = '.chart-container',
                    heightContainer = $('.bloc_hash .content').outerHeight(),
                    dist = _this.parents('li').offset().top - $('.bloc_hash .content').find('li:first-child').offset().top,
                    paddingContainer = ($('.bloc_hash .content li:first-child').offset().top - $('.bloc_hash .content').offset().top) * 2,
                    heightLi = _this.parents('li').innerHeight(),
                    distGraf = (paddingContainer + heightLi);
                $('.bloc_hash .content').css({
                    'height': heightContainer,
                    'position': 'relative'
                });
                $('.bloc_hash .content .wrapper').css({
                    'height': $('.bloc_hash .content ul').outerHeight(),
                });

                if ($(grafContainer).hasClass('chart-deploy')) {
                    _this.parents('li').nextAll('li').show();
                    _this.parents('li').prevAll('li').show();
                    $(grafContainer).html('');
                    $('.bloc_hash .content').find('li.current-graf').removeClass('current-graf');
                    $(grafContainer).removeClass('chart-deploy');

                    $('.bloc_hash .content .wrapper').css({
                        'overflow': ''
                    });
                    $('.bloc_hash .content ul').css({
                        'transform': 'translateY(' + 0 + ')',
                        'transition': 'all 0.5s',
                    });

                    $(grafContainer).css({
                        'transform': 'translateY(' + 0 + ')',
                        'transition': 'all 0.5s',
                        'opacity': '',
                    });
                    _this.parents('li').nextAll('li').animate({
                        'opacity': 1
                    }, 100);
                } else {

                    $('.bloc_hash .content .wrapper').css({
                        'overflow': 'hidden'
                    });

                    _this.parents('li').addClass('current-graf');
                    $(grafContainer).addClass('chart-deploy');

                    $('.bloc_hash .content ul').css({
                        'transform': 'translateY(' + (-dist) + 'px)',
                        'transition': 'all 0.5s',
                    });
                    _this.parents('li').nextAll('li').animate({
                        'opacity': 0
                    }, 200);

                    $(grafContainer).css({
                        'transform': 'translateY(' + (-(heightContainer - distGraf)) + 'px)',
                        'transition': 'all 0.5s',
                        'opacity': 1,
                    });

                    $(document).one('click', function closeGraf(e) {

                        if ($('.bloc_hash .content').has(e.target).length === 0) {

                            $('.bloc_hash .content').find('li').show();
                            $('.bloc_hash .content').find('li').show();
                            $(grafContainer).html('');
                            $('.bloc_hash .content').find('li.current-graf').removeClass('current-graf');
                            $(grafContainer).removeClass('chart-deploy');

                            $('.bloc_hash .content .wrapper').css({
                                'overflow': ''
                            });
                            $('.bloc_hash .content ul').css({
                                'transform': 'translateY(' + 0 + ')',
                                'transition': 'all 0.5s',
                            });

                            $(grafContainer).css({
                                'transform': 'translateY(' + 0 + ')',
                                'transition': 'all 0.5s',
                                'opacity': '',
                            });
                            $('.bloc_hash .content').find('li').animate({
                                'opacity': 1
                            }, 100);
                        } else if ($(grafContainer).hasClass('chart-deploy')) {
                            $(document).one('click', closeGraf);
                        }
                    });

                }

            };
        }
    };
});