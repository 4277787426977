AgrimarketServices.service('commandService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
	function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

		var SendRequest = function (configfile, callback) {
			$http(configfile)
				.success(function (data) {
					callback(data, null);
				})
				.error(function (data, status) {
					callback(data, status);
				});
		};
	
        this.GetCommands = function (dateStart, dateEnd, idOperation ,callback) {
		    var config = {
		        method: 'GET',
		        params: {
                    idOperation: idOperation,
                    dateStart: dateStart,
                    dateEnd: dateEnd
		        },
		        url: configurationFactory.Url['Api'] + 'Command/GetCommands'
		    };
		    SendRequest(config, callback);
		};

        this.GetCommandsXls = function (dateStart, dateEnd, idOperation, idUser, idCompagny, idLanguage) {
			return configurationFactory.Url['Api'] + 'Command/GetCommandsXls/' +
                '?dateStart=' + dateStart +
                '&dateEnd=' + dateEnd +
				'&idOperation=' + idOperation +
				'&idUser=' + idUser +
				'&idCompagny=' + idCompagny +
				'&idLanguage=' + idLanguage;
		};

        this.GetCommandsPaginateWithFilter = function (dateStart, dateEnd, idOperation, idCompagny, searchText, idCommandDeliveryStateSelected, callback) {
		    var config = {
		        method: 'GET',
		        params: {
					idOperation: idOperation,
                    dateStart: dateStart,
                    dateEnd: dateEnd,
					idCompagny: idCompagny,
					searchText: searchText,
                    idCommandDeliveryState: idCommandDeliveryStateSelected
		        },
		        url: configurationFactory.Url['Api'] + 'Command/GetCommandsPaginateWithFilter'
		    };
		    SendRequest(config, callback);
		};

        this.GetOperationCommands = function (dateStart, dateEnd, idCompagny, idExtCommandForOperation, callback) {
		    var config = {
		        method: 'GET',
		        params: {
		            dateStart: dateStart,
		            dateEnd: dateEnd,
		            idCompagny: idCompagny,
                    idExtCommandForOperation: idExtCommandForOperation
		        },
				url: configurationFactory.Url['Api'] + 'Command/GetOperationCommands'
		    };
		    SendRequest(config, callback);
        };

        this.GetFiltersByDates = function (dateStart, dateEnd, callback) {
            var config = {
                method: 'GET',
                params: {
                    dateStart: dateStart,
                    dateEnd: dateEnd
                },
                url: configurationFactory.Url['Api'] + 'Command/GetFiltersByDates'
            };
            SendRequest(config, callback);
		};

		this.GetArticlesFromCommandPaginate = function (orderNumber, sortKey, reverse, pageNo, max, callback) {
			var config = {
				method: 'GET',
				params: {
					orderNumber: orderNumber,
					sortKey: sortKey,
					reverse: reverse,
					pageNo: pageNo,
					itemPerPage: max
				},
				url: configurationFactory.Url['Api'] + 'Command/GetArticlesFromCommandPaginate'
			};
			SendRequest(config, callback);
		};
	}]);