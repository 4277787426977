AgrimarketServices.service('operationService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

    	var SendRequest = function (configfile, callback) {
    		$http(configfile)
			    .success(function (data) {
			    	callback(data, null);
			    })
			    .error(function (data, status) {
			    	callback(data, status);
			    });
    	};

    	this.GetOperationsByTreatment = function (callback) {
    		var config = {
    			method: 'GET',
    			url: configurationFactory.Url['Api'] + 'Operation/GetOperationsByTreatment'
    		};
    		SendRequest(config, callback);
    	};

		this.GetOperationsForAlertMail = function (callback) {
    		var config = {
    			method: 'GET',
    			url: configurationFactory.Url['Api'] + 'Operation/GetOperationsForAlertMail'
    		};
    		SendRequest(config, callback);
    	};

		this.GetOperationsInOffers = function (callback) {
			var config = {
				method: 'GET',
				url: configurationFactory.Url['Api'] + 'Operation/GetOperationsInOffers'
			};
			SendRequest(config, callback);
		};
    }]);