AgrimarketServices.service('userTableService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
	function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

		var SendRequest = function (configfile, callback) {
			$http(configfile)
				.success(function (data) {
					callback(data, null);
				})
				.error(function (data, status) {
					callback(data, status);
				});
		};
	
		this.GetById = function (name, callback) {
		    var config = {
		        method: 'GET',
		        url: configurationFactory.Url['Api'] + 'ColumnTable/GetColumnTable',
		        params: { idDefaultTable: name }
		    };
		    return $http(config, callback);
		};

		this.GetTableById = function (name, callback) {
			var config = {
				method: 'GET',
				url: configurationFactory.Url['Api'] + 'ColumnTable/GetColumnTable',
				params: { idDefaultTable: name }
			};
			SendRequest(config, callback);
		};

		this.SaveColumn = function (val,callback) {
		    var config = {
		        method: 'POST',
		        url: configurationFactory.Url['Api'] + 'ColumnTable/PostColumnTable',
		        data: val 
		    };
		    SendRequest(config, callback);
		};
		
		this.PutColumn = function (val, callback) {
			var config = {
				method: 'PUT',
				url: configurationFactory.Url['Api'] + 'ColumnTable/PutColumnTable',
				data: val
			};
			SendRequest(config, callback);
		};
	}]);