AgrimarketServices.service('editableObjectsService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
	function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

		var sendRequest = function (configfile, callback) {
			$http(configfile)
				.success(function (data) {
					callback(data, null, null, null);
				})
				.error(function (data, status, headers, config) {
					callback(data, status, headers, config);
				});
		};

		this.GetEditableObjectsAndMenu = function (callback) {
		    var config = {
		        method: 'GET',
		        url: configurationFactory.Url['Api'] + 'EditableObject/GetEditableObjectsAndMenu'
		    };
		    sendRequest(config, callback);
		};


		this.GetEditableMenuByKey = function (keyParent, callback) {
		    var config = {
		        method: 'GET',
		        url: configurationFactory.Url['Api'] + 'EditableObject/GetEditableMenuByKey',
		        params: { keyParent: keyParent }
		    };
		    sendRequest(config, callback);
		};

		this.SaveEditableObject = function (editableObject, callback) {
			var config = {
				method: 'POST',
				url: configurationFactory.Url['Api'] + 'EditableObject/SaveEditableObject',
				data: editableObject
			};

			sendRequest(config, callback);
		};

		this.UpdateContentEditableObject = function (editableObjects, callback) {
		    var config = {
		        method: 'POST',
		        url: configurationFactory.Url['Api'] + 'EditableObject/UpdateContentEditableObject',
		        data: editableObjects
		    };

		    sendRequest(config, callback);
		};

		this.SaveEditableObjects = function (editableObjects, callback) {
		    var config = {
		        method: 'POST',
		        url: configurationFactory.Url['Api'] + 'EditableObject/SaveEditableObjects',
		        data: editableObjects
		    };

		    sendRequest(config, callback);
		};

}]);