AgrimarketServices.service('outstandingsService', ['$http', 'configurationFactory',
    function ($http, configurationFactory) {

        var sendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null);
                })
                .error(function (data, status) {
                    callback(data, status);
                });
        };

        this.GetOutstandingsPaginateWithFilter = function (idTypeOutstanding, idCompagny, searchText, beginDate, endDate, max, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Outstanding/GetOutstandingsPaginateWithFilter',
                params: {
                    idTypeOutstanding: idTypeOutstanding,
                    idCompagny: idCompagny,
                    searchText: searchText,
                    beginDate: beginDate,
                    endDate: endDate,
                    itemPerPage: max,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };
            sendRequest(config, callback);
        };

        this.GetOutstandingsXls = function (idCompagnySelected, idUser, startDate, endDate, searchText, idLanguage) {
            return configurationFactory.Url['Api'] + 'Outstanding/GetOutstandingsXls/' +
                '?idCompagnySelected=' + idCompagnySelected +
                '&idUser=' + idUser +
                '&startDate=' + startDate +
                '&endDate=' + endDate +
                '&searchText=' + searchText +
                '&idLanguage=' + idLanguage;
        };

        this.GetTypeOutstandings = function (idCompagny, callback) {
            var config = {
                method: 'GET',
                params: {
                    idCompagny: idCompagny
                },
                url: configurationFactory.Url['Api'] + 'Outstanding/GetTypeOutstandings'
            };
            sendRequest(config, callback);
        };

        this.GetFilters = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Outstanding/GetFilters',
            };
            sendRequest(config, callback);
        };
    }
]);