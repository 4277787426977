AgrimarketControllers.controller('globalController', ['$scope', '$rootScope', '$http', '$location', "currentUserFactory",
	'configurationFactory', 'parametersFactory', 'parametersService', '$sce', '$upload', 'uploadService', 'enumFactory',
       function ($scope, $rootScope, $http, $location, currentUserFactory, configurationFactory, parametersFactory, parametersService, $sce, $upload,
       	uploadService, enumFactory) {

           $scope.showDetail = true;
           $scope.uploads = {};
           $scope.typeParameters = enumFactory.TypeParameters;

           $scope.init = function () {
               var idsGroupParameters = enumFactory.GroupParameters.Offer + ";" + enumFactory.GroupParameters.Documentation + ";" + enumFactory.GroupParameters.ManagementSite + ";" + enumFactory.GroupParameters.ManagementMessage;
               parametersService.GetParametersByIdsGroup(idsGroupParameters, function (data, status) {
                   if (status !== null) {
                       if (status == 400)
                           $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                   }
                   else {
                       $scope.globalParameters = data;
                       $scope.globalParameters.forEach(function (group) {
                           group.Parameters.forEach(function (parameter, index, object) {
                               if (parameter.IdTypeParameter == enumFactory.TypeParameters.FileUpload) {
                                   if (parameter.Value !== undefined && parameter.Value !== null && parameter.Value !== "") {
                                       uploadService.GetFileName(parameter.Value, function (d, sta) {
                                           if (sta === undefined || sta === null) {
                                               $scope.uploads[parameter.Key].fileName = d;
                                           } else if (sta == 400) {
                                               $scope.errorMessage = d[0].errors[0].Message;
                                           } else {
                                               $scope.errorMessage = sta;
                                           }
                                       });
                                   }

                                   $scope.uploads[parameter.Key] = {};
                                   $scope.uploads[parameter.Key].fileName = '';
                                   $scope.uploads[parameter.Key].percent = '';
                                   $scope.uploads[parameter.Key].linkFile = '';
                               }
                               //affichage dans l'onglet Routage d'ordre
                               if (parameter.Key === "TypePresentOffers")
                                   object.splice(index, 1);
                           });
                       });
                   }
               });
           };

           $scope.init();

           $scope.SaveGlobalParameters = function () {
               //if (!$scope.CheckParameters())
               //    return false;

               $scope.errorMessage = "";
               $scope.validationMessage = "";

               $scope.globalParameters.forEach(function (param) {
                   if (param.IdTypeParameter == enumFactory.TypeParameters.CheckBox) {
                       param.Value = "";
                       param.PossibleValuesTab.forEach(function (item) {
                           if (item.Checked)
                               param.Value += item.Value + ";";
                       });
                   }
               });

               parametersService.SaveParametersGlobal($scope.globalParameters, function (data, status) {
                   if (status !== null) {
                       if (status == 400)
                           $scope.errorMessage = data[0].errors[0].Message;
                   }
                   else {
                       $scope.globalParameters.forEach(function (param) {
                           if (param.IdTypeParameter === enumFactory.TypeParameters.FileUpload && param.Value === null) {
                               $scope.deleteFile(param);
                           }
                       });

                       $scope.validationMessage = "Vos paramètres ont bien été pris en compte.";
                   }
               });
               return true;
           };

           $rootScope.onRefresh = function () { $scope.init(); };

           $scope.onFileSelect = function ($files, parameter) {
               var upload = function (i, $file) {
                   $scope.uploads[parameter.Key].fileName = '';
                   $scope.uploads[parameter.Key].percent = '';
                   $scope.uploads[parameter.Key].linkFile = '';
                   $scope.errorMessage = null;
                   $upload.upload({
                       url: configurationFactory.Url['Api'] + 'Upload/Upload', // webapi url
                       method: "POST",
                       params: { idAttachment: -1},
                       file: $file
                   }).progress(function (evt) {
                       // get upload percentage
                       $scope.uploads[parameter.Key].percent = parseInt(100.0 * evt.loaded / evt.total) + '%';
                   }).success(function (data) {
                       // file is uploaded successfully
                       parameter.Value = data.PrivateLabel;
                       $scope.uploads[parameter.Key].fileName = data.PublicLabel;
                   }).error(function (data) {
                       $scope.errorMessage = data;
                   });
               };

               for (var i = 0; i < $files.length; i++) {
                   var $file = $files[i];
                   upload(i, $file);
               }
           };

           $scope.resetFile = function (parameter) {
               $scope.uploads[parameter.Key].fileName = '';
               $scope.uploads[parameter.Key].percent = '';
               $scope.uploads[parameter.Key].linkFile = '';
               $scope.errorMessage = null;
               parameter.Value = null;
           };

           $scope.deleteFile = function (parameter) {
               uploadService.DeleteUpload(parameter.Value, function (data, status) {
                   if (status === undefined || status === null) {

                       $scope.uploads[parameter.Key].fileName = '';
                       $scope.uploads[parameter.Key].percent = '';
                       $scope.uploads[parameter.Key].linkFile = '';
                       $scope.errorMessage = null;
                       parameter.Value = null;

                   } else if (status == 400) {
                       $scope.errorMessage = data[0].errors[0].Message;
                   } else {
                       $scope.errorMessage = status;
                   }

               });
           };

       }]);

