AgrimarketServices.service('newsService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null, null, null);
                })
                .error(function (data, status, headers, config) {
                    callback(data, status, headers, config);
                });
        };

        this.GetNewsPaginateWithFilter = function (searchText, max, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'News/GetNewsPaginateWithFilter',
                params: {
                	searchText: searchText,
                	itemPerPage: max,
                	pageNo: pageNo,
                	sortKey: sortKey,
                	reverse: reverse
                }
            };
            SendRequest(config, callback);
        };

        this.GetNewsById = function (idnews, callback) {
        	var config = {
        		method: 'GET',
        		url: configurationFactory.Url['Api'] + 'News/GetNewsById',
        		params: { idnews: idnews }
        	};
        	SendRequest(config, callback);
        };
      
        this.SaveNews = function (news, callback) {
        	var config = {
        		method: 'POST',
        		url: configurationFactory.Url['Api'] + 'News/SaveNews',
        		data: news
        	};
        	SendRequest(config, callback);
        };
	    
        this.DeleteNews = function (idnews, callback) {
            var config = {
                method: 'DELETE',
                url: configurationFactory.Url['Api'] + 'News/DeleteNews',
                params: { idnews: idnews }
            };

            SendRequest(config, callback);
        };
    }]);