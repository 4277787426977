/*
*   Directive d'Autocompletion
*	2 parametres sont nécéssaire :
*	- confirmMessage : text du boutton de confirmation
*	- cancelMessage : text du bouton d'annulation
*
*/

AgrimarketDirectives.directive('autoComplete', function ($timeout) {
	return function(scope, iElement, iAttrs) {
		iElement.autocomplete({
			source: scope[iAttrs.uiItems],
			messages: {
				noResults: '',
				results: function() {
				}
			},
			select: function(event, ui) {
				scope[iAttrs.uiTarget] = ui.item.item;
				iElement.trigger('submit');
			}
		});
	};
});