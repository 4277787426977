AgrimarketControllers.controller('manageUsersOfTcController', ['$scope', '$rootScope', '$routeParams', '$sce', '$filter', '$http', '$location', 'newsService', 'colonnes', 'userTableService', 'userService', 'enumFactory',
    function ($scope, $rootScope, $routeParams, $sce, $filter, $http, $location, newsService, colonnes, userTableService, userService, enumFactory) {

        $scope.searchText = "";
        $scope.currentUser = {};
        $scope.max = 10;
        $scope.actions = "";
        $scope.isActif = enumFactory.DropDownList.Tous;

        var Init = function () {
            $scope.tableUsersDatasVisible = colonnes.data.DataVisible.split(',');
            $scope.tableUsersLabels = colonnes.data.ColumnName.split(',');
            $scope.data = { reverse: false, sortKey: "Login" };
            $scope.pageNo = 1;
            $scope.GetData($scope.pageNo);
        };


        $scope.GetData = function (pageNo) {
            $scope.pageNo = pageNo;
            $scope.users = $filter('filter')($rootScope.lstClientsFromTc, function (val) {
                if (val.FullName.toLowerCase().indexOf($scope.searchText) > -1 ||
                    val.Login.toLowerCase().indexOf($scope.searchText) > -1)
                    if ($scope.isActif === enumFactory.DropDownList.Tous || val.IsEnabled.toString() === $scope.isActif)
                        return true;
                return false;
            }, true);
            $scope.users = $filter('orderBy')($scope.users, $scope.data.sortKey, $scope.data.reverse);
            $scope.total = angular.copy($scope.users.length);

            $scope.users = $scope.users.slice(($scope.pageNo - 1) * $scope.max);
        };

        $scope.Sort = function (sortKey, reverse) {
            $scope.data.sortKey = sortKey;
            $scope.data.reverse = reverse;
            $scope.GetData($scope.pageNo);
        };

        $scope.$watch("searchText", function (newValue, oldValue) {
            if ((newValue && oldValue !== newValue) || (!newValue && oldValue)) {
                $scope.GetData($scope.pageNo);
            }
        });

        $scope.SendIndentifiersPopUp = function (idUserSelected, showPopUp) {
            $scope.idUserSelectedForSendIdentifiers = idUserSelected;
            $scope.succesMessage = "";
            showPopUp();
        };

        $scope.SendIdentifiersToUser = function () {
            $scope.userAction = {
                IdTypeAction : enumFactory.TypeUserActions.EnvoyerIdentifiants,
                LstIdUser: [$scope.idUserSelectedForSendIdentifiers]
            };

            userService.DoUsersAction($scope.userAction, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                }
                else
                    $scope.succesMessage = $rootScope.editableObjectsFactory.EditableObjects.Tc_ManageTCUsers_SendIdentifiersSuccessMessage.Content;
            });
        };

        $scope.UpdateUserPopup = function (user, popup) {
            $scope.errorPopupHtml = "";
            $scope.succesMessage = "";
            $scope.modalTitle = $rootScope.editableObjectsFactory.EditableObjects.Tc_ManageTCUsers_PopupTitleModifyUser.Content;
            $scope.currentUser = angular.copy(user);
            popup();
        };

        $scope.VerifUser = function () {
            var errorsPopup = userService.VerifUserLight($scope.currentUser);

            if (errorsPopup) {
                $scope.errorPopupHtml = $sce.trustAsHtml(errorsPopup);
                return false;
            }
            else
                return true;
        };

        $scope.SaveUser = function () {
            userService.UpdateUserInfosByTc($scope.currentUser, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorPopupHtml = $sce.trustAsHtml(data[0].errors[0].Message);
                    }
                } else {
                    $scope.succesMessage = $rootScope.editableObjectsFactory.EditableObjects.Tc_ManageTCUsers_UpdateUSersInfosSuccessMessage.Content;
                    $scope.currentUser = {};
                    $rootScope.lstClientsFromTc = data;
                    Init();
                }
            });
        };

        $rootScope.onRefresh = function () {
            Init();
        };

        Init();
    }]);
