AgrimarketServices.service('compagnyService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null);
                })
                .error(function (data, status) {
                    callback(data, status);
                });
        };

        this.GetAll = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Compagny/GetAll',
            };
            SendRequest(config, callback);
        };

        this.GetAllCompagnies = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Compagny/GetAllCompagnies'
            };
            SendRequest(config, callback);
        };

        this.SaveCompagnie = function (compagnie, callback) {
            var config = {
                method: 'PUT',
                url: configurationFactory.Url['Api'] + 'Compagny/PutCompagnie',
                headers: { 'Content-Type': 'application/json' },
                data: compagnie
            };
            SendRequest(config, callback);
        };

}]);