AgrimarketServices.service('videoService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

    	var SendRequest = function (configfile, callback) {
    		$http(configfile)
                .success(function (data) {
                	callback(data, null, null, null);
                })
                .error(function (data, status, headers, config) {
                	callback(data, status, headers, config);
                });
    	};

    	this.GetVideoPaginateWithFilter = function (searchText, max, pageNo, sortKey, reverse, callback) {
    		var config = {
    			method: 'GET',
                url: configurationFactory.Url['Api'] + 'Video/GetvideoPaginateWithFilter',
    			params: {
    				searchText: searchText,
    				itemPerPage: max,
    				pageNo: pageNo,
    				sortKey: sortKey,
    				reverse: reverse
    			}
    		};
    		SendRequest(config, callback);
    	};

    	this.PostVideo = function (data, callback) {
    		var config = {
    			method: 'POST',
                url: configurationFactory.Url['Api'] + 'video/PostVideo',
    			data: data
    		};
    		SendRequest(config, callback);
    	};

	    this.DeleteVideo = function(idVideo, callback) {
		    var config = {
			    method: 'DELETE',
                url: configurationFactory.Url['Api'] + 'video/DeleteVideo',
                params: { idVideo: idVideo }
		    };
		    SendRequest(config, callback);
	    };
    }]);