/*
*   Directive pour l'affichage de tooltip
*	1 parametres sont nécéssaire :
*	- idTooltip : id de l'element HTML a afficher
*
*   2 fonctions sont nécéssaires:
*       -hoverFunction: fonction du hover
*       -leaveFunction: fonction au leave de la souris
*/

AgrimarketDirectives.directive('tooltipDirective', function ($timeout) {
	return function (scope, iElement, iAttrs) {
	    var elemWidth = $(iElement).outerWidth(true);
	    var elemHeight = $(iElement).outerHeight(true);
	    var elemTop = $(iElement).offset().top;
	    var elemLeft = $(iElement).offset().left;


	    $("#" + iAttrs.idTooltip).css({
	        'opacity': 0,
	        'position': 'absolute',
	        'bottom': -elemHeight + 15,
	        'left': (elemWidth + elemLeft)/2
	    }).hide();

		scope[iAttrs.hoverFunction] = function () {
		    $("#" + iAttrs.idTooltip).show().animate({
		        'opacity': 1,
		        'bottom': -elemHeight - 5
		    },150);

		};

		scope[iAttrs.leaveFunction] = function () {
		    $("#" + iAttrs.idTooltip).animate({
		        'opacity': 0,

		    }, 100).hide().css({
		        'bottom': -elemHeight + 15
		    });
		};
	};
});

AgrimarketDirectives.directive('helpTooltip', function () {
    return {
        restrict: 'A',
        link: function(scope, element) {
            
            var randomInteger = function (pow) {
                return Math.floor(Math.random() * pow);
            };
            var id = "tooltip-id-" + randomInteger(10000);

            scope.$parent.$on('$destroy', function() {
                $('#'+id).removeClass('show').remove();
            });

            var leaveOnClick = $(element).attr('data-leave-onClick');

            
            $('.remove-line').click(function() {
                $('#'+id).removeClass('show').remove();
            });

            $(element).mouseenter(function () {
                var _this = $(element),
                    elemH = _this.outerHeight(),
                    elemW = _this.outerWidth(),
                    msg = _this.attr('data-help-tooltip'),
                    posT = _this.offset().top,
                    posFromBottomWindow = (_this.offset().top - $(document).scrollTop() - $(window).height() + _this.outerHeight()) * (-1),
                    posL = _this.offset().left,
                    posElT = 0,
                    posElL = 0,
                    verticalPosition = _this.attr('data-help-tooltip-v');
                

                $('<div class="help-tooltip" style="position: absolute;z-index: 1000;" id="'+id+'">' + msg + '</div>').appendTo('body');

                if (verticalPosition === "bottom") {
                    if (posFromBottomWindow < $('#'+id).outerHeight() + 10) {
                        posElT = (posT - $('#'+id).outerHeight());
                    } else {
                        posElT = (posT + elemH);
                    }
                } else {
                    if (posT < 50) {
                        posElT = (posT + elemH + 10);
                    } else {
                        posElT = (posT - ($('#'+id).outerHeight() + 10));
                    }
                }


                if (posL < 80) {
                    posElL = 0;
                } else {
                    posElL = ((posL + (_this.outerWidth() / 2)) - ($('#'+id).outerWidth() / 2));
                }

                $('#'+id).css({
                    top: posElT,
                    left: posElL,
                });

                $('#'+id).addClass('show');
            }).mouseleave(function () {
                //Si souris quitte l'element
                setTimeout(function () {
                    //Si souris sur la tooltip
					if ($('.help-tooltip:hover').length != 0) {
                        //Si souris quitte la tooltip
                        $('#'+id).mouseleave(function () {
                            setTimeout(function () {
                                $('#'+id).removeClass('show').remove();
                            }, 1);
                        });
                    } else {
                        //Si souris quitte l'element pour de vrais
                        setTimeout(function () {
                            $('#'+id).removeClass('show').remove();
                        }, 1);
                    }                
                }, 200);
            });

            if (leaveOnClick === 'true') {
                $(element).click(function () {
                    $('#'+id).removeClass('show').remove();
                });
            }
        }
    };
});