AgrimarketControllers.controller('quoteAdminController', ['$scope', '$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory', 'cropsService', '$filter',
	'parametersService', '$sce', 'parametersFactory', 'coverService', 'productsService', 'typeCoversService', 'enumFactory', 'wsdApiService', 'quoteService',
	function ($scope, $rootScope, $http, $location, currentUserFactory, configurationFactory, cropsService, $filter, parametersService, $sce, parametersFactory,
		coverService, productsService, typeCoversService, enumFactory, wsdApiService, quoteService) {
		$scope.isAutomatic = false;
		$scope.TypeCover = enumFactory.TypeCover;
		$scope.market = {};
		$scope.errorMessage = "";
		$scope.successMessage = "";
		$scope.txtButton = "";
		$scope.quoteexpiry = {};
		$scope.lstMarketsWSD = [];
		$scope.lstMarketsWSDFull = [];
		$scope.edit = false;
		$scope.showDetail = true;


		if (!$rootScope.ModuleCotation.Active)
			$location.path('/admin/parameters/global');

		$scope.InitPopup = function (popup) {
			$scope.GetMarketNotSelected();
			$scope.edit = false;
			$scope.market = {};
			$scope.title = "Ajouter un marché";
			$scope.InitMessage();
			popup();
		};
		$scope.InitPopupEdit = function (popup, market) {
			$scope.lstMarketsWSD = [];
			$scope.lstMarketsWSD.push($filter('filter')($scope.lstMarketsWSDFull, { IdMarket: market.IdMarketWSD }, true)[0]);
			$scope.edit = true;
			$scope.title = "Modifier un marché";
			$scope.market = angular.copy(market);
			$scope.InitMessage();
			popup();
		};

		$scope.closePopUp = function () {
			$scope.market={};
			$scope.formCancel();
		};

		$scope.ToAddExpiry = function (item) {
			$scope.lstMarkets.forEach(function (market, index) {
				market.QuoteExpiries.forEach(function (item3, index2) {
							var expiry = $filter('filter')($scope.lstMarkets[index].QuoteExpiries, { IdExpiryWSD: item.IdExpiryWSD }, true)[0];
							if (expiry !== undefined && expiry !== null && item3 === expiry) {
								market.QuoteExpiries[index2].IdQuoteExpiry = expiry.IdQuoteExpiry;
								market.QuoteExpiries[index2].IsVisible = !expiry.IsVisible;
						return false;
					}
				});
			});
		};

		$scope.CheckMarket = function () {
			$scope.InitMessage();
			if (($scope.market.IdMarketWSD !== null && $scope.market.IdMarketWSD !== undefined) && (!$scope.edit && $filter('filter')($scope.lstMarkets, { IdMarketWSD: $scope.market.IdMarketWSD }, true)[0] !== undefined && $filter('filter')($scope.lstMarkets, { IdMarketWSD: $scope.market.IdMarketWSD }, true)[0] !== null)) {
				$scope.errorMessagePopup = "Ce marché a déjà été ajouté";
				return false;
			}

			if ($scope.market.IdMarketWSD === undefined || $scope.market.IdMarketWSD === null || $scope.market.IdMarketWSD === 0){
				$scope.errorMessagePopup = "Veuillez renseigner un marché";
				return false;
			}
			return true;
		};

		$scope.AddMarket = function () {
			$scope.InitMessage();
			if ($scope.market.Label === undefined || $scope.market.Label === null || $scope.market.Label.trim() === "") {
				$scope.market.Label = $filter('filter')($scope.lstMarketsWSD, { IdMarket: $scope.market.IdMarketWSD }, true)[0].MarketNameFR;
			}
			if ($scope.market.IdQuoteMarket === undefined) {
				wsdApiService.GetExpiriesWSDByIdMarket($scope.market.IdMarketWSD, function (data, status) {
					if (status === null) {
						$scope.market.QuoteExpiries = [];
						data.Expiries.forEach(function(item) {
							$scope.market.QuoteExpiries.push($scope.ConvertExpiryWSDToQuoteExpiry($scope.market.IdMarketWSD, item));
						});
						$scope.SaveMarket($scope.market);
					} else if (status == 400)
						$scope.errorMessage = data[0].errors[0].Message;
					else {
						$scope.errorMessage = status;
					}
				});
			}
			else
				$scope.SaveMarket($scope.market);
		};

		$scope.SaveMarket = function (market) {
			$scope.InitMessage();
			quoteService.AddMarket(market, function (data, status) {
				if (status === null) {
					if ($scope.edit)
						$scope.successMessage = "Vos modifications ont bien été prises en compte.";
					else
						$scope.successMessage = "Votre marché a bien été pris en compte.";
					if (market.IdQuoteMarket !== undefined) {
						$filter('filter')($scope.lstMarkets, { IdQuoteMarket: market.IdQuoteMarket }, true)[0].Label = market.Label;
						$filter('filter')($scope.lstMarkets, { IdQuoteMarket: market.IdQuoteMarket }, true)[0].IsVisible = market.IsVisible;
					}
					$scope.market = {};
					if(!$scope.edit)
					$scope.GetMarket();
				} else if (status == 400) {
					$scope.errorMessage = data[0].errors[0].Message;
					$scope.market = {};
				} else {
					$scope.errorMessage = status;
					$scope.market = {};
				}
			});
		};
		$scope.DeleteMarket = function (market) {
			$scope.InitMessage();
			quoteService.DeleteQuoteMarket(market, function (data, status) {
				if (status === null) {
					$scope.successMessage = "Votre marché a bien été supprimé";
					$scope.market = {};
					$scope.GetMarket();
				} else if (status == 400) {
					$scope.errorMessage = data[0].errors[0].Message;
					$scope.market = {};
				} else {
					$scope.errorMessage = status;
					$scope.market = {};
				}
			});
		};
		$scope.InitMessage = function() {
			$scope.errorMessage = "";
			$scope.successMessage = "";
			$scope.errorMessagePopup = "";
		};

		$scope.GetMarketNotSelected = function () {
			$scope.lstMarketsWSD = angular.copy($scope.lstMarketsWSDFull);
			$scope.lstMarkets.forEach(function(market) {
				if ($filter('filter')($scope.lstMarketsWSD, { IdMarket: market.IdMarketWSD }, true)[0] !== undefined) {
					$scope.lstMarketsWSD.splice($scope.lstMarketsWSD.indexOf($filter('filter')($scope.lstMarketsWSD, { IdMarket: market.IdMarketWSD }, true)[0]), 1);
				}
			});
		};
		$scope.GetMarket = function() {
			quoteService.GetMarkets(function (data, status) {
				if (status === null) {
					$scope.lstMarkets = data;
					$scope.lstMarketsWSD = angular.copy($scope.lstMarketsWSDFull);
					$scope.lstMarkets.forEach(function (market, index) {
						if ($filter('filter')($scope.lstMarketsWSD, { IdMarket: market.IdMarketWSD }, true)[0] !==undefined) {
							$scope.lstMarketsWSD.splice($scope.lstMarketsWSD.indexOf($filter('filter')($scope.lstMarketsWSD, { IdMarket: market.IdMarketWSD }, true)[0]), 1);
						}
						wsdApiService.GetExpiriesWSDByIdMarket(market.IdMarketWSD, function (data2, status2) {
							if (status2 === null) {
								var tmplst = angular.copy($scope.lstMarkets[index].QuoteExpiries);
								market.QuoteExpiries = [];
								data2.Expiries.forEach(function (item2, index2) {
									var expiry = $filter('filter')(tmplst, { IdExpiryWSD: item2.IdExpiry }, true)[0];
									market.QuoteExpiries.push($scope.ConvertExpiryWSDToQuoteExpiry(market.IdQuoteMarket, item2));
									if (expiry !== undefined && expiry !== null) {
										market.QuoteExpiries[index2].IdQuoteExpiry = expiry.IdQuoteExpiry;
										market.QuoteExpiries[index2].IsVisible = true;
									} else {
										market.QuoteExpiries[index2].IdQuoteExpiry = 0;
										market.QuoteExpiries[index2].IsVisible = false;
									}
								});
							} else if (status2 == 400)
								$scope.errorMessage = data2[0].errors[0].Message;
							else {
								$scope.errorMessage = status2;
							}
						});
					});
				} else {
					$scope.errorMessage = status;
				}
			});
		};
		
		
		$scope.init = function() {
			wsdApiService.GetMarketsWSDToEuronext(function (data, status) {
				if (status === null) {
					$scope.lstMarketsWSDFull = data;
					$scope.GetMarket();
				} else {
					$scope.errorMessage = status;
				}
			});
			
		};

		$scope.ConvertExpiryWSDToQuoteExpiry = function (idquotemarket,expirywsd) {
			var tmpquoteexpiry = {};
				tmpquoteexpiry.IdQuoteMarket = idquotemarket;
				tmpquoteexpiry.IdExpiryWSD = expirywsd.IdExpiry;
				tmpquoteexpiry.Label = expirywsd.ExpiryNameFR;
			return tmpquoteexpiry;
		};

		$scope.SaveExpiry = function () {
			$scope.InitMessage();
			$scope.tmplstmarket = angular.copy($scope.lstMarkets);
			$scope.tmplstmarket.forEach(function (item) {
				item.QuoteExpiries = $filter('filter')(item.QuoteExpiries, { IsVisible: true }, true);
			});
			quoteService.PutQuoteExpiry($scope.tmplstmarket, function (data, status) {
				if (status === null) {
					$scope.successMessage = "Vos modifications ont bien été enregistrées";
					//$scope.GetMarket();
				} else if (status == 400)
					$scope.errorMessage = data[0].errors[0].Message;
				else {
					$scope.errorMessage = status;
				}
			});
		};

		$scope.ChangePosition = function (positionarray) {
			$scope.InitMessage();
			var pos = 1;
			positionarray.End.forEach(function (item) {
				item = JSON.parse(item);
				$scope.lstMarkets.forEach(function (item2, index) {
					if (item2.IdMarketWSD == item.quoteId) {
						item2.Position = pos++;
						return false;
					}
				});
			});
		
		};

		$scope.init();

		$rootScope.onRefresh = function () { $scope.init(); };
	}]);