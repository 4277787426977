AgrimarketServices.service('deliveriesService', ['$http', 'configurationFactory',
    function ( $http, configurationFactory) {

    	var SendRequest = function (configfile, callback) {
    		$http(configfile)
			    .success(function (data) {
			    	callback(data, null);
			    })
			    .error(function (data, status) {
			    	callback(data, status);
			    });
    	};

        this.GetDeliveriesPaginateWithFilter = function (dateStart, dateEnd, idOperation, idCompagny, sortKey, reverse, pageNo, max, searchText, deliveryState, orderNumber, callback) {
    	    var config = {
    	        method: 'GET',
                params: {
                    dateStart: dateStart,
                    dateEnd: dateEnd,
                    idOperation: idOperation,
                    idCompagny: idCompagny,
    	            sortKey: sortKey,
    	            reverse: reverse,
    	            pageNo: pageNo,
    	            itemPerPage: max,
                    searchText: searchText,
                    deliveryState: deliveryState,
                    orderNumber: orderNumber
    	        },
    	        url: configurationFactory.Url['Api'] + 'Delivery/GetDeliveriesPaginateWithFilter',
    	    };
    	    SendRequest(config, callback);
    	};

        this.GetOperationDeliveries = function (dateStart, dateEnd, idCompagny, callback) {
    	    var config = {
    	        method: 'GET',
                params: {
                    dateStart: dateStart,
                    dateEnd: dateEnd,
                    idCompagny: idCompagny
    	        },
    	        url: configurationFactory.Url['Api'] + 'Delivery/GetOperationDeliveries'
    	    };
    	    SendRequest(config, callback);
        };

        this.GetDeliveriesXls = function (dateStart, dateEnd, idOperation, idUser, idCompagny, idLanguage) {
            return configurationFactory.Url['Api'] + 'Delivery/GetDeliveriesXls/' +
                '?dateStart=' + dateStart +
                '&dateEnd=' + dateEnd +
                '&idOperation=' + idOperation +
                '&idUser=' + idUser +
                '&idCompagny=' + idCompagny +
                '&idLanguage=' + idLanguage;
        };

        this.GetFiltersByDates = function (dateStart, dateEnd, callback) {
            var config = {
                method: 'GET',
                params: {
                    dateStart: dateStart,
                    dateEnd: dateEnd
                },
                url: configurationFactory.Url['Api'] + 'Delivery/GetFiltersByDates'
            };
            SendRequest(config, callback);
        };
    }]);