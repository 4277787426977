AgrimarketServices.service('contractsOptionService', ['$http', 'configurationFactory', 'enumFactory',
    function ($http, configurationFactory) {

        this.GetDataForSubscribeOption = function (idExpiry, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'ContractsOption/GetDataForSubscribeOption?idExpiry='+idExpiry                
            };
            sendRequest(config, callback);
        };
        

        this.SaveNewContractOption = function (contractOptionVm, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'ContractsOption/SaveNewContractOption',
                cache: false,
                crossDomain: true,
                data: contractOptionVm
            };
            sendRequest(config, callback);
        };

        this.SaveNewContractOptionAndSendSmsYouSign = function (contractOptionVm, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'ContractsOption/SaveNewContractOptionAndSendSmsYouSign',
                cache: false,
                crossDomain: true,
                data: contractOptionVm
            };
            sendRequest(config, callback);
        };

        var sendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null);
                })
                .error(function (data, status) {
                    callback(data, status);
                });
        };

        this.GetFiltersWithContractsOptionByCrop = function (idCrop, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'ContractOption/GetFiltersWithContractsOptionByCrop',
                params: { idCrop: idCrop }
            };

            sendRequest(config, callback);
        };

        this.GetMonitoringContractOptionExpiredPaginate = function (idCrop, idProduct, idTypeOption, date, date2, date3, idContractStr, numClient, idCompagny, itemPerPage, pageNo, sortKey, reverse, callback){
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'ContractOption/GetMonitoringContractOptionExpiredPaginate',
                params: {
                    idCrop: idCrop,
                    idProduct: idProduct,
                    idTypeOption: idTypeOption,
                    dateCreate: date,
                    dateExerced: date2,
                    dateAbandoned: date3,
                    idContractStr: idContractStr,
                    numClient: numClient,
                    idCompagny: idCompagny,
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };
            sendRequest(config, callback);
        };

        this.GetMonitoringContractOptionPendingPaginate = function (idCrop, idProduct, idTypeOption, date, date2, date3, idContractStr, numClient, idCompagny, itemPerPage, pageNo, sortKey, reverse, callback){
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'ContractOption/GetMonitoringContractOptionPendingPaginate',
                params: {
                    idCrop: idCrop,
                    idProduct: idProduct,
                    idTypeOption: idTypeOption,
                    dateCreate: date,
                    dateExerced: date2,
                    dateAbandoned: date3,
                    idContractStr: idContractStr,
                    numClient: numClient,
                    idCompagny: idCompagny,
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };
            sendRequest(config, callback);
        };

        this.GetGrossGainByContractOptionId = function (idContractOption, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'ContractOption/GetGrossGainByContractOptionId',
                params: { idContractOption: idContractOption }
            };

            sendRequest(config, callback);
        };

        this.GetContractOptionDetails = function (idContractOption, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'ContractOption/GetContractOptionDetails',
                params: { idContractOption: idContractOption }
            };
            sendRequest(config, callback);
        };
    }]);