AgrimarketControllers.controller('resetPasswordController', ['$scope', '$routeParams', 'configurationFactory', '$rootScope', 'authenticationService',
    function ($scope, $routeParams, configurationFactory, $rootScope, authenticationService) {

        $rootScope.currentUserRole = '';
        $scope.errorResetPasswordMessage = '';
        $scope.resetPasswordMessage = '';

        if ($routeParams.keyPassword) {

            authenticationService.GetEditableObjectForResetPassword($routeParams.keyPassword, function (data, status) {
                if (status !== null) {

                    if (status === 400)
                        $scope.errorResetPasswordMessage = data[0].errors[0].Message;
                } else {
                    $rootScope.editableObjectsFactory = data;

                    if (!$routeParams.title) {
                        $scope.title = $rootScope.editableObjectsFactory.EditableObjects.ResetPassword_Title.Content;
                    }
                    else
                        $scope.title = $routeParams.title;
                }
            });
        }

        $rootScope.skin = configurationFactory.Skin;


        $scope.ResetPassword = function () {
            if ($scope.password === undefined || $scope.password === null || $scope.confirmPassword === undefined || $scope.confirmPassword === null) {
                $scope.errorResetPasswordMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_ResetPassword_AllFieldsNotFilled.Content;
                return;
            }
            if ($scope.password.length < 8) {
                $scope.errorResetPasswordMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_ResetPassword_PasswordLength.Content;
                return;
            }

            if ($scope.password !== $scope.confirmPassword) {
                $scope.errorResetPasswordMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_ResetPassword_PasswordNotMatch.Content;
                return;
            }

            authenticationService.ResetPassword($routeParams.keyPassword, $scope.password, function (data, status) {
                if (status !== null) {

                    if (status === 400)
                        $scope.errorResetPasswordMessage = data[0].errors[0].Message;
                } else {
                    $scope.resetPasswordMessage = $rootScope.editableObjectsFactory.EditableObjects.ResetPassword_PasswordChanged.Content;
                }

                $scope.password = null;
                $scope.confirmPassword = null;
            });
        };
    }]);