/*
*   Directive de gestion du dragAndDrop ainsi que de la gestion des dimension des bloc
*	2 parametres sont Obligatoire :
*	- sortable-element : defini le parent qui contient les elements draggable
*	- start : Objet qui doit changer d'etre remplis pour lancer la directive (watcher)
*	- child-element-html : defini les element Html qui peuvent etre manipulés
*
*   2 parametre sont facultatif:
*   -connect-With: parametrage pour le plugin sortable
*   -handle: parametre pour le plugin sortable
*	-useChildSortable: permet de dire si des objet fils contiennent aussi des elements draggable
*	-onchangeposition : nom de la fonction a lancer a chaque dragAndDrop ou modification de taille
*	-sortable-child-element : quand on est le parent permet de dire qui sont les elements fils qui possedent des elements draggable
*	-sortable-parent-element : quand on est un fils permet de dire qui est le pere
*	-place-holder : a renseigneé si un place holder est utilisé
*	-click-size-element : objet html des fils qui doit etre utilisé pour le changement de taille de bloc
*/


AgrimarketDirectives.directive('sortableElement', function () {


	return function (scope, element, attrs) {
		var posArray = {};
		var handle = "";
		var connectWith = "";
		var tableSizes = [];
		var menuArray = [];
		var canUpdate = false; //méthode pour éviter qu'il passe 2 fois dans l'update...

		function returnOrder(nested) {
			var sortable;
			menuArray = [];
			if (attrs.sortableParentElement !== undefined && attrs.sortableParentElement !== null)
				sortable = attrs.sortableParentElement;
			else
				sortable = attrs.sortableElement;

			$(sortable + '>' + attrs.childElementHtml).not(attrs.placeHolder).each(function () {
				var idElemString = $(this).attr('id');
				var classElem = idElemString.split("_")[0];
				var idElem = idElemString.split(classElem + "_")[1];

				var nbChildren = 0;
				var child = "";
				if (nested === true) {

					if (attrs.sortableChildElement !== undefined && attrs.sortableChildElement !== null)
						child = attrs.sortableChildElement;
					else
						child = attrs.sortableElement;

					nbChildren = $(this).find(child + '>' + attrs.childElementHtml).length;
				}

				var obj = '{"' + classElem + 'Id":"' + idElem + '"';

				if (tableSizes[idElemString] !== undefined && tableSizes[idElemString] !== null)
					obj += ',"size":"' + tableSizes[idElemString] + '"';

				if (nbChildren > 0 && nested === true) {
					obj += ',"children":[';
					$(this).find(child + '>' + attrs.childElementHtml).each(function () {
						var idSsElemString = $(this).attr('id');
						if (idSsElemString !== undefined && idSsElemString !== null) {
							var classSsElem = idSsElemString.split("_")[0];
							var idSsElem = idSsElemString.split(classSsElem + "_")[1];

							obj += '{"' + classSsElem + 'Id":"' + idSsElem + '"';

							if (tableSizes[idSsElemString] !== undefined && tableSizes[idSsElemString] !== null)
								obj += ',"size":"' + tableSizes[idSsElemString] + '"';

							if ($(this).is(':last-child'))
								obj += '}';
							else
								obj += '},';
						}
					});
					obj += ']';
				}

				obj += '}';
				menuArray.push(obj);

			});

		}

		var InitSwitchElement = function () {
			if (attrs.connectWith !== undefined && attrs.connectWith !== null && attrs.connectWith !== "") {
				connectWith = attrs.connectWith;
			}

			if (attrs.handle !== undefined && attrs.handle !== null && attrs.handle !== "") {
				handle = attrs.handle;
			}
			//DragAndDrop
			$(attrs.sortableElement).sortable({
				connectWith: connectWith,
				handle: handle,
				start: function (event, ui) {					
					if (attrs.clickSizeElement !== undefined && attrs.clickSizeElement !== null) {
						$(attrs.sortableElement + ' ' + attrs.childElementHtml).each(function () {
							var itemSize = parseInt($(this).attr('data-size'));
							var id = $(this).attr('id');
							if (itemSize === 0)
								tableSizes[id] = 0;
							else
								tableSizes[id] = 1;
						});
					}
					returnOrder(attrs.useChildSortable == "true" ? true : false);
					posArray.Start = menuArray;
					posArray.MovedId = ui.item.attr('id');
					posArray.MovedParentIndexStart = ui.item.parents('li').prevAll('li').length;
					posArray.MovedIndexStart = ui.item.prevAll('li').length;
					canUpdate = true;
				},
				update: function (event, ui) {
					if (canUpdate) {
						returnOrder(attrs.useChildSortable == "true" ? true : false);
						posArray.End = menuArray;
						posArray.MovedParentIdEnd = ui.item.parents('li').attr('id');
						posArray.MovedIndexEnd = ui.item.prevAll('li').length;
						posArray.MovedParentIndexEnd = ui.item.parents('li').prevAll('li').length;

						if (attrs.onchangeposition !== undefined && attrs.onchangeposition !== null && attrs.onchangeposition !== "") {
							scope[attrs.onchangeposition](posArray);
						}
						canUpdate = false;
					}
				},
			});
			//Resize
			if (attrs.clickSizeElement !== undefined && attrs.clickSizeElement !== null) {
				$(attrs.sortableElement + ' ' + attrs.childElementHtml + ' ' + attrs.clickSizeElement).click(function () {
					$(attrs.sortableElement + ' ' + attrs.childElementHtml).each(function () {
						var itemSize = parseInt($(this).attr('data-size'));
						var id = $(this).attr('id');
						if (itemSize === 0)
							tableSizes[id] = 0;
						else
							tableSizes[id] = 1;
					});
					returnOrder(attrs.useChildSortable == "true" ? true : false);
					posArray.Start = menuArray;
					var $parent = $(this).parents(attrs.childElementHtml);
					var id = $parent.attr('id');
					var itemSize = parseInt($parent.attr('data-size'));

					if (itemSize === 0) {
						$(this).addClass('ico-resize-min').removeClass('ico-resize');
						$parent
							.removeClass('cols_4').addClass('cols_8')
							.attr('data-size', 1);
					} else {
						$(this).addClass('ico-resize').removeClass('ico-resize-min');
						$parent
							.removeClass('cols_8').addClass('cols_4')
							.attr('data-size', 0);
					}

					itemSize = parseInt($parent.attr('data-size'));

					// On met à jour la data-size dans le tableau
					tableSizes[id] = itemSize;

					returnOrder(attrs.useChildSortable == "true" ? true : false);
					posArray.End = menuArray;

					if (attrs.onchangeposition !== undefined && attrs.onchangeposition !== null && attrs.onchangeposition !== "")
						scope[attrs.onchangeposition](posArray);
				});
			}
		};

		scope.$watchCollection(attrs.start, function (newValue) {
			if (newValue === undefined || newValue === null) return;
			InitSwitchElement();
		});
	};
});