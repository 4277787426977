AgrimarketControllers.controller('billsController', [
    '$scope', '$rootScope', '$filter', '$location', 'colonnes', 'billsService', 'configurationFactory', 'parametersFactory', 'currentUserFactory', 'enumFactory',
    function ($scope, $rootScope, $filter, $location, colonnes, billsService, configurationFactory, parametersFactory, currentUserFactory, enumFactory) {

        if (!$rootScope.ModuleMyAccount.Active)
            $location.path('/');


        
    }
]);