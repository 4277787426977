AgrimarketServices.service('currencyService', ['$http', 'configurationFactory',
    function ( $http, configurationFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null);
                })
                .error(function (data, status) {
                    callback(data, status);
                });
        };


        this.GetAllCurrencies = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Currency/GetAllCurrencies'
            };
            SendRequest(config, callback);
        };

    }]);