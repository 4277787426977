AgrimarketServices.service('communicationMarketInfoService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null);
                })
                .error(function (data, status) {
                    callback(data, status);
                });
        };

        this.GetAllCommunicationMarketInfoPaginate = function (idCommunicationMarketInfoSelected, searchLabelCommunicationMarketInfotring, itemPerPage, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'CommunicationMarketInfo/GetAllCommunicationMarketInfoPaginate',
                params: {
                    idCommunicationMarketInfoSelected: idCommunicationMarketInfoSelected,
                    searchLabelCommunicationMarketInfotring: searchLabelCommunicationMarketInfotring,
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };
            SendRequest(config, callback);
        };

        this.GetItemsCommunicationMarketInfoByAutoComplete = function (searchString, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'CommunicationMarketInfo/GetItemsCommunicationMarketInfoByAutoComplete',
                params: { searchString: searchString }
            };
            SendRequest(config, callback);
        };

        this.GetById = function (id, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'CommunicationMarketInfo/GetById',
                params: { id: id }
            };
            SendRequest(config, callback);
        };

        this.Save = function (comMarketInfo, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'CommunicationMarketInfo/Save',
                data: comMarketInfo
            };
            SendRequest(config, callback);
        };

        this.DeleteCommunicationMarketInfo = function (idCommunicationMarketInfo, idCommunicationMarketInfoSelected, searchLabelCommunicationMarketInfotring, itemPerPage, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'DELETE',
                url: configurationFactory.Url['Api'] + 'CommunicationMarketInfo/DeleteCommunicationMarketInfo',
                params: {
                    idCommunicationMarketInfo: idCommunicationMarketInfo,
                    idCommunicationMarketInfoSelected: idCommunicationMarketInfoSelected,
                    searchLabelCommunicationMarketInfotring: searchLabelCommunicationMarketInfotring,
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };

            SendRequest(config, callback);
        };
}]);