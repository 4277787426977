AgrimarketControllers.controller('portfolioController', [
    '$scope', '$rootScope', '$filter', '$sce', 'portfolioService', '$routeParams', 'contractsColumns', 'ordersColumns', 'wsdApiService',
    'typeContractsService', 'productsService', 'ceremisApiService', 'coverService', 'enumFactory', 'athenaOrdersService',
    function ($scope, $rootScope, $filter, $sce, portfolioService, $routeParams, contractsColumns, ordersColumns, wsdApiService,
        typeContractsService, productsService, ceremisApiService, coverService, enumFactory, athenaOrdersService) {

        $scope.markets = [];
        $scope.expiries = [];
        $scope.enumOrderTypes = enumFactory.AthenaOrderTypes;
        $scope.enumOrderStates = enumFactory.AthenaOrderStates;
        $scope.viewMode = $routeParams.typeView;
        $scope.IsUneal = $rootScope.paramNameCoop === enumFactory.Coops.Uneal;
        $scope.init = function () {

            wsdApiService.GetMarketsWSDToEuronext(function (data, status) {
                $scope.markets = data;
            });

            $scope.filters = {
                startDate: "",
                endDate: "",
                reverse: false,
                page: 1,
                itemsPerPage: 10
            };
            $scope.$watch("filters", function (newValue, oldValue) {
                if (oldValue.idMarket !== newValue.idMarket) {
                    $scope.filters.idExpiry = undefined;
                    if (newValue.idMarket !== undefined) {
                        wsdApiService.GetExpiriesWSDByIdMarket(newValue.idMarket, function (data, status) {
                            $scope.expiries = data.Expiries;
                        });
                    }
                }
                if ($scope.HandleFiltersChange !== undefined)
                    $scope.HandleFiltersChange(newValue, oldValue);
                if ((newValue && oldValue !== newValue) || (!newValue && oldValue)) {
                    $scope.fetch();
                }
            }, true);

            switch ($scope.viewMode) {
                case "contracts":
                    $scope.contractsKeys = contractsColumns.data.DataVisible.split(',');
                    $scope.contractsColumns = contractsColumns.data.ColumnName.split(',');
                    $scope.varieties = [{ Label: 'Aucune', IdVariety: undefined }];

                    $scope.HandleFiltersChange = function (newValue, oldValue) {
                        if (oldValue.idProduct !== newValue.idProduct) {
                            $scope.filters.idVariety = undefined;
                            if (newValue.idProduct !== undefined) {
                                var selectedProduct = $rootScope.products.find(function (p) {
                                    return p.IdProduct === newValue.idProduct;
                                });
                                $scope.varieties = $scope.varities = [{ Label: 'Aucune', IdVariety: undefined }];
                                selectedProduct.Varieties.forEach(function (x) { $scope.varieties.push(x); });
                            }
                            else {
                                $scope.varieties = [{ Label: 'Aucune', IdVariety: undefined }];
                            }
                        }
                    };

                    $scope.fetch = $scope.getContracts;

                    typeContractsService.GetTypeContracts(function (data) {
                        $scope.contractTypes = [
                            { Label: 'Aucun', IdTypeContract: undefined }
                        ];
                        data.forEach(function (x) { $scope.contractTypes.push(x); });
                    });

                    productsService.GetAllProducts(function (data) {
                        $scope.products = [
                            { Label: 'Aucun', IdProduct: undefined }
                        ];
                        data.forEach(function (x) { $scope.products.push(x); });
                    });

                    portfolioService.GetContractStatuses(function (data) {
                        $scope.contractStatuses = [
                            { Label: 'Aucun', IdStatus: undefined }
                        ];
                        data.forEach(function (x) { $scope.contractStatuses.push(x); });
                    });

                    $scope.showContractDetails = function (contract, show) {
                        $scope.contractDetailsTitle = "Ordres couvrant le contrat " + contract.IdContract;
                        $scope.orders = contract.Orders;
                        show();
                    };

                    break;
                case "orders":
                    // Orders
                    $scope.ordersKeys = ordersColumns.data.DataVisible.split(',');
                    $scope.ordersColumns = ordersColumns.data.ColumnName.split(',');

                    $scope.orderTypes = [
                        enumFactory.AthenaOrderTypes.Market,
                        enumFactory.AthenaOrderTypes.Limit
                    ];

                    portfolioService.GetOrderStatuses(function (data, status) {
                        $scope.orderStatuses = [{ Label: 'Aucun', IdStatus: undefined }];
                        data.forEach(function (x) { $scope.orderStatuses.push(x); });
                    });

                    $scope.directions = [
                        { label: 'Vente', id: 'v' },
                        { label: 'Achat', id: 'a' }
                    ];

                    $scope.origins = [
                        { label: 'Aucun', id: undefined },
                        { label: 'Couverture de contrat', id: 'Couverture de contrat' },
                        { label: 'Ordre seul', id: 'Ordre seul' }
                    ];

                    $scope.fetch = $scope.getOrders;

                    $scope.showOrderDetails = function (order, show) {
                        $scope.orderDetailsTitle = "Contrats couverts par l'ordre " + order.IdOrder;
                        $scope.contracts = order.Contracts;
                        $scope.selectedOrder = order;
                        show();
                    };

                    break;
            }
            $scope.fetch();
        };

        $scope.GetData = function (page) {
            $scope.filters.page = page;
        };

        $scope.Sort = function (sortKey, reverse) {
            $scope.filters.sortKey = sortKey;
            $scope.filters.reverse = reverse;
        };
        $scope.getContracts = function () {
            var params = {};
            Object.assign(params, $scope.filters);
            params.startDate = toDate($scope.filters.startDate);
            params.endDate = toDate($scope.filters.endDate);
            portfolioService.GetContracts(params, function (data, status) {
                $scope.contracts = angular.copy(data.Page);
                $scope.contractsTotal = data.Total;
            });
        };

        $scope.getOrders = function () {
            var params = {};
            Object.assign(params, $scope.filters);
            params.startDate = toDate($scope.filters.startDate);
            params.endDate = toDate($scope.filters.endDate);
            portfolioService.GetOrders(params, function (data, status) {
                $scope.orders = angular.copy(data.Page);
                $scope.ordersTotal = data.Total;
            });
        };

        $scope.OpenPopUpOrder = function (openPopup) {
            $scope.openPopupOrder = openPopup;
            $scope.orderPopUp = {};
            $scope.popupOrderErrorMessage = "";
            $scope.modalTitle = $rootScope.editableObjectsFactory.EditableObjects.ModalTitleAddOrder.Content;
            $scope.marketsPopUp = $scope.markets;

            if ($scope.IsUneal)
                filterMarketsWsdByOffersCeremis();

            getAccounts();
            openPopup();
        };

        $scope.openPopUpOrderRestart = function (order, openPopup) {
            $scope.modalTitle = $rootScope.editableObjectsFactory.EditableObjects.ModalTitleRestartOrder.Content + " " + order.IdOrder;
            $scope.openPopupOrder = openPopup;
            $scope.orderPopUp = {};
            $scope.loadPopUpOrder(order);
            $scope.popupOrderErrorMessage = "";
            $scope.marketsPopUp = $scope.markets;
            $scope.orderPopUp.IdOfferBuiltPrice = order.IdOfferBuiltPrice;

            if ($scope.IsUneal)
                filterMarketsWsdByOffersCeremis();

            getAccounts();
            openPopup();
        };

        $scope.loadPopUpOrder = function (order) {
            $scope.orderPopUp.IdOrder = order.IdOrder;
            $scope.orderPopUp.IdExt = order.IdExt;
            $scope.orderPopUp.IdMarketWsd = order.IdMarket;
            $scope.GetExpiriesForPopUp(order.IdMarket);
            $scope.orderPopUp.IdExpiryWsd = order.IdExpiry;
            $scope.orderPopUp.IdAccount = order.IdAccount;
            $scope.orderPopUp.IdOrderType = order.OrderTypeId;
            $scope.orderPopUp.Price = order.TargetPrice;
            $scope.orderPopUp.EndDate = order.ExpireDate;
            $scope.orderPopUp.Direction = order.DirectionId;
            $scope.orderPopUp.Qty = order.lotNumber;
        };

        $scope.GetExpiriesForPopUp = function (idMarket) {
            if (idMarket) {
                wsdApiService.GetExpiriesWSDByIdMarket(idMarket, function (data, status) {
                    if (status !== null) {
                        if (status == 400)
                            $scope.popupOrderErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        $scope.expiriesPopUp = data.Expiries;

                        if ($scope.IsUneal) {
                            $scope.lstExpiryTmp = [];
                            $.each($scope.expiriesPopUp, function (index, item) {
                                if ($scope.lstOffersCeremis.indexOf($filter('filter')($scope.lstOffersCeremis, { "IdExpiryWSD": Number(item.IdExpiry) }, true)[0]) !== -1) {
                                    $scope.lstExpiryTmp.push(item);
                                }
                            });
                            $scope.expiriesPopUp = $scope.lstExpiryTmp;
                        }
                    }
                });
            }
            else
                $scope.expiriesPopUp = [];
        };

        $scope.SavePopupOrder = function () {
            $scope.orderPopUp.EndDate = toDate($scope.orderPopUp.EndDate);
            athenaOrdersService.PostAthenaOrderVm($scope.orderPopUp, function (data, status) {
                if (status !== null) {
                    if (status == 400) {
                        $scope.popupOrderErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                        $scope.openPopupOrder();
                    }
                }
                else
                    $scope.init();
            });
        };

        function filterMarketsWsdByOffersCeremis() {
            ceremisApiService.GetOffersCeremis("", function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.popupOrderErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.lstOffersCeremis = data;
                    $scope.lstmarketTmp = [];
                    $.each($scope.marketsPopUp, function (index, item) {
                        if ($scope.lstOffersCeremis.indexOf($filter('filter')($scope.lstOffersCeremis, { "IdMarketWSD": Number(item.IdMarket) }, true)[0]) !== -1) {
                            $scope.lstmarketTmp.push(item);
                        }
                    });
                    $scope.marketsPopUp = $scope.lstmarketTmp;
                }
            });
        }

        function getAccounts() {
            coverService.GetAllAccounts(function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.popupOrderErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else
                    $scope.lstAccounts = data;
            });
        }

        $scope.$watch("orderPopUp.Qty", function (newValue, oldValue) {
            if (newValue && !isNaN(newValue))
                $scope.textConversionTon = $rootScope.editableObjectsFactory.EditableObjects.Admin_Routing_Orders_TextConversionTon.Content.replace("[qty]", newValue * 50);
            else
                $scope.textConversionTon = "";
        }, true);

        function toDate(date) {
            if (!date) return undefined;
            var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
            var dt = new Date(date.replace(pattern, '$3-$2-$1'));
            var currentDate = new Date(dt.toString());
            return moment(currentDate).format('YYYY/MM/DD');
        }

        $scope.init();

        $rootScope.onRefresh = function () {
            $scope.init();
        };
    }]);