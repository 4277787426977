AgrimarketServices.service('quoteCashHashtagriService', ['$rootScope', '$http', '$location', 'configurationFactory',
    function ($rootScope, $http, $location, configurationFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
			    .success(function (data) {
			        callback(data, null, null, null);
			    })
			    .error(function (data, status, headers, config) {
			        callback(data, status, headers, config);
			    });
        };

        this.SaveCashMarkets = function (collection, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'QuoteCashHashtagri/SaveCashMarkets',
                data: collection
            };
            SendRequest(config, callback);
        };

        this.GetCashMarketsSelected = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'QuoteCashHashtagri/GetCashMarketsSelected'
            };
            SendRequest(config, callback);
        };

        this.GetCashMarketsFromWsdApi = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'QuoteCashHashtagri/GetCashMarketsFromWsdApi'
            };
            SendRequest(config, callback);
        };


        this.GetHistoCashForGraphByIdExpiryFromWsdApi = function (idExpiry, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'QuoteCashHashtagri/GetHistoCashForGraphByIdExpiryFromWsdApi',
                params: {
                    idExpiry: idExpiry
                }
            };
            SendRequest(config, callback);
        };
    }]);