AgrimarketControllers.controller('contractsBaseMonitoringController', ['$scope', '$rootScope', '$routeParams', '$http', '$filter', '$location', "currentUserFactory", 'configurationFactory',
    'contractBaseService', 'cropsService', 'athenaOrdersService', '$log', '$timeout', '$sce', 'parametersFactory', 'enumFactory', 'userTableService', 'colonnesContractsBaseValidated', 'colonnesContractsBasePending', 'colonnesContractsBasePriced','colonnesContractsBaseCanceled',
    function ($scope, $rootScope, $routeParams, $http, $filter, $location, currentUserFactory, configurationFactory, contractBaseService, cropsService, athenaOrdersService, $log, $timeout, $sce,
        parametersFactory, enumFactory, userTableService, colonnesContractsBaseValidated, colonnesContractsBasePending, colonnesContractsBasePriced, colonnesContractsBaseCanceled) {


        var init = function () {            

            // Contracts Base validé
            $scope.tableContractsBaseValidatedDataVisible = colonnesContractsBaseValidated.data.DataVisible.split(',');
            $scope.tableContractsBaseValidatedLibelle = colonnesContractsBaseValidated.data.ColumnName.split(',');

            $scope.tableContractsBasePendingDataVisible = colonnesContractsBasePending.data.DataVisible.split(',');
            $scope.tableContractsBasePendingLibelle = colonnesContractsBasePending.data.ColumnName.split(',');

            $scope.tableContractsBasePricedDataVisible = colonnesContractsBasePriced.data.DataVisible.split(',');
            $scope.tableContractsBasePricedLibelle = colonnesContractsBasePriced.data.ColumnName.split(',');

            $scope.tableContractsBaseCanceledDataVisible = colonnesContractsBaseCanceled.data.DataVisible.split(',');
            $scope.tableContractsBaseCanceledLibelle = colonnesContractsBaseCanceled.data.ColumnName.split(',');

            $scope.viewMode = $routeParams.typeContract;
            $scope.max = 10;

            $scope.validatedPageNo = 1;            
            $scope.pendingPageNo = 1;  
            $scope.pricedPageNo = 1;  
            $scope.canceledPageNo = 1;  
            
            $scope.dataValidated = { reverse: true, sortKey: "IdContractBase" };                                    
            $scope.dataPending = { reverse: true, sortKey: "IdContractBase" };   
            $scope.dataPriced = { reverse: true, sortKey: "IdContractBase" };   
            $scope.dataCanceled = { reverse: true, sortKey: "IdContractBase" };  
            
            $scope.idCropSelected = Number(parametersFactory.Parameters.DefaultCrop.Value);
            $scope.idProductSelected = "";
            $scope.dateFilter = "";
            $scope.idCompagnySelected = "";            
            $scope.dateSelected = "";
            $scope.selectedClient = {};                                    

            var refreshUsers = null; // On crée un timer car si on tape trop vite dans le champ de recherche le listing ne correspond pas au nom recherché
            $scope.$watch("selectedIdClient", function (newValue, oldValue) {
                if (newValue && oldValue != newValue) {
                    if (newValue.length >= 3 || newValue.length < oldValue.length) {
                        if (refreshUsers === null)
                            refreshUsers = setTimeout(function () { $scope.GetData(); }, 800);
                        else {
                            clearTimeout(refreshUsers);
                            refreshUsers = setTimeout(function () { $scope.GetData(); }, 800);
                        }
                    }
                }
            });

            $scope.$watch("dateSelected", function (newValue) {
                if (newValue === "")
                    $scope.dateFilter = "";
                else if (newValue) {
                    //permet de gérer l'internalisation de tout les formats des dates possibles.
                    //Au final nous faisons en sorte de la retrouvé au format yyyy/mm/dd pour l'envoyer à l'API.
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    var dt = new Date(newValue.replace(pattern, '$3-$2-$1'));
                    var currentDate = new Date(dt.toString());
                    var converDate = moment(currentDate);
                    $scope.dateFilter = converDate.format('YYYY/MM/DD');
                }
            });

            $scope.$watch("dateFilter", function (newValue, oldValue) {
                if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                    $scope.GetData();
            });
            
            GetCropsWithContracts();            
        };


        //Initialisation des filtres généraux
        var GetCropsWithContracts = function () {
            cropsService.GetCropsWithContractsBase(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    if (data) {
                        $scope.crops = data;
                        var defaultCropInCrops = $filter('filter')($scope.crops, { IdCrop: $scope.idCropSelected })[0] !== undefined;
                        if (!defaultCropInCrops && $scope.crops.length > 0)
                            $scope.idCropSelected = $scope.crops[0].IdCrop;
                        $scope.GetFiltersWithContracts();
                    }
                }
            });
        };

        $scope.GetFiltersWithContracts = function () {
            $scope.loading = true;
            $scope.idProductSelected = "";            
            $scope.idCompagnySelected = "";
            $scope.selectedIdContract = "";
            $scope.dateSelected = "";
            $scope.selectedIdClient = "";   
            contractBaseService.GetFiltersWithContractsBaseByCrop($scope.idCropSelected, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    if (data) {
                        $scope.lstProducts = data.LstProductFilterVm;                        
                        if ($scope.lstProducts.length === 1)
                            $scope.idProductSelected = $scope.lstProducts[0].IdProduct;
                        else if ($scope.lstProducts.length > 1 && $filter('filter')($scope.lstProducts, { IdProduct: "", ProductLabel: "Tous" }).length === 0)
                            $scope.lstProducts.unshift({ IdProduct: "", ProductLabel: "Tous" }); 

                        $scope.lstCompagnies = data.LstCompagnies;                        
                        $scope.lstCompagnies.unshift({ IdCompagny: "", Label: "Toutes" });                                                                                               
                    }
                }
            });
            $scope.GetData();
        };             
          
        $scope.GetData = function () {
            if (!$scope.selectedIdContract) $scope.selectedIdContract = "";
            if (!$scope.selectedIdClient) $scope.selectedIdClient = "";

            if ($scope.viewMode === "validated") {
                $scope.GetContractsBaseValidateWithFilter(1);
            }
            else if ($scope.viewMode === "pending") {
                $scope.GetContractsBasePendingWithFilter(1);
            }
            else if ($scope.viewMode === "priced") {                
                $scope.GetContractsBasePricedWithFilter(1);
            }
            else if ($scope.viewMode === "canceled") {                
                $scope.GetContractsBaseCancelWithFilter(1);
            }                        
        };        
        
        $scope.GetContractsBaseValidateWithFilter = function (pagenum) {
            $scope.loading = true;
            $scope.errorMessageContractBaseValidated = "";            
            contractBaseService.GetContractsBasePaginate(enumFactory.ContractState.Valide, $scope.idCropSelected, $scope.idProductSelected, $scope.dateFilter, $scope.selectedIdContract, $scope.selectedIdClient, $scope.idCompagnySelected, '', $scope.max, pagenum, $scope.dataValidated.sortKey, $scope.dataValidated.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessageContractBaseValidated = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.totalValidated = data.Total;
                    $scope.ContractsBasesValidated = data.ListVm;
                    $scope.loading = false;
                }
            });
        };    

        $scope.GetContractsBasePendingWithFilter = function (pagenum) {
            $scope.loading = true;
            $scope.errorMessageContractBasePending = "";
            contractBaseService.GetContractsBasePaginate(enumFactory.ContractState.AttenteDernierComposant, $scope.idCropSelected, $scope.idProductSelected, $scope.dateFilter, $scope.selectedIdContract, $scope.selectedIdClient, $scope.idCompagnySelected, '', $scope.max, pagenum, $scope.dataValidated.sortKey, $scope.dataValidated.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessageContractBasePending = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.totalPending = data.Total;
                    $scope.ContractsBasesPending = data.ListVm;
                    $scope.loading = false;
                }
            });
        }; 

        $scope.GetContractsBasePricedWithFilter = function (pagenum) {
            $scope.loading = true;
            $scope.errorMessageContractBasePriced = "";
            if (!$scope.selectedIdContract) $scope.selectedIdContract = "";
            if (!$scope.selectedIdClient) $scope.selectedIdClient = "";
            contractBaseService.GetContractsBasePaginate(enumFactory.ContractState.AttenteValidation, $scope.idCropSelected, $scope.idProductSelected, $scope.dateFilter, $scope.selectedIdContract, $scope.selectedIdClient, $scope.idCompagnySelected, '', $scope.max, pagenum, $scope.dataValidated.sortKey, $scope.dataValidated.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessageContractBasePriced = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.totalPriced = data.Total;
                    $scope.ContractsBasesPriced = data.ListVm;
                    $scope.loading = false;
                }
            });
        }; 

        $scope.GetContractsBaseCancelWithFilter = function (pagenum) {
            $scope.loading = true;
            $scope.errorMessageContractBaseCanceld = "";
            contractBaseService.GetContractsBasePaginate(enumFactory.ContractState.Annulé, $scope.idCropSelected, $scope.idProductSelected, $scope.dateFilter, $scope.selectedIdContract, $scope.selectedIdClient, $scope.idCompagnySelected, '', $scope.max, pagenum, $scope.dataValidated.sortKey, $scope.dataValidated.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessageContractBaseCanceld = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.totalCanceled = data.Total;
                    $scope.ContractsBasesCanceled = data.ListVm;
                    $scope.loading = false;
                }
            });
        };            

        $scope.Sort = function (sortKey, reverse) {
            $scope.dataValidated.sortKey = sortKey;
            $scope.dataValidated.reverse = reverse;
            $scope.GetData();
        };

        $scope.CancelContractPopUp = function (contractSelected, index, showPopUpContract) {
            $scope.PopUpMessageCancelContract = "";
            $scope.MessageDeleteContractBaseTargetPrice = $rootScope.editableObjectsFactory.EditableObjects.MessageDeleteContractBaseTargetPrice.Content;
            $scope.CanceledContract = $rootScope.editableObjectsFactory.EditableObjects.CanceledContract.Content;

            $scope.PopupCanceledContractsSelectedTitle = $scope.CanceledContract + contractSelected.IdContractBase;
            if (contractSelected !== undefined)
                $scope.PopUpMessageCancelContract = $scope.MessageDeleteContractBaseTargetPrice;

            showPopUpContract(contractSelected);
        };

        $scope.CancelContract = function (element) {
            $scope.CancelContractBaseSuccessMessage = $rootScope.editableObjectsFactory.EditableObjects.Success_AdminContractBasesMonitoring_CancelContractsBaseMessage.Content;
            contractBaseService.UpdateContractBase(element, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    else
                        $location.path("/");
                } else {
                    $scope.successMessage = $scope.CancelContractBaseSuccessMessage;
                    GetCropsWithContracts();
                }
            });
        };

        $scope.ExportValidatedContractsBase = function () {
            var downloadPath = configurationFactory.Url['Api'] + 'ContractBase/GetValidatedContractsBaseXls/' +
                '?idCrop=' + $scope.idCropSelected +
                '&idProduct=' + $scope.idProductSelected +
                '&date=' + $scope.dateFilter +
                '&idContractStr=' + $scope.selectedIdContract +
                '&numClient=' + $scope.selectedIdClient +
                '&idCompagny=' + $scope.idCompagnySelected +
                '&idLanguage=' + $rootScope.language.IdLanguage;
            window.open(downloadPath, '_blank', '');
        };

        $scope.ExportPricedContractsBase = function () {
            var downloadPath = configurationFactory.Url['Api'] + 'ContractBase/GetPricedContractsBaseXls/' +
                '?idCrop=' + $scope.idCropSelected +
                '&idProduct=' + $scope.idProductSelected +
                '&date=' + $scope.dateFilter +
                '&idContractStr=' + $scope.selectedIdContract +
                '&numClient=' + $scope.selectedIdClient +
                '&idCompagny=' + $scope.idCompagnySelected +
                '&idLanguage=' + $rootScope.language.IdLanguage +
                '&idTypeFixation=' + $scope.idTypeFixationSelected;
            window.open(downloadPath, '_blank', '');
        };

        $scope.ExportCanceledContractsBase = function () {
            var downloadPath = configurationFactory.Url['Api'] + 'ContractBase/GetCanceledContractsBaseXls/' +
                '?idCrop=' + $scope.idCropSelected +
                '&idProduct=' + $scope.idProductSelected +
                '&date=' + $scope.dateFilter +
                '&idContractStr=' + $scope.selectedIdContract +
                '&numClient=' + $scope.selectedIdClient +
                '&idCompagny=' + $scope.idCompagnySelected +
                '&idLanguage=' + $rootScope.language.IdLanguage;
            window.open(downloadPath, '_blank', '');
        };

        $scope.ExportPendingContractsBase = function () {
            var downloadPath = configurationFactory.Url['Api'] + 'ContractBase/GetPendingContractsBaseXls/' +
                '?idCrop=' + $scope.idCropSelected +
                '&idProduct=' + $scope.idProductSelected +
                '&date=' + $scope.dateFilter +
                '&idContractStr=' + $scope.selectedIdContract +
                '&numClient=' + $scope.selectedIdClient +
                '&idCompagny=' + $scope.idCompagnySelected +
                '&idLanguage=' + $rootScope.language.IdLanguage;
            window.open(downloadPath, '_blank', '');
        };

        init();
    }]);
