AgrimarketControllers.controller('saveNotificationController', ['$scope', '$rootScope', '$routeParams', '$sce', '$filter', 'notificationsService',
    function ($scope, $rootScope, $routeParams, $sce, $filter, notificationsService) {

        $scope.Init = function () {
            $scope.tmpGroupContactsLeft = [];
            $scope.tmpGroupContactsRight = [];
            $scope.showActionButtons = true;
            $scope.tmpTcsLeft = [];
            $scope.tmpTcsRight = [];
            $scope.lstTypeSends = [
                { bSendDelayed: false, Label: $rootScope.editableObjectsFactory.EditableObjects.Admin_Notifications_Create_TypeSendImmediate.Content },
                { bSendDelayed: true, Label: $rootScope.editableObjectsFactory.EditableObjects.Admin_Notifications_Create_TypeSendDelayed.Content}
            ];

            $scope.GetNotification();
        };

        $scope.GetNotification = function () {
            notificationsService.GetNotificationById($routeParams.idNotification, function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.notification = data;
                    $scope.notification.DateSend = $filter('date')($scope.notification.DateSend, 'dd/MM/yyyy');
                }
            });
        };

        $scope.Save = function () {
            $scope.showActionButtons = false;
            if ($scope.notification.DateSend) {
                var tabDateSend = $scope.notification.DateSend.split('/');
                $scope.notification.DateSend = new Date(tabDateSend[2] + "-" + tabDateSend[1] + "-" + tabDateSend[0]).toISOString();
            }

            notificationsService.Save($scope.notification, function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                        $scope.showActionButtons = true;
                } else {
                    $rootScope.goTo('/admin/contents/notifications');
                }
            });
        };

        // Gestion des groupes de contacts
        $scope.toogleAllElementsLeft = function () {
            $scope.allSelectedLeft = !$scope.allSelectedLeft;
            $.each($scope.notification.LstGroupContacts, function (index, item) {
                item.checked = $scope.allSelectedLeft;
            });
        };

        $scope.toogleAllElementsRight = function () {
            $scope.allSelectedRight = !$scope.allSelectedRight;
            $.each($scope.notification.LstGroupContactSelecteds, function (index, item) {
                item.checked = $scope.allSelectedRight;
            });
        };

        $scope.AddToSelectedGroups = function () {
            $scope.allSelectedLeft = false;
            $scope.allSelectedRight = false;

            $.each($scope.notification.LstGroupContacts, function (index, item) {
                if (item.checked) {
                    item.checked = false;
                    $scope.notification.LstGroupContactSelecteds.push(item);
                }
            });

            $.each($scope.notification.LstGroupContactSelecteds, function (index, item) {
                var indexItem = $scope.notification.LstGroupContacts.indexOf(item);
                if (indexItem !== -1)
                    $scope.notification.LstGroupContacts.splice(indexItem, 1);
            });
        };

        $scope.DeleteInSelectedGroups = function () {
            $scope.allSelectedLeft = false;
            $scope.allSelectedRight = false;

            $.each($scope.notification.LstGroupContactSelecteds, function (index, item) {
                if (item.checked) {
                    item.checked = false;
                    $scope.notification.LstGroupContacts.push(item);
                }
            });

            $.each($scope.notification.LstGroupContacts, function (index, item) {
                var indexItem = $scope.notification.LstGroupContactSelecteds.indexOf(item);
                if (indexItem !== -1)
                    $scope.notification.LstGroupContactSelecteds.splice(indexItem, 1);
            });
        };

        // Gestion des techniciens
        $scope.toogleAllElementsLeftTc = function () {
            $scope.allSelectedTcsLeft = !$scope.allSelectedTcsLeft;
            $.each($scope.notification.LstTcs, function (index, item) {
                item.checked = $scope.allSelectedTcsLeft;
            });
        };

        $scope.toogleAllElementsRightTc = function () {
            $scope.allSelectedTcsRight = !$scope.allSelectedTcsRight;
            $.each($scope.notification.LstTcsSelecteds, function (index, item) {
                item.checked = $scope.allSelectedTcsRight;
            });
        };

        $scope.AddToSelectedTcs = function () {
            $scope.allSelectedTcsLeft = false;
            $scope.allSelectedTcsRight = false;

            $.each($scope.notification.LstTcs, function (index, item) {
                if (item.checked) {
                    item.checked = false;
                    $scope.notification.LstTcsSelecteds.push(item);
                }
            });

            $.each($scope.notification.LstTcsSelecteds, function (index, item) {
                var indexItem = $scope.notification.LstTcs.indexOf(item);
                if (indexItem !== -1)
                    $scope.notification.LstTcs.splice(indexItem, 1);
            });
        };

        $scope.DeleteInSelectedTcs = function () {
            $scope.allSelectedTcsLeft = false;
            $scope.allSelectedTcsRight = false;

            $.each($scope.notification.LstTcsSelecteds, function (index, item) {
                if (item.checked) {
                    item.checked = false;
                    $scope.notification.LstTcs.push(item);
                }
            });

            $.each($scope.notification.LstTcs, function (index, item) {
                var indexItem = $scope.notification.LstTcsSelecteds.indexOf(item);
                if (indexItem !== -1)
                    $scope.notification.LstTcsSelecteds.splice(indexItem, 1);
            });
        };

        $scope.Init();
    }]);
