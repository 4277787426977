AgrimarketServices.service('athenaOrdersService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
	function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

		var SendRequest = function (configfile, callback) {
			$http(configfile)
				.success(function (data) {
					callback(data, null);
				})
				.error(function (data, status) {
					callback(data, status);
				});
		};

		this.GetAthenaOrdersVmByIdContract = function (idContract, callback) {
			var config = {
				method: 'GET',
				url: configurationFactory.Url['Api'] + 'AthenaOrders/GetAthenaOrdersVmByIdContract',
				params: { idContract: idContract }
			};
			SendRequest(config, callback);
		};

		this.GetInfosPopUpAthenaOrderBuiltPrice = function (idOfferBuiltPrice, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'AthenaOrders/GetInfosPopUpAthenaOrderBuiltPrice',
                params: { idOfferBuiltPrice: idOfferBuiltPrice }
            };
            SendRequest(config, callback);
        };

		this.GetAthenaOrdersSellByIdOfferBuiltPriceObservation = function (idOfferBuiltPriceObservation, callback) {
			var config = {
				method: 'GET',
				url: configurationFactory.Url['Api'] + 'AthenaOrders/GetAthenaOrdersSellByIdOfferBuiltPriceObservation',
				params: { idOfferBuiltPriceObservation: idOfferBuiltPriceObservation }
			};
			SendRequest(config, callback);
		};

		this.GetAthenaOrdersExitAnticipatedByIdOfferBuiltPriceObservation = function (idOfferBuiltPriceObservation, callback) {
			var config = {
				method: 'GET',
				url: configurationFactory.Url['Api'] + 'AthenaOrders/GetAthenaOrdersExitAnticipatedByIdOfferBuiltPriceObservation',
				params: { idOfferBuiltPriceObservation: idOfferBuiltPriceObservation }
			};
			SendRequest(config, callback);
		};

		this.PostAthenaOrderVm = function (order, callback) {
			var config = {
				method: 'POST',
				url: configurationFactory.Url['Api'] + 'AthenaOrders/PostAthenaOrderVm',
				data: order
			};
			SendRequest(config, callback);
		};


	}]);