/*
*   Directive d'ouverture de pop up de confirmation
*	2 parametres sont nécéssaire :
*	- confirmMessage : text du boutton de confirmation
*	- cancelMessage : text du bouton d'annulation
*
*   2 fonctions sont nécéssaires:
*       -callFunctionName: ouvre la modal
*       -confirmFunction: fonction a lancer apres la confirmation
*/

AgrimarketDirectives.directive('listtoselected', function () {
	var globParam;
	return function (scope, iElement, iAttrs) {

		scope[iAttrs.popupConfirm] = function() {
			iElement.dialog("close");
			scope[iAttrs.confirmFunction](globParam);
		};

		scope[iAttrs.popupCancel] = function() {
			iElement.dialog("close");
			if (iAttrs.confirmCancelFunction !== undefined && iAttrs.confirmCancelFunction !== null) {
				scope[iAttrs.confirmCancelFunction](globParam);
			}
			
		};

		scope[iAttrs.callFunctionName] = function (param) {
		    globParam = param;
		    if (iAttrs.popupTitle) {
		        iElement.dialog({
		            resizable: false,
		            modal: true,
		            title: scope[iAttrs.popupTitle]
		        });
		    }
		    else {
		        iElement.dialog({
		            resizable: false,
		            modal: true,
		        });
		    }
		};		
	};
});