AgrimarketFactories.factory("enumFactory", [
    function () {
        var coops = {
            "Ducroquet": 'ducroquet',
            "Uneal": 'uneal',
            "Nutrinor": 'nutrinor',
            "Prograin": 'prograin',
            "Creully": 'creully'
        };
        var roles = {
            "Admin": 1,
            "User": 2,
            "TC": 3,
            "Gateway": 4
        };
        var typeUserActions = {
            "EnvoyerIdentifiants": 1,
            "ActiverAcces": 2,
            "DesactiverAcces": 3,
            "ChangerDatesAbonnement": 4,
            "EnvoyerMail": 5,
            "GroupeUtilisateur": 6,
            "ActiverConnexionAgri": 7,
            "DesactiverConnexionAgri": 8,
            "EnregistrerLiaisonTC": 9
        };
        var typeRequest = {
            "Disponibilite": 1,
            "Tarif": 2
        };
        return {
            Coops: coops,
            TypeQuotes: {
                "Fixe": 1,
                "IndexeCoursCompensation": 2,
                "Indexe3FoisParJour": 3,
                "IndexeTempsReel": 4
            },
            TypeOrders: {
                "Vente": { "IdTypeOrder": 1, "Label": "Vente", "LabelAction": "Vente" },
                "Prixobjectif": { "IdTypeOrder": 2, "Label": "Prix objectif", "LabelAction": "Prix objectif" },
                "Engagement": { "IdTypeOrder": 3, "Label": "Engagement", "LabelAction": "S'engager" },
                "Option": { "IdTypeOrder": 4, "Label": "Option", "LabelAction": "Souscrire " },
                "ContratVierge": { "IdTypeOrder": 5, "Label": "Vente contrat vierge", "LabelAction": "Vente" },
                "EngagementSurface": { "IdTypeOrder": 7, "Label": "Engagement de surface", "LabelAction": "S'engager" },
            },
            Action: {
                "Action_Signing": { "EditableKey": "Action_Signing", "ContentDefault": "Signer" },
                "Action_Modification": { "EditableKey": "Action_Modification", "ContentDefault": "Modifier" },
                "Action_Cancel": { "EditableKey": "Action_Cancel", "ContentDefault": "Annuler" },
                "Action_Detail": { "EditableKey": "Action_Detail", "ContentDefault": "Détail" },
                "Action_DisplayPdf": { "EditableKey": "Action_DisplayPdf", "ContentDefault": "Visualiser son contrat Pdf" },
                "Action_Delete": { "EditableKey": "Action_Delete", "ContentDefault": "Supprimer" },
                "Action_Validate": { "EditableKey": "Action_Validate", "ContentDefault": "Valider" },
                "Action_Refuse": { "EditableKey": "Action_Refuse", "ContentDefault": "Refuser" },
                "Action_Duplicate": { "EditableKey": "Action_Duplicate", "ContentDefault": "Dupliquer" },
            },
            ActionOption: {
                "Action_Execute": { "EditableKey": "Action_Execute", "ContentDefault": "Exécuter" },
                "Action_Abandon": { "EditableKey": "Action_Abandon", "ContentDefault": "Abandonner" },
            },
            CommitmentAction: {
                "CommitFixation": { "EditableKey": "CommitFixation", "ContentDefault": "Fixations" }
            },
            StateArticle : {
                "DELIVERED": 1,
                "NOT_DELIVERED": 2,
                "PARTIALLY_DELIVERED": 3
            },
            ProductAndTypeCommitAction: {
                "CommitOffer": { "EditableKey": "CommitOffer", "ContentDefault": "Offre", "IsVisible": true },
                "CommitDetail": { "EditableKey": "CommitDetail", "ContentDefault": "Detail", "IsVisible": true },
            },
            ContractState: {
                "AttenteValidation": 1,
                "Valide": 2,
                "Annulé": 3,
                "Refusé": 4,
                "AttenteAnnulationOrdreLie": 5,
                "AttenteDernierComposant": 6,
                "Abandonne": 7,
                "EnAttenteExecution": 8,
                "Exécuté": 9,
                "PrixConstruitSouscrit": 13,
                "PrixConstruitEnCoursCalcul": 14

            },
            GroupParameters: {
                "Crop": 1,
                "Offer": 2,
                "Cover": 3,
                "Documentation": 4,
                "MyAccount": 5,
                "DelayedCover": 6,
                "ContractingDelay": 7,
                "ManagementSite": 9,
                "ManagementMessage": 10
            },
            Module: {
                "Cover": 1,
                "Ecommerce": 2,
                "News": 3,
                "Cotation": 4,
                "Hashtagri": 10,
                "Option": 18
            },
            TypeCover:
            {
                "Automatique": 1,
                "Differee": 2,
                "Manuel": 3
            },
            TypeOffer:
            {
                "Ferme": 1,
                "Engagement": 2,
                "Option": 3,
                "ContratVierge": 4,
                "PrixConstruit": 5,
                "EngagementSurface": 6,
                "MultiCampagne": 7
            },
            TypeContract:
            {
                "Ferme": 1,
                "Engagement": 2,
                "ContratVierge": 3,
                "PrixConstruit": 4,
                "EngagementSurface": 5,
                "MultiCampagne": 6
            },
            TypeCommitment:
            {
                "Libre": 1
            },
            StatusBills:
            {
                "IsPayed": 1,
                "IsNotPayed": 2,
                "Undetermined": 3
            },
            TypeParameters:
            {
                "String": 1,
                "RadioButton": 2,
                "CheckBox": 3,
                "DropDownList": 4,
                "TimePicker": 5,
                "FileUpload": 6,
                "HourTimePicker": 7,
                "DoubleHourTimePicker": 8,
                "DoubleDouble": 9
            },
            TypeTemplates: {
                "Vente": 1,
                "Objectif": 2,
                "Engagement": 3,
                "Option": 4,
                "VenteBase": 5,
                "ObjectifBase": 6,
                "BlankContract": 7,
                "ExerciseOption": 8,
                "ContratBuiltPrice": 9,
                "ExitAnticipatedBuiltPrice": 10,
                "TargetExerciseOption": 11,
                "EngagementSurface": 12,
                "MultiCrop": 13
            },
            TypeChart: [
                { Name: "Courbe", Value: "line" },
                { Name: "Chandelle", Value: "candlestick" },
                { Name: "OHLC", Value: "ohlc" }
            ],
            TypeDashboardActuality:
            {
                'NEWS': 1,
                'RSS': 2,
                'HASHTAGRI': 3,
                'VIDEO': 4,
                'CAROUSEL' : 5,
                'CONTACTS' : 6
            },
            Roles: roles,
            UserTable: {
                "FluxRss": 1,
                "News": 2,
                "Commands": 3,
                "Outstandings": 4,
                "Bills": 5,
                "Contributions": 6,
                "ManageUser": 7,
                "ManageTc": 8,
                "ManageUserFromTc": 9
            },
            Profiles: [
                { Name: "Tous", Value: 0 },
                { Name: "Agriculteur", Value: roles.User },
                { Name: "TC", Value: roles.TC }
            ],
            TypeInvoiceState: {
                "Facture": 1,
                "NonFacture": 2
            },
            CommandState: {
                "Sold": 1,
                "NoSold": 2
            },
            DropDownList: {
                "Tous": -1,
                "Aucun": 0
            },
            TypeBills: {
                "Appros": 1,
                "Apport": 2,
                "Cereal": 3
            },
            TypeUserActions: typeUserActions,
            TypeUserActionsList: [
                { Name: "Envoyer les identifiants", Value: typeUserActions.EnvoyerIdentifiants },
                { Name: "Activer l'accès Extranet", Value: typeUserActions.ActiverAcces },
                { Name: "Désactiver l'accès Extranet", Value: typeUserActions.DesactiverAcces },
                { Name: "Changer les dates d'abonnement", Value: typeUserActions.ChangerDatesAbonnement },
                { Name: "Envoyer un mail", Value: typeUserActions.EnvoyerMail },
                { Name: "Groupe utilisateur", Value: typeUserActions.GroupeUtilisateur },
                { Name: "Activer la connexion pour un agri", Value: typeUserActions.ActiverConnexionAgri },
                { Name: "Désactiver la connexion pour un agri", Value: typeUserActions.DesactiverConnexionAgri },
                { Name: "Liaison TC", Value: typeUserActions.EnregistrerLiaisonTC }
            ],
            TypeRequest: typeRequest,
            TypeRequestList: [
                { Name: "Demande de disponibilité", Value: typeRequest.Disponibilite },
                { Name: "Demande de tarif", Value: typeRequest.Tarif }
            ],
            ListRoles: [
                { Name: "Admin", Value: roles.Admin },
                { Name: "Agriculteur", Value: roles.User },
                { Name: "TC", Value: roles.TC }
            ],
            TypesMails: {
                "Confirmation prise d'une vente": 1,
                "Modification d'un prix objectif": 2,
                "Annulation d'un prix objectif": 3,
                "Validation d'un prix objectif": 4,
                "Fin de validité d'un prix objectif": 5,
                "Annulation partielle d'un ordre": 6,
                "Rejet d'un ordre": 7,
                "Mot de passe perdu": 8,
                "Envoyer les identifiants": 9,
                "Demande de tarif / disponibilité": 11,
                "Rejet prix objectif coupure du routage d'ordre": 12,
                "Rejet couverture différée coupure du routage d'ordre": 13,
                "Demande de couverture manuelle pour un prix Objectif": 14,
                "Seuil de tonnage limite atteinte": 15,
                "Annulation d'' un ordre": 16,
                "Confirmation d'un prix objectif": 17,
                "Alerte mail": 49
            },
            OfferStatus: {
                "Enabled": 1,
                "Disabled": 2,
                "Expired": 3
            },
            IdStateCover: {
                "NotCovered": 1,
                "PendingCover": 2,
                "Covered": 3
            },
            IdTypeEditableObject: {
                "Texte": 1,
                "ButtonConfirm": 2,
                "ButtonCancel": 3
            },
            ElementsInfoMarketHomePage: {
                "Quotes": { "IdElementInfoMarketHomePage": 1, "Label": "Cotations", "LabelDefault": "Cotations" },
                "News": { "IdElementInfoMarketHomePage": 2, "Label": "News", "LabelDefault": "News" },
                "Synthesis": { "IdElementInfoMarketHomePage": 3, "Label": "Synthèse", "LabelDefault": "Synthèse" }
            },
            ValidityDurations: {
                "Day": 1,
                "Week": 2,
                "Month": 3,
                "EndDateOffer": 4
            },
            TabContract: {
                "Validated": 1,
                "Priced": 2,
                "Canceled": 3,
                "Pending": 4,
                "ToValidated": 5,
                "delayedSigning": 6,
            },
            ContentBlocDispositions: [
                { Id: 1, Label: 'Cotations Global Macro' },
                { Id: 2, Label: 'Cotations Physique' },
                { Id: 3, Label: 'Cotations Futures' },
                { Id: 4, Label: 'News Météo' },
                { Id: 5, Label: 'News USDA' }
            ],
            TypeBlockEditables: {
                "Bandeau": 1,
                "TAB": 2
            },
            TypeCategoryInfoMarket: {
                "Cereales": 1,
                "Oleagineux": 2,
                "GlobalMacro": 3,
                "Eleveurs": 4,
                "INFOOS": 5
            },
            TypeOfferCommitment: {
                "EngagementFacultatif": 1,
                "EngagementObligatoire": 2,
                "ObligerSansEngagement": 3
            },
            EuronextRoyalTy: {
                "Choice1": 1, // Le client paye la redevance directement à Euronext -> La page existante ne bouge pas, on affiche en différé 15 min
                "Choice2": 2, // Le client paye la redevance directement à Euronext mais moins chère -> On cache les colonnes lié au carnet d’ordre, on affiche en différé 15 min
                "Choice3": 3, // Le client ne paye pas la redevance -> On cache les colonnes lié au carnet d’ordre, on affiche plus que le settle de la veille (maj à minuit)
                "Choice4": 4, // Le client ne paye pas la redevance Euronext mais souhaite garder le différé 15 min -> Redirection vers la page de cotations Logaviv
                "Choice5": 5 // on cache la page de cotation
            },
            TypeSends: {
                "Immediat": 1,
                "Journalier": 2,
                "Hebdomadaire": 3,
                "Mensuel": 4
            },
            ValuesIncidenceRuleGroup: {//Paramètre : Id:79 (Règle sur les incidences des groupes)
                "MoinsAvantageuse": 1,
                "PlusAvantageuse": 2
            },
            TypeTargetPriceMessage: {
                "Mail": 1,
                "Sms": 2
            },
            TypeFixations: {
                "Base": { "IdTypeFixation": 1, "Label": "Fixation base", "LabelAction": "Fixation base" },
                "Future": { "IdTypeFixation": 2, "Label": "Prix de référence", "LabelAction": "Prix de référence" },
                "GlobalPrice": { "IdTypeFixation": 3, "Label": "Fixation prix", "LabelAction": "Fixation prix" },
                "ExerciceOption": { "IdTypeFixation": 4, "Label": "Exercice d'option", "LabelAction": "Exercice d'option" }
            },
            TypeOption: {
                "Call": 1,
                "Put": 2
            },
            SubscribeActions: {
                "BaseSold": { "IdTypeFixation": 1, "IdTypeOrder": 1, "LabelDefault": "Fixation base", "Label": "Fixation base" },
                "BaseTargetPrice": { "IdTypeFixation": 1, "IdTypeOrder": 2, "LabelDefault": "Objectif base", "Label": "Objectif base" },
                "FutureSold": { "IdTypeFixation": 2, "IdTypeOrder": 1, "LabelDefault": "Fixation prix de ref", "Label": "Fixation prix de ref" },
                "FutureTargetPrice": { "IdTypeFixation": 2, "IdTypeOrder": 2, "LabelDefault": "Objectif prix de ref", "Label": "Objectif prix de ref" },
                "GlobalSold": { "IdTypeFixation": 3, "IdTypeOrder": 1, "LabelDefault": "Fixation prix", "Label": "Fixation prix" },
                "GlobalTargetPrice": { "IdTypeFixation": 3, "IdTypeOrder": 2, "LabelDefault": "Objectif prix", "Label": "Objectif prix" },
                "SoldExerciceOption": { "IdTypeFixation": 4, "IdTypeOrder": 1, "LabelDefault": "Exercice", "Label": "Exercice" },
                "TargetExerciceOption": { "IdTypeFixation": 4, "IdTypeOrder": 2, "LabelDefault": "Objectif d'exercice", "Label": "Objectif d'exercice" },
                "CommitCommitment": { "IdTypeFixation": null, "IdTypeOrder": 3, "LabelDefault": "Engagement", "Label": "S'engager" },
                "CommitCommitmentSurface": { "IdTypeFixation": null, "IdTypeOrder": 3, "LabelDefault": "Engagement de surface", "Label": "S'engager" },
                "SubscribeOption": { "IdTypeFixation": null, "IdTypeOrder": 4, "LabelDefault": "Souscription option", "Label": "Souscrire" },
                "SubscribeBlankContract": { "IdTypeFixation": null, "IdTypeOrder": 5, "LabelDefault": "Vente", "Label": "Vente" },
            },
            TypeCategoryTypeTemplateMail: {
                "Contract": 1,
                "Base": 2,
                "Commitment": 3,
                "Option": 4,
                "RoutingOrder": 5,
                "Other": 6,
                "BuiltPrice": 7,
                "Offer": 8,
                "MultiCrop": 9,
            },
            TypeEffortTc: {
                "Base": 1,
                "Price": 2
            },
            TypeFixationBases: {
                "Obligatoire": 1,
                "Libre": 2,
                "Automatic": 3
            },
            TypeCalculOption: {
                "Manual": 1,
                "Automatic": 2
            },
            UsersOfTcActions: {
                "Action_EditInfosPerso": { "EditableKey": "Action_EditInfosPerso", "ContentDefault": "Modifier" },
                "Action_SendIdentifiers": { "EditableKey": "Action_SendIdentifiers", "ContentDefault": "Envoyer les identifiants" }
            },
            OfferActions: {
                "Action_SetLimitsAlertSms": { "EditableKey": "Action_SetLimitsAlertSms", "ContentDefault": "Alerte" }
            },
            Origins: {
                "Web": 1,
                "Projection": 2,
                "Passerelle": 3,
                "Mobile": 4,
                "AppMobile": 5
            },
            AthenaOrderTypes: {
                "Market": { Name: "Marché", Id: 1 },
                "Limit": { Name: "Objectif", Id: 2 }
            },
            VisibilityOffer: {
                "All": 1,
                "Tc_Only": 2,
                "User_Only": 3,
                "Dr_Ra": 4,
                "Siege": 5
            },
            AthenaOrderStates: {
                "PendingCancel": -3,
                "ModificationAborted": -2,
                "PendingNew": -1,
                "New": 0,
                "PartiallyFilled": 1,
                "Filled": 2,
                "Cancel": 4,
                "Reject": 8
            },
            TypeFreeInputs:{
                "Numeric": 1,
                "AlphaNumeric": 2
            },
            TypePages:{
                "Content": 1,
                "DirectUrl": 2
            },
            TypePageAccess:{
                "Page": 1,
                "iFrame": 2
            }
        };
    }]);