AgrimarketControllers.controller('saveNewsController', ['$scope', '$rootScope', '$routeParams', '$sce', '$filter', '$http', '$location', 'newsService', 'dateService', 'configurationFactory', 'groupContactService', '$upload', 'uploadService',
    function ($scope, $rootScope, $routeParams, $sce, $filter, $http, $location, newsService, dateService, configurationFactory, groupContactService, $upload, uploadService) {

        var idnews = $routeParams.idnews;
        $rootScope.succesMessageNews = "";
        $scope.News = {
            GroupContacts: []
        };
        $scope.tmpGroupContacts1 = [];
        $scope.tmpGroupContacts2 = [];

        $scope.uploads = [];
        $scope.lstAttachments = [];

        var init = function () {
            $scope.NewNews = false;

            if (idnews === undefined || idnews === null || idnews == -1) { //nouvelle actualité
                $scope.News.ShowPreviewActuality = true;
                $scope.blocTitle = "Ajouter une nouvelle actualité";
                $scope.action = "Créer";
                $scope.NewNews = true;
                getGroupContacts();
            } else { // modifier actualité
                $scope.blocTitle = "Modifier une nouvelle actualité";
                $scope.action = "Modifier";
                newsService.GetNewsById(idnews, function (data, status) {
                    if (status !== null) {
                        if (status == 400) {
                            $scope.NewsErrorMessage = data[0].errors[0].Message;
                        }
                    } else {
                        $scope.News = data;
                        $scope.lstAttachments = $scope.News.Attachments;
                        getGroupContacts();
                    }
                });
            }
        };

        $scope.UploadImage = function (data) {
            CKEDITOR.instances.txtContent.insertHtml("<img src='" + $filter('uploadLinkInline')(data.PrivateLabel) + "'>");
        };

        $scope.checkNews = function () {
            $scope.NewsErrorMessage = "";
            $scope.successMessage = "";

            if (!$scope.News.Content)
                $scope.NewsErrorMessage += "Veuillez renseigner un contenu. <br/>";

            if (!$scope.News.Title)
                $scope.NewsErrorMessage += "Veuillez renseigner un titre. <br/>";

            if ($scope.News.ShowPreviewActuality && !$scope.News.Preview)
                $scope.NewsErrorMessage += "Veuillez renseigner un aperçu. <br/>";

            if (!$scope.News.StartPublication)
                $scope.NewsErrorMessage += "Veuillez renseigner une date de début de publication. <br/>";

            if ($scope.News.StartPublication && $scope.News.EndPublication && !$scope.compareDate($scope.News.StartPublication, $scope.News.EndPublication))
                $scope.NewsErrorMessage += "Veuillez renseigner une date de fin de publication postérieur à la date de début de publication.";

            if ($scope.NewsErrorMessage !== "") {
                $scope.NewsErrorMessage = $scope.NewsErrorMessage;
                return false;
            }

            $scope.saveNews();
            return true;
        };

        $scope.saveNews = function () {
            $scope.NewsErrorMessage = "";
            $scope.News.Attachments = $scope.lstAttachments;
            newsService.SaveNews($scope.News, function (data, status) {
                if (status !== null) {
                    if (status == 400) {
                        $scope.NewsErrorMessage = data[0].errors[0].Message;
                    }
                } else {
                    if ($scope.action === "Modifier")
                        $rootScope.succesMessageNews = "Vos modifications ont bien été prises en compte.";
                    else
                        $rootScope.succesMessageNews = "Votre actualité a bien été prise en compte.";

                    $location.path('/admin/contents/news/');
                }
            });
        };

        $scope.compareDate = function (date1, date2) {
            return dateService.compareDate(date1, date2);
        };

        $scope.Cancel = function () {
            $location.path('/admin/contents/news');
        };

        var getGroupContacts = function () {
            groupContactService.GetAll(function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.NewsErrorMessage = data[0].errors[0].Message;
                } else {
                    $scope.lstGroupContacts = data;
                    $scope.lstGroupContacts = $scope.lstGroupContacts.filter(function (element) { return $scope.News.GroupContacts.filter(function (e) { return e.IdGroupContact === element.IdGroupContact; }); });
                    $scope.setSelectedList();
                }
            });
        };

        $scope.setSelectedList = function () {
            if ($scope.News.GroupContacts === undefined || $scope.News.GroupContacts === null) {
                $scope.News.GroupContacts = [];
            } else {
                $scope.$watch("lstGroupContacts", function (value) {
                    if (value === undefined) return;
                    $.each($scope.News.GroupContacts, function (index, item) {
                        if ($scope.lstGroupContacts.indexOf($filter('filter')($scope.lstGroupContacts, { "IdGroupContact": Number(item.IdGroupContact) }, true)[0]) !== -1) {
                            $scope.lstGroupContacts.splice($scope.lstGroupContacts.indexOf($filter('filter')($scope.lstGroupContacts, { "IdGroupContact": Number(item.IdGroupContact) }, true)[0]), 1);
                        }
                    });
                });
            }
        };

        $scope.toogleAllElementsLeft = function () {
            $scope.master3 = !$scope.master3;
            $.each($scope.lstGroupContacts, function (index, item) {
                item.checked = $scope.master3;
            });
        };

        $scope.toogleAllElementsRight = function () {
            $scope.master4 = !$scope.master4;
            $.each($scope.News.GroupContacts, function (index, item) {
                item.checked = $scope.master4;
            });
        };

        $scope.ListToSelectedGroup = function () {
            $scope.master3 = false;
            $scope.master4 = false;

            $.each($scope.lstGroupContacts, function (index, item) {
                if (item.checked) {
                    item.checked = false;
                    $scope.News.GroupContacts.push(item);
                }
            });

            $.each($scope.News.GroupContacts, function (index, item) {
                var indexItem = $scope.lstGroupContacts.indexOf(item);
                if (indexItem !== -1)
                    $scope.lstGroupContacts.splice(indexItem, 1);
            });
        };

        $scope.SelectedToListGroup = function () {
            $scope.master3 = false;
            $scope.master4 = false;

            $.each($scope.News.GroupContacts, function (index, item) {
                if (item.checked) {
                    item.checked = false;
                    $scope.lstGroupContacts.push(item);
                }
            });

            $.each($scope.lstGroupContacts, function (index, item) {
                var indexItem = $scope.News.GroupContacts.indexOf(item);
                if (indexItem !== -1)
                    $scope.News.GroupContacts.splice(indexItem, 1);
            });
        };

        $rootScope.onRefresh = function () {
            init();
        };

        init();

        $scope.UploadAttachments = function (data) {
            CKEDITOR.instances.txtContent.insertHtml("{{Attachment|" + data.IdAttachment + "|" + $scope.fileName + "}}");
        };

    }]);
