// Gestion des hauteurs de ligne des tableaux responsive
// Directive à appliquer sur le table .t_resp_center
// @Author : Alexandre Riquet
// [responsivetable]
// @Param : singleExpander => option permettant de limiter l'ouverture à un seul accordéon.

AgrimarketDirectives.directive('responsivetable', function ($timeout) {
    return {
        restrict: 'EA',
        scope: {
            data: '=loadedData'
        },
        link: function ($scope, elem, attrs) {

            $(window).resize(function () {
                onHeightCalcul(elem, $timeout, false, 0);
            });

            $scope.$watch("data", function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    onHeightCalcul(elem, $timeout, true, 0);
                }
            });

            $(".deploy").click(function () {
                onHeightCalcul(elem, $timeout, true, 200);
            });
        }
    };
});

var oldHeaderHeight;

function onHeightCalcul(elem, timeout, dataChanged, time) {
    var tableLeft = $(elem).find('.t_resp_left'),
        tableScrollable = $(elem).find('.t_resp_center'),
        tableRight = $(elem).find('.t_resp_right'),
        currentHeaderHeight = $('#idForReCalculHeight').find('.t_resp_center thead').outerHeight();


    if (dataChanged) {
        timeout(function () {
            tableScrollable.find('tr').each(function (i) {
                var currentLineHeight = $(this)[0].offsetHeight;

                tableLeft.find('tr').eq(i).css({
                    'height': currentLineHeight
                });
                
                tableScrollable.find('tr').eq(i).css({
                    'height': currentLineHeight
                });
                
                tableRight.find('tr').eq(i).css({
                    'height': currentLineHeight
                });

                i++;
            });
        }, time);
    }
    else if (currentHeaderHeight !== oldHeaderHeight) {
        oldHeaderHeight = currentHeaderHeight;

        timeout(function () {
            $('#idForReCalculHeight .t_resp_left thead tr').css({ 'height': currentHeaderHeight });
            $('#idForReCalculHeight .t_resp_right thead tr').css({ 'height': currentHeaderHeight });
        }, time);
    }
    var startt = new Date();
}

