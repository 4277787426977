AgrimarketControllers.controller('contractsOptionMonitoringController', ['$scope', '$rootScope', '$routeParams', '$filter', 'configurationFactory',
    'contractsOptionService', 'cropsService', '$sce', 'parametersFactory', 'colonnesContractsOptionExpired', 'colonnesContractsOptionPending', 'colonnesContractsOptionDetails',
    function ($scope, $rootScope, $routeParams, $filter, configurationFactory, contractsOptionService, cropsService, $sce, parametersFactory, colonnesContractsOptionExpired, colonnesContractsOptionPending, colonnesContractsOptionDetails) {

        var init = function () {
            // Contracts Option expirés
            $scope.tableContractsOptionExpiredDataVisible = colonnesContractsOptionExpired.data.DataVisible.split(',');
            $scope.tableContractsOptionExpiredLibelle = colonnesContractsOptionExpired.data.ColumnName.split(',');

            // Contracts Option en cours
            $scope.tableContractsOptionPendingDataVisible = colonnesContractsOptionPending.data.DataVisible.split(',');
            $scope.tableContractsOptionPendingLibelle = colonnesContractsOptionPending.data.ColumnName.split(',');

            // Détails contrats , liste des exercices
            $scope.tableContractsOptionDetailsDataVisible = colonnesContractsOptionDetails.data.DataVisible.split(',');
            $scope.tableContractsOptionDetailsLibelle = colonnesContractsOptionDetails.data.ColumnName.split(',');  

            $scope.viewMode = $routeParams.typeContract;
            $scope.max = 10;

            $scope.expiredPageNo = 1;
            $scope.pendingPageNo = 1;

            $scope.dataExpired = { reverse: true, sortKey: "IdContractOption" };
            $scope.dataPending = { reverse: true, sortKey: "IdContractOption" };

            $scope.idCropSelected = Number(parametersFactory.Parameters.DefaultCrop.Value);
            $scope.idProductSelected = "";
            $scope.idTypeOptionSelected = "";
            $scope.dateFilter = "";
            $scope.date2Filter = "";
            $scope.idCompagnySelected = "";
            $scope.dateSelected = "";
            $scope.dateExecutedSelected = "";
            $scope.dateAbandonedSelected = "";
            $scope.selectedClient = {};

            $scope.detailsMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_ContractsOption_DetailsMessage.Content;
            $scope.detailsMessageList = $rootScope.editableObjectsFactory.EditableObjects.Admin_ContractsOption_DetailsMessage_List.Content;

            $scope.$watch("selectedIdClient", function (newValue, oldValue) {
                if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                    $scope.GetData();
            });

            $scope.$watch("dateSelected", function (newValue) {
                if (newValue === "")
                    $scope.dateFilter = "";
                else if (newValue) {
                    //permet de gérer l'internalisation de tout les formats des dates possibles.
                    //Au final nous faisons en sorte de la retrouvé au format yyyy/mm/dd pour l'envoyer à l'API.
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    var dt = new Date(newValue.replace(pattern, '$3-$2-$1'));
                    var currentDate = new Date(dt.toString());
                    var converDate = moment(currentDate);
                    $scope.dateFilter = converDate.format('YYYY/MM/DD');
                }
            });

            $scope.$watch("dateExecutedSelected", function (newValue) {
                if (newValue === "")
                    $scope.date2Filter = "";
                else if (newValue) {
                    //permet de gérer l'internalisation de tout les formats des dates possibles.
                    //Au final nous faisons en sorte de la retrouvé au format yyyy/mm/dd pour l'envoyer à l'API.
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    var dt = new Date(newValue.replace(pattern, '$3-$2-$1'));
                    var currentDate = new Date(dt.toString());
                    var converDate = moment(currentDate);
                    $scope.date2Filter = converDate.format('YYYY/MM/DD');
                }
            });

            $scope.$watch("dateAbandonedSelected", function (newValue) {
                if (newValue === "")
                    $scope.date3Filter = "";
                else if (newValue) {
                    //permet de gérer l'internalisation de tout les formats des dates possibles.
                    //Au final nous faisons en sorte de la retrouvé au format yyyy/mm/dd pour l'envoyer à l'API.
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    var dt = new Date(newValue.replace(pattern, '$3-$2-$1'));
                    var currentDate = new Date(dt.toString());
                    var converDate = moment(currentDate);
                    $scope.date3Filter = converDate.format('YYYY/MM/DD');
                }
            });

            $scope.$watch("dateFilter", function (newValue, oldValue) {
                if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                    $scope.GetData();
            });

            $scope.$watch("date2Filter", function (newValue, oldValue) {
                if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                    $scope.GetData();
            });

            $scope.$watch("date3Filter", function (newValue, oldValue) {
                if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                    $scope.GetData();
            });

            GetCropsWithContracts();
        };


        //Initialisation des filtres généraux
        var GetCropsWithContracts = function () {
            cropsService.GetCropsWithContractsOption(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    if (data) {
                        $scope.crops = data;
                        var defaultCropInCrops = $filter('filter')($scope.crops, { IdCrop: $scope.idCropSelected })[0] !== undefined;
                        if (!defaultCropInCrops && $scope.crops.length > 0)
                            $scope.idCropSelected = $scope.crops[0].IdCrop;
                        $scope.GetFiltersWithContractsOption();
                    }
                }
            });
        };

        $scope.GetFiltersWithContractsOption = function () {
            $scope.loading = true;
            $scope.idProductSelected = "";
            $scope.idTypeOptionSelected = "";
            $scope.idCompagnySelected = "";
            $scope.selectedIdContract = "";
            $scope.dateSelected = "";
            $scope.dateExecutedSelected = "";
            $scope.dateAbandonedSelected = "";
            $scope.selectedIdClient = "";
            contractsOptionService.GetFiltersWithContractsOptionByCrop($scope.idCropSelected, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    if (data) {
                        $scope.lstProducts = data.LstProductFilterVm;
                        if ($scope.lstProducts.length === 1)
                            $scope.idProductSelected = $scope.lstProducts[0].IdProduct;
                        else if ($scope.lstProducts.length > 1 && $filter('filter')($scope.lstProducts, { IdProduct: "", ProductLabel: $rootScope.globalLabels.AllItems }).length === 0)
                            $scope.lstProducts.unshift({ IdProduct: "", ProductLabel: $rootScope.globalLabels.AllItems });

                        $scope.lstCompagnies = data.LstCompagnies;
                        $scope.lstCompagnies.unshift({ IdCompagny: "", Label: $rootScope.globalLabels.AllItems_F });

                        $scope.lstTypeOption = data.LstTypeOptionFilterVm;
                        if ($scope.lstTypeOption.length === 1)
                            $scope.idTypeOptionSelected = $scope.lstTypeOption[0].IdTypeOption;
                        else if ($scope.lstTypeOption.length > 1 && $filter('filter')($scope.lstTypeOption, { IdTypeOption: "", TypeOptionLabel: $rootScope.globalLabels.AllItems }).length === 0)
                            $scope.lstTypeOption.unshift({ IdTypeOption: "", TypeOptionLabel: $rootScope.globalLabels.AllItems });
                    }
                }
            });
            $scope.GetData();
        };

        $scope.GetData = function () {
            if (!$scope.selectedIdContract) $scope.selectedIdContract = "";
            if (!$scope.selectedIdClient) $scope.selectedIdClient = "";

            if ($scope.viewMode === "expired") {
                $scope.GetContractsOptionExpiredWithFilter(1);
            }
            else if ($scope.viewMode === "pending") {
                $scope.GetContractsOptionPendingWithFilter(1);
            }

        };

        //GetContractsOptionExecutedWithFilter
        $scope.GetContractsOptionExpiredWithFilter = function (pagenum) {
            $scope.loading = true;
            $scope.errorMessageContractOptionExpired = "";
            contractsOptionService.GetMonitoringContractOptionExpiredPaginate($scope.idCropSelected, $scope.idProductSelected, $scope.idTypeOptionSelected, $scope.dateFilter, $scope.date2Filter, $scope.date3Filter, $scope.selectedIdContract, $scope.selectedIdClient, $scope.idCompagnySelected, $scope.max, pagenum, $scope.dataExpired.sortKey, $scope.dataExpired.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessageContractOptionExpired = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.totalExpired = data.Total;
                    $scope.ContractsOptionExpired = data.ListVm;
                    $scope.loading = false;
                }
            });
        };

        $scope.GetContractsOptionPendingWithFilter = function (pagenum) {
            $scope.loading = true;
            $scope.errorMessageContractOptionPending = "";
            contractsOptionService.GetMonitoringContractOptionPendingPaginate($scope.idCropSelected, $scope.idProductSelected, $scope.idTypeOptionSelected, $scope.dateFilter, $scope.date2Filter, $scope.date3Filter, $scope.selectedIdContract, $scope.selectedIdClient, $scope.idCompagnySelected, $scope.max, pagenum, $scope.dataPending.sortKey, $scope.dataPending.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessageContractOptionPending = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.totalPending = data.Total;
                    $scope.ContractsOptionPending = data.ListVm;
                    $scope.loading = false;
                }
            });
        };

        $scope.Sort = function (sortKey, reverse) {
            if ($scope.viewMode === "expired") {
                $scope.dataExpired.sortKey = sortKey;
                $scope.dataExpired.reverse = reverse;
            }
            else if ($scope.viewMode === "pending") {
                $scope.dataPending.sortKey = sortKey;
                $scope.dataPending.reverse = reverse;
            }
            $scope.GetData();
        };

        $scope.GetDetail = function (idContract, openPopUp, modalTitle) {
            $scope.errorMessage = "";
            $scope.detailedContract = [];
            $scope.lstAthenaOrdersVm = [];
            $scope.popupHeight = undefined;
            $scope.textDetail = $scope.detailsMessage.replace("[IdContractOption]", idContract);
            $scope.textDetailList = $scope.detailsMessageList.replace("[IdContractOption]", idContract);
            $scope.modalTitle = modalTitle;

            contractsOptionService.GetContractOptionDetails(idContract, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessageContractsNoCover = $sce.trustAsHtml(data[0].errors[0].Message);
                }
                else {
                    if (data) {
                        data.forEach(function (item) {
                            if (item.Date !== null)
                                item.Date = $filter('date')(item.Date, "dd/MM/yyyy");
                        });
                        $scope.lstAthenaOrdersVm = data;
                        
                        // On défini la taille de la popup en fonction du nombre de ligne du tableau. (33 ~ la taille d'une ligne du tableau)
                        $scope.popupHeight = (data.length * 33) + 150; }
                    openPopUp();
                }
            });
        };

        init();
    }]);
