AgrimarketControllers.controller('notificationsController', ['$scope', '$rootScope', '$sce', 'notificationsService', 'colonnesNotifications', 'enumFactory',
    function ($scope, $rootScope, $sce, notificationsService, colonnesNotifications, enumFactory) {

        $scope.Init = function () {
            $scope.tableNotificationsDataVisible = colonnesNotifications.data.DataVisible.split(',');
            $scope.tableNotificationsLibelle = colonnesNotifications.data.ColumnName.split(',');
            $scope.titlePopupDelete = $rootScope.editableObjectsFactory.EditableObjects.Admin_Notifications_PopupDelete_Title.Content;
            $scope.enumFactory = enumFactory;
            $scope.max = 10;
            $scope.pageNo = 1;
            $scope.reverse = false;
            $scope.sortKey = "";
            $scope.GetNotifications();
        };

        $scope.GetNotifications = function () {
            $scope.loading = true;
            notificationsService.GetNotificationsPaginate($scope.max, $scope.pageNo, $scope.sortKey, $scope.reverse, function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.notifications = data.ListVm;
                    $scope.total = data.Total;
                    $scope.loading = false;
                }
            });
        };

        $scope.Sort = function (sortKey, reverse) {
            $scope.sortKey = sortKey;
            $scope.reverse = reverse;
            $scope.GetNotifications();
        };

        $scope.GetData = function (pageNo) {
            $scope.pageNo = pageNo;
            $scope.GetNotifications();
        };

        $scope.DeleteNotification = function (idNotification) {
            $scope.errorMessage = "";
            $scope.successMessage = "";
            notificationsService.DeleteNotification(idNotification, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } 
                } else {
                    $scope.successMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Notifications_MessageDelete.Content;
                    $scope.Init();
                }
            });
        };

        $scope.Init();
        $rootScope.onRefresh = function () { $scope.Init(); };
    }]);
