AgrimarketServices.service('elementInfoMarketHomePagesService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

    	var SendRequest = function (configfile, callback) {
    		$http(configfile)
			    .success(function (data) {
			    	callback(data, null);
			    })
			    .error(function (data, status) {
			    	callback(data, status);
			    });
    	};

    	this.PutElementInfoMarketHomePageLabel = function (lstElementInfoMarketHomePageVms, callback) {
    	    var config = {
    	        method: 'PUT',
    	        url: configurationFactory.Url['Api'] + 'ElementInfoMarketHomePage/PutElementInfoMarketHomePageLabel',
    	        data: lstElementInfoMarketHomePageVms
    	    };
    	    SendRequest(config, callback);
    	};

    }]);