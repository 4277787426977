AgrimarketServices.service('typeOrdersService', ['$http', 'configurationFactory', 'editableObjectsFactory', 'enumFactory',
    function ($http, configurationFactory, editableObjectsFactory, enumFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null);
                })
                .error(function (data, status) {
                    callback(data, status);
                });
        };

        this.PutTypeOrderLabelAction = function (lstTypeOrderFilterVms, callback) {
            var config = {
                method: 'PUT',
                url: configurationFactory.Url['Api'] + 'TypeOrder/PutTypeOrderLabelAction',
                data: lstTypeOrderFilterVms
            };
            SendRequest(config, callback);
        };

        this.PutSubscribeActionLabels = function (lstTypeOrderFilterVms, callback) {
            var config = {
                method: 'PUT',
                url: configurationFactory.Url['Api'] + 'TypeOrder/PutSubscribeActionLabels',
                data: lstTypeOrderFilterVms
            };
            SendRequest(config, callback);
        };

        this.CheckIsValidTypeOrder = function (idTypeOrder, callback) {
            var res = {
                errorMessage: "",
                isSell: false,
                isObjective: false,
                idTypeOrder: null
            };

            switch (idTypeOrder) {
                case enumFactory.TypeOrders.Vente.IdTypeOrder:
                    res.isSell = true;
                    break;
                case enumFactory.TypeOrders.Prixobjectif.IdTypeOrder:
                    res.isObjective = true;
                    break;
                case enumFactory.TypeOrders.Engagement.IdTypeOrder:
                    break;
                default:
                    res.errorMessage = editableObjectsFactory.EditableObjects.Error_OfferSubscribe_OrderTypeIsEmptyOrIncorrect.Content;
            }

            if (!res.errorMessage)
                res.idTypeOrder = idTypeOrder;

            callback(res);
        };

    }]);