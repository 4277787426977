/*
*   Directive d'ouverture de pop up de confirmation
*   1 parametres est nécéssaire :
*/

AgrimarketDirectives.directive('subnav', function ($timeout) {
	var globParam;
	return function (scope, iElement, iAttrs) {

	    $.fn.subNavTouch = function (options) {

	        var defaults =
            {
            };

	        var params = $.extend(defaults, options);

	        return this.each(function () {
	            var $container = $(this);
	            var currentElem = $(this).find('.current');

		        $container.wrap('<div class="containerNav"></div>');
	            $('#secondary_nav').append('<span class="subMenu_button"><span></span></span><ul class="listSubMenu"></ul>');

	            $('.subMenu_button').css({
	                'position': 'absolute',
	            });
	            $('.listSubMenu').css({
	                'position': 'absolute',
	                'right': 0,
	                'z-index': 300
	            }).hide();

	            $('.containerNav').css({
	                'overflow': 'hidden',
	            });

	            deviceWidth = $('#main').outerWidth() - $('.subMenu_button').outerWidth();

	            $(window).bind('resize', function (e) {
	                window.resizeEvt = null;
	                $(window).resize(function () {
	                    clearTimeout(window.resizeEvt);
	                    window.resizeEvt = setTimeout(function () {
	                        deviceWidth = $('#main').outerWidth() - $('.subMenu_button').outerWidth();
	                        init();
	                    }, 250);
	                });
	            });

	            init();

	            function init() {
	                // On calcule la largeur totale de la navigation
	                navWidth = 0;

	                $container.find('li').each(function (i) {
	                    navWidth += $(this).outerWidth(true);

	                    if (deviceWidth < navWidth) {
	                        $(this).addClass('hiddenElem').hide();
	                    } else {
	                        $(this).removeClass('hiddenElem').show();
	                    }
		                i++;
	                });
	                $('.listSubMenu').hide();
	                $('.subMenu_button').removeClass('active');

	                // Si la navigation dépasse la largeur du device
	                if (deviceWidth < navWidth) {

	                    $('.listSubMenu li').remove();
	                    $('.hiddenElem').each(function (i) {
	                        $(this).clone().appendTo('.listSubMenu').show();
		                    i++;
	                    });

	                    // On applique la largeur de la navigation calculée plus haut
	                    $container.css({
	                        'width': navWidth + 5
	                    });
	                    $('.subMenu_button').show();
	                    $('.subMenu_button').unbind();
	                    $('.subMenu_button').bind('click', function () {
	                        if ($('.listSubMenu').is(':visible')) {
	                            $('.listSubMenu').slideUp();
	                            $(this).removeClass('active');
	                        } else {
	                            $('.listSubMenu').slideDown();
	                            $(this).addClass('active');
	                        }
	                    });
	                } else {
	                    $('.subMenu_button').hide();
	                    $container.css({
	                        'width': 'auto'
	                    });
	                }
	            }

	        });
	    };

	    $timeout(function () {
	        $('#' + iAttrs.id + ' ul').subNavTouch({
	        });
	    },0);

	};
});