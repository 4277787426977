AgrimarketServices.service('smsAlertService', ['$http', 'configurationFactory', '$rootScope',
    function ($http, configurationFactory, $rootScope) {

    	var SendRequest = function (configfile, callback) {
    		$http(configfile)
                .success(function (data) {
                	callback(data, null, null, null);
                })
                .error(function (data, status, headers, config) {
                	callback(data, status, headers, config);
                });
    	};

        this.SaveUserExpirySmsLimits = function (idExpiry, limitMin, limitMax, callback) {
    		var config = {
    			method: 'POST',
                url: configurationFactory.Url['Api'] + 'SmsAlert/Save',
                params: { idExpiry: idExpiry, limitMin: limitMin, limitMax: limitMax}
    		};
    		SendRequest(config, callback);
        };

        this.GetSmsAlertsPaginateWithFilter = function ( idCrop, max, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                params: {
                    idCrop: idCrop,
                    itemPerPage: max,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                },
                url: configurationFactory.Url['Api'] + 'SmsAlert/GetSmsAlertsPaginateWithFilter',
            };
            SendRequest(config, callback);
        };

        this.DeleteAlertSms = function (currentAlertSms, callback) {
            var config = {
                method: 'PUT',
                url: configurationFactory.Url['Api'] + 'SmsAlert/DeleteAlertSms',
                data: currentAlertSms
            };

            SendRequest(config, callback);
        };

        this.CheckSmsAlertIsValid = function (limitMax, limitMin, price) {
            if (limitMin && isNaN(limitMin) || limitMax && isNaN(limitMax)) {
                return $rootScope.editableObjectsFactory.EditableObjects.User_OfferFirm_PopupSmsAlert_Error_LimitMustBeFloat.Content;
            }

            if (limitMin && limitMax && Number(limitMin) > Number(limitMax)) {
                return $rootScope.editableObjectsFactory.EditableObjects.User_OfferFirm_PopupSmsAlert_Error_LimitMinSuperiorLimitMax.Content;
            }

            if ((limitMin && Number(limitMin) > Number(price)) || (limitMax && Number(limitMax) < Number(price))) {
                return $rootScope.editableObjectsFactory.EditableObjects.User_OfferFirm_PopupSmsAlert_Error_OfferPriceNotBetweenLimits.Content;
            }

            return "";
        };

    }]);