AgrimarketControllers.controller('videoController', ['$scope', '$rootScope',  '$sce',  '$location',  'videoService', 'colonnes',
    function ($scope, $rootScope,  $sce, $location, videoService, colonnes) {

    	if (!$rootScope.ModuleNews.Active)
    		$location.path('/admin/parameters/global');

    	$scope.searchText = "";
    	$scope.errorMessage = "";
    	$scope.lstVideo = [];
    	$scope.video = {};
    	$scope.InitMessage = function () {
    		$scope.errorMessage = "";
    		$scope.successMessage = "";
    		$scope.errorMessagePopup = "";
    	};
    	$scope.data = { reverse: false, sortKey: "Position" };
    	$scope.pageNo = 1;
    	$scope.max = 10;
        
        $scope.GetVideo = function () {
            videoService.GetVideoPaginateWithFilter($scope.searchText, $scope.max, $scope.pageNo, $scope.data.sortKey, $scope.data.reverse, function (data, status) {
    			if (status !== null) {
    				if (status === 400)
    					$scope.errorMessage = data[0].errors[0].Message;
    				else
    					$scope.errorMessage = data;
    			}
    			else {
                    $scope.lstVideo = data.ListVm;
    				$scope.total = data.Total;
    			}
    		});
    	};

    	$scope.AddVideo = function (popup) {
    		$scope.InitMessage();
    		$scope.video = {};
    		$scope.txtButton = "Ajouter";
    		$scope.modalTitle = "Ajouter une vidéo";
    		popup();
    	};

    	$scope.EditVideo = function (item, index, openPoPup) {
    		$scope.InitMessage();
    		$scope.video = angular.copy(item);
    		$scope.txtButton = "Modifier";
    		$scope.modalTitle = "Modifier une vidéo";
    		openPoPup();
    	};

    	$scope.CheckVideo = function () {
    		$scope.InitMessage();
            if ($scope.video.Label === undefined || $scope.video.Label === null || $scope.video.Label === "")
                $scope.errorMessagePopup += "Veuillez renseigner un nom de vidéo<br/>";
            else if ($scope.video.Label.length > 20)
                $scope.errorMessagePopup += "Veuillez renseigner un nom de vidéo de 20 caractères maximum<br/>";

            if ($scope.video.Link === undefined || $scope.video.Link === null || $scope.video.Link === "")
                $scope.errorMessagePopup += "Veuillez renseigner le lien du vidéo<br/>";

            if ($scope.video.Position === undefined || $scope.video.Position === null || $scope.video.Position === "")
                $scope.errorMessagePopup += "Veuillez renseigner la position du vidéo<br/>";
            else if (($scope.lstVideo.length === 0 && ($scope.video.Position < 0 || $scope.video.Position > $scope.lstVideo.length + 1) ||
                ($scope.video.IdVideo !== undefined && $scope.lstVideo.length > 0 && ($scope.video.Position < 0 || $scope.video.Position > $scope.lstVideo.length)
				)))
    			$scope.errorMessagePopup += "La position doit être de 1 <br/>";
            else if ($scope.lstVideo.length > 0 && ($scope.video.Position < 0 || $scope.video.Position > ($scope.lstVideo.length + 1)))
    			$scope.errorMessagePopup += "La position doit être comprise entre 1 et " + ($scope.lstVideo.length + 1) + "<br/>";

    		if ($scope.errorMessagePopup !== "") {
    			$scope.errorMessagePopup = $sce.trustAsHtml($scope.errorMessagePopup);
    			return false;
    		}
    		return true;
    	};

    	$scope.SaveVideo = function () {
            videoService.PostVideo($scope.video, function (data, status) {
    			if (status !== null) {
    				if (status == 400) {
    					$scope.errorMessagePopup = $sce.trustAsHtml(data[0].errors[0].Message);
    					$scope.openPopUp();
    					return false;
    				}
    			}
    			else {
    				$scope.lstVideo = $scope.filterGeneral = data;
    				$scope.Filter();
                    $scope.video = {};
                    $scope.successMessage = "Votre vidéo a bien été enregistrée";
    			}
    			return true;
    		});
    	};

    	$scope.DeleteVideo = function (item) {
    		$scope.InitMessage();
    		videoService.DeleteVideo(item.IdVideo, function (data, status) {
    			if (status !== null) {
    				if (status == 400)
    					$scope.errorMessage = data[0].errors[0].Message;
    				else
    					$scope.errorMessage = status;
    			} else {
    				$scope.lstVideo = $scope.filterGeneral = data;
    				$scope.Filter();
                    $scope.video = {};
                    $scope.successMessage = "Votre vidéo a bien été supprimé";
    			}
    		});
    	};

    	$scope.GetData = function (pageNo) {
    		$scope.pageNo = pageNo;
    		$scope.GetVideo();
    	};

    	$scope.Sort = function (sortKey, reverse) {
    		$scope.data.sortKey = sortKey;
    		$scope.data.reverse = reverse;
    		$scope.GetVideo();
    	};

    	$scope.$watch("searchText", function (newValue, oldValue) {
    		if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
    			$scope.GetVideo();
    	});

        $scope.Init = function () {            
    		$scope.tableVideoDataVisible = colonnes.data.DataVisible.split(',');
    		$scope.tableVideoLabels = colonnes.data.ColumnName.split(',');
    		$scope.InitMessage();
    		$scope.GetVideo();
    	};

    	$rootScope.onRefresh = function () {
    		$scope.Init();
    	};

    	$scope.Init();
    }]);
