AgrimarketControllers.controller('cropsController', ['$scope', '$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory', 'cropsService', '$filter', 'parametersService', '$sce', 'parametersFactory',
	function ($scope, $rootScope, $http, $location, currentUserFactory, configurationFactory, cropsService, $filter, parametersService, $sce, parametersFactory) {

	    $scope.init = function () {
	        cropsService.GetCrops(function (data, status) {
	            if (status !== null) {
	                if (status == 400)
	                    $scope.errorMessage = data[0].errors[0].Message;
	            } else
	                $scope.crops = data;
	            $scope.ChangeDefaultCrop(parametersFactory.Parameters.DefaultCrop.Value);
	        });
	    };

	    $scope.init();

	    $scope.ChangeDefaultCrop = function (idDefaultCrop) {
	        $scope.crops.forEach(function (crop) {
	            if (crop.IdCrop == idDefaultCrop)
	                crop.IsDefault = true;
	            else
	                crop.IsDefault = false;
	        });
	    };

	    $scope.CheckCrops = function () {
	        var crop = $filter('filter')($scope.crops, { IsDefault: true })[0];
	        if (!crop)
	            $scope.errorMessage = "Veuillez sélectionner une campagne par défaut";
	        else if (!crop.Visible)
	            $scope.errorMessage = "Vous ne pouvez pas définir comme campagne par défaut une campagne qui n'est pas visible.";
	    };

	    $scope.SaveCrops = function () {
	        $scope.errorMessage = "";
	        $scope.validationMessage = "";
	        $scope.CheckCrops();

	        if ($scope.errorMessage !== "") {
	            $scope.errorMessage = $sce.trustAsHtml($scope.errorMessage);
	            return;
	        }

	        cropsService.SaveCrops($scope.crops, function (data, status) {
	            if (status !== null) {
	                if (status == 400)
	                    $scope.errorMessage = data[0].errors[0].Message;
	            }
	            parametersService.SaveParameterByKey("DefaultCrop", $filter('filter')($scope.crops, { IsDefault: true })[0].IdCrop, function (dataParam, statusPram) {
	                if (statusPram !== undefined && statusPram !== null) {
	                    if (status == 400) {
	                        if ($scope.errorMessage !== "")
	                            $scope.errorMessage += "<br />";

	                        $scope.errorMessage += dataParam[0].errors[0].Message;
	                    }
	                }
	                else
	                    parametersFactory.Parameters.DefaultCrop.Value = dataParam.Value;

	                if ($scope.errorMessage === "")
	                    $scope.validationMessage = "Mise à jour effectuée avec succès.";
	                else
	                    $scope.errorMessage = $sce.trustAsHtml($scope.errorMessage);
	            });
	        });
	    };
	    $rootScope.onRefresh = function () { $scope.init(); };
	}]);