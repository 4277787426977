AgrimarketControllers.controller('ValidateCGUController', ['$scope', '$rootScope', '$location', 'userService', 'parametersFactory', 'configurationFactory', 'currentUserFactory',
    function ($scope, $rootScope, $location, userService, parametersFactory, configurationFactory, currentUserFactory) {

        $scope.errorMessage = "";
        $scope.title = "Connexion";

        $scope.cgu = {
            Accepted: false,
            bPdfCGU: false,
            bPdfPrivacyPolicy: false,
            UrlCGU: null,
            UrlPrivacyPolicy: null,
            IdUserToValidate: $rootScope.IdUserToValidateCGU,
            ReceiveNewsMarketInfo: null
        };

        $scope.bAskAuthorizeSendMail = parametersFactory.Parameters.bAskAuthorizeSendMail && parametersFactory.Parameters.bAskAuthorizeSendMail.Value;

        var init = function () {
            if (parametersFactory && parametersFactory.Parameters && parametersFactory.Parameters.FileTermeUse && parametersFactory.Parameters.FileTermeUse.Value) {
                $scope.cgu.bPdfCGU = true;
                $scope.cgu.UrlCGU = configurationFactory.Url['Api'] + 'Upload/GetAttachment?p=' + parametersFactory.Parameters.FileTermeUse.Value;
            }
            if (parametersFactory && parametersFactory.Parameters && parametersFactory.Parameters.FilePrivacyPolicy && parametersFactory.Parameters.FilePrivacyPolicy.Value) {
                $scope.cgu.bPdfPrivacyPolicy = true;
                $scope.cgu.UrlPrivacyPolicy = configurationFactory.Url['Api'] + 'Upload/GetAttachment?p=' + parametersFactory.Parameters.FilePrivacyPolicy.Value;
            }
        };

        $scope.Validate = function () {
            if (!$scope.cgu.Accepted) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_ValidateCGU_NotAccepted.Content;
            }
            else if ($scope.cgu.ReceiveNewsMarketInfo === null && $scope.bAskAuthorizeSendMail === "1") {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_ValidateCGU_ReceiveNewsMarketInfo_NotSelected.Content;
            } else {
                userService.SaveValidatedCGU($scope.cgu.IdUserToValidate, $scope.cgu.ReceiveNewsMarketInfo !== null ? $scope.cgu.ReceiveNewsMarketInfo : '', function (data, status) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                    } else {
                        sessionStorage.setItem("hasValidateCGU", true);
                        $location.path("/login");
                    }
                });
            }
        };

        $rootScope.onRefresh = function () {
            init();
        };

        init();
    }
]);
