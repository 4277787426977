AgrimarketServices.service('quoteService', ['$rootScope', '$http', '$location', 'configurationFactory',
    function ($rootScope, $http, $location, configurationFactory) {

    	var SendRequest = function (configfile, callback) {
    		$http(configfile)
			    .success(function (data) {
			    	callback(data, null, null, null);
			    })
			    .error(function (data, status, headers, config) {
			    	callback(data, status, headers, config);
			    });
    	};

    	this.AddMarket = function (market, callback) {
    		var config = {
    			method: 'POST',
    			url: configurationFactory.Url['Api'] + 'Quote/PostMarket',
    			data: market
    		};
    		SendRequest(config, callback);
    	};
	    
    	this.DeleteQuoteMarket = function (market, callback) {
    		var config = {
    			method: 'DELETE',
    			url: configurationFactory.Url['Api'] + 'Quote/DeleteQuoteMarket',
    			params: { idmarket: market.IdQuoteMarket }
    		};
    		SendRequest(config, callback);
    	};
	    
    	this.GetMarkets = function (callback) {
    		var config = {
    			method: 'GET',
    			url: configurationFactory.Url['Api'] + 'Quote/GetMarkets'
    		};
    		SendRequest(config, callback);
    	};
	    
	    
    	this.GetQuoteExpiryBody = function (callback) {
    		var config = {
    			method: 'GET',
    			url: configurationFactory.Url['Api'] + 'Quote/GetQuoteExpiryBody'
    		};
    		SendRequest(config, callback);
    	};
	    
    	this.PutQuoteExpiry = function (lstmarkets, callback) {
    		var config = {
    			method: 'Put',
    			url: configurationFactory.Url['Api'] + 'Quote/PutQuoteExpiry',
    			data: lstmarkets
    		};
    		SendRequest(config, callback);
    	};
	    
    	this.GetSettleDataFromEuronext = function (idExpiry, idMarket, shortData, callback) {
    		var config = {
    			method: 'GET',
    			url: configurationFactory.Url['Api'] + 'Quote/GetSettleDataFromEuronext',
    			params: {
    				idExpiry: idExpiry,
    				idMarket: idMarket,
    				shortData: shortData
    			}
    		};
    		SendRequest(config, callback);
    	};
	    
    	this.GetLstMarketsCotationsDelayed = function (callback) {
    		var config = {
    			method: 'GET',
    			url: configurationFactory.Url['Api'] + 'Quote/GetLstMarketsCotationsDelayed'
    		};
    		SendRequest(config, callback);
    	};
    }]);