AgrimarketServices.service('templatesService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null, null, null);
                })
                .error(function (data, status, headers, config) {
                    callback(data, status, headers, config);
                });
        };

        this.GetTemplates = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Template/GetTemplates'
            };
            SendRequest(config, callback);
        };
        this.GetTemplateById = function (idTemplate,callback) {
        	var config = {
        		method: 'GET',
        		url: configurationFactory.Url['Api'] + 'Template/GetTemplateById',
        		params: { idTemplate: idTemplate }
        	};
        	SendRequest(config, callback);
        };

        this.GetAllTypeTemplate = function(callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Template/GetTypeTemplates'
            };
            SendRequest(config, callback);
        };       

        this.SaveTemplate = function (template, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Template/SaveTemplate',
                data: template
            };
            SendRequest(config, callback);
        };

        this.UpdateTemplate = function (template, callback) {
            var config = {
                method: 'PUT',
                url: configurationFactory.Url['Api'] + 'Template/UpdateTemplate',
                data: template
            };
            SendRequest(config, callback);
        };

        this.DeleteTemplate = function (idTemplate, callback) {
            var config = {
                method: 'DELETE',
                url: configurationFactory.Url['Api'] + 'Template/DeleteTemplate',
                params: { idTemplate: idTemplate }
            };

            SendRequest(config, callback);
        };

        this.DisableTemplate = function (idTemplate, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Template/DisableTemplate',
                params: { idTemplate: idTemplate }
            };

            SendRequest(config, callback);
        };

        this.OverviewTemplate = function (template, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Template/OverviewTemplate',
                data: template
            };
            SendRequest(config, callback);
        };
    }]);