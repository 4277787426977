
AgrimarketDirectives.directive('translate', function () {
	return {
		link: function(scope, element, attrs) {

			scope[attrs.displayBloc] = function () {
				element.animate({ 'left': 0 }, 300, "easeInOutQuart");
			};

			scope[attrs.hideBloc] = function () {
				var widthContainer = element.outerWidth(true);

				element.animate({ 'left': widthContainer }, 200, "easeInOutQuart", function () { $(this).css('left', -widthContainer); });
			};
		}
	};
});