AgrimarketServices.service('criteriaOfferService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null, null, null);
                })
                .error(function (data, status, headers, config) {
                    callback(data, status, headers, config);
                });
        };

        this.GetAllCriteriaOffers = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'criteriaOffer/GetAllCriteriaOffers'
            };
            SendRequest(config, callback);
        };

    }]);