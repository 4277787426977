// Gestion des accordéons

// @Author : Alexandre Riquet
// [accordionDirective]
// @Param : singleExpander => option permettant de limiter l'ouverture à un seul accordéon.
// [expanderDirective]
// @Param : title => titre de l'accordéon.
// @Param : url => url permettant d'accéder à la page des cotations.
// @Param : openDefault => bool gérant l'état par défaut (déplié ou non).

AgrimarketDirectives.directive('accordion', function () {
    return{
        restrict: 'EA',
        replace: true,
        transclude: true,
        template: '<div ng-transclude></div>',
        scope: {
            singleExpander: '=singleExpander'
        },
        controller: function ($scope) {
            var expanders = [];

            this.gotOpened = function(selectedExpander){
                expanders.forEach(function (expander) {
                    if($scope.singleExpander !== false && selectedExpander !== expander){
                        expander.showMe = false;
                    }
                });
            };
            this.addExpander = function (expander) {
                if (expanders.length === 0)
                    expander.showMe = true;
                expanders.push(expander);
            };
        }
    };
});

AgrimarketDirectives.directive('expander', function () {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        require: '^?accordion',
        scope: {
            title: '=expanderTitle',
            icon: '=expanderIcon',
            url: '@linkToQuotes',
            openDefault: '=openDefault'
        },
        template: '<div>' +
                '<div class="accordion-title" ng-class="{deploy : showMe == true}" ng-click="toggle()">' +
                    '<span ng-if="icon" class="ico-title {{icon}}"></span>' +
                    '<p>{{title}}</p>' +
                    '<a href="{{url}}" class="ico-table" data-help-tooltip="Accéder aux cotations" ng-if="url"></a>' +
                    '<span class="ico-accordion"></span></div>' +
                '<div class="accordion-content" ng-show="showMe" ng-transclude></div>' +
            '</div>',
        link: function (scope, element, attrs, accordionController) {
            accordionController.addExpander(scope);
            scope.toggle = function toggle() {
                scope.showMe = !scope.showMe;
                accordionController.gotOpened(scope);
            };
        }
    };
});