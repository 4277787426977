AgrimarketControllers.controller('editTemplateMailController', ['$scope', '$rootScope', '$routeParams', '$sce', '$filter', '$http', '$location', 'currentUserFactory', 'configurationFactory', '$log', 'enumFactory', 'templateMailsService', 'tagsService',
    function ($scope, $rootScope, $routeParams, $sce, $filter, $http, $location, currentUserFactory, configurationFactory, $log, enumFactory, templateMailsService, tagsService) {


        var Init = function () {
            $scope.viewMode = (!$routeParams.viewMode && !$scope.viewMode) ? 'Contract' : $routeParams.viewMode;
            $scope.errorMessage = "";
            $scope.tagselected = "";
            $scope.enumRoles = angular.copy(enumFactory.ListRoles);
            $scope.enumTypeTemplateMails = enumFactory.ListRoles;
            $scope.template = {
                IdTypeTemplateMail: $routeParams.idTypeTemplateMail,
                IdRole: $routeParams.idRole,
                IdLanguage: $routeParams.idLanguage,
                Content: ""
            };
            $scope.GetTemplate();
            $scope.GetTypeTemplateMails();
            $scope.GetTags();
            $scope.Focus = '';
        };

        $scope.GetTemplate = function () {
            templateMailsService.GetTemplateByIdTypeTemplateMailAndIdRoleAndIdLanguage($scope.template.IdTypeTemplateMail, $scope.template.IdRole, $scope.template.IdLanguage, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    }
                } else {
                    $scope.template = data;
                }
            });
        };

        $scope.GetTags = function () {
            tagsService.GetTagsForTemplatesMails($scope.template.IdTypeTemplateMail, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.lstTags = data;
                    $scope.enumRoles = angular.copy(enumFactory.ListRoles);
                    if (enumFactory.ListRoles[0].Name === "Admin" && ($scope.template.IdTypeTemplateMail === enumFactory.TypesMails["Mot de passe perdu"] || $scope.template.IdTypeTemplateMail === enumFactory.TypesMails["Envoyer les identifiants"]))
                        $scope.enumRoles.splice(0, 1);
                    if (enumFactory.ListRoles[1].Name === "Agriculteur" && $scope.template.IdTypeTemplateMail === enumFactory.TypesMails["Demande de tarif / disponibilité"])
                        $scope.enumRoles.splice(1, 1);
                }
            });
        };

        $scope.AddTag = function () {
            if ($scope.Focus && $scope.Focus == "mailObject") {
                $scope.AddTagInput($scope.tagselected);
            } else {
                CKEDITOR.instances.txtTemplate.insertHtml($scope.tagselected);
            }
        };

        $scope.AddTagInput = function (tag) {
            var el = $scope.ActiveElementMailObject;
            var start = el.selectionStart;
            var end = el.selectionEnd;
            var text = el.value;
            var before = text.substring(0, start);
            var after = text.substring(end, text.length);
            el.value = (before + tag + after);
            $scope.template.Object = el.value;
        };

        $scope.FocusMailObject = function () {
            $scope.Focus = 'mailObject';
            $scope.ActiveElementMailObject = document.activeElement;
        };
        
        $scope.GetTypeTemplateMails = function () {
            templateMailsService.GetAllTypeTemplateMails($scope.template.IdLanguage, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.typeTemplateMails = data.filter(function (element) { return element.IdTypeTemplateMail !== enumFactory.TypesMails["Rejet d'un ordre"] && element.IdTypeTemplateMail !== enumFactory.TypesMails["Annulation partielle d'un ordre"]; });
                }
            });
        };

        $scope.Cancel = function () {
            $location.path('/admin/editorial/view/mails/' + $routeParams.idLanguage + '/' + $scope.viewMode);
        };

        $scope.Save = function () {
            if (!$scope.CheckTemplate()) return;
            templateMailsService.SaveTemplateMail($scope.template, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    if ($scope.txtBtnSave === "Modifier")
                        $rootScope.succesMessageTemplate = "Vos modifications ont bien été prises en compte.";
                    else
                        $rootScope.succesMessageTemplate = "Votre template a bien été pris en compte.";
                    $scope.Cancel();
                }
            });
        };

        $scope.CheckTemplate = function () {
            $scope.errorMessage = "";
            if (!$scope.template.Object)
                $scope.errorMessage += "Veuillez renseigner un objet de mail.<br/>";

            if (!$scope.template.IdTypeTemplateMail)
                $scope.errorMessage += "Veuillez renseigner un type de mail.<br/>";

            if (!$scope.template.IdRole)
                $scope.errorMessage += "Veuillez renseigner un destinataire.<br/>";

            if (!$scope.template.Content)
                $scope.errorMessage += "Veuillez renseigner un contenu pour le mail.<br/>";

            for (var i = 0; i < $scope.template.Content.length; i++) {
                if ($scope.template.Content[i] === "{" && $scope.template.Content[i + 1] === "{") {
                    var key = "{{";
                    for (var j = i + 2; i < $scope.template.Content.length; j++) {
                        if ($scope.template.Content[j] === "}" && $scope.template.Content[j + 1] === "}") {
                            key += "}}";

                            if (key.indexOf("&nbsp;") !== -1) { key = key.replace("&nbsp;", " "); }
                            if (key.indexOf('@') !== -1) {
                                var params = key.substring(key.indexOf('@') + 1, key.indexOf(' }}'));
                                var isNumber = params.match("[0-9]+");

                                if (params === 'X' || isNumber === null) {
                                    $scope.errorMessage = "Une des balises insérée nécessite une valeur numérique : " + key + " Remplacez 'X' par un numéro.";
                                    return false;
                                }

                                key = key.replace(params, 'X');
                            }
                            
                            if ($filter('filter')($scope.lstTags, { "KeyTag": key }, true)[0] === undefined)
                                $scope.errorMessage = "Une des balises insérée n'existe pas pour ce template : " + key;
                            break;
                        }
                        key += $scope.template.Content[j];
                    }
                }
            }

            if ($scope.errorMessage) {
                $scope.errorMessage = $sce.trustAsHtml($scope.errorMessage);
                return false;
            }
            else
                return true;
        };

        $scope.UploadImage = function (data) {
            CKEDITOR.instances.txtTemplate.insertHtml("<img src='" + $filter('uploadLinkInline')(data.PrivateLabel) + "'>");
        };

        $rootScope.onRefresh = function () {
            Init();
        };

        Init();
    }]);