AgrimarketDirectives.directive('editable', function () {
	return {
		transclude: true,
		replace: false,
		restrict: 'EA',
	    // data-editable-content =>  pour éviter les conflits de style (cf. reset.scss) 
        template: '<span ng-click="blockClick()"><ng-transclude></ng-transclude><span ng-bind-html="contentBody" class="editable-content"></span></span><span ng-click="icoAction()" ng-if="icoVisible" class="bt_edit {{icoAlterClass}} ico-content-edit"></span>',
		scope: {
			icoAction: '&editableIcoAction',
			hrefLink: '@?editableHref',
			contentAction: '&editableContentAction',
			icoVisible: '=?editableIcoVisible',
			contentBody: "=?editableContentBody",
			contentVisible: "=?editableContentVisible",
            key: "@?editableKey",
            icoAlterClass:'@?editableIcoAlterClass'
		},
		link: function (scope, elm, attrs, ctrl, transclude) {

            if (scope.icoVisible == 'false')
                scope.icoVisible = false;

            if (scope.contentVisible == 'false')
                scope.contentVisible = false;

			scope.blockClick = function () {
				if (scope.hrefLink)
					window.location.replace(scope.hrefLink);
				if (scope.contentAction)
					scope.contentAction();
			};

			scope.$watch(
				'contentBody',
				  function (newValue, oldValue) {
				  	if (newValue !== oldValue) {
				  		var e = elm.find('ng-transclude');
				  		e.remove();
				  	}
				  }
            );

            if (!scope.icoVisible && !scope.contentVisible) {
				elm.css("display", "none");
				// On va chercher tous les élements qui dépendent de cet objet éditable afin de les cacher également
				var dependeciesElements = document.querySelectorAll("[editable-dependency-key='" + scope.key + "']");
				dependeciesElements.forEach(function (item) {
					$(item).css("display", "none");
				});
			}

			transclude(scope, function (clone) {
				if (!scope.contentBody) {
					var e = elm.find('ng-transclude');
					e.append(clone);
				}
			});
		}
	};
});