AgrimarketServices.service('typeDashboardActualityAppMobileService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

    	var SendRequest = function (configfile, callback) {
    		$http(configfile)
			    .success(function (data) {
			    	callback(data, null, null, null);
			    })
			    .error(function (data, status, headers, config) {
			    	callback(data, status, headers, config);
			    });
    	};

    	this.GetAllTypeDashboardActualityAppMobile = function (callback) {
    		var config = {
    			method: 'GET',
    			url: configurationFactory.Url['Api'] + 'TypeDashboardActualityAppMobile/GetAll'
    		};
    		SendRequest(config, callback);
        };

		this.SaveTypeDashboardActualityAppMobileOrder = function (typeDashboardActualityAppMobile, callback) {
    		var config = {
    			method: 'PUT',
    			url: configurationFactory.Url['Api'] + 'TypeDashboardActualityAppMobile/PutTypeDashboardActualityAppMobile',
    			headers: { 'Content-Type': 'application/json' },
    			data: typeDashboardActualityAppMobile
    		};
    		SendRequest(config, callback);
    	};

    }]);