AgrimarketServices.service('fluxrssService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

    	var SendRequest = function (configfile, callback) {
    		$http(configfile)
                .success(function (data) {
                	callback(data, null, null, null);
                })
                .error(function (data, status, headers, config) {
                	callback(data, status, headers, config);
                });
    	};

    	this.GetFluxPaginateWithFilter = function (searchText, max, pageNo, sortKey, reverse, callback) {
    		var config = {
    			method: 'GET',
    			url: configurationFactory.Url['Api'] + 'FluxRss/GetFluxPaginateWithFilter',
    			params: {
    				searchText: searchText,
    				itemPerPage: max,
    				pageNo: pageNo,
    				sortKey: sortKey,
    				reverse: reverse
    			}
    		};
    		SendRequest(config, callback);
    	};

    	this.PostFlux = function (data, callback) {
    		var config = {
    			method: 'POST',
    			url: configurationFactory.Url['Api'] + 'FluxRss/PostFlux',
    			data: data
    		};
    		SendRequest(config, callback);
    	};

	    this.DeleteFlux = function(idFluxRss, callback) {
		    var config = {
			    method: 'DELETE',
			    url: configurationFactory.Url['Api'] + 'FluxRss/DeleteFlux',
			    params: { idFluxRss: idFluxRss }
		    };
		    SendRequest(config, callback);
	    };
    }]);