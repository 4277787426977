AgrimarketServices.service('offerVisibilityService', ['$http', 'configurationFactory',
    function ($http, configurationFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null);
                })
                .error(function (data, status) {
                    callback(data, status);
                });
        };

        this.GetOfferVisibilities = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'VisibilityOffer/GetAll'
            };
            SendRequest(config, callback);
        };
    }]);