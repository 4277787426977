AgrimarketControllers.filter('formatDataTable', function ($filter, $sce) {
	return function (data, key) {
		var result = "";
        if (data !== null && data !== undefined) { // ne pas mettre if(data), car data peut valoir false

			// Gestion du tofixed
			var numToFixed = null;
			if (key && key.indexOf('tofixed') !== -1 && key.length == 8) {
				numToFixed = key.slice(-1);
				key = 'tofixed';
            }

			switch (key) {
				case 'date': result = $filter('date')(data, 'dd/MM/yyyy').toString();
					break;
				case 'fulldate': result = $filter('date')(data, 'dd/MM/yyyy HH:mm:ss').toString();
					break;
				case "fulldatewithoutsec": result = $filter("date")(data, "dd/MM/yyyy HH:mm").toString();
					break;
				case 'hours': result = $filter('date')(data, 'HH:mm').toString();
					break;
				case 'bool': result = data ? "oui" : "non";
					break;
				case 'lien': result = "<a target='_blank' href='" + data + "'>" + data + "</a>";
					break;
				case 'liste': result += "<ul>";
					data.forEach(function (item) { result += "<li>" + item + "</li>"; });
					result += "</ul>";
					break;
				case 'tofixed': result = data.toFixed(numToFixed).toString();
					break;
				default: result = data.toString();
					break;
			}
		}
		return $sce.trustAsHtml(result);
	};
});