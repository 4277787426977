AgrimarketControllers.controller('carouselPictureController', ['$scope', '$rootScope', '$sce', '$location', 'configurationFactory', '$upload', 'carouselPictureService', 'colonnes',
    function ($scope, $rootScope, $sce, $location, configurationFactory, $upload, carouselPictureService, colonnes) {

    	if (!$rootScope.ModuleNews.Active)
    		$location.path('/admin/parameters/global');

        $scope.uploads = {};
    	$scope.searchText = "";
    	$scope.errorMessage = "";
    	$scope.lstCarouselPicture = [];
        $scope.carouselPicture = {};
    	$scope.InitMessage = function () {
    		$scope.errorMessage = "";
    		$scope.successMessage = "";
    		$scope.errorMessagePopup = "";
    	};
    	$scope.data = { reverse: false, sortKey: "Position" };
    	$scope.pageNo = 1;
    	$scope.max = 10;
        
        $scope.GetCarouselPicture = function () {
            carouselPictureService.GetCarouselPicturePaginateWithFilter($scope.searchText, $scope.max, $scope.pageNo, $scope.data.sortKey, $scope.data.reverse, function (data, status) {
    			if (status !== null) {
    				if (status === 400)
    					$scope.errorMessage = data[0].errors[0].Message;
    				else
    					$scope.errorMessage = data;
    			}
    			else {
                    $scope.lstCarouselPicture = data.ListVm;
    				$scope.total = data.Total;
    			}
    		});
    	};

        $scope.AddCarouselPicture = function (popup) {
            $scope.InitMessage();
            $scope.carouselPicture = {};
            $scope.uploads.fileName = '';
            $scope.txtButton = "Ajouter";
            angular.element("input[type='file']").val(null);
            $scope.modalTitle = "Ajouter une image";
            popup();
        };

        $scope.onFileSelect = function ($files) {
            $scope.carouselPicture.IdAttachment = undefined;

            var upload = function (i, $file) {
                $scope.uploads.fileName = '';
                $scope.uploads.percent = '';
                $scope.errorMessage = null;
                $upload.upload({
                    url: configurationFactory.Url['Api'] + 'Upload/Upload', // webapi url
                    method: "POST",
                    params: { idAttachment: -1, fromCarousel: true },
                    file: $file
                }).progress(function (evt) {
                    // get upload percentage
                    $scope.uploads.percent = parseInt(100.0 * evt.loaded / evt.total) + '%';
                }).success(function (data) {
                    // file is uploaded successfully
                    $scope.carouselPicture.IdAttachment = data.IdAttachment;
                    $scope.uploads.fileName = data.PublicLabel;
                }).error(function (data) {
                    $scope.errorMessagePopup = data;
                });
            };

            for (var i = 0; i < $files.length; i++) {
                var $file = $files[i];
                upload(i, $file);
            }
        };

        $scope.EditCarouselPicture = function (item, index, openPoPup) {
    		$scope.InitMessage();
            $scope.carouselPicture = angular.copy(item);
            $scope.uploads.fileName = $scope.carouselPicture.Attachment.PublicLabel;
            angular.element("input[type='file']").val(null);
            $scope.uploads.percent = "100%";
    		$scope.txtButton = "Modifier";
    		$scope.modalTitle = "Modifier une image";
            openPoPup();
    	};

        $scope.CheckCarouselPicture = function () {
    		$scope.InitMessage();
            if ($scope.carouselPicture.Label === undefined || $scope.carouselPicture.Label === null || $scope.carouselPicture.Label === "")
                $scope.errorMessagePopup += "Veuillez renseigner un nom de l'image<br/>";
            else if ($scope.carouselPicture.Label.length > 20)
                $scope.errorMessagePopup += "Veuillez renseigner un nom de l'image de 20 caractères maximum<br/>";

            if ($scope.carouselPicture.IdAttachment === undefined || $scope.carouselPicture.IdAttachment === 0 || $scope.carouselPicture.IdAttachment === "")
                $scope.errorMessagePopup += "Veuillez inserer une image<br/>";

            if ($scope.carouselPicture.Position === undefined || $scope.carouselPicture.Position === null || $scope.carouselPicture.Position === "")
                $scope.errorMessagePopup += "Veuillez renseigner la position de l'image<br/>";
            else if (($scope.lstCarouselPicture.length === 0 && ($scope.carouselPicture.Position < 0 || $scope.carouselPicture.Position > $scope.lstCarouselPicture.length + 1) ||
                ($scope.carouselPicture.IdCarouselPicture !== undefined && $scope.lstCarouselPicture.length > 0 && ($scope.carouselPicture.Position < 0 || $scope.carouselPicture.Position > $scope.lstCarouselPicture.length)
				)))
    			$scope.errorMessagePopup += "La position doit être de 1 <br/>";
            else if ($scope.lstCarouselPicture.length > 0 && ($scope.carouselPicture.Position < 0 || $scope.carouselPicture.Position > ($scope.lstCarouselPicture.length + 1)))
                $scope.errorMessagePopup += "La position doit être comprise entre 1 et " + ($scope.lstCarouselPicture.length + 1) + "<br/>";

    		if ($scope.errorMessagePopup !== "") {
    			$scope.errorMessagePopup = $sce.trustAsHtml($scope.errorMessagePopup);
    			return false;
    		}
    		return true;
    	};

        $scope.SaveCarouselPicture = function () {
            carouselPictureService.PostCarouselPicture($scope.carouselPicture, function (data, status) {
    			if (status !== null) {
    				if (status == 400) {
    					$scope.errorMessagePopup = $sce.trustAsHtml(data[0].errors[0].Message);
    					$scope.openPopUp();
    					return false;
    				}
    			}
    			else {           
                    $scope.carouselPicture = {};
                    $scope.successMessage = "Votre image a bien été enregistrée";
                    $scope.GetCarouselPicture();
    			}
    			return true;
            });
    	};

        $scope.DeleteCarouselPicture = function (item) {
    		$scope.InitMessage();
            carouselPictureService.DeleteCarouselPicture(item.IdCarouselPicture, function (data, status) {
    			if (status !== null) {
    				if (status == 400)
    					$scope.errorMessage = data[0].errors[0].Message;
    				else
    					$scope.errorMessage = status;
    			} else {
                    $scope.carouselPicture = {};
                    $scope.successMessage = "Votre image a bien été supprimé";
                    $scope.GetCarouselPicture();
    			}
            });     
    	};


    	$scope.GetData = function (pageNo) {
    		$scope.pageNo = pageNo;
            $scope.GetCarouselPicture();
    	};

    	$scope.Sort = function (sortKey, reverse) {
    		$scope.data.sortKey = sortKey;
    		$scope.data.reverse = reverse;
            $scope.GetCarouselPicture();
    	};

    	$scope.$watch("searchText", function (newValue, oldValue) {
    		if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                $scope.GetCarouselPicture();
    	});

        $scope.Init = function () {            
            $scope.tableCarouselPictureDataVisible = colonnes.data.DataVisible.split(',');
            $scope.tableCarouselPictureLabels = colonnes.data.ColumnName.split(',');
    		$scope.InitMessage();
            $scope.GetCarouselPicture();
    	};

    	$rootScope.onRefresh = function () {
    		$scope.Init();
    	};

    	$scope.Init();
    }]);
