AgrimarketServices.service('writingContentInfoMarketService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
			    .success(function (data) {
			        callback(data, null);
			    })
			    .error(function (data, status) {
			        callback(data, status);
			    });
        };

        this.SaveContentInfoMarket = function (data, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'WritingContentInfoMarket/SaveContentInfoMarket',
                data: data
            };
            SendRequest(config, callback);
        };

        this.GetContentsInfoMarketByFilter = function(idWritingContentInfoMarket, searchValue, itemsPerPage, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                params: { idWritingContentInfoMarket: idWritingContentInfoMarket, searchValue: searchValue, itemsPerPage: itemsPerPage, pageNo: pageNo, sortKey: sortKey, reverse: reverse },
                url: configurationFactory.Url['Api'] + 'WritingContentInfoMarket/GetContentsInfoMarketByFilter'
            };
            SendRequest(config, callback);
        };

        this.GetItemsContentsByAutoComplete = function(searchString, timeoutPromise, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'WritingContentInfoMarket/GetItemsContentsByAutoComplete',
                params: { searchString: searchString }
            };
            SendRequest(config, callback);
        };

        this.GetContentInfoMarketById = function (idWritingContentInfoMarket, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'WritingContentInfoMarket/GetContentInfoMarketById',
                params: { idWritingContentInfoMarket: idWritingContentInfoMarket }
            };
            SendRequest(config, callback);
        };

        this.DeleteContentInfoMarket = function (idWritingContentInfoMarket, callback) {
            var config = {
                method: 'DELETE',
                url: configurationFactory.Url['Api'] + 'WritingContentInfoMarket/DeleteContentInfoMarket',
                params: { idWritingContentInfoMarket: idWritingContentInfoMarket }
            };

            SendRequest(config, callback);
        };

    }]);