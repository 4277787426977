AgrimarketControllers.controller('productsController', ['$scope', '$rootScope', '$http', '$location', '$filter', 'currentUserFactory', 'configurationFactory', 'productsService', 'modulesFactory',
	function ($scope, $rootScope, $http, $location, $filter, currentUserFactory, configurationFactory, productsService, modulesFactory) {

		$scope.products = [];
		$scope.Ckeditor = false;
		$scope.imageLibrary = [];
		$scope.productId = undefined;
		$scope.selectedIdImageLibrary = undefined;
		$scope.Variety = {};
        $scope.criteriaContent = "";
		$scope.imageLibraryErrorMessage = "";
		$scope.selectedProductName = "";
		$rootScope.loading = false;
		

		var GetProducts = function () {
			$scope.loading = true;
			productsService.GetAllProducts(function (data, status) {
				if (status !== null) {
					if (status === 400)
						$scope.productDefaultErrorMessage = data[0].errors[0].Message;
				}
				$scope.products = data;
				$scope.showDetails = [];
				$scope.products.forEach(function (item, index) {
					$scope.showDetails[index] = false;
				});
				$scope.loading = false;
			});
		};

		var GetImageLibrary = function () {
			productsService.GetAllImgProducts(function (data, status) {
				if (status !== null) {
					if (status === 400)
						$scope.productDefaultErrorMessage = data[0].errors[0].Message;
				}
				$scope.imageLibrary = data.images;
			});
		};

		$scope.ClosePopUpCkeditor = function () {
            $scope.Ckeditor = false;
            $rootScope.modalVisibility = false;
		};

		$scope.SaveCriteriaVariety = function (criteriaContent) {
		    $scope.Variety.Criteria = criteriaContent;
			$scope.Ckeditor = false;
			$rootScope.modalVisibility = false;
		    productsService.SaveVariety($scope.Variety, function (data, status) {
		        if (status !== null) {
		            if (status === 400)
		                $scope.productDefaultErrorMessage = data[0].errors[0].Message;
		        }
		    });
		};

		$scope.EditProductCriteria = function (item) {
			$scope.Variety = item;
            $scope.Ckeditor = true;
            $rootScope.modalVisibility = true;
			$scope.criteriaContent = item.Criteria;
		};

		$scope.EditImageLibraryPopUp = function (product, showPopUpImageLibrary) {
			$scope.selectedProductName = "Sélectionner l’image de "+ product.Label  + " à présenter sur l’application";
			$scope.imageLibraryErrorMessage = "";
			$scope.productId = product.IdProduct;
			$scope.selectedIdImageLibrary = null;
			if (product.IdImageLibrary) {
				$scope.selectedIdImageLibrary = product.IdImageLibrary;
			}
			$scope.productDefaultErrorMessage = "";
			showPopUpImageLibrary();
		};

		var CheckIsNotAFloat = function (n) {
			return n % 1 === 0;
		};

		var CheckValidOrderData = function (data, isProduct) {
			$scope.productDefaultErrorMessage = "";
			$scope.productValidMessage = "";
			if (!CheckIsNotAFloat(data.NewOrder)) {
				$scope.productDefaultErrorMessage += "Le nombre doit être un entier.";
				return false;
			}

			if (!angular.isNumber(data.NewOrder)) {
				$scope.productDefaultErrorMessage += "L'ordre doit être un nombre";
				return false;
			}
			if (isProduct && (data.NewOrder < 1 || data.NewOrder > $scope.products.length)) {
				$scope.productDefaultErrorMessage += "L'ordre doit être compris entre 1 et " + $scope.products.length;
				return false;
			} else if (!isProduct) {
				var product = $filter('filter')($scope.products, { "IdProduct": data.IdProduct }, true)[0];
				if (data.NewOrder < 1 || data.NewOrder > product.Varieties.length) {
					$scope.productDefaultErrorMessage += "L'ordre doit être compris entre 1 et " + product.Varieties.length;
					return false;
				}
			}
			return true;
		};


		var RemoveSelectedInput = function () {
			$scope.products.forEach(function (p) {
				p.Selected = false;
				p.Editing = false;
				p.Varieties.forEach(function (v) {
					v.Selected = false;
					v.Editing = false;
				});
			});
		};


		$scope.ConfirmImageChoose = function (val) {
			$scope.selectedIdImageLibrary = val;
		};

		$scope.EditProductOrVariety = function (data) {
			$scope.productDefaultErrorMessage = "";
			$scope.productValidMessage = "";
			RemoveSelectedInput();
			data.Editing = true;
			data.Selected = true;
		};

		$scope.SaveProductOrder = function (product) {
			product.Editing = false;

			if (CheckValidOrderData(product, true)) {
				productsService.SaveProduct(product, function (data, status) {
					if (status !== null) {
						if (status === 400)
							$scope.productDefaultErrorMessage = data[0].errors[0].Message;
							$scope.openPopUp();
					}
					$scope.productValidMessage = "Vos modifications ont bien été prises en compte.";
					GetProducts();
				});
			} else
				ErrorData(product);
		};

		$scope.CheckImageLibraryIsValid = function () {
			$scope.imageLibraryErrorMessage = productsService.CheckImageLibraryIsValid($scope.selectedIdImageLibrary);
			if ($scope.imageLibraryErrorMessage !== "") {
				return false;
			}
			else {
				return true;
			}
		};

		$scope.SaveImageProduct = function () 
		{
			var productImage = {
				"IdProduct": $scope.productId,
				"IdImageLibrary" : $scope.selectedIdImageLibrary
			};
			productsService.PutImgProduct(productImage, function (data, status) {
				if (status !== null) {
					if (status === 400)
						$scope.productDefaultErrorMessage = data[0].errors[0].Message;
				}
				else {
					$scope.productValidMessage = "Vos modifications ont bien été prises en compte.";
					GetProducts();
				}
			});
			$scope.selectedIdImageLibrary = null;

		};

		$scope.SaveVarietyOrder = function (variety) {
			variety.Editing = false;
			if (CheckValidOrderData(variety, false)) {
				productsService.SaveVariety(variety, function (data, status) {
					if (status !== null) {
						if (status === 400)
							$scope.productDefaultErrorMessage = data[0].errors[0].Message;
					}
					$scope.productValidMessage = "Vos modifications ont bien été prises en compte.";
					GetProducts();
				});
			} else
				ErrorData(variety);
		};

		var ErrorData = function (productOrVariety) {
			productOrVariety.Editing = true;
			productOrVariety.Selected = true;
		};

		GetProducts();
		GetImageLibrary();

		$rootScope.onRefresh = function () { GetProducts(); GetImageLibrary(); };
	}]);