/*
*   Directive d'ouverture de pop up de confirmation
*	2 parametres sont nécéssaire :
*	- confirmMessage : text du boutton de confirmation
*	- cancelMessage : text du bouton d'annulation
*
*   2 fonctions sont nécéssaires:
*       -callFunctionName: ouvre la modal
*       -confirmFunction: fonction a lancer apres la confirmation
*/

AgrimarketDirectives.directive('popupdetails', function ($timeout) {
	var globParam;
	return function (scope, iElement, iAttrs) {

		scope[iAttrs.callFunctionName] = function (param) {
			globParam = param;

			var parampop = {
				resizable: false,
				draggable: false,
				modal: true,
				position: {
					my: "center",
					at: "center",
					of: window,
					collision: "none"
				},
				create: function (event, ui) {
					iElement.parent().css('position', 'fixed');
				}
			};

			var viewportWidth = window.innerWidth - 20;
			var viewportHeight = window.innerHeight - 20;
			if (iAttrs.popupTitle) {
			    parampop.title = (scope[iAttrs.popupTitle] !== undefined ? scope[iAttrs.popupTitle] : iAttrs.popupTitle);
		    }
		    if (iAttrs.popupWidth !== undefined && iAttrs.popupWidth) {
		        if (viewportWidth > 1000 && iAttrs.popupWidth <= viewportWidth) viewportWidth = (scope[iAttrs.popupWidth] !== undefined ? scope[iAttrs.popupWidth] : iAttrs.popupWidth);
		    } else {
		        if (viewportWidth > 1000) viewportWidth = "auto";
		    }
			
		    if (iAttrs.popupHeight !== undefined && iAttrs.popupHeight) {
		        if (viewportHeight > 500)
					viewportHeight =
					scope[iAttrs.popupHeight] !== undefined ? scope[iAttrs.popupHeight] <= viewportHeight? scope[iAttrs.popupHeight]: viewportHeight : !isNaN(iAttrs.popupHeight) ? iAttrs.popupHeight : "auto";
				}
		    else {
		        if (viewportHeight > 500) viewportHeight = "auto";
		    }
		    
		    parampop.height = viewportHeight;
		    parampop.width = viewportWidth;

			iElement.dialog(parampop);
		};
	};
});