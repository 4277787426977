/*global define, CKEDITOR*/

(function (angular, factory) {
    if (typeof define === 'function' && define.amd) {
        define(['angular', 'ckeditor'], function (angular) {
            return factory(angular);
        });
    } else {
        return factory(angular);
    }
}(angular || null, function (angular) {
    var app = angular.module('ngCkeditor', []);
    var $defer, loaded = false;

    app.run(['$q', '$timeout', function ($q, $timeout) {
        $defer = $q.defer();

        if (angular.isUndefined(CKEDITOR)) {
            throw new Error('CKEDITOR not found');
        }
        CKEDITOR.disableAutoInline = true;
        function checkLoaded() {
            if (CKEDITOR.status === 'loaded') {
                loaded = true;
                $defer.resolve();
            } else {
                checkLoaded();
            }
        }
        CKEDITOR.on('loaded', checkLoaded);
        $timeout(checkLoaded, 100);
    }]);

    AgrimarketDirectives.directive('ckeditor', ['$timeout', '$q', '$compile', function ($timeout, $q, $compile) {
    	'use strict';
	    
    	var reload = function (scope, element, attrs, ctrls) {
    		var ngModel = ctrls[0];
    		var form = ctrls[1] || null;
    		var EMPTY_HTML = '<p></p>',
                isTextarea = element[0].tagName.toLowerCase() === 'textarea',
				data = [],
                isReady = false;

    		if (!isTextarea) {
    			element.attr('contenteditable', true);
            }
    		var onLoad = function () {
    			var options = {
    				toolbar: 'full',
    				toolbar_full: [//jshint ignore:line
						{ name: 'document', items: ['Source'] },
						{ name: 'clipboard', groups: ['clipboard', 'undo'], items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
						{ name: 'editing', items: ['Scayt'] },
						{ name: 'links', items: ['Link', 'Unlink'] },
						{ name: 'insert', items: ['Image', 'Table', 'Iframe', 'PageBreak'] },
						'/',
    					{ name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat'] },
						{ name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'], items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl'] },
						{ name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
						{ name: 'colors', items: ['TextColor', 'BGColor'] }
    				],
    				disableNativeSpellChecker: false,
    				uiColor: '#FAFAFA',
    				height: '250px',
    				width: '100%'
                };

    			if (attrs.useSimplyEditor && attrs.useSimplyEditor == "true") {
    				var result = $.grep(options.toolbar_full, function (e) { return e.name == "insert"; });
    				var index = options.toolbar_full.indexOf(result[0]);
    				options.toolbar_full[index] = '/';
    			}

    			options = angular.extend(options, scope[attrs.ckeditor]);

    			var instance = (isTextarea) ? CKEDITOR.replace(element[0], options) : CKEDITOR.inline(element[0], options),
					configLoaderDef = $q.defer();

    			element.bind('$destroy', function () {
    				instance.destroy(
						true //If the instance is replacing a DOM element, this parameter indicates whether or not to update the element with the instance contents.
					);
    			});

                var setModelData = function (setPristine) {
    				var data = instance.getData(); //textArea
    				if (data === '') {
    					data = null;
    				}
    				$timeout(function () { // for key up event
    					if (setPristine !== true || data !== ngModel.$viewValue)
    						ngModel.$setViewValue(data);
    					if (setPristine === true && form)
    						form.$setPristine();
    				}, 0);
    			},
					onUpdateModelData = function (setPristine) {
						if (!data.length) {
							return;
						}

						var item = data.pop() || EMPTY_HTML;
						isReady = false;
						instance.setData(item, function () {
							setModelData(setPristine);
							isReady = true;
						});
                    },
                    onFocus = function (setPristine) {
                        scope[attrs.autoFocus] = setPristine.editor.name;
                    };

    			//instance.on('pasteState',   setModelData);
    			instance.on('change', setModelData);
                instance.on('blur', setModelData);
                instance.on('focus', onFocus);
    			//instance.on('key', setModelData); // for source view

    			instance.on('instanceReady', function () {
    				scope.$broadcast('ckeditor.ready');
    				scope.$apply(function () {
    					onUpdateModelData(true);
    				});

    				instance.document.on('keyup', setModelData);
    			});
    			instance.on('customConfigLoaded', function () {
    				configLoaderDef.resolve();
    			});

    			ngModel.$render = function () { // affichage des données dans l'instance texteArea
    				data.push(ngModel.$viewValue);
    				if (isReady) {
    					onUpdateModelData();
    					element.show();
    				}
    			};
    		};

    		if (CKEDITOR.status === 'loaded') { // chargement de la directive => extend ckEditor Js
    			loaded = true;
    		}
    		if (loaded) {
    			onLoad();
    		} else {
    			$defer.promise.then(onLoad);
    		}
        };

        return {
		    restrict: 'AC',
		    require: ['ngModel', '^?form'],
		    scope: false,
            link: function (scope, element, attrs, ctrls) {
    			element.hide(); // on cache l'élément avant le chargement des données dans le CkEditor
    			$compile(element.contents())(scope); // on rafraichit le content de l'élément
                if (attrs.forceLoad && attrs.forceLoad == "true") {
    				reload(scope, element, attrs, ctrls);
    			}

    			scope.$watch(attrs.ngModel, function (n, o) {// on osberve les changement de valeur éffectifs du scope.Content
    				if (n !== o) {
    					reload(scope, element, attrs, ctrls);
    				}
                }, true);
    		}
    	};
    }]);

    return app;
}));