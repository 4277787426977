AgrimarketControllers.controller('manageGroupUsersController', [
	'$scope', '$routeParams', '$sce', 'groupContactService', '$location', 'userService', 'enumFactory', '$filter', '$timeout', 'parametersFactory', function ($scope, $routeParams, $sce, groupContactService, $location, userService, enumFactory, $filter, $timeout, parametersFactory) {

	$scope.parambActiveOfferGroupContactIncidence = parametersFactory.Parameters.bActiveOfferGroupContactIncidence.Value === "1";

	 $scope.Init = function () {
		$scope.formAddGroupUser = { groupUser: {} };
		initFormAddGroupUser();

		groupContactService.GetAllForManage(function (data, status) {
			if (status !== null) {
				if (status === 400)
					$scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
			} else {
				$scope.lstGroupUsers = data;
			    $scope.showDetails = [];
			    $scope.lstGroupUsers.forEach(function (item, index) {
			        if (item.HadUser)
			            $scope.lstGroupUsers[index].LstUserVms.push({ FullName : "", Loading : true});
				    $scope.showDetails[index] = false;
				});
			}
		});

		userService.GetUserByIdRole(enumFactory.Roles.User, function (data, status) {
			if (status !== null) {
				if (status === 400)
					$scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
			} else {
				$scope.lstUsers = data;
			}
		});
	 };

        $scope.ShowDetailUser = function(index) {


            var group = $scope.lstGroupUsers[index];

            if (group.lstGroupUsers === undefined || group.lstGroupUsers === null || group.lstGroupUsers.length === 0)
                groupContactService.GetUserForGroup(group.IdGroupContact, function(data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        $scope.lstGroupUsers[index].LstUserVms = data;
                    }
                });
        };

	$scope.SaveFormAddGroupUser = function () {
	    if ($scope.CheckFormAddGroupUserValues()) {

	        if ($scope.formAddGroupUser.ActionType == "new") {
	            $scope.formAddGroupUser.groupUser.IdGroupContact = 0;
	            $scope.lstGroupUsers.push($scope.formAddGroupUser.groupUser);
	        } else if ($scope.formAddGroupUser.ActionType == "edit" && $scope.lstGroupUsers[$scope.formAddGroupUser.index] !== undefined && $scope.lstGroupUsers[$scope.formAddGroupUser.index] !== null &&  $scope.lstGroupUsers[$scope.formAddGroupUser.index] !== "") {
                $scope.lstGroupUsers[$scope.formAddGroupUser.index].Label = $scope.formAddGroupUser.groupUser.Label;
                $scope.lstGroupUsers[$scope.formAddGroupUser.index].Incidence = $scope.formAddGroupUser.groupUser.Incidence;
	        }

	        groupContactService.SaveGroupContact($scope.formAddGroupUser.groupUser, function (data, status) {
	            if (status !== null) {
	                if (status == 400) {
	                    $scope.errorMessage = data[0].errors[0].Message;
	                }
	            }
	            else {
	            	if ($scope.formAddGroupUser.ActionType == "new") {
			            $scope.formAddGroupUser.groupUser.IdGroupContact = data.IdGroupContact;
	                    $scope.formAddGroupUser.successMessage = "Le groupe a bien été ajouté en base de données.";
	                } else {
	                    $scope.formAddGroupUser.successMessage = "Le groupe a bien été modifié en base de données.";
	                }
	                
	                var oldIndex = $scope.formAddGroupUser.index;
	                sortLstGroupUsers();
	                var newIndex = $scope.lstGroupUsers.indexOf($filter('filter')($scope.lstGroupUsers, { Label: $scope.formAddGroupUser.groupUser.Label }, true)[0]);//Il faut le faire après avoir ordonner la liste
	                var bshowDetailsOldIndex = $scope.showDetails[oldIndex];

	                //Permet de garder les états des différents groupes déplier ou replier en tenant compte des index
	                if (oldIndex < newIndex) {
	                    for (var i = oldIndex; i < newIndex; i++) {
	                        $scope.showDetails[i] = $scope.showDetails[i + 1];
	                    }
	                }
	                else if (newIndex < oldIndex) {
	                    for (var j = oldIndex; j > newIndex; j--) {
	                        $scope.showDetails[j] = $scope.showDetails[j - 1];
	                    }
	                }
	                
	                $scope.showDetails[newIndex] = bshowDetailsOldIndex;

	                $scope.formAddGroupUser.groupUser = {};
	                initFormAddGroupUser();
	            }
	        });
	    }
	};

	$scope.CancelFormAddGroupUser = function () {
	    initMessage();
	    initFormAddGroupUser();
		$scope.formAddGroupUser.groupUser = {};
	};

	$scope.CheckFormAddGroupUserValues = function () {
	    initMessage();
	    
		if (!$scope.formAddGroupUser.groupUser.Label) {
			$scope.formAddGroupUser.errorMessage = "Veuillez saisir un libellé.";
        }

		if (!$scope.formAddGroupUser.groupUser.Incidence)
			$scope.formAddGroupUser.groupUser.Incidence = 0;

        if (isNaN($scope.formAddGroupUser.groupUser.Incidence)) {
            $scope.formAddGroupUser.errorMessage = "L'incidence n'est pas valide.";
        }

		if ($scope.formAddGroupUser.action === 'Ajouter' && $scope.lstGroupUsers && $scope.lstGroupUsers.filter(function (element) { return element.Label === $scope.formAddGroupUser.groupUser.Label; }).length > 0) {
			$scope.formAddGroupUser.errorMessage = "Le libellé du groupe existe déjà.";
		}

		if ($scope.formAddGroupUser.errorMessage !== "") {
			$scope.formAddGroupUser.errorMessageHtml = $sce.trustAsHtml($scope.formAddGroupUser.errorMessage);
			return false;
		}
		return true;
        };

	var initMessage = function () {
	    $scope.formAddGroupUser.errorMessage = "";
	    $scope.formAddGroupUser.successMessage = "";
	    $scope.formAddGroupUser.errorMessageHtml = "";
	    $scope.successMessage = '';
	    $scope.errorMessage = '';
	};
	    
	var initFormAddGroupUser = function () {
	    $scope.formAddGroupUser.title = "Ajouter un groupe";
	    $scope.formAddGroupUser.action = "Ajouter";
	    $scope.formAddGroupUser.ActionType = "new";
	};
	    
	var sortLstGroupUsers = function () {
	    $scope.lstGroupUsers.sort(function (a, b) {//On ré-ordonne par ordre alphabétique à chaque fois que l'on ajoute un nouveau groupe
	        if (a.Label.toLowerCase() < b.Label.toLowerCase())
	            return -1;
	        if (a.Label.toLowerCase() > b.Label.toLowerCase())
	            return 1;
	        return 0;
	    });
	};
	    
	$scope.AddUserPopup = function (openPopUp, modalTitle, indexGroup, id) {
	    initMessage();
		$scope.modalTitle = modalTitle;
		$scope.indexGroup = indexGroup;
	    $scope.idGroupContactToSave = id;
		$scope.groupUserSelected = $scope.lstGroupUsers[indexGroup];
		$scope.errorMessage = '';
		$scope.successMessage = '';
		$scope.selectedAutoCompleteObject = {};
		$scope.selectedAutoCompleteInput = '';
		$timeout(function() {
		    openPopUp();
		}, 0);
	};

	$scope.AddUserConf = function () {
		if (!$scope.selectedAutoCompleteInput) {
			$scope.errorMessage = $sce.trustAsHtml("Impossible d'ajouter un utilisateur vide.");
			return;
		}
		if (!$scope.selectedAutoCompleteObject.originalObject) {
			$scope.errorMessage = $sce.trustAsHtml("Impossible d'ajouter un utilisateur qui n'existe pas dans la base de données.");
			return;
		}

		if ($scope.groupUserSelected.LstUserVms && $scope.groupUserSelected.LstUserVms.filter(function(element) { return element.IdUser === $scope.selectedAutoCompleteObject.originalObject.IdUser; }).length > 0) {
			$scope.errorMessage = $sce.trustAsHtml("L'utilisateur est déjà dans le groupe.");
			return;
		}
		if ($scope.groupUserSelected.LstUserVms === undefined)
		    $scope.groupUserSelected.LstUserVms = [];

		groupContactService.AddUserInGroupContact($scope.idGroupContactToSave, $scope.selectedAutoCompleteObject.originalObject.IdUser, function (data, status) {
	        if (status !== null) {
	            if (status == 400) {
	                $scope.errorMessage = data[0].errors[0].Message;
	            }
	        } else {
	            $scope.groupUserSelected.LstUserVms.push(data);
	        }
	    });
	    
		$scope.showDetails[$scope.indexGroup] = true;
		$scope.selectedAutoCompleteObject = {};
		$scope.selectedAutoCompleteInput = '';
	};
	    
	$scope.EditGroupUser = function (index, groupUser) {
	    initMessage();
	    $scope.formAddGroupUser.title = "Modifier un groupe";
	    $scope.formAddGroupUser.action = "Modifier";
	    $scope.formAddGroupUser.ActionType = "edit";
	    $scope.formAddGroupUser.index = index;
	    $scope.formAddGroupUser.groupUser = angular.copy(groupUser);
	};

	$scope.DeletePopup = function (indexUser, openPopUp, modalTitle, type, indexGroup, idGroupContact, idUser) {
	    initMessage();
		$scope.modalTitle = modalTitle;
		$scope.indexUser = indexUser;
		$scope.indexGroup = indexGroup;
		$scope.idGroupContactToDelete = idGroupContact;
		$scope.idUserToDelete = idUser;
		$scope.typeDeleted = type;
		$scope.groupUserSelected = $scope.lstGroupUsers[indexGroup];
		$scope.errorMessage = '';
		$scope.successMessage = '';

		if (type === "groupUser") {
			$scope.textPopUpDelete = "Êtes-vous sûr de vouloir supprimer ce groupe ?";
		}
		else {
			$scope.textPopUpDelete = "Êtes-vous sûr de vouloir supprimer cet utilisateur du groupe ?";
		}
		openPopUp();
	};

	$scope.DeleteConf = function() {
		if ($scope.typeDeleted === "groupUser") {
			groupContactService.DeleteGroupContact($scope.idGroupContactToDelete, function (data, status) {
			    if (status !== null) {
			        if (status == 400) {
			            $scope.errorMessage = data[0].errors[0].Message;
			        }
			    } else {
			        $scope.successMessage = $sce.trustAsHtml("Le groupe a été supprimé avec succés.");
			        $scope.showDetails[$scope.indexGroup] = false;
			        $scope.lstGroupUsers.splice($scope.indexGroup, 1);
			    }
			});    
		} else {
		    groupContactService.RemoveUserInGroupContact($scope.idGroupContactToDelete, $scope.idUserToDelete, function (data, status) {
		        if (status !== null) {
		            if (status == 400) {
		                $scope.errorMessage = data[0].errors[0].Message;
		            } 
		        } else {
		            $scope.successMessage = $sce.trustAsHtml("L'utilisateur a été supprimé avec succés.");
		            $scope.groupUserSelected.LstUserVms.splice($scope.indexUser, 1);
		        }
		    });   
		}
	};
  
	$scope.Init();
	    
}]);
