AgrimarketControllers.controller('newsController', ['$scope', '$rootScope', '$routeParams', '$sce', '$filter', '$http', '$location', 'newsService', 'colonnes', 'userTableService', 'parametersFactory', 'parametersService', 'enumFactory',
    function ($scope, $rootScope, $routeParams, $sce, $filter, $http, $location, newsService, colonnes, userTableService, parametersFactory, parametersService, enumFactory) {

        $scope.data = { reverse: true, sortKey: "IdNews" };
        $scope.pageNo = 1;
        $scope.max = 10;
        $scope.News = {};

        if (!$rootScope.ModuleNews.Active)
            $location.path('/admin/parameters/global');

        if ($rootScope.succesMessageNews !== undefined && $rootScope.succesMessageNews !== null)
            $scope.successMessage = $rootScope.succesMessageNews;

        $scope.GetNews = function () {
        	newsService.GetNewsPaginateWithFilter($scope.searchText, $scope.max, $scope.pageNo, $scope.data.sortKey, $scope.data.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } 
                } else {
                	$scope.lstNews = data.ListVm;
                	$scope.total = data.Total;

	                $scope.lstNews.forEach(function(item) {
	                	item.CreateDate = $filter('date')(item.CreateDate, 'dd/MM/yyyy');
	                	item.StartPublication = $filter('date')(item.StartPublication, 'dd/MM/yyyy');
	                	item.EndPublication = $filter('date')(item.EndPublication, 'dd/MM/yyyy');
	                });
                }
            });
        };

        $scope.AddNews = function () {
            $location.path('/admin/contents/news/saveNews/-1');
        };

        $scope.EditNews = function (news) {
            $location.path('/admin/contents/news/saveNews/' + news.IdNews);
        };

        $scope.DeleteNews = function (news, index, openPopUp, modalTitle) {
            $scope.successMessage = "";
            $scope.errorMessage = "";
            $scope.index = index;
            $scope.ModalTitle = modalTitle;
            $scope.News = angular.copy(news);
            openPopUp();
        };

        $scope.deleteNews = function () {
            newsService.DeleteNews($scope.News.IdNews, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } 
                } else {
                	$scope.successMessage = "L'actualité a bien été supprimée.";
                	$scope.GetNews();
                }
                return true;
            });
        };

        $scope.GetData = function (pageNo) {
        	$scope.pageNo = pageNo;
        	$scope.GetNews();
        };

        $scope.Sort = function (sortKey, reverse) {
        	$scope.data.sortKey = sortKey;
        	$scope.data.reverse = reverse;
        	$scope.GetNews();
        };

        $scope.$watch("searchText", function (newValue, oldValue) {
        	if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
        		$scope.GetNews();
        });

        var init = function () {
        	$scope.errorMessage = "";
        	$scope.searchText = "";
        	$scope.tableNewsDataVisible = colonnes.data.DataVisible.split(',');
        	$scope.tableNewsLabels = colonnes.data.ColumnName.split(',');
            $scope.GetNews();
        };

        $rootScope.onRefresh = function () {
        	init();
        };

        init();
    }]);
