AgrimarketControllers.controller('userSmsAlertController', [
    '$scope', '$rootScope',  '$location', 'colonnes', 'smsAlertService', 'offerService',
    function ($scope, $rootScope,  $location, colonnes, smsAlertService, offerService) {

        if (!$rootScope.ModuleAlertesSms.Active)
            $location.path('/');




        $scope.AsChangedCrop = function () {
            Init();
        };

        var Init = function () {
            $scope.smsAlerts = [];

            $scope.max = 10;

            $scope.errorMessage = "";
            $scope.tableSmsAlertsDatasVisible = colonnes.data.DataVisible.split(',');
            $scope.tableSmsAlertsLabels = colonnes.data.ColumnName.split(',');
            $scope.data = { reverse: true, sortKey: "IdSmsAlert" };
            $scope.pageNo = 1;
            $scope.GetSmsAlertsPaginateWithFilter();
        };

       

        $scope.GetSmsAlertsPaginateWithFilter = function () {
            $scope.loading = true;
            smsAlertService.GetSmsAlertsPaginateWithFilter($rootScope.crop.IdCrop, $scope.max, $scope.pageNo, $scope.data.sortKey, $scope.data.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else
                        $location.path('/');
                }
                else {
                    $scope.loading = false;
                    $scope.listSmsAlerts = data.ListVm;
                    $scope.total = data.Total;                   
                }
            });
        };

        $scope.DeleteAlertSmsPopUp = function (smsAlert, index, showPopUpDeleteAlertSms) {
            $scope.errorMessage = '';
            $scope.PopupDeleteAlertSmsSelectedTitle = $rootScope.editableObjectsFactory.EditableObjects.User_AlertSmsList_PopupDeleteAlertSms_Title.Content;
            showPopUpDeleteAlertSms(smsAlert);
        };

        $scope.DeleteAlertSms = function (element) {
            smsAlertService.DeleteAlertSms(element, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.GetSmsAlertsPaginateWithFilter();
                }
            });
        };

        $scope.EditAlertSmsPopUp = function (smsAlert, showPopUpEditAlertSms) {
            $scope.popupSmsAlertErrorMessage = "";
            offerService.GetOfferPrice(smsAlert.IdExpiry, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                } else {
                    $scope.editSmsAlert = {
                        idExpiry: smsAlert.IdExpiry,
                        price: data.FinalPrice,
                        symbol: data.Symbol,
                        limitMin: smsAlert.LimitMin,
                        limitMax: smsAlert.LimitMax
                    };
                    showPopUpEditAlertSms();
                }
            });
        };

        $scope.CheckSmsAlertIsValid = function () {
            $scope.popupSmsAlertErrorMessage = smsAlertService.CheckSmsAlertIsValid($scope.editSmsAlert.limitMax, $scope.editSmsAlert.limitMin, $scope.editSmsAlert.price);

            if ($scope.popupSmsAlertErrorMessage !== "") {
                return false;
            }
            else {
                return true;
            }
        };

        $scope.EditSmsAlertLimit = function () {
            if (!$scope.editSmsAlert.limitMin)
                $scope.editSmsAlert.limitMin = "";
            if (!$scope.editSmsAlert.limitMax)
                $scope.editSmsAlert.limitMax = "";

            smsAlertService.SaveUserExpirySmsLimits($scope.editSmsAlert.idExpiry, $scope.editSmsAlert.limitMin, $scope.editSmsAlert.limitMax, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    Init();
                }
            });
        };

        $scope.GetData = function (pageNo) {
            $scope.pageNo = pageNo;
            $scope.GetSmsAlertsPaginateWithFilter();
        };

        $scope.Sort = function (sortKey, reverse) {
            $scope.data.sortKey = sortKey;
            $scope.data.reverse = reverse;
            $scope.GetSmsAlertsPaginateWithFilter();
        };

        Init();

    }
]);