// Permet d'animer le ticker de News
AgrimarketDirectives.directive('newsTicker', ['$window', function ($window) {
    
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        template: '<div class="news_ticker ticker-multiple"><div class="news_list news_list_complete" ng-transclude></div></div>',
        scope: {
            title: '=tickerTitle',
            height: '=tickerHeight',
            speed: '=tickerDuration',
            datawatch: '=tickerDatawatch'
        },
        link: function (scope, element, attrs) {

            var tickerInterval;
            scope.$parent.$on('$destroy', function () {
                clearInterval(tickerInterval);
            });

            jQuery(function() {

                var fn = function (resize) {

                    if (resize) {
                        $('.news_list.anim').remove();
                        $(element).find('h1').remove();
                        $(element).attr('style', '');
                    }

                    $(element).prepend('<h1>' + attrs.tickerTitle + '</h1>');
                    $(element).append('<div class="news_list anim"></div>');
                    $(element).find('.news_list_complete>*').clone().appendTo('.news_list.anim');

                    function splitNews(el, content, limit, endString) {
                        var res = content.substring(0, limit);
                        var trimmedString = res.substr(0, Math.min(res.length, res.lastIndexOf(" ")));

                        $(el).text(trimmedString + endString);
                    }

                    function ticker(height, duration, speed, limit, endString, resize) {
                        $('.news_list_complete').hide();
                        var el = $(element).find('.anim article');
                        var nbEl = $(el).length;

                        $(el).each(function(i) {
                            var content = $(this).find('header+p').text();
                            splitNews($(this).find('header+p'), content, limit, endString);
                        });
                        $(el).each(function (i) {
                            var tempHeight = $(this).outerHeight();

                            $(this).attr('data-news', i);
                            if (tempHeight > height) {
                                height = tempHeight;
                            }
                            i++;
                        });
                        $('.ticker-multiple').css({
                            'height': height,
                            'overflow': 'hidden',
                            'position': 'relative'
                        }).addClass('ticker-anim');

                        $(el).css({
                            'height': $('.news_ticker').outerHeight(),
                        });

                        $('.ticker-multiple').hover(function () {
                            $(this).removeClass('ticker-anim');
                        }, function () {
                            $(this).addClass('ticker-anim');
                        });

                        $('.ticker-multiple').unbind();
                        $('.ticker-multiple').bind('click',function () {

                            if ($('.news_list_complete').is(':visible')) {
                                //$('.ghost').remove();
                                $('.ticker-multiple').css({
                                    'position': '',
                                    'width': '',
                                    'z-index': ''
                                });
                                $('.news_list_complete').css('display', 'none');
                                $('.news_list.anim').css('display', '');
                                $('.ticker-multiple').css({
                                    'height': height,
                                    'overflow': 'hidden',
                                    'position': 'relative'
                                });
                                $('.ticker-multiple .anim').css({
                                    'height': height
                                });

                            }
                            else {
                                //$('<div class="ghost" style="height: ' + $('.ticker-multiple').outerHeight(true) + 'px;"></div>').insertAfter('.ticker-multiple');
                                $('.ticker-multiple').css({
                                    'width': $('.ticker-multiple').outerWidth(),
                                    'position': '',
                                    'z-index': 100
                                });
                                $('.news_list_complete').css('display', '');
                                $('.news_list.anim').css('display', 'none');
                                $('.ticker-multiple, .ticker-multiple .anim').css({
                                    'height': '',
                                    'overflow': ''
                                });

                                $(document).one('click', function closeTooltip(e) {

                                    if ($('.ticker-multiple').has(e.target).length === 0) {

                                        //$('.ghost').remove();
                                        $('.ticker-multiple').css({
                                            'position': '',
                                            'width': '',
                                            'z-index': ''
                                        });
                                        $('.news_list_complete').css('display', 'none');
                                        $('.news_list.anim').css('display', '');
                                        $('.ticker-multiple').css({
                                            'height': height,
                                            'overflow': 'hidden',
                                            'position': 'relative'
                                        });
                                        $('.ticker-multiple .anim').css({
                                            'height': height
                                        });
                                    } else if ($('.news_list_complete').is(':visible')) {
                                        $(document).one('click', closeTooltip);
                                    }
                                });

                            }
                        });

                        if (!resize) {
                            initAnim(nbEl, speed, duration);
                        }
                    }

                    function initAnim(nbEl, speed, duration) {

                        tickerInterval = setInterval(function () {
                            if (nbEl > 1 && $('.ticker-multiple').hasClass('ticker-anim')) {
                                $('.anim').animate({ marginTop: -$('.ticker-multiple').outerHeight() }, speed, 'easeOutBack', function () {
                                    $(this).css({ marginTop: 0 }).find("article:last").after($(this).find("article:first"));
                                });
                            }
                        }, duration);
                    }

                    // Params : height ticker, durée anim, vitesse, nb caractères visibles, fin de trim
                    ticker(scope.height, 15000, 450, 200, '...', resize);
                };

                //Permet de lancer la fonction ticker quand les données du scope sont présentes dans le rendu html
                scope.$parent.$watch(attrs.tickerDatawatch, function (newValue) {
                    if (newValue !== undefined && newValue !== null && newValue.length > 0) {
                        window.setTimeout(fn, 0);
                        //clearInterval(ticker);
                        //ticker = window.setTimeout(fn, 0);
                    }
                });

               

                scope.width = $window.innerWidth;

                angular.element($window).bind('resize', function () {
                     window.setTimeout(fn(true), 250);

                    // manuall $digest required as resize event
                    // is outside of angular
                    scope.$digest();
                });                

            });
        }
    };
}]);
