AgrimarketControllers.controller('categoryOfferController', ['$scope', '$rootScope', '$sce', '$location', 'configurationFactory', '$upload', 'categoryOfferService', 'colonnes', 'languageService',
    function ($scope, $rootScope, $sce, $location, configurationFactory, $upload, categoryOfferService, colonnes, languageService) {

        $scope.searchText = "";
        $scope.errorMessage = "";
        $scope.lstCategoryOffer = [];
        $scope.categoryOffer = {};
        $scope.Languages = {};

        $scope.InitMessage = function () {
            $scope.errorMessage = "";
            $scope.successMessage = "";
            $scope.errorMessagePopup = "";
        };
        $scope.data = { reverse: false, sortKey: "Position" };
        $scope.pageNo = 1;
        $scope.max = 10;

        $scope.GetLanguages = function () {
            languageService.GetLanguages(function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    }
                } else {
                    $scope.Languages = data;
                }
            });
        };


        $scope.GetCategoryOffer = function () {
            categoryOfferService.GetCategoryOfferPaginateWithFilter($scope.searchText, $scope.max, $scope.pageNo, $scope.data.sortKey, $scope.data.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else
                        $scope.errorMessage = data;
                }
                else {
                    $scope.lstCategoryOffer = data.ListVm;
                    $scope.total = data.Total;
                }
            });
        };

        $scope.GetCategoryOfferLanguage = function (test) {
            categoryOfferService.GetCategoryOfferPaginateWithFilter($scope.searchText, $scope.max, $scope.pageNo, $scope.data.sortKey, $scope.data.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else
                        $scope.errorMessage = data;
                }
                else {
                    $scope.lstCategoryOffer = data.ListVm;
                    $scope.total = data.Total;
                }
            });
        };

        $scope.AddCategoryOffer = function (popup) {
            $scope.InitMessage();
            $scope.categoryOffer = {};
            $scope.categoryOffer.CategoryOfferLanguages = [];
            $scope.Languages.forEach(function (item) {
                var categoryOfferLanguage = {};
                categoryOfferLanguage.IdCategoryOffer = 0;
                categoryOfferLanguage.IdLanguage = item.IdLanguage;
                categoryOfferLanguage.Language = item;
                categoryOfferLanguage.Label = "";

                $scope.categoryOffer.CategoryOfferLanguages.push(categoryOfferLanguage);
            });

            $scope.txtButton = "Ajouter";
            $scope.modalTitle = "Ajouter une catégorie";
            popup();
        };

        $scope.EditCategoryOffer = function (item, openPoPup) {
            $scope.InitMessage();
            $scope.categoryOffer = angular.copy(item);
            $scope.txtButton = "Modifier";
            $scope.modalTitle = "Modifier une catégorie";
            openPoPup();
        };

        $scope.CheckCategoryOffer = function () {
            $scope.InitMessage();
            if ($scope.categoryOffer.CategoryOfferLanguages.length > 0) {
                $scope.categoryOffer.CategoryOfferLanguages.forEach(function (item) { 
                    if (item.Label === undefined || item.Label === null || item.Label === "")
                        $scope.errorMessagePopup += "Veuillez renseigner le nom (" + item.Language.Label + ") de la catégorie<br/>";
                    else if (item.Label.length > 100)
                        $scope.errorMessagePopup += "Veuillez renseigner le nom (" + item.Language.Label + ") de 100 caractères maximum de la catégorie<br/>";                 
                });
            }
            if ($scope.categoryOffer.Position === undefined || $scope.categoryOffer.Position === null || $scope.categoryOffer.Position === "")
                $scope.errorMessagePopup += "Veuillez renseigner la position de la catégorie<br/>";
            else if (($scope.lstCategoryOffer.length === 0 && ($scope.categoryOffer.Position < 0 || $scope.categoryOffer.Position > $scope.lstCategoryOffer.length + 1) ||
                ($scope.categoryOffer.IdCategoryOffer !== undefined && $scope.lstCategoryOffer.length > 0 && ($scope.categoryOffer.Position < 0 || $scope.categoryOffer.Position > $scope.lstCategoryOffer.length)
                )))
                $scope.errorMessagePopup += "La position doit être de 1 <br/>";
            else if ($scope.lstCategoryOffer.length > 0 && ($scope.categoryOffer.Position < 0 || $scope.categoryOffer.Position > ($scope.lstCategoryOffer.length + 1)))
                $scope.errorMessagePopup += "La position doit être comprise entre 1 et " + ($scope.lstCategoryOffer.length + 1) + "<br/>";

            if ($scope.errorMessagePopup !== "") {
                $scope.errorMessagePopup = $sce.trustAsHtml($scope.errorMessagePopup);
                return false;
            }
            return true;
        };

        $scope.SaveCategoryOffer = function () {
            categoryOfferService.PostCategoryOffer($scope.categoryOffer, function (data, status) {
                if (status !== null) {
                    if (status == 400) {
                        $scope.errorMessagePopup = $sce.trustAsHtml(data[0].errors[0].Message);
                        $scope.openPopUp();
                        return false;
                    }
                }
                else {
                    $scope.categoryOffer = {};
                    $scope.successMessage = "Votre catégorie a bien été enregistrée";
                    $scope.GetCategoryOffer();
                }
                return true;
            });
        };

        $scope.DeleteCategoryOffer = function (item) {
            $scope.InitMessage();
            categoryOfferService.DeleteCategoryOffer(item.IdCategoryOffer, function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else
                        $scope.errorMessage = status;
                } else {
                    $scope.categoryOffer = {};
                    $scope.successMessage = "Votre catégorie a bien été supprimé";
                    $scope.GetCategoryOffer();
                }
            });
        };


        $scope.GetData = function (pageNo) {
            $scope.pageNo = pageNo;
            $scope.GetCategoryOffer();
        };

        $scope.Sort = function (sortKey, reverse) {
            $scope.data.sortKey = sortKey;
            $scope.data.reverse = reverse;
            $scope.GetCategoryOffer();
        };

        $scope.$watch("searchText", function (newValue, oldValue) {
            if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                $scope.GetCategoryOffer();
        });

        $scope.Init = function () {
            $scope.tableCategoryOfferDataVisible = ["Position","IdCategoryOffer","Label","StatusCategory"];
            $scope.tableCategoryOfferLabels = ["Position","IdCategorie","Nom","Etat"];
            $scope.InitMessage();
            $scope.GetCategoryOffer();
            $scope.GetLanguages();
        };

        $rootScope.onRefresh = function () {
            $scope.Init();
        };

        $scope.Init();
    }]);
