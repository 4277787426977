AgrimarketDirectives.directive('ngIncludeReplace', function ($timeout, $rootScope) {
    return {
        require: 'ngInclude',
        link: function (scope, element, attrs) {
            $timeout(function () {
                element.replaceWith(element.children());
            }, 0);
           
        }
    };
});