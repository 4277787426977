AgrimarketControllers.controller('saveContentInfoMarketController', ['$scope', '$rootScope', '$routeParams', '$sce', '$filter', '$http', '$location', 'typeBlockEditableService', 'writingContentInfoMarketService', 'enumFactory', 'parametersService', 'dateService', 'configurationFactory', 'parametersFactory',
    function ($scope, $rootScope, $routeParams, $sce, $filter, $http, $location, typeBlockEditableService, writingContentInfoMarketService, enumFactory, parametersService, dateService, configurationFactory, parametersFactory) {

        $scope.writingContentInfoMarket = {};
        $scope.viewMode = "saveContentInfoMarket";
        $scope.enumTypeBlockEditables = enumFactory.TypeBlockEditables;
        $scope.pushNotificationEnabled = parametersFactory.Parameters.GoogleConsoleCloudServiceFilename.Value ;

        var init = function () {
            $scope.forceLoad = false;
            $scope.writingContentInfoMarketErrorMessage = "";
            $rootScope.successMessageContentInfosMarket = "";
            $scope.TypeBlockEditables = [];

            typeBlockEditableService.GetTypeBlockEditable(function (data, status) {
                if (status !== null) {
                    if (status == 400) {
                        $scope.writingContentInfoMarketErrorMessage = data[0].errors[0].Message;
                    } 
                } else {
                    $scope.typeBlockEditables = data;
                }
            });

            if ($routeParams.idWritingContentInfoMarket == -1) {
                $scope.blocTitle = "Création d'un contenu";
                $scope.action = "Créer";
                $scope.forceLoad = true;
            }
            else {
                writingContentInfoMarketService.GetContentInfoMarketById($routeParams.idWritingContentInfoMarket, function (data, status) {
                    if (status !== null) {
                        if (status === 400) {
                            $scope.writingContentInfoMarketErrorMessage = data[0].errors[0].Message;
                        }
                    } else {
                        $scope.writingContentInfoMarket = data;
                        $scope.writingContentInfoMarket.StartPublication = $filter('date')($scope.writingContentInfoMarket.StartPublication, "dd/MM/yyyy");
                        $scope.writingContentInfoMarket.EndPublication = $filter('date')($scope.writingContentInfoMarket.EndPublication, "dd/MM/yyyy");
                        $scope.blocTitle = "Modification d'un contenu";
                        $scope.action = "Modifier";
                        $scope.forceLoad = true;
                    }
                });
            }
        };

        $scope.checkWritingContentInfoMarket = function () {
            $scope.writingContentInfoMarketErrorMessage = "";
            $rootScope.successMessageContentInfosMarket = "";
            var startPublicationVerif = false;
            var endPublicationVerif = false;
            var maxNbCharacters = 300;
            var dateDay = $filter('date')(new Date(), "dd/MM/yyyy");
            var regexDate = /^(\d{2}\/){2}\d{4}$/;

            if (!$scope.writingContentInfoMarket.Title)
                $scope.writingContentInfoMarketErrorMessage = "Veuillez renseigner un titre pour le contenu.";

            if (!$scope.writingContentInfoMarket.Content) {
                if ($scope.writingContentInfoMarketErrorMessage)
                    $scope.writingContentInfoMarketErrorMessage += "<br/>";

                $scope.writingContentInfoMarketErrorMessage += "Veuillez renseigner un contenu.";
            }

            if (!$scope.writingContentInfoMarket.IdTypeBlockEditable) {
                if ($scope.writingContentInfoMarketErrorMessage)
                    $scope.writingContentInfoMarketErrorMessage += "<br/>";

                $scope.writingContentInfoMarketErrorMessage += "Veuillez choisir un type de bloc éditable sur lequel doit apparaître le contenu.";
            } else {
                if (Number($scope.writingContentInfoMarket.IdTypeBlockEditable) === enumFactory.TypeBlockEditables.Bandeau && $scope.writingContentInfoMarket.Content && $scope.writingContentInfoMarket.Content.length > maxNbCharacters) {
                    if ($scope.writingContentInfoMarketErrorMessage)
                        $scope.writingContentInfoMarketErrorMessage += "<br/>";

                    $scope.writingContentInfoMarketErrorMessage += "Votre contenu est trop long pour être présenté dans le bloc bandeau, Veuillez le réduire ou changer de type de bloc.";
                }
            }

            if (!$scope.writingContentInfoMarket.StartPublication) {
                if ($scope.writingContentInfoMarketErrorMessage)
                    $scope.writingContentInfoMarketErrorMessage += "<br/>";

                $scope.writingContentInfoMarketErrorMessage += "Veuillez renseigner une date de début de publication pour le contenu.";
            } else {
                if ((!(regexDate.test($scope.writingContentInfoMarket.StartPublication))) || (!dateService.VerifDateIsCalendar($scope.writingContentInfoMarket.StartPublication))) {
                    if ($scope.writingContentInfoMarketErrorMessage)
                        $scope.writingContentInfoMarketErrorMessage += "<br/>";

                    $scope.writingContentInfoMarketErrorMessage += "Veuillez renseigner une date de début de publication valide pour le contenu valide (format attendu dd/MM/YYYY).";
                } else
                    startPublicationVerif = true;
            }

            if (!$scope.writingContentInfoMarket.EndPublication) {
                if ($scope.writingContentInfoMarketErrorMessage)
                    $scope.writingContentInfoMarketErrorMessage += "<br/>";

                $scope.writingContentInfoMarketErrorMessage += "Veuillez renseigner une date de fin de publication pour le contenu.";
            } else {
                if ((!(regexDate.test($scope.writingContentInfoMarket.EndPublication))) || (!dateService.VerifDateIsCalendar($scope.writingContentInfoMarket.EndPublication))) {
                    if ($scope.writingContentInfoMarketErrorMessage)
                        $scope.writingContentInfoMarketErrorMessage += "<br/>";

                    $scope.writingContentInfoMarketErrorMessage += "Veuillez renseigner une date de fin de publication valide pour le contenu valide (format attendu dd/MM/YYYY).";
                } else
                    endPublicationVerif = true;
            }

            if (startPublicationVerif && endPublicationVerif) {

                if (!dateService.compareDateOverride(dateDay, $scope.writingContentInfoMarket.EndPublication) && $routeParams.idWritingContentInfoMarket == -1) {
                    if ($scope.writingContentInfoMarketErrorMessage)
                        $scope.writingContentInfoMarketErrorMessage += "<br/>";

                    $scope.writingContentInfoMarketErrorMessage += "Veuillez renseigner une date de fin de publication posterieur à la date du jour.";
                }

                if (!dateService.compareDate($scope.writingContentInfoMarket.StartPublication, $scope.writingContentInfoMarket.EndPublication)) {
                    if ($scope.writingContentInfoMarketErrorMessage)
                        $scope.writingContentInfoMarketErrorMessage += "<br/>";

                    $scope.writingContentInfoMarketErrorMessage += "Veuillez renseigner une date de début de publication anterieur à la date de fin de publication.";
                }
            }

            if ($scope.writingContentInfoMarketErrorMessage)
                return false;

            $scope.saveContentInfoMarket();
            return true;
        };

        $scope.saveContentInfoMarket = function () {
            $scope.writingContentInfoMarketErrorMessage = "";
            $rootScope.successMessageContentInfosMarket = "";

            $scope.writingContentInfoMarket.StartPublication = dateService.ConvertDateToDateAPI($scope.writingContentInfoMarket.StartPublication);
            $scope.writingContentInfoMarket.EndPublication = dateService.ConvertDateToDateAPI($scope.writingContentInfoMarket.EndPublication);
            $scope.writingContentInfoMarket.Id = $routeParams.idWritingContentInfoMarket == -1 ? 0 : $routeParams.idWritingContentInfoMarket;

            writingContentInfoMarketService.SaveContentInfoMarket($scope.writingContentInfoMarket, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.writingContentInfoMarket.StartPublication = $filter('date')($scope.writingContentInfoMarket.StartPublication, "dd/MM/yyyy");
                        $scope.writingContentInfoMarket.EndPublication = $filter('date')($scope.writingContentInfoMarket.EndPublication, "dd/MM/yyyy");
                        $scope.writingContentInfoMarketErrorMessage = data[0].errors[0].Message;
                    }
                } else {
                    if ($routeParams.idWritingContentInfoMarket == -1)
                        $rootScope.successMessageContentInfosMarket = "Votre contenu a bien été pris en compte.";
                    else
                        $rootScope.successMessageContentInfosMarket = "Vos modifications ont bien été prises en compte.";


                    $location.path('admin/contents/infoMarketHashtagri/view/writingContentInfoMarket');
                }
            });
        };

        $scope.Cancel = function () {
            $location.path('admin/contents/infoMarketHashtagri/view/writingContentInfoMarket');
        };

        $scope.NbCharactersWriting = function() {
            if ($scope.writingContentInfoMarket.IdTypeBlockEditable && Number($scope.writingContentInfoMarket.IdTypeBlockEditable) === enumFactory.TypeBlockEditables.Bandeau && $scope.writingContentInfoMarket.Content)
                return "Nombre de caractères y compris balises HTML :" + $scope.writingContentInfoMarket.Content.length;
        };

        $scope.UploadImage = function (data) {
            CKEDITOR.instances.txtContent.insertHtml("<img src='" + $filter('uploadLinkInline')(data.PrivateLabel) + "'>");
        };

        $scope.changeTypeBlockEditable = function () {
            if ($scope.writingContentInfoMarket.IdTypeBlockEditable === $scope.enumTypeBlockEditables.Bandeau && $scope.writingContentInfoMarket.Content && $scope.writingContentInfoMarket.Content.indexOf("<img src=") >= 0)
                $scope.writingContentInfoMarket.Content.match(/<img [^>]*src="[^"]*"[^>]*>/gm).forEach(function (item) {
                    $scope.writingContentInfoMarket.Content = $scope.writingContentInfoMarket.Content.replace(item, '');
                });
        };

        init();
    }]);
