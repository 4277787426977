AgrimarketControllers.controller('contractsCommitmentMonitoringController', ['$scope', '$rootScope', '$routeParams', '$http', '$filter', '$location', "currentUserFactory", 'configurationFactory',
    'contractsService', 'cropsService', 'athenaOrdersService', '$log', '$timeout', '$sce', 'parametersFactory', 'enumFactory', 'userTableService', 'colonnesContractsCommitmentValidated',
    function ($scope, $rootScope, $routeParams, $http, $filter, $location, currentUserFactory, configurationFactory, contractsService, cropsService, athenaOrdersService, $log, $timeout, $sce,
        parametersFactory, enumFactory, userTableService, colonnesContractsCommitmentValidated) {

        var getCropsWithContractsCommitment = function () {
            cropsService.GetCropsWithContracts(enumFactory.TypeContract.Engagement, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    if (data) {
                        $scope.crops = data;
                        var defaultCropInCrops = $filter('filter')($scope.crops, { IdCrop: $scope.idCropSelected })[0] !== undefined;
                        if (!defaultCropInCrops && $scope.crops.length > 0)
                            $scope.idCropSelected = $scope.crops[0].IdCrop;
                        $scope.GetFiltersWithContractsCommitment();
                    }
                }
            });
        };

    	var init = function () {
    		// Contracts engagement valider
    	    $scope.tableContractsCommitmentValidatedDataVisible = colonnesContractsCommitmentValidated.data.DataVisible.split(',');
    	    $scope.tableContractsCommitmentValidatedLibelle = colonnesContractsCommitmentValidated.data.ColumnName.split(',');

    		// Gestion du trie de l'onglet Valider
    		$scope.dataValidated= { reverse: true, sortKey: "IdContract" };

    		// Pagination onglet Valider
    		$scope.validatedPageNo = 1;

    		$scope.errorMessage = "";
    		$scope.successMessage = "";
    		$scope.max = 10;
    		$scope.idCropSelected = Number(parametersFactory.Parameters.DefaultCrop.Value);
    		$scope.idProductSelected = "";
    		$scope.idTypeCommitmentContractSelected = "";
    		$scope.idCompagnySelected = "";
    		$scope.dateFilter = "";
    		$scope.dateSelected = "";
    		$scope.selectedClient = {};
    		$scope.showDetailsValidated = true;
    	    $scope.viewMode = $routeParams.typeContract;
    		$scope.lstProducts = [];
    		$scope.lstTypeCommitments = [];
    		$scope.listIdContractsToCover = [];
            $scope.allContractsInView = [];
            $scope.titleFixContractCommitmentPopUp = $rootScope.editableObjectsFactory.EditableObjects.Admin_ContractCommitment_PopupFix_Title.Content;

    		//Permet de gerer les arrondis des prix pour les decimales > 2
    		$scope.$watch("allContractsInView", function (n, o) {
    			if (n !== o) {
    				var regexfloat = /^[0-9]+.[0-9]{3,100}/;
    				n.forEach(function (item) {
    					if (regexfloat.test(item.Price))
    						item.Price = Number(item.Price).toFixed(2);
    				});
    			}
    		}, true);

            var refreshUsers = null; // On crée un timer car si on tape trop vite dans le champ de recherche le listing ne correspond pas au nom recherché
            $scope.$watch("selectedIdClient", function (newValue, oldValue) {
                if (newValue && oldValue != newValue) {
                    if (newValue.length >= 3 || newValue.length < oldValue.length) {
                        if (refreshUsers === null)
                            refreshUsers = setTimeout(function () { $scope.Filter(); }, 800);
                        else {
                            clearTimeout(refreshUsers);
                            refreshUsers = setTimeout(function () { $scope.Filter(); }, 800);
                        }
                    }
                }
            });

    		$scope.$watch("dateSelected", function (newValue) {
    			if (newValue === "")
    				$scope.dateFilter = "";
    			else if (newValue) {
    				//permet de gérer l'internalisation de tout les formats des dates possibles.
    				//Au final nous faisons en sorte de la retrouvé au format yyyy/mm/dd pour l'envoyer à l'API.
    				var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
    				var dt = new Date(newValue.replace(pattern, '$3-$2-$1'));
    				var currentDate = new Date(dt.toString());
    				var converDate = moment(currentDate);
    				$scope.dateFilter = converDate.format('YYYY/MM/DD');
    			}
    		});

    		$scope.$watch("dateFilter", function (newValue, oldValue) {
    			if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
    				$scope.Filter();
    		});

    		getCropsWithContractsCommitment();
    	};

        var getContractsCommitmentValidated = function () {
            $scope.loading = true;
    	    $scope.errorMessage = "";
    	    $scope.successMessage = "";
    	    if (!$scope.selectedIdContract) $scope.selectedIdContract = "";
    	    if (!$scope.selectedIdClient) $scope.selectedIdClient = "";
    	    contractsService.GetContractsCommitmentValidatedPaginate($scope.idCropSelected, $scope.idProductSelected, $scope.idTypeCommitmentContractSelected, $scope.dateFilter, $scope.selectedIdContract, $scope.selectedIdClient, $scope.idCompagnySelected, $scope.max, $scope.validatedPageNo, $scope.dataValidated.sortKey, $scope.dataValidated.reverse, function (data, status) {
    	        if (status !== null) {
    	            if (status === 400)
    	                $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
    	        } else {
    	            $scope.totalValidated = data.Total;
                    $scope.contractsCommitmentValidated = data.ListVm;
    	          
                    data.ListVm.forEach(function (contract) {
    	                $scope.allContractsInView.push(contract);
                    });
                    $scope.loading = false;
    	        }
    	    });
    	};

        $scope.GetFiltersWithContractsCommitment = function () {
            $scope.loading = true;
    	    $scope.errorMessage = "";
    	    $scope.successMessage = "";
		    $scope.idProductSelected = "";
		    $scope.idTypeCommitmentContractSelected = "";
		    $scope.idCompagnySelected = "";
		    $scope.selectedIdContract = "";
		    $scope.dateSelected = "";
		    $scope.selectedIdClient = "";
		    contractsService.GetFiltersWithContractsCommitmentByCrop($scope.idCropSelected, function (data, status) {
    			if (status !== null) {
    				if (status === 400)
    					$scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
    			} else {
    				if (data) {
    					$scope.lstProducts = data.LstProductFilterVm;
    					$scope.lstTypeCommitments = data.LstTypeCommitmentFilterVm;
    					$scope.lstCompagnies = data.LstCompagnies;

    					$scope.lstCompagnies.unshift({ IdCompagny: "", Label: "Toutes" });

    					if ($scope.lstProducts.length === 1)
    						$scope.idProductSelected = $scope.lstProducts[0].IdProduct;
    					else if ($scope.lstProducts.length > 1 && $filter('filter')($scope.lstProducts, { IdProduct: "", ProductLabel: "Tous" }).length === 0)
    						$scope.lstProducts.unshift({ IdProduct: "", ProductLabel: "Tous" });

    					if ($scope.lstTypeCommitments.length === 1)
    						$scope.idTypeOrderContractSelected = $scope.lstTypeCommitments[0].IdTypeOrder;
    					else if ($scope.lstTypeCommitments.length > 1 && $filter('filter')($scope.lstTypeCommitments, { IdTypeCommitment: "", LabelTypeCommitment: "Tous" }).length === 0)
    					    $scope.lstTypeCommitments.unshift({ IdTypeCommitment: "", LabelTypeCommitment: "Tous" });

    					$scope.Filter();
    				}
    			}
    		});
    	};

    	$scope.GetData = function (pageNo) {
    	    $scope.validatedPageNo = pageNo;
    	    getContractsCommitmentValidated();
    	};

    	$scope.Sort = function (sortKey, reverse) {
    	    $scope.dataValidated.sortKey = sortKey;
    	    $scope.dataValidated.reverse = reverse;
	        getContractsCommitmentValidated();
	    };

    	$scope.Filter = function () {
    		$scope.errorMessage = "";
    		$scope.successMessage = "";
    		getContractsCommitmentValidated();
    	};

        $scope.ExportValidatedContracts = function() {
            var downloadPath = configurationFactory.Url['Api'] + 'Contracts/GetContractsCommitmentValidatedXls/' +
                '?idCrop=' + $scope.idCropSelected +
                '&idProduct=' + $scope.idProductSelected +
                '&idTypeCommitment=' + $scope.idTypeCommitmentContractSelected +
                '&date=' + $scope.dateFilter +
                '&idContractStr=' + $scope.selectedIdContract +
                '&numClient=' + $scope.selectedIdClient +
                '&idCompagny=' + $scope.idCompagnySelected +
				'&idLanguage=' + $rootScope.language.IdLanguage;
            window.open(downloadPath, '_blank', '');
        };

        $scope.OpenFixContractCommitmentPopUp = function (idContract, openPopUp) {
            $scope.errorMessage = "";
            $scope.successMessage = "";
            $scope.fixContractCommimentErrorMessage = "";
            $scope.fixContractCommimentNoOfferMessage = "";
            $scope.loading = true;
            contractsService.GetInfosForFixContractCommitment(idContract, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.fixContractCommitmentVm = data;

                    if (!data)
                        $scope.fixContractCommimentNoOfferMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_ContractCommitment_PopupFix_NoOfferMessage.Content;

                    $scope.loading = false;
                    openPopUp();
                }
            });
        };

        $scope.ChkFixContractCommitmentIsValid = function () {
            if ($scope.fixContractCommitmentVm.QuantityToFix > $scope.fixContractCommitmentVm.QuantityLeftToFix) {
                $scope.fixContractCommimentErrorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_ContractCommitment_PopupFix_QtyErrorMessage.Content;
                return false;
            }

            return true;
        };

        $scope.FixContractCommitment = function () {
            contractsService.FixNewContractFromContractCommitment($scope.fixContractCommitmentVm, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                        $scope.loading = false;
                    }
                } else {
                    $scope.successMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_ContractCommitment_FixContractCommitment_SuccessMessage.Content;
                    getContractsCommitmentValidated();
                }
            });
        };

		//Pop-up de la fonction
		$scope.ShowPopUpDeleted = function (idContract, openPopUpDeleted) {
			$scope.idContractSelected = idContract;
			openPopUpDeleted();
		};
	
		//Suppression d'un contrat
		$scope.SetIsLogicallyDeleted = function () {
			contractsService.SetIsLogicallyDeleted($scope.idContractSelected, function (data, status) {
				//Erreur
				if (status !== null) {
					if (status === 400)
						$scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
					else
						$location.path("/");
				}
				//Si tout fonctionne
				else {
					getCropsWithContractsCommitment();
				}
			});
		};

    	$rootScope.onRefresh = function () {
            init();
    	};

    	init();
    }]);
