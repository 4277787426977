AgrimarketServices.service('contractBaseService', ['$http', 'configurationFactory',
    function ($http, configurationFactory) {

        var sendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null);
                })
                .error(function (data, status) {
                    callback(data, status);
                });
        };

        this.CheckContractBase = function (contractBaseVm, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'ContractBase/CheckContractBase',
                cache: false,
                crossDomain: true,
                data: contractBaseVm
            };
            sendRequest(config, callback);
        };

        this.SaveNewContractBase = function (contractBaseVm, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'ContractBase/SaveNewContractBase',
                cache: false,
                crossDomain: true,
                data: contractBaseVm
            };

            sendRequest(config, callback);
        };

        this.SaveNewContractBaseAndSendSmsYouSign = function (contractBaseVm, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'ContractBase/SaveNewContractBaseAndSendSmsYouSign',
                cache: false,
                crossDomain: true,
                data: contractBaseVm
            };
            sendRequest(config, callback);
        };

        this.GetContractBaseByIdContractBase = function (idContractBase, marketPrice, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'ContractBase/GetContractBaseByIdContractBase',
                params: { idContractBase: idContractBase, marketPrice: marketPrice}
            };
            sendRequest(config, callback);
        };

        this.GetCompatibleContractBasesWithFilter = function (idExpiry, idContractCommitment, max, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'ContractBase/GetCompatibleContractBasesWithFilter',
                params: {
                    idExpiry: idExpiry,
                    idContractCommitment: idContractCommitment,
                    itemPerPage: max,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };
            sendRequest(config, callback);
        };               

        this.GetContractsBasePaginate = function (idContractState, idCrop, idProduct, date, idContractStr, numClient, idCompagny, idTypeOrder, itemPerPage, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'ContractBase/GetContractsBasePaginate',
                params: {        
                    idContractState: idContractState,
                    idCrop: idCrop,
                    idProduct: idProduct,                    
                    date: date,
                    idContractStr: idContractStr,
                    numClient: numClient,
                    idCompagny: idCompagny,
                    idTypeOrder: idTypeOrder,                    
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse                    
                }
            };
            sendRequest(config, callback);
        };

        this.GetFiltersWithContractsBaseByCrop = function (idCrop, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'ContractBase/GetFiltersWithContractsBaseByCrop',
                params: { idCrop: idCrop }
            };

            sendRequest(config, callback);
        };

        this.GetFiltersWithContractsBase = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'ContractBase/GetFiltersWithContractsBase'                
            };
            sendRequest(config, callback);
        };

        this.GetTargetContractBasesFixToValidate = function (offerExpiriesVm, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'ContractBase/GetTargetContractBasesFixToValidate',
                data: offerExpiriesVm
            };
            sendRequest(config, callback);
        };

        this.ValidTargetContractBasesFix = function (offerExpiriesVm, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'ContractBase/ValidTargetContractBasesFix',
                data: offerExpiriesVm
            };
            sendRequest(config, callback);
        };

        this.UpdateContractBase = function (currentContractBase, callback) {
            var config = {
                method: 'PUT',
                url: configurationFactory.Url['Api'] + 'ContractBase/UpdateContractBase',
                data: currentContractBase
            };

            sendRequest(config, callback);
        };

        this.GetHistoContractBase = function (idContractBase, idCrop, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'ContractBase/GetHistoryContractBasesTargetPriceByIdInit',
                params: { idParentContract: idContractBase, idCrop: idCrop }
            };

            sendRequest(config, callback);
        };

    }]);