AgrimarketControllers.controller('requestPricesController', [
	'$scope', '$rootScope', '$routeParams', '$http', '$filter', '$location', '$sce', 'colonnesCart', 'categoryArticleService', 'articleService', 'enumFactory', 'configurationFactory', '$timeout', '$q',
    function ($scope, $rootScope, $routeParams, $http, $filter, $location, $sce, colonnesCart, categoryArticleService, articleService, enumFactory, configurationFactory, $timeout, $q) {

        if (!$rootScope.Modules.ModuleRequestPrices.Active)
            $location.path('/login');

        $scope.typeRequest = enumFactory.TypeRequest;
        $scope.typeRequestList = enumFactory.TypeRequestList;

        $scope.Init = function () {
            $scope.categories = [];
            $scope.families = [];
            $scope.articles = [];
            $scope.resultsFilter = [];
            $scope.filter = {};
            $scope.articlesSelected = [];
            $scope.articlesCateg = [];
            $scope.cart = [];

            if ($rootScope.cart) {
                $scope.cart = $rootScope.cart;
                $rootScope.cart = null;
            }

            $scope.request = {};
            $scope.Edit = false;
	        $scope.tableCartDataVisible = colonnesCart.data.DataVisible.split(',');
	        $scope.tableCartLabels = colonnesCart.data.ColumnName.split(',');
            $scope.max = 10;

            categoryArticleService.GetCategoryArticle(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    if (data !== null) {
                        $scope.categories = data;
                    }
                }
            });

            articleService.GetArticles(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    if (data !== null) {
                        $scope.articles = data;
                    }
                }
            });
        };

        $scope.FilterCateg = function () {

            $scope.articlesCateg = [];
            $scope.families = [];
            $scope.articlesSelected = [];
            $scope.filter.Name = null;
            $scope.Edit = false;

            if ($scope.filter.Categ) {

                $scope.articlesCateg = [];
                $scope.families = [];

                $scope.articles.forEach(function (article) {
                    if (article.IdCategoryArticle == $scope.filter.Categ) {

                        $scope.articlesSelected.push(article);
                        $scope.articlesCateg.push(article);

                        var find = false;

                        $scope.families.forEach(function (family) {
                            if (family.IdFamilyArticle == article.FamilyArticle.IdFamilyArticle) {
                                find = true;
                                return;
                            }
                        });

                        if (!find) {
                            $scope.families.push(article.FamilyArticle);
                        }

                    }
                });
                if ($scope.families.length == 1) {
                    $scope.families = [];
                }
            }

        };

        $scope.FilterFamily = function () {
            $scope.Edit = false;
            $scope.filter.Name = null;

            if ($scope.filter.Family) {
                $scope.articlesSelected = $filter('filter')($scope.articlesCateg, { "IdFamilyArticle": $scope.filter.Family }, true);
            } else {
                $scope.articlesSelected = $scope.articlesCateg;
            }

        };

        $scope.Cancel = function () {
            $scope.families = [];
            $scope.articlesSelected = [];
            $scope.articlesCateg = [];
            $scope.request = {};
        };

        $scope.Valide = function () {
            $scope.errorMessage = "";
            $scope.successMessage = "";

            if (!$scope.request.ArticleSelected) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_RequestPrices_NoArticleSelected.Content;
                return;
            }

            if (!$scope.request.Quantity) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_RequestPrices_PleaseEnterQuantity.Content;
                return;
            }

            if (isNaN($scope.request.Quantity)) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_RequestPrices_PleaseEnterValidQuantity.Content;
                return;
            }

            if ($scope.request.Quantity < $scope.request.ArticleSelected.MinimumSell) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_RequestPrices_PleaseEnterQuantityMinimum.Content + $scope.request.ArticleSelected.MinimumSell;
                return;
            }

            if ($scope.request.ArticleSelected.MultipleSell !== 0 && $scope.request.Quantity % $scope.request.ArticleSelected.MultipleSell !== 0) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_RequestPrices_PleaseEnterQuantityMultiple.Content + $scope.request.ArticleSelected.MultipleSell;
                return;
            }

            if (!$scope.request.IdTypeRequestArticle) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_RequestPrices_PleaseChooseTypeRequest.Content;
                return;
            }

            if ($scope.request.IdTypeRequestArticle === $scope.typeRequest.Disponibilite && (!$scope.request.DeliveryDate)) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_RequestPrices_PleaseEnterDeliveryDate.Content;
                return;
            }

            if ($scope.request.IdTypeRequestArticle === $scope.typeRequest.Disponibilite && !$scope.isDate($scope.request.DeliveryDate)) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_RequestPrices_PleaseEnterValidDate.Content;
                return;
            }

            if ($scope.request.IdTypeRequestArticle === $scope.typeRequest.Disponibilite && !$scope.VerifDate($scope.request.DeliveryDate)) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_RequestPrices_DateMustBeSuperiorThanCurrentDate.Content;
                return;
            }

            if ($scope.request.IdTypeRequestArticle !== $scope.typeRequest.Disponibilite) {
                $scope.request.DeliveryDate = null;
            }
            else {
            	var datePattern = /(\d{2})\/(\d{2})\/(\d{4})/;
            	$scope.request.DeliveryDate = new Date($scope.request.DeliveryDate.replace(datePattern, '$3-$2-$1'));
            }

            var typeRequestArticleSelected = $filter('filter')($scope.typeRequestList, { "Value": $scope.request.IdTypeRequestArticle }, true)[0];

            if ($scope.Edit)
            	$scope.cart[$scope.index] = { IdArticle: $scope.request.ArticleSelected.IdArticle, ArticleLabel: $scope.request.ArticleSelected.Label, IdTypeRequestArticle: typeRequestArticleSelected.Value, TypeRequestArticleLabel: typeRequestArticleSelected.Name, Quantity: $scope.request.Quantity, DeliveryDate: $scope.request.DeliveryDate, Article: $scope.request.ArticleSelected };
            else
            	$scope.cart.push({ IdArticle: $scope.request.ArticleSelected.IdArticle, ArticleLabel: $scope.request.ArticleSelected.Label, IdTypeRequestArticle: typeRequestArticleSelected.Value, TypeRequestArticleLabel: typeRequestArticleSelected.Name, Quantity: $scope.request.Quantity, DeliveryDate: $scope.request.DeliveryDate, Article: $scope.request.ArticleSelected });

            $scope.request = {};
        };

        $scope.isDate = function (input) {
            var yearToCheck = input.split('/')[2];
            var monthToCheck = input.split('/')[1] - 1; // 0-based... so 1 = february
            var dateToCheck = input.split('/')[0];
            var dateObj = new Date(yearToCheck, monthToCheck, dateToCheck);
            if (dateObj.getFullYear() == yearToCheck && dateObj.getMonth() == monthToCheck && dateObj.getDate() == dateToCheck) {
                return true;
            } else {
                return false;
            }
        };

        $scope.VerifDate = function (input) {
            var yearToCheck = input.split('/')[2];
            var monthToCheck = input.split('/')[1] - 1; // 0-based... so 1 = february
            var dateToCheck = input.split('/')[0];
            var dateObj = new Date(yearToCheck, monthToCheck, dateToCheck);
            if (dateObj <= new Date()) {
                return false;
            }
            return true;
        };

        $scope.Send = function () {
            $scope.errorMessage = "";
            $scope.successMessage = "";
            articleService.PostArticles($scope.cart, $rootScope.skin.css, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.successMessage = $rootScope.editableObjectsFactory.EditableObjects.User_RequestPrices_Succes.Content;
                    $scope.cart = [];
                }
            });
        };

        $scope.EditCart = function (item, index) {
        	$scope.request = angular.copy(item);
	        $scope.request.DeliveryDate = $filter('date')($scope.request.DeliveryDate, 'dd/MM/yyyy');
	        $scope.request.ArticleSelected = item.Article;
            $scope.articlesSelected = [];
            $scope.articlesSelected[0] = item.Article;
            $scope.Edit = true;
            $scope.index = index;
        };

        $scope.RemoveCart = function (item, index) {
            $scope.cart = $scope.cart.splice(index, 0);
        };

        $scope.SaveAndRedirect = function (path) {
            $rootScope.cart = $scope.cart;
            $location.path(path);
        };

        $scope.SelectArticle = function (article) {
            $scope.request.ArticleSelected = article;
        };

        $scope.GetArticleSelected = function (articleSelected) {
            if (articleSelected) {
                $scope.articlesSelected = [];
                $scope.articlesSelected.push(articleSelected.originalObject);
                $scope.Edit = false;
            }
        };

        $scope.search = function (userInputString, timeoutPromise) {
            $scope.articlesSelected = [];
            var defered = $q.defer();
            var t = articleService.FindArticle(userInputString, timeoutPromise, function (data, status) { });
            t.then(function (result) {
                if (result.status != 200)
                    defered.reject(status);
                else {
                    defered.resolve(result.data);
                    $scope.articlesSelected = result.data;
                }
            });
            return defered.promise;
        };

        $scope.focusOut = function () {
            if (!$scope.request.SearchString)
                $scope.articlesSelected = [];
        };

        $scope.Init();
    }
]);

