//Directive permettant d'éxucter une fonction lorsqu'on appuie sur Entrer

AgrimarketDirectives.directive('ngEnter', function ($timeout) {

	return function (scope, element, attrs) {
		element.bind("keydown keypress", function (event) {
			if(event.which === 13) {
				scope.$apply(function (){
					scope.$eval(attrs.ngEnter);
				});
 
				event.preventDefault();
			}
		});
	};
});