
AgrimarketServices.service('testsocketService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function($rootScope, $http, $location, currentUserFactory, configurationFactory) {
        var SendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null, null, null);
                })
                .error(function (data, status, headers, config) {
                    callback(data, status, headers, config);
                });
        };

        this.SendAthenaOrder = function (actionToOrder, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'TestSocket/SendOrderToAthena',
                params: { actionToOrder: actionToOrder }
            };

            SendRequest(config, callback);
        };
    }]);