AgrimarketServices.service('quoteFutureHashtagriService', ['$rootScope', '$http', '$location', 'configurationFactory',
    function ($rootScope, $http, $location, configurationFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
			    .success(function (data) {
			        callback(data, null, null, null);
			    })
			    .error(function (data, status, headers, config) {
			        callback(data, status, headers, config);
			    });
        };

        this.SaveFutureMarketsExpiries = function (collection, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'QuoteFutureHashtagri/SaveFutureMarketsExpiries',
                data: collection
            };
            SendRequest(config, callback);
        };

        this.GetFutureMarketExpiriesSelected = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'QuoteFutureHashtagri/GetFutureMarketExpiriesSelected'
            };
            SendRequest(config, callback);
        };

        this.GetFutureMarketsExpiriesFromWsdApi = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'QuoteFutureHashtagri/GetFutureMarketsExpiriesFromWsdApi'
            };
            SendRequest(config, callback);
        };
    }]);