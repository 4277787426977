AgrimarketServices.service('portfolioService', ['$http', 'configurationFactory',
    function ($http, configurationFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null);
                })
                .error(function (data, status) {
                    callback(data, status);
                });
        };

        this.GetContracts = function (params, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Portfolio/GetContracts',
                data: params
            };
            SendRequest(config, callback);
        };

        this.GetOrders = function (params, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'Portfolio/GetOrders',
                data: params
            };
            SendRequest(config, callback);
        };

        this.GetOrderStatuses = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Portfolio/GetOrderStatuses'
            };
            SendRequest(config, callback);
        };
        this.GetContractStatuses = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Portfolio/GetContractStatuses'
            };
            SendRequest(config, callback);
        };
    }
 ]);