AgrimarketControllers.controller('editorialContractController', ['$scope', '$rootScope', '$sce', '$filter', '$location', 'templatesService', 'enumFactory',
    function ($scope, $rootScope,  $sce, $filter, $location, templatesService,  enumFactory) {

        var init = function () {
            $scope.enumRoles = enumFactory.Roles;

            if ($rootScope.succesMessageTemplate !== undefined && $rootScope.succesMessageTemplate !== null)
                $scope.successMessage = $rootScope.succesMessageTemplate;

            $rootScope.succesMessageTemplate = "";
            $scope.errorMessage = "";
            $scope.lstTypesTemplates = [];
            $scope.templatesFilter = [];
            $scope.templates = [];
            $scope.lstCompagnies = [];
            $scope.TypeTemplateSelected = { id: "" };
            $scope.CompagnySelected = { id: "" };
            $scope.LanguageSelected = { id: "" };

            $scope.Action_Disable = $rootScope.editableObjectsFactory.EditableObjects.Admin_EditorialContract_Action_Disable.Content;
            $scope.PopUpDisable_Title = $rootScope.editableObjectsFactory.EditableObjects.Admin_EditorialContract_PopUpDisable_Title.Content;
            $scope.PopUpDisable_Message = $rootScope.editableObjectsFactory.EditableObjects.Admin_EditorialContract_PopUpDisable_Message.Content;
            $scope.PopUpDisable_Button_Yes = $rootScope.globalLabels.Yes;
            $scope.PopUpDisable_Button_No = $rootScope.globalLabels.No;

            GetEnableTemplates();

        };

        var GetEnableTemplates = function () {
            templatesService.GetTemplates(function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } 
                } else {
                    $scope.templates = data;
                    $scope.templates = $scope.templates.filter(function (element) { return element.IsEnable === true; });
                    $scope.Filter();
                }
            });
        };

        $scope.Filter = function () {
            $scope.errorMessage = "";
            $scope.lstTypesTemplates = [];
            $scope.lstCompagnies = [];
            $scope.lstLanguages = [];
            var filterGeneral = angular.copy($scope.templates);

            if ($scope.TypeTemplateSelected && $scope.TypeTemplateSelected.id)
                filterGeneral = filterGeneral.filter(function (element) { return element.IdTypeTemplate === Number($scope.TypeTemplateSelected.id); });

            if ($rootScope.ModuleMultipleCompagnies.Active && $scope.CompagnySelected && $scope.CompagnySelected.id)
                filterGeneral = filterGeneral.filter(function (element) { return element.IdCompagny === Number($scope.CompagnySelected.id); });

            if ($rootScope.ModuleMultilingue.Active && $scope.LanguageSelected && $scope.LanguageSelected.id)
                filterGeneral = filterGeneral.filter(function (element) { return element.IdLanguage === Number($scope.LanguageSelected.id); });

            $scope.templatesFilter = angular.copy(filterGeneral);


            //création des filtre en fonction des templates
            if ($scope.templates && $scope.templates.length > 0) {
                $scope.templates.forEach(function (item) {
                    if ($filter('filter')($scope.lstTypesTemplates, { IdTypeTemplate: item.TypeTemplate.IdTypeTemplate }).length === 0)
                        $scope.lstTypesTemplates.push({ IdTypeTemplate: item.TypeTemplate.IdTypeTemplate, Label: item.TypeTemplate.Label });
                    if ($rootScope.ModuleMultipleCompagnies.Active && item.Compagny) {
                        if ($filter('filter')($scope.lstCompagnies, { IdCompagny: item.Compagny.IdCompagny }).length === 0)
                            $scope.lstCompagnies.push({ IdCompagny: item.Compagny.IdCompagny, Label: item.Compagny.Label });
                    }
                    if ($rootScope.ModuleMultilingue.Active && item.Language) {
                        if ($filter('filter')($scope.lstLanguages, { IdLanguage: item.Language.IdLanguage }).length === 0)
                            $scope.lstLanguages.push({ IdLanguage: item.Language.IdLanguage, Label: item.Language.Label });
                    }
                });
            }
        };

        $scope.Delete = function (idTemplate) {
            $scope.errorMessage = "";
            $scope.successMessage = "";
            templatesService.DeleteTemplate(idTemplate, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    }
                } else {
                    $scope.templates = data;
                    $scope.templatesFilter = angular.copy($scope.templates);
                    $scope.Filter();
                    $scope.successMessage = "Le template a bien été supprimé.";
                }
            });
        };

        $scope.Disable = function (idTemplate) {
            $scope.errorMessage = "";
            $scope.successMessage = "";
            templatesService.DisableTemplate(idTemplate, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    }
                } else {
                    $scope.templates = data;
                    $scope.templatesFilter = angular.copy($scope.templates);
                    $scope.Filter();
                    $scope.successMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_EditorialContract_PopUpDisable_MessageSuccess.Content;
                }
            });
        };

        $scope.AddTemplate = function () {
            $location.path('/admin/editorial/editTemplateContract/-1');
        };

        $scope.EditTemplateContract = function (id) {
            $location.path('/admin/editorial/editTemplateContract/' + id);
        };


        init();
    }]);
