AgrimarketControllers.controller('manageContractsOfTcUsersController', ['$scope', '$rootScope', '$routeParams', '$filter', 'configurationFactory',
    'contractsService', '$sce', '$location', 'enumFactory', 'columnsValidated', 'columnsPriced', 'columnsCanceled', 'columnsPending', 'columnsDelayedSigning', 'userService', 'parametersFactory',
    function ($scope, $rootScope, $routeParams, $filter, configurationFactory, contractsService, $sce, $location, enumFactory, columnsValidated, columnsPriced, columnsCanceled, columnsPending, columnsDelayedSigning, userService, parametersFactory) {

        var idTabContracts;

        var Init = function () {
            // Contracts validés
            $scope.tableValidatedDataVisible = columnsValidated.data.DataVisible.split(',');
            $scope.tableValidatedLibelle = columnsValidated.data.ColumnName.split(',');

            // Contracts en attente
            $scope.tablePendingDataVisible = columnsPending.data.DataVisible.split(',');
            $scope.tablePendingLibelle = columnsPending.data.ColumnName.split(',');

            // Contracts en attente de signature
            $scope.tableDelayedSigningDataVisible = columnsDelayedSigning.data.DataVisible.split(',');
            $scope.tableDelayedSigningLibelle = columnsDelayedSigning.data.ColumnName.split(',');

            // Contracts prix objectif
            $scope.tablePricedDataVisible = columnsPriced.data.DataVisible.split(',');
            $scope.tablePricedLibelle = columnsPriced.data.ColumnName.split(',');

            // Contracts annulés
            $scope.tableCanceledDataVisible = columnsCanceled.data.DataVisible.split(',');
            $scope.tableCanceledLibelle = columnsCanceled.data.ColumnName.split(',');

            // Gestion du trie de l'onglet Validés
            $scope.dataValidated = { reverse: true, sortKey: "IdContract" };

            // Gestion du trie de l'onglet En attente
            $scope.dataPending = { reverse: true, sortKey: "IdContract" };

            // Gestion du trie de l'onglet En attente de signature
            $scope.dataDelayedSigning = { reverse: true, sortKey: "DelayedSigning" };

            // Gestion du trie de l'onglet Validés
            $scope.dataPriced = { reverse: true, sortKey: "IdContract" };

            // Gestion du trie de l'onglet Validés
            $scope.dataCanceled = { reverse: true, sortKey: "IdContract" };

            // Paginations onglets
            $scope.validatedPageNo = 1;
            $scope.pendingPageNo = 1;
            $scope.pricedPageNo = 1;
            $scope.canceledPageNo = 1;
            $scope.delayedSigningPageNo = 1;

            $scope.max = 10;
            $scope.idCropSelected = Number(parametersFactory.Parameters.DefaultCrop.Value);
            $scope.idProductSelected = "";
            $scope.idTypeOrderContractSelected = "";
            $scope.idTypeFixationSelected = "";
            $scope.idCompagnySelected = "";
            $scope.dateFilter = "";
            $scope.viewMode = $routeParams.typeContract;
            $scope.idProductSelected = "";
            $scope.idTypeOrderSelected = "";
            $scope.idTypeFixationSelected = "";
            $scope.dateSelected = "";
            $rootScope.isTcSigningForUser = false;
            $scope.isTCAuthorizedToSignInModeDelayed = $rootScope.paramIsTcAuthorizedToSigningInModeDelayed == "1";

            if ($scope.viewMode === "validated")
                idTabContracts = enumFactory.TabContract.Validated;
            else if ($scope.viewMode === "priced")
                idTabContracts = enumFactory.TabContract.Priced;
            else if ($scope.viewMode === "canceled")
                idTabContracts = enumFactory.TabContract.Canceled;
            else if ($scope.viewMode === "pending")
                idTabContracts = enumFactory.TabContract.Pending;
            else if ($scope.viewMode === "delayedSigning")
                idTabContracts = enumFactory.TabContract.delayedSigning;

            GetFiltersWithContracts();
        };

        $scope.$watch("selectedIdContract", function (newValue, oldValue) {
            if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                $scope.GetContractsWithFilter();
        });

        $scope.$watch("selectedUserFullName", function (newValue, oldValue) {
            if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                $scope.GetContractsWithFilter();
        });

        $scope.$watch("selectedNameOffer", function (newValue, oldValue) {
            if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                $scope.GetContractsWithFilter();
        });

        $scope.$watch("dateFilter", function (newValue, oldValue) {
            if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                $scope.GetContractsWithFilter();
        });

        $scope.$watch("dateSelected", function (newValue) {
            if (newValue === "")
                $scope.dateFilter = "";
            else if (newValue) {
                //permet de gérer l'internalisation de tout les formats des dates possibles.
                //Au final nous faisons en sorte de la retrouvé au format dd/mm/yyyy.
                var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                var dt = new Date(newValue.replace(pattern, '$3-$2-$1'));
                var currentDate = new Date(dt.toString());
                var converDate = moment(currentDate);
                $scope.dateFilter = converDate.format('YYYY/MM/DD');
            }
        });

        var GetFiltersWithContracts = function () {
            if (!$scope.idCropSelected) $scope.idCropSelected = "";
            if (!$scope.selectedIdClient) $scope.selectedIdClient = "";
            if (!$scope.idTypeContractSelected) $scope.idTypeContractSelected = "";
            $scope.idProductSelected = "";
            $scope.selectedIdContract = "";
            $scope.selectedUserFullName = "";
            $scope.selectedNameOffer = "";
            $scope.idTypeContractSelected = "";
            $scope.idTypeOrderSelected = "";
            $scope.idTypeFixationSelected = "";
            $scope.dateSelected = "";
            $scope.loading = true;
            var labelAll = $rootScope.editableObjectsFactory.EditableObjects.All.Content;
            contractsService.GetFiltersWithUserContractsByCropAndTabContract(idTabContracts, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    if (data) {
                        $scope.lstTypeContracts = data.LstTypeContractFilterVm;
                        $scope.lstProducts = data.LstProductFilterVm;
                        $scope.lstTypeOrders = data.LstTypeOrderFilterVm;
                        $scope.lstTypeFixations = data.LstTypeFixationFilterVm;
                        $scope.lstCrops = data.LstCrops;

                        if ($scope.lstProducts.length === 1)
                            $scope.idProductSelected = $scope.lstProducts[0].IdProduct;
                        else if ($scope.lstProducts.length > 1)
                            $scope.lstProducts.unshift({ IdProduct: "", ProductLabel: labelAll });

                        if ($scope.lstTypeOrders.length === 1)
                            $scope.idTypeOrderSelected = $scope.lstTypeOrders[0].IdTypeOrder;
                        else if ($scope.lstTypeOrders.length > 1)
                            $scope.lstTypeOrders.unshift({ IdTypeOrder: "", TypeOrderLabel: labelAll });

                        if ($scope.lstCrops.length === 1)
                            $scope.idCropSelected = $scope.lstCrops[0].IdCrop;
                        else if ($scope.lstCrops.length > 1)
                            $scope.lstCrops.unshift({ IdCrop: "", Label: $rootScope.editableObjectsFactory.EditableObjects.AllItems_F.Content });

                        if ($scope.lstTypeContracts.length === 1)
                            $scope.idTypeContractSelected = $scope.lstTypeContracts[0].IdTypeContract;
                        else if ($scope.lstTypeContracts.length > 1)
                            $scope.lstTypeContracts.unshift({ IdTypeContract: "", TypeContractLabel: labelAll });

                        if ($scope.lstTypeFixations.length === 1)
                            $scope.idTypeFixationSelected = $scope.lstTypeFixations[0].Id;
                        else if ($scope.lstTypeFixations.length > 1)
                            $scope.lstTypeFixations.unshift({ Id: "", Label: labelAll });

                        $scope.GetContractsWithFilter();
                    }
                }
            });
        };

        $scope.GetContractsWithFilter = function () {
            if (!$scope.selectedIdContract) $scope.selectedIdContract = "";
            if (!$scope.selectedUserFullName) $scope.selectedUserFullName = "";
            if (!$scope.selectedNameOffer) $scope.selectedNameOffer = "";
            $scope.loading = true;
            if ($scope.viewMode === "validated")
                contractsService.GetUserValidatedContractsPaginate($scope.idCropSelected, $scope.idProductSelected, $scope.idTypeOrderSelected, $scope.dateFilter, $scope.selectedIdContract, $scope.selectedUserFullName, $scope.max, $scope.validatedPageNo, $scope.dataValidated.sortKey, $scope.dataValidated.reverse, $scope.selectedNameOffer, $scope.idTypeContractSelected, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        $scope.total = data.Total;
                        $scope.contracts = data.ListVm;
                        $scope.loading = false;
                    }
                });
            else if ($scope.viewMode === "priced")
                contractsService.GetUserPricedContractsPaginate($scope.idCropSelected, $scope.idProductSelected, $scope.idTypeOrderSelected, $scope.dateFilter, $scope.selectedIdContract, $scope.selectedUserFullName, $scope.idTypeFixationSelected, $scope.max, $scope.pricedPageNo, $scope.dataPriced.sortKey, $scope.dataPriced.reverse, $scope.selectedNameOffer, $scope.idTypeContractSelected, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        $scope.total = data.Total;
                        $scope.contracts = data.ListVm;
                        $scope.loading = false;
                    }
                });
            else if ($scope.viewMode === "canceled")
                contractsService.GetUserCanceledContractsPaginate($scope.idCropSelected, $scope.idProductSelected, $scope.idTypeOrderSelected, $scope.dateFilter, $scope.selectedIdContract, $scope.selectedUserFullName, $scope.max, $scope.canceledPageNo, $scope.dataCanceled.sortKey, $scope.dataCanceled.reverse, $scope.selectedNameOffer, $scope.idTypeContractSelected, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        $scope.total = data.Total;
                        $scope.contracts = data.ListVm;
                        $scope.loading = false;
                    }
                });
            else if ($scope.viewMode === "pending")
                contractsService.GetUserPendingContractsPaginate($scope.idCropSelected, $scope.idProductSelected, $scope.idTypeOrderSelected, $scope.dateFilter, $scope.selectedIdContract, $scope.selectedUserFullName, $scope.max, $scope.validatedPageNo, $scope.dataValidated.sortKey, $scope.dataValidated.reverse, $scope.selectedNameOffer, $scope.idTypeContractSelected, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        $scope.total = data.Total;
                        $scope.contracts = data.ListVm;
                        $scope.loading = false;
                    }
                });
            else if ($scope.viewMode === "delayedSigning")
                contractsService.GetUserDelayedSigningContractsPaginate($scope.idCropSelected, $scope.idProductSelected, $scope.dateFilter, $scope.selectedUserFullName, $scope.max, $scope.delayedSigningPageNo, $scope.dataDelayedSigning.sortKey, $scope.dataDelayedSigning.reverse, $scope.selectedNameOffer, $scope.idTypeContractSelected, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        $scope.total = data.Total;
                        $scope.contracts = data.ListVm;
                        $scope.loading = false;
                    }
                });
        };

        $scope.CancelContractPopUp = function (contractSelected, index, showPopUpContract) {
            $scope.PopUpMessageDeleteContract = "";
            $scope.PopupCanceledContractsSelectedTitle = "Annulation du contrat n° " + contractSelected.IdContract;
            $scope.MessageDeleteContractFirmTargetPrice = $rootScope.editableObjectsFactory.EditableObjects.MessageDeleteContractFirmTargetPrice.Content;
            if (contractSelected !== undefined)
                $scope.PopUpMessageDeleteContract = $scope.MessageDeleteContractFirmTargetPrice;

            showPopUpContract(contractSelected);
        };

        $scope.ChangeTargetPricePopUp = function (currentContract, index, openPopUp) {
            $scope.selectedContract = currentContract;
            $scope.targetPriceErrorMessage = "";
            $scope.titleTargetPricePopUp = $rootScope.editableObjectsFactory.EditableObjects.User_ManageContractsOfTcUsers_PopupChangeTargetPrice_Title.Content;
            $scope.ActionType = "edit";
            openPopUp();
        };

        $scope.HistoContractPopUp = function (currentContract, index, openPopUpHisto) {
            contractsService.GetHistoContract(currentContract.IdInit, currentContract.IdCrop, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.lstHistoContract = data;
                    $scope.ModalTitle = "Historique du contrat";
                    openPopUpHisto();
                }
            });
        };

        $scope.ChkTargetPriceIsValid = function () {
            $scope.targetPriceErrorMessage = "";
            $scope.errorMessage = "";
            $scope.successMessage = "";
            $scope.ErrorTargetPriceMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_UserContractsFirmMonitoring_MessageTargetPrice.Content;
            $scope.ErrorPriceMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_UserContractsFirmMonitoring_PriceMessage.Content;
            $scope.TargetPriceCompareErrorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_UserContractsFirmMonitoring_TargetPriceCompareMessage.Content;
            $scope.TargetPriceCompareErrorMessage2 = $rootScope.editableObjectsFactory.EditableObjects.Currency.Content;
            $scope.ChangeTargetPriceSuccessMessage = $rootScope.editableObjectsFactory.EditableObjects.Success_UserContractsFirmMonitoring_ChangeTargetPriceMessage.Content;
            $scope.CancelContractSuccessMessage = $rootScope.editableObjectsFactory.EditableObjects.Success_UserContractsFirmMonitoring_CancelContractMessage.Content;
            $scope.paramAuthorizeTargetPriceUnderOfferPrice = parametersFactory.Parameters.bAuthorizeTargetPriceUnderOfferPrice.Value === "1";

            if (!$scope.selectedContract.hasOwnProperty("NewTargetPrice") || $scope.selectedContract.NewTargetPrice === null || $scope.selectedContract.NewTargetPrice < 0) {
                $scope.targetPriceErrorMessage = $scope.ErrorTargetPriceMessage;
                $scope.selectedContract.NewTargetPrice = null;
                return false;
            } else {
                $scope.selectedContract.NewTargetPrice = $scope.selectedContract.NewTargetPrice.replace(",", ".");
                if (!parseFloat($scope.selectedContract.NewTargetPrice)) {
                    $scope.errorPriceMessage = $scope.ErrorPriceMessage;
                    return false;
                } else {
                    $scope.selectedContract.NewTargetPrice = Number($scope.selectedContract.NewTargetPrice);
                }
                if (!$scope.paramAuthorizeTargetPriceUnderOfferPrice && $scope.selectedContract.NewTargetPrice < $scope.selectedContract.OfferPriceUpdated) { // Remplacement de OfferPrice par OfferPriceUpdated car OfferPrice est à null
                    $scope.targetPriceErrorMessage = $scope.TargetPriceCompareErrorMessage + ' ' + $scope.selectedContract.OfferPriceUpdated + ' ' + $scope.TargetPriceCompareErrorMessage2;
                    $scope.selectedContract.NewTargetPrice = null;
                    return false;
                }
            }
            return true;
        };

        $scope.ChangeTargetPrice = function () {
            contractsService.UpdateContract($scope.selectedContract, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.successMessage = $scope.ChangeTargetPriceSuccessMessage;
                    GetFiltersWithContracts();
                }
            });

        };

        $scope.CancelContract = function (element) {
            contractsService.UpdateContract(element, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.successMessage = $scope.CancelContractSuccessMessage;
                    GetFiltersWithContracts();
                }
            });
        };

        $scope.GetDataOrSort = function (sortKey, reverse, pageNo) {
            if ($scope.viewMode === "validated") {
                if (pageNo) $scope.validatedPageNo = pageNo;
                if (sortKey) $scope.dataValidated.sortKey = sortKey;
                if (reverse !== null) $scope.dataValidated.reverse = reverse;
            }
            else if ($scope.viewMode === "priced") {
                if (pageNo) $scope.pricedPageNo = pageNo;
                if (sortKey) $scope.dataPriced.sortKey = sortKey;
                if (reverse !== null) $scope.dataPriced.reverse = reverse;
            }
            else if ($scope.viewMode === "canceled") {
                if (pageNo) $scope.canceledPageNo = pageNo;
                if (sortKey) $scope.dataCanceled.sortKey = sortKey;
                if (reverse !== null) $scope.dataCanceled.reverse = reverse;
            }
            else if ($scope.viewMode === "pending") {
                if (pageNo) $scope.pendingPageNo = pageNo;
                if (sortKey) $scope.dataPending.sortKey = sortKey;
                if (reverse !== null) $scope.dataPending.reverse = reverse;
            }
            else if ($scope.viewMode === "delayedSigning") {
                if (pageNo) $scope.delayedSigningPageNo = pageNo;
                if (sortKey) $scope.dataDelayedSigning.sortKey = sortKey;
                if (reverse !== null) $scope.dataDelayedSigning.reverse = reverse;
            }
            $scope.GetContractsWithFilter();
        };

        $scope.ExportValidatedContracts = function () {
            var downloadPath = configurationFactory.Url['Api'] + 'Contracts/GetValidatedContractsXls/' +
                '?idCrop=' + $scope.idCropSelected +
                '&idProduct=' + $scope.idProductSelected +
                '&idTypeOrder=' + $scope.idTypeOrderContractSelected +
                '&date=' + $scope.dateFilter +
                '&idContractStr=' + $scope.selectedIdContract +
                '&numClient=' + $scope.selectedIdClient +
                '&idCompagny=' + $scope.idCompagnySelected +
                '&idContractBaseStr=' + $scope.selectedIdContractBase +
                '&idLanguage=' + $rootScope.language.IdLanguage +
                '&nameOffer=' + $scope.selectedNameOffer +
                '&idTypeContractSelected=' + $scope.idTypeContractSelected;
            window.open(downloadPath, '_blank', '');
        };

        $scope.ExportPricedContracts = function () {
            var downloadPath = configurationFactory.Url['Api'] + 'Contracts/GetPricedContractsXls/' +
                '?idCrop=' + $scope.idCropSelected +
                '&idProduct=' + $scope.idProductSelected +
                '&idTypeOrder=' + $scope.idTypeOrderContractSelected +
                '&date=' + $scope.dateFilter +
                '&idContractStr=' + $scope.selectedIdContract +
                '&numClient=' + $scope.selectedIdClient +
                '&idCompagny=' + $scope.idCompagnySelected +
                '&idLanguage=' + $rootScope.language.IdLanguage +
                '&idTypeFixation=' + $scope.idTypeFixationSelected +
                '&nameOffer=' + $scope.selectedNameOffer +
                '&idTypeContractSelected=' + $scope.idTypeContractSelected;
            window.open(downloadPath, '_blank', '');
        };

        $scope.ExportCanceledContracts = function () {
            var downloadPath = configurationFactory.Url['Api'] + 'Contracts/GetCanceledContractsXls/' +
                '?idCrop=' + $scope.idCropSelected +
                '&idProduct=' + $scope.idProductSelected +
                '&idTypeOrder=' + $scope.idTypeOrderContractSelected +
                '&date=' + $scope.dateFilter +
                '&idContractStr=' + $scope.selectedIdContract +
                '&numClient=' + $scope.selectedIdClient +
                '&idCompagny=' + $scope.idCompagnySelected +
                '&idLanguage=' + $rootScope.language.IdLanguage +
                '&nameOffer=' + $scope.selectedNameOffer +
                '&idTypeContractSelected=' + $scope.idTypeContractSelected;
            window.open(downloadPath, '_blank', '');
        };

        $scope.ExportPendingContracts = function () {
            var downloadPath = configurationFactory.Url['Api'] + 'Contracts/GetPendingContractsXls/' +
                '?idCrop=' + $scope.idCropSelected +
                '&idProduct=' + $scope.idProductSelected +
                '&idTypeOrder=' + $scope.idTypeOrderContractSelected +
                '&date=' + $scope.dateFilter +
                '&idContractStr=' + $scope.selectedIdContract +
                '&numClient=' + $scope.selectedIdClient +
                '&idCompagny=' + $scope.idCompagnySelected +
                '&idLanguage=' + $rootScope.language.IdLanguage +
                '&nameOffer=' + $scope.selectedNameOffer +
                '&idTypeContractSelected=' + $scope.idTypeContractSelected;
            window.open(downloadPath, '_blank', '');
        };

        $scope.GoToSubscribeDelayedSigning = function (contract) {
            $rootScope.goToDelayedSigning = true;
            $rootScope.idContractForDelayedSigning = contract.IdContract;
            $rootScope.hasSmsCodeSigningAlreadySend = contract.HasSmsCodeSigningAlreadySend;
            $rootScope.selectedClientFromTc = $filter('filter')($rootScope.lstClientsFromTc, { IdUser: contract.IdUser }, true)[0];
            userService.SaveClientSelectedFromTC($rootScope.selectedClientFromTc.IdUser, true, function (data, status) {
                if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
            });
            $rootScope.isTcSigningForUser = true;
            if (contract.IdTypeContract == enumFactory.TypeContract.Ferme)
                $location.path('/user/subscribe/contractFirm/');
            else if (contract.IdTypeContract == enumFactory.TypeContract.ContratVierge)
                $location.path('/user/subscribe/contractBlank/');
        };

        $rootScope.onRefresh = function () {
            Init();
        };

        Init();
    }]);
