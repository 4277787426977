/* Directive permettant de trier des tables
*  1 paramétres sont nécessaires : 
*       - le nom de la colonne de trie
        - l'index du predicate et du renverse utilisé (pour gérer le cas de plusieurs tables dans une même page)
*   exemple d'utilisation : <table>...<th sort-me ng-click='change(nom de la colonne)'...... | orderBy:predicate[index]:renverse[index]
*/

AgrimarketDirectives.directive('sortTable', function () {
	return {
		restrict: 'AE',
		controller: function($scope) {

			$scope.predicate = [];
			$scope.renverse = [];
			$scope.notSorted = [];
			$scope.topSorted = [];
			$scope.bottomSorted = [];

			$scope.indexActive = 0;


			$scope.change = function(column, i) {
				$scope.notSorted[i] = false;

				$scope.indexActive = i;
				if ($scope.predicate[i] === undefined || $scope.predicate[i] === null) {
					$scope.predicate[i] = column;
					$scope.renverse[i] = false;
					$scope.topSorted[i] = false;
					$scope.bottomSorted[i] = true;
				}

				if ($scope.predicate[i] == column) {
					$scope.renverse[i] = !$scope.renverse[i];
					$scope.topSorted[i] = !$scope.topSorted[i];
					$scope.bottomSorted[i] = !$scope.bottomSorted[i];
				} else {
					$scope.predicate[i] = column;
					$scope.renverse[i] = false;
					$scope.topSorted[i] = false;
					$scope.bottomSorted[i] = true;
				}
			};

		}
	};

});
