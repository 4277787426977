AgrimarketDirectives.directive('agrimarketEditableGraph', function ($compile) {
	return {
		transclude: false,
		replace: false,
		restrict: 'EA',
		compile: function (elm, attrs) {
			var key = attrs.agrimarketEditableGraphKey;
			var contentAction = attrs.agrimarketEditableContentAction;
			var href = attrs.agrimarketEditableHref;
			var contentDefault = elm.text().replace("'", "\\'").replace('"', '\\"');
			elm.removeAttr('agrimarket-editable-graph');
			elm.removeAttr('agrimarket-editable-graph-key');
			elm.removeAttr('agrimarket-editable-graph-content-action');
			elm.removeAttr('agrimarket-editable-graph-href');
			elm.attr('editable-content-body', "editableObjectsFactory.getContentByKey('" + key + "', '" + contentDefault + "')");
			elm.attr('editable-content-action', contentAction);
			elm.attr('editable-content-visible', 'editableObjectsFactory.getIsVisibleByKey(\'' + key + '\')');
			elm.attr('editable-ico-visible', 'modeEdition');
			elm.attr('editable-ico-action', "openPopEditableGraph('" + key + "', '" + contentDefault + "')");
			elm.attr('editable-href',href);
			elm.attr('editable','');
			var fn = $compile(elm);
			return function (scope) {
				fn(scope);
			};
		}
	};
});