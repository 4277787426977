AgrimarketDirectives.directive('agrimarketEditableBlock', function ($compile) {
	return {
		transclude: false,
		replace: false,
		restrict: 'EA',
		compile: function (elm, attrs) {
			var key = attrs.agrimarketEditableBlockKey;
			elm.removeAttr('agrimarket-editable-block');
			elm.removeAttr('agrimarket-editable-block-key');
			elm.attr('editable-content-visible', 'false');
			elm.attr('editable-content-body', 'globalLabels.Edit_Blocks');
			elm.attr('editable-ico-visible', 'modeEdition');
			elm.attr('editable-ico-action', 'openPopEditableBlock(\'' + key + '\')');
			elm.attr('editable', '');
			var fn = $compile(elm);
			return function (scope) {
				fn(scope);
			};
		}
	};
});