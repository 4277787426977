AgrimarketControllers.filter('uploadLinkInline', function (configurationFactory) {
    return function (value) {

        var url = configurationFactory.Url['Api'] + 'Upload/GetInlineAttachment?p=' + value ;

        return url;
    };
});



