AgrimarketControllers.controller('contactsController', ['$scope', '$rootScope', '$sce', 'parametersService',
	function ($scope, $rootScope, $sce, parametersService) {

		$scope.lstOptionTypeContact = [];

		$scope.Init = function () {
			parametersService.GetParameterByKey('contactTypeDisplayedOnHomePage', function (data, status) {
				if (status !== null) {
					if (status == 400)
						$scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
				}
				else if (data && data.PossibleLabels && data.PossibleValues) {
					$scope.parameter = data;
					
					var tabValues = data.PossibleValues.split(';');
					var tabLabels = data.PossibleLabels.split(';');

					for (var i = 0; i < tabLabels.length; i++) {
						$scope.lstOptionTypeContact.push({ value: tabValues[i], label: tabLabels[i]});
					}
				}
			});
		};

		$scope.Init();

		$scope.SaveContactTypeParameter = function () {
			$scope.successMessage = "";
			parametersService.SaveParameterByKey("contactTypeDisplayedOnHomePage", $scope.parameter.Value, function (data, status) {
				if (status !== null) {
					if (status === 400)
						$scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
				}else {
					$scope.successMessage = $rootScope.editableObjectsFactory.EditableObjects.EditorialContent_Contacts_SuccessMessage.Content;
				}
			});
		};
	}]);
