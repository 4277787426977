AgrimarketDirectives.directive('tooltipAutoSize', function () {
    return function ($scope, elem, iAttrs) {

        // Ajouter le paramètre tooltip-auto-size-disable-watch="true" si la page est chargé d'un coup (pas d'appel ajax), car l'ajout des watchs sur le ng-repeat ralenti beaucoup l'affichage de la page
        if (!iAttrs.tooltipAutoSizeDisableWatch || iAttrs.tooltipAutoSizeDisableWatch !== 'true') {
            $scope.$watch('ngRepeat', function () {
                $('.actions .actions_list').hide().css({ 'right': 0 });
            });
        }

        var $tooltip;

        // Initialisation variable + fonction pour choper la position du curseur à l'entrée de la souris
        var cursorPosition = 0;
        var _listener = function (event) {
            cursorPosition = event.clientY;
        };

        elem.hover(function () {
            $tooltip = this.querySelector('ul.actions_list');

            if ($tooltip) {
                $tooltip.style.display = 'block';

                // Ajout d'un listener pour choper la position
                document.addEventListener("mousemove", _listener(event), true);

                // Calcul de la position du curseur par rapport au bouton positionCurseur - positionDeL'element - 25 pour remonter au milieu.
                var positionInDiv = cursorPosition - this.getBoundingClientRect().top - 25;

                var scrollTop = window.scrollY ? window.scrollY : 0;
                var elemPos = getOffset(this).top - scrollTop;
                var elemWidth = this.offsetWidth;
                var elemHeight = this.offsetHeight;
                var tooltipHeight = $tooltip.offsetHeight;
                var ratio = window.innerHeight - elemPos;

                var offsetop = getOffset(this).top;
                var windowHeight = window.innerHeight;
                var result = windowHeight - (offsetop - window.pageYOffset);

                if (result < tooltipHeight) {
                    $tooltip.style.top = (-tooltipHeight + elemHeight) + 'px';
                    $tooltip.style.right = elemWidth + 'px';
                } else { // utilisation de al position du curseur dans la div si la div est assez grande
                    $tooltip.style.top = this.offsetHeight > 150 && positionInDiv > 0 ? positionInDiv + 'px' : 0;
                    $tooltip.style.right = elemWidth + 'px';
                }
            }
        }, function () {
            $tooltip = this.querySelector('ul.actions_list');

            if ($tooltip) { // Suppression du listener
                document.removeEventListener("click", _listener, true);
                $tooltip.style.display = 'none';
            }
        });

        function getOffset(el) {
            var _x = 0;
            var _y = 0;

            while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
                _x += el.offsetLeft - el.scrollLeft;
                _y += el.offsetTop - el.scrollTop;
                el = el.offsetParent;
            }
            return { top: _y, left: _x };
        }
    };
});