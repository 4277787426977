//Permet d'afficher le timer coté agri
AgrimarketDirectives.directive('counter', function () {

    //@Param : timer => temps renseigné par l'admin
    //@Param : counterType => le type de forme du compteur {value : 'Circular', 'Linear'}
    //@Param : counterCicularSize => valeur de l'arc du compteur, penser à modifier la fontSize en conséqence
    //@Param : strokeWidth => valeur du contour du compteur
    //@Param : strokeWidthBg => valeur du contour du fond du compteur
    //@Param : strokeWidthBg => valeur du contour du fond du compteur
    //@Param : showShadow => permet d'appliquer un effet de style ombré au compteur
    //@Param : showRays => permet d'appliquer un effet des rayons sur le compteur
    //@Param : colorRays => couleur appliquée aux rayons (couleur correspondante au fond de la page)
    //@Param : fontSize => taille de la police à adapter selon les dimensions renseignées pour le compteur circulaire 
    //@Param : fontColor => couleur de la police
    //@Param : colorCounter => couleur du compteur
    //@Param : colorCounterBg => couleur de fond du compteur
    //@Param : colorCounterHalf => couleur appliquée à la moitié du compteur
    //@Param : colorCounterThird => couleur appliquée au dernier tiers du compteur

    return {
        restrict: 'EA',
        scope: {
            timer: '=timer',
            // pause
            pause: '=pause',
            // Counter style
            counterType: '=counterType',
            counterCicularSize: '=counterCicularSize',
            counterLinearWidth: '=counterLinearWidth',
            strokeWidth: '=strokeWidth',
            strokeWidthBg: '=strokeWidthBg',
            showShadow: '=showShadow',
            showRays: '=showRays',
            colorRays: '=colorRays',
            // Typo
            fontSize: '=fontSize',
            fontColor: '=fontColor',
            // Counter colors
            colorCounter: '=colorCounter',
            colorCounterBg: '=colorCounterBg',
            colorCounterHalf: '=colorCounterHalf',
            colorCounterThird: '=colorCounterThird'
        },
        link: function (scope, element, attrs) {
            jQuery(function () {

                /*
                    VARS
                */
                var
                    // COMMONS OPTIONS
                    // Typo param
                    fontWeight = 'bold',

                    // Rays param
                    nbRays = 12,
                    sizeRays = 2,

                    // Shadow param
                    colorShadow = '#212121',
                    widthShadow = 3,
                    offsetXShadow = 1, // Toujours positif !
                    offsetYShadow = 2, // Toujours positif !

                    // Counter dimensions | DON'T TOUCH !!!					
                    counterSize = (scope.counterCicularSize * 2) + scope.strokeWidthBg,
                    canvasWidth = (scope.counterCicularSize * 2) + scope.strokeWidthBg,
                    canvasHeight = (scope.counterCicularSize * 2) + scope.strokeWidthBg;

                // Counter dimensions | DON'T TOUCH !!!				
                if (scope.showShadow && scope.counterType !== 'linear') {
                    canvasWidth = (scope.counterCicularSize * 2) + scope.strokeWidthBg + offsetXShadow + widthShadow;
                    canvasHeight = (scope.counterCicularSize * 2) + scope.strokeWidthBg + offsetYShadow + widthShadow;
                }

                // LINEAR OPTIONS								
                var counterPosition = 'horizontal', // Horizontal | TO DO : Vertical
                    tooltipWidth = 48,
                    tooltipHeight = 25,
                    tooltipFontSize = 14,
                    tooltipColor = '#f1f1f1',
                    tooltipMargin = 8,
                    lineHeight = tooltipHeight + scope.strokeWidthBg + tooltipMargin;

                // Counter dimensions | DON'T TOUCH !!!				
                if (scope.counterType === 'linear') {
                    canvasWidth = scope.counterLinearWidth;
                    canvasHeight = lineHeight;
                    counterSize = scope.counterLinearWidth;
                }

                // Timer config | DON'T TOUCH !!!
                var minutes = scope.timer.substring(0, 2),
                    secondes = scope.timer.substring(3, 5),
                    totalSeconds = (parseInt(minutes) * 60) + parseInt(secondes),
                    tiers = totalSeconds / 3;

                /*
                    END VARS
               */

                // Draw the context
                var counter = Raphael(attrs.id, canvasWidth, canvasHeight);

                // Init counter elems				
                if (scope.counterType === 'linear') {
                    linear();
                } else {
                    circular();
                }

                ////////////////////////////////////////////////////////////////////////////////
                //     						CIRCULAR COUNTER								  //
                ////////////////////////////////////////////////////////////////////////////////								
                function circular() {

                    // Custom Arc Attribute, position x&y, value portion of total, total value, Radius								
                    counter.customAttributes.arc = function (xloc, yloc, value, total, r) {
                        var alpha = 360 / total * value,
                            a = (90 - alpha) * Math.PI / 180,
                            x = xloc + r * Math.cos(a),
                            y = yloc - r * Math.sin(a),
                            path;

                        if (total == value) {
                            path = [
                                ["M", xloc, yloc - r],
                                ["A", r, r, 0, 1, 1, xloc - 0.01, yloc - r]
                            ];
                        } else {
                            path = [
                                ["M", xloc, yloc - r],
                                ["A", r, r, 0, +(alpha > 180), 1, x, y]
                            ];
                        }
                        return {
                            path: path
                        };
                    };

                    // Function to create circular rays

                    Raphael.fn.MyRay = function (topWidth, bottomWidth, nbOccurence) {
                        var xLeftTop = counterSize / 2;
                        var yLeftTop = 0;
                        var yLeftBottom = scope.strokeWidthBg;
                        var nb = nbOccurence; // Nb of rays
                        var path = 'M' + (xLeftTop - (topWidth / 2)) + ',' + yLeftTop + 'L' + (xLeftTop + (topWidth / 2)) + ',' + yLeftTop + 'L' + (xLeftTop + (bottomWidth / 2)) + ',' + yLeftBottom + 'L' + (xLeftTop - (topWidth / 2)) + ',' + yLeftBottom + 'L' + (xLeftTop - (topWidth / 2)) + ',' + yLeftTop + 'Z';

                        // Loop for each ray to create
                        for (var i = 0; i < nb; i++) {

                            // Create the path, add attributes and rotation
                            this.pathObj = counter.path(path)
                                .attr({
                                    'fill': scope.colorRays,
                                    'stroke': 'transparent',
                                    'stoke-width': 0
                                }).rotate((360 / nb) * i, xLeftTop, counterSize / 2);
                        }
                        return this;
                    };

                    drawCircular();
                }

                function drawCircular() {

                    // Create background and shadow
                    var myBgArc = counter.path().attr({
                        'stroke': scope.colorCounterBg,
                        'stroke-width': scope.strokeWidthBg,
                        arc: [scope.counterCicularSize + (scope.strokeWidthBg / 2) + (widthShadow / 2), scope.counterCicularSize + (scope.strokeWidthBg / 2), 100, 100, scope.counterCicularSize]
                    });

                    if (scope.showShadow) {
                        myBgArc.glow({ color: colorShadow, width: widthShadow, offsetx: offsetXShadow, offsety: offsetYShadow });
                    }

                    // Create timer
                    var myArc = counter.path().attr({
                        'stroke': scope.colorCounter,
                        'stroke-width': scope.strokeWidth,
                        arc: [scope.counterCicularSize + (scope.strokeWidthBg / 2) + (widthShadow / 2), scope.counterCicularSize + (scope.strokeWidthBg / 2), 100, 100, scope.counterCicularSize]
                    });


                    // Insert counter text
                    count = counter.text(counterSize / 2 + (widthShadow / 2), counterSize / 2, pad(parseInt(totalSeconds / 60)) + ':' + pad(totalSeconds % 60))
                        .attr({
                            'fill': scope.fontColor,
                            'font-size': scope.fontSize + 'px',
                            'line-height': scope.fontSize + 'px',
                            'font-weight': fontWeight
                        });

                    // Create rays
                    if (scope.showRays) {
                        var rays = counter.MyRay(sizeRays, sizeRays, nbRays);
                    }

                    animateCircular(myArc, myBgArc, count);
                }

                function animateCircular(obj1, obj2, obj3) {
                    var myArc = obj1,
                        myBgArc = obj2,
                        count = obj3;

                    // Pause watcher
                    scope.$watch('pause', function () {
                        if (this.last) {
                            // On stoppe le déplacement de l'arc
                            myArc.stop();
                            // On stoppe les animations de couleur
                            clearInterval(timerHalfCirc);
                            clearInterval(timerThirdCirc);
                            // On stoppe le décompte
                            clearInterval(textCirc);

                        } else {

                            // Animate counter text
                            textCirc = setInterval(setTimeCircular, 1000);

                            // Anim color => 1/2
                            timerHalfCirc = setInterval(setTimeHalfTimerAnimCirc, ((totalSeconds * 1000) / 4) * 2);

                            // Anim color => 2/3
                            timerThirdCirc = setInterval(setTimeThirdTimerAnimCirc, ((totalSeconds * 1000) / 3) * 2);

                            // Timer animation
                            myArc.animate({
                                arc: [scope.counterCicularSize + (scope.strokeWidthBg / 2) + (widthShadow / 2), scope.counterCicularSize + (scope.strokeWidthBg / 2), 0, 100, scope.counterCicularSize]

                            }, (totalSeconds * 1000), 'linear');

                        }

                        function setTimeHalfTimerAnimCirc() {
                            myArc.animate({ 'stroke': scope.colorCounterHalf }, 1000, 'linear');
                        }

                        function setTimeThirdTimerAnimCirc() {
                            myArc.animate({ 'stroke': scope.colorCounterThird }, 1000, 'linear');
                        }

                        // Fn for circular counter
                        function setTimeCircular() {

                            --totalSeconds;

                            count.attr({ 'text': pad(parseInt(totalSeconds / 60)) + ':' + pad(totalSeconds % 60) });

                            // Blink animation on last third
                            if (totalSeconds > 0 && totalSeconds <= tiers) {
                                count.animate({ 'opacity': 1 }, 35, 'linear', function () {
                                    this.animate({ 'opacity': 0 }, 500, 'linear');
                                });
                            } else {
                                count.animate({ 'opacity': 1 }, 35, 'linear');
                            }
                        }


                    });
                }

                ////////////////////////////////////////////////////////////////////////////////
                //     						LINEAR COUNTER									  //
                ////////////////////////////////////////////////////////////////////////////////
                function linear() {

                    // Custom Line Attribute, position x&y, value portion of total, total value, Radius, Position								
                    counter.customAttributes.line = function (xloc, yloc, value, total, position) {
                        var posx = canvasWidth - (tooltipWidth / 2),
                            alpha = posx / total * value,
                            path;

                        if (total === value) {

                            path = [
                                ["M", posx, yloc],
                                ["L", xloc, yloc]
                            ];
                        } else {

                            path = [
                                ["M", alpha, yloc],
                                ["L", xloc, yloc]
                            ];
                        }

                        return {
                            path: path
                        };
                    };

                    // Custom LineBloc Attribute, position x&y, value portion of total, total value, Radius, Position								
                    counter.customAttributes.lineBloc = function (xloc, yloc, value, total, position) {
                        var posx = canvasWidth - tooltipWidth,
                            alpha = posx / total * value,
                            path;

                        if (total === value) {

                            path = [
                                ["M", xloc, yloc],
                                ["L", xloc + tooltipWidth, yloc],
                                ["L", xloc + tooltipWidth, tooltipHeight - 4],
                                ["L", xloc + (tooltipWidth / 2) - 4, tooltipHeight - 4],
                                ["L", xloc + (tooltipWidth / 2), tooltipHeight],
                                ["L", xloc + (tooltipWidth / 2) + 4, tooltipHeight - 4],
                                ["L", xloc, tooltipHeight - 4],
                                ["L", xloc, yloc]
                            ];

                        } else {

                            path = [
                                ["M", alpha, yloc],
                                ["L", alpha + tooltipWidth, yloc],
                                ["L", alpha + tooltipWidth, tooltipHeight - 4],
                                ["L", alpha + ((tooltipWidth / 2) - 4), tooltipHeight - 4],
                                ["L", alpha + (tooltipWidth / 2), tooltipHeight],
                                ["L", alpha + ((tooltipWidth / 2) + 4), tooltipHeight - 4],
                                ["L", alpha, tooltipHeight - 4],
                                ["L", alpha, yloc]
                            ];
                        }

                        return {
                            path: path
                        };
                    };

                    // Function to create linear rays
                    Raphael.fn.MyLinearRay = function (topWidth, bottomWidth, nbOccurence) {
                        var xLeftTop = tooltipWidth / 2;
                        var yLeftTop = tooltipHeight + tooltipMargin;
                        var yLeftBottom = yLeftTop + scope.strokeWidthBg;
                        var nb = nbOccurence; // Nb of rays
                        var path = 'M' + xLeftTop + ',' + yLeftTop + 'L' + (xLeftTop + topWidth) + ',' + yLeftTop + 'L' + (xLeftTop + topWidth) + ',' + yLeftBottom + 'L' + xLeftTop + ',' + yLeftBottom + 'Z';

                        // Loop for each ray to create
                        for (var i = 0; i < nb; i++) {

                            // Create the path, add attributes and rotation
                            this.pathObj = counter.path(path)
                                .attr({
                                    'fill': scope.colorRays,
                                    'stroke': 'transparent',
                                    'stoke-width': 0
                                }).translate((counterSize / nb) * i, 0);
                        }
                        return this;
                    };

                    drawLinear();
                }

                function drawLinear() {
                    // Create timer
                    var myLine = counter.path().attr({
                        'stroke': scope.colorCounter,
                        'stroke-width': scope.strokeWidth,
                        line: [tooltipWidth / 2, tooltipHeight + tooltipMargin + (scope.strokeWidth / 2), 100, 100, counterPosition]
                    });

                    // Create mask
                    var myMaskLine = counter.path().attr({
                        'stroke': scope.colorCounterBg,
                        'stroke-width': scope.strokeWidthBg,
                        line: [tooltipWidth / 2, tooltipHeight + tooltipMargin + (scope.strokeWidthBg / 2), ((tooltipWidth / 2) / canvasWidth) * 100, 100, counterPosition]
                    });

                    // Create container
                    myBloc = counter.path().attr({
                        'fill': tooltipColor,
                        'stroke-width': 0,
                        'stroke': '#bcbcbc',
                        lineBloc: [canvasWidth - (tooltipWidth), 0, 0, 100, counterPosition]
                    });

                    // Create counter
                    myCount = counter.text(5, 12, pad(parseInt(totalSeconds / 60)) + ':' + pad(totalSeconds % 60))
                        .attr({
                            'fill': scope.fontColor,
                            'font-size': tooltipFontSize + 'px',
                            'line-height': tooltipFontSize + 'px',
                            'font-weight': fontWeight,
                            'text-anchor': 'start'
                        });

                    // Create rays
                    if (scope.showRays) {
                        var myLinearRay = counter.MyLinearRay(sizeRays, sizeRays, nbRays);
                    }

                    animateLinear(myLine, myMaskLine, myCount);
                }

                function animateLinear(obj1, obj2, obj3) {
                    var myLine = obj1,
                       myMaskLine = obj2;
                    myCount = obj3;


                    // Pause watcher
                    scope.$watch('pause', function () {

                        if (this.last) {
                            // On stoppe le déplacement de la ligne
                            myMaskLine.stop();
                            // On stoppe les animations de couleur
                            clearInterval(timerHalfLinear);
                            clearInterval(timerThirdLinear);
                            // On stoppe le bloc
                            myBloc.stop();
                            myCount.stop();
                            // On stoppe le décompte
                            clearInterval(textLinear);

                        } else {

                            // Create a group 
                            group = counter.set();

                            // Add elements in group
                            group.push(myBloc);
                            group.push(myCount);

                            textLinear = setInterval(setTimeLinear, 1000);

                            // Anim color => 1/2
                            timerHalfLinear = setInterval(setTimetimerHalfLinear, ((totalSeconds * 1000) / 4) * 2);
                            // Anim color => 2/3
                            timerThirdLinear = setInterval(setTimetimerThirdLinear, ((totalSeconds * 1000) / 3) * 2);

                            // Timer animation
                            myMaskLine.animate({
                                line: [tooltipWidth / 2, tooltipHeight + tooltipMargin + (scope.strokeWidthBg / 2), 100, 100, counterPosition]
                            }, (totalSeconds * 1000), 'linear');

                            //Translate animation
                            myCount.animate({ 'x': (canvasWidth + 5) - (tooltipWidth) }, (totalSeconds * 1000), 'linear');

                            // Timer animation
                            myBloc.animate({
                                lineBloc: [canvasWidth - (tooltipWidth), 0, 100, 100, counterPosition]
                            }, (totalSeconds * 1000), 'linear');
                        }

                        function setTimetimerHalfLinear() {
                            myLine.animate({ 'stroke': scope.colorCounterHalf }, 1000, 'linear');
                        }
                        function setTimetimerThirdLinear() {
                            myLine.animate({ 'stroke': scope.colorCounterThird }, 1000, 'linear');
                        }

                        // Fn for linear counter (animate group)
                        function setTimeLinear() {

                            --totalSeconds;

                            myCount.attr({ 'text': pad(parseInt(totalSeconds / 60)) + ':' + pad(totalSeconds % 60) });

                            // Blink animation on last third
                            if (totalSeconds > 0 && totalSeconds <= tiers) {
                                group.animate({ 'opacity': 1 }, 35, 'linear', function () {
                                    this.animate({ 'opacity': 0 }, 500, 'linear');
                                });
                            } else {
                                group.animate({ 'opacity': 1 }, 35, 'linear');
                            }
                        }

                    });

                }

                ////////////////////////////////////////////////////////////////////////////////
                //     							COMMON										  //
                ////////////////////////////////////////////////////////////////////////////////				

                function pad(val) {
                    var valString = val + '';

                    if (val >= 0) {
                        if (valString.length < 2) {
                            return '0' + valString;
                        } else {
                            return valString;
                        }
                    } else {
                        return '00';
                    }
                }
            });
        }
    };
});