AgrimarketServices.service('alertMailService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null);
                })
                .error(function (data, status) {
                    callback(data, status);
                });
        };

        this.GetAlertMailsPaginate = function (isTabAlertMailsEnabled, idGroupContact, itemPerPage, pageNo, sortKey, reverse, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'AlertMail/GetAlertMails',
                params: {
                    isTabAlertMailsEnabled: isTabAlertMailsEnabled,
                    idGroupContact: idGroupContact,
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse
                }
            };
            SendRequest(config, callback);
        };

        this.EditAlertMailState = function (alertMailAction, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'AlertMail/EditAlertMailState',
                data: alertMailAction
            };
            SendRequest(config, callback);
        };

        this.DeleteAlertMail = function (idAlertMail, callback) {
            var config = {
                method: 'DELETE',
                url: configurationFactory.Url['Api'] + 'AlertMail/DeleteAlertMail',
                params: { idAlertMail: idAlertMail }
            };
            SendRequest(config, callback);
        };

        this.GetAlertMailById = function (idAlertMail, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'AlertMail/GetAlertMailById',
                params: { idAlertMail: idAlertMail }
            };
            SendRequest(config, callback);
        };

        this.GetTypeSends = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'AlertMail/GetTypeSends',
            };
            SendRequest(config, callback);
        };

        this.GetDaysOfWeek = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'AlertMail/GetDaysOfWeek',
            };
            SendRequest(config, callback);
        };

        this.GetWeekNumbers = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'AlertMail/GetWeekNumbers',
            };
            SendRequest(config, callback);
        };

        this.CheckStep = function (alertMailAction, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'AlertMail/CheckStep',
                data: alertMailAction
            };
            SendRequest(config, callback);
        };

        this.SaveAlertMail = function (alertMailAction, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'AlertMail/Save',
                data: alertMailAction
            };
            SendRequest(config, callback);
        };
}]);