AgrimarketServices.service('uploadService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
	function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

	    var SendRequest = function (configfile, callback) {
	        $http(configfile)
				.success(function (data) {
				    callback(data, null);
				})
				.error(function (data, status) {
				    callback(data, status);
				});
	    };

	    this.DeleteUpload = function (idAttachment, callback) {
	        var config = {
	            method: 'DELETE',
	            url: configurationFactory.Url['Api'] + 'Upload/DeleteUpload',
	            params: { idAttachment: idAttachment }
	        };
	        SendRequest(config, callback);

	    };

	    this.GetFileName = function (p, callback) {
	        var config = {
	            method: 'GET',
	            url: configurationFactory.Url['Api'] + 'Upload/GetFileName',
	            params: { p : p}

	    };
	        SendRequest(config, callback);
	    };
	}]);