// Gestion des filtres multi-sélection
AgrimarketDirectives.directive('multiSelect', function () {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            label: '=label'
        },
        template: '<div class="selectBox" data-help-tooltip="{{label}}" data-title="{{label}}" data-label-base="{{label}}">' +
                        '<ul class="checkboxes" ng-transclude>' +
                        '</ul>' +
                    '</div>',
        link: function(scope, element, attrs) {
            jQuery(function() {
                var el = '.selectBox';

                $(el).unbind();
                $(el).bind('click', function(e) {

                    if ($(this).hasClass('deploy')) {
                        $(el).removeClass('deploy');
                    } else {
                        $(el).removeClass('deploy');
                        $(this).addClass('deploy');
                    }

                    e.stopPropagation();     
                    
                });

                $(document).bind('click', function closeTooltip(e) {
                    if ($(el).has(e.target).length === 0) {
                        $(el).removeClass('deploy');
                    } else if ($(el).hasClass('deploy')) {
                        $(document).one('click', closeTooltip);
                    }
                    e.stopPropagation();
                });

            });

        }
    };
});