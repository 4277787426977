AgrimarketControllers.controller('testsocketController', [
	'$scope', '$rootScope', '$routeParams', '$http', '$filter', '$location', 'testsocketService',
	function ($scope, $rootScope, $routeParams, $http, $filter, $location, testsocketService) {

	    var actionToOrder = $routeParams.ActionToOrder ? $routeParams.ActionToOrder : 1;
	    $scope.ErrorSocketMessage = "";
	    $scope.SuccesSocketMessage = "";

	    var Init = function() {
	        testsocketService.SendAthenaOrder(actionToOrder, function(data, status) {
	                if (status !== null) {
	                    if (status == 400) {
	                        $scope.ErrorSocketMessage = data[0].errors[0].Message;
	                        $rootScope.loading = false;
	                    } 
	                } else {
	                    $scope.SuccesSocketMessage = data;
	                    $rootScope.loading = false;
	                }
	            }
	        );
	    };

	    Init();
	}
]);