AgrimarketServices.service('tagsService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null, null, null);
                })
                .error(function (data, status, headers, config) {
                    callback(data, status, headers, config);
                });
        };
     
        this.GetTagsForTemplatesMails = function (idTypeTemplateMail, callback) {
        	var config = {
        		method: 'GET',
        		url: configurationFactory.Url['Api'] + 'Tag/GetTagsForTemplatesMails',
        		params: { idTypeTemplateMail: idTypeTemplateMail }
        	};
        	SendRequest(config, callback);
        };

        this.GetTagsByIdTypeTemplate = function (idTypeTemplate, callback) {
        	var config = {
        		method: 'GET',
        		url: configurationFactory.Url['Api'] + 'Tag/GetTagsByIdTypeTemplate',
        		params: { idTypeTemplate: idTypeTemplate }
        	};
        	SendRequest(config, callback);
        };
    }]);