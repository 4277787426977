AgrimarketServices.service('templateMailsService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null);
                })
                .error(function (data, status) {
                    callback(data, status);
                });
        };

        this.GetAllTypeTemplateMails = function (idLanguage, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'TypeTemplateMail/GetAllTypeTemplateMails',
                params: { idLanguage: idLanguage }
            };
            SendRequest(config, callback);
        };

        this.SaveTemplateMail = function (template, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'TemplateMail/SaveTemplateMail',
                data: template
            };
            SendRequest(config, callback);
        };

        this.GetTemplateByIdTypeTemplateMailAndIdRoleAndIdLanguage = function (idTypeTemplateMail, idRole, idLanguage, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'TemplateMail/GetTemplateByIdTypeTemplateMailAndIdRoleAndIdLanguage',
                params: { idTypeTemplateMail: idTypeTemplateMail, idRole: idRole, idLanguage : idLanguage }
            };
            SendRequest(config, callback);
        };

        this.SaveConfigMails = function (templateMailsVm, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'TypeTemplateMail/SaveConfigMails',
                data: templateMailsVm
            };
            SendRequest(config, callback);
        };
    }]);